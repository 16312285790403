import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import Api from '../../RestApis/Api';
import { MyContext } from "../../context";
import { getdateformat } from "../../utils/dateFormatterForTI/dateFormatter";
import { formatDate, getLinkElement } from '../../utils/math';
import { fetchTeXCompiledCaption, modifyHref, modifyWebsiteName } from '../../utils/post/manageCaptions';
import { addToRecents, seeLessLikeThis, seeMoreLikeThis } from '../../utils/profiles/manageProfilesList';
import { ReactComponent as TimelineSvg } from '../../utils/svg/Bidirectionally-traversed-timeline.svg';
import { ReactComponent as CalendarSvg } from '../../utils/svg/Calendar.svg';
import { ReactComponent as DepartSvg } from '../../utils/svg/Depart.svg';
import { ReactComponent as FilterSvg } from '../../utils/svg/Filter.svg';
import { ReactComponent as BlockNavigation } from '../../utils/svg/Forward-slash.svg';
import { ReactComponent as RevisitSvg } from '../../utils/svg/Revisit.svg';
import {
   getActiveDatesOfTheMonth, handleSubCalendarData, determineActiveDateForCurrentMonth,
   unsetTimetableData, shiftMonthsBackward, shiftMonthsForward, addNextAndPrevDates, updateVisibilityOfSubTimetables
} from "../../utils/timetableHelper/timetableHelper";
import { determineAndUpdateActiveDatesForTimetables } from "../../utils/timetableHelper/timetableActiveDatesUpdater";
import Footer from '../Footer';
import GuestFooter from '../GuestFooter';
import Timetable from "../Timetable/Timetable";
import CustomVideoPlayer from "../customVideoPlayer/customVideoPlayer";
import { handleClickOfProfiles } from "../../utils/profiles/clickHandler";
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Users extends React.Component {
   static contextType = MyContext
   timeoutId = null;
   constructor(props) {
      super(props);
      let UserName = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      UserName = params[params.length - 1];
      this.state = {
         Username: UserName,
         randColor: {},
         c_userid: "",
         cemail: "",
         cscreenname: "",
         ctotalpost: "",
         cusername: "",
         cfollowers: "",
         crequest: "",
         cbio: "",
         cfollowing: "",
         cprofileCover: "",
         cprofileImage: "",
         cuserid: "",
         ctoken: "",
         cmutual: "",
         cwebsite: "",
         schedule: "",
         c_isFollowing: 0,
         follower: 0,
         c_isRequested: 0,
         c_make_public: "",
         searchinguser: [],
         searchPerameter: "",
         otherMarks: [],
         other_minimum: "",
         other_maximum: "",
         NextO: "",
         PreviousO: "",
         profilecheckOther: "",
         valueCalendarOther: new Date(),
         otherProfiledata: [],
         slectedVar: "",
         evntData: [],
         contat: "of",
         topcontat: "of",
         baseUrl: process.env.REACT_APP_BASE_URL,
         baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
         profilecheckOther: "",
         evtId: "",
         time: false,
         mobi: 0,
         other_monthyear: [],
         umid: "",
         hasMore: true,
         profileType: '',
         seeLessPage: 1,
         seeMorePage: 1,
         pathname: this.props.location.pathname,
         clickedButtonToExit: "",
         activeTab: "",
         leftSideArray: [],
         rightSideArray: [],
         currentMonthYear: "",
         monthYearDateMap: {},
         monthYearShortAndFull: {},
         preventNavigation: false,
         shouldUpdateOnSamePath: false,
         userDetails: {},
         timetableData: {
            threeMonthsEarlier: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            twoMonthsEarlier: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            previous: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            current: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            next: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            twoMonthsAhead: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            threeMonthsAhead: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            }
         },
         intermediateTimetableData: {},
         seeMoreSeeLessPopup: false,
         isProfileUpdatedInContext: false,
         updateTemporalInformation: false,
         earliestDatesByYear: null,
         screenWidth: window.innerWidth,
      };
      this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      this.child = React.createRef();
      this.calendarRef = React.createRef();
      this.blockuserpop = this.blockuserpop.bind(this);
      this.handleResize = this.handleResize.bind(this);
      this.footerPropsForUpdateOnSamePath = {
         isParentUserProfile:true ,
         setShouldUpdateOnSamePath:this.setShouldUpdateOnSamePath
      }
   }
   componentDidUpdate(prevProps,prevState) {
      const { appContext } = this.props;
      const { state } = appContext;
      // check when the homepage status will update
      if(prevProps.appContext.state !== state) {
         this.checkBackNavigation();
      }

      // if (prevProps.location.pathname !== this.props.location.pathname) {
      //    this.setState({ pathname: this.props.location.pathname }); // update the latest pathname
      // }

      if (this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== "" && this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== undefined) {
         if (this.state.Username !== this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]) {
            var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
            this.setState({ randColor: random_color, Username: this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] });
         }
      }
      const { activeTab, sharedData} = this.context
      if (this.state.shouldUpdateOnSamePath) {
         if (activeTab !== prevState.activeTab)
            this.setState({ activeTab })

         this.props.setVisitedPaths([...sharedData.tabWiseData[activeTab].openProfiles])
         this.checkBackNavigation();
         this.setShouldUpdateOnSamePath(false)
      }
   }
   handleMouseOver() {
      $(".katexContentBlock .katex a").attr("target", "_blank");
      $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
   }
   componentDidMount() {
      document.addEventListener('mouseover', this.handleMouseOver);
      document.addEventListener('click', this.handleClickOfUsers);
      window.addEventListener('resize', this.handleResize);

      localStorage.setItem("footer", "second");
      const { sharedData, setSharedData, activeTab } = this.context

      if (sharedData) {
         if (sharedData.pageWiseData[this.state.pathname]) {
            this.setState({ ...sharedData.pageWiseData[this.state.pathname], screenWidth: window.innerWidth });
            return;
         }

         this.setState({ seeMorePage: 1, pathname: this.props.location.pathname, clickedButtonToExit: "" })
      }

      this.setState({ activeTab: activeTab, preventNavigation: false });

      $("#one_4 .upbutton").click(function () {
         $("#one_4 .pupup2").toggle();
      });
      $("#one_4 .upbutton2").click(function () {
         $("#one_4 .pupup2").toggle();
      });
      $("#one_4 .errorbtn").click(function () {
         $("#one_4 .errorpop").toggle();
      });
      $("#one_4 .remove").click(function () {
         $("#one_4 .pupup3").toggle();
      });
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      this.setState({ randColor: random_color }, () => {
         //this.mainProfileColor();
      })
      this.setfunction(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]);

      // to check and prevent the back navigation when home page is already active somewhere
      this.checkBackNavigation();
   }

   handleResize() {
      this.setState({ screenWidth: window.innerWidth });
   }

   handleClickOfUsers = (event) => {
      handleClickOfProfiles(event, this.props, this.props.setVisitedPaths);
   };

   updateSharedDataInContext() {
      const { sharedData, setSharedData } = this.context
      this.updateOpenedProfilesInContext();

      sharedData.pageWiseData[this.state.pathname] = this.state
      
      setSharedData({
         ...sharedData,
         lastViewedUser: this.state,
      });
   }

   updateOpenedProfilesInContext() {
      if (this.state.isProfileUpdatedInContext)
         return;

      const { appContext } = this.props;
      const {  actions } = appContext;
      const { setLastOpenedProfile } = actions;

      setLastOpenedProfile((prevLastOpenedProfile) => {
         const currentUserId = this.state.c_userid;

         const filteredProfiles = prevLastOpenedProfile.filter(
            (profile) => !profile[currentUserId]
         );

         const updatedProfiles = [
            ...filteredProfiles,
            {
               [currentUserId]: {
                  userId: currentUserId,
                  events: this.state.otherMarks,
                  monthYearDateMap: this.state.monthYearDateMap,
                  monthYear: this.state.other_monthyear,
                  userData: this.state.userDetails,
                  timetableData: this.state.timetableData['current'],
               },
            },
         ];

         return updatedProfiles;
      });

      this.setState({isProfileUpdatedInContext: true });
   }


   /**
    * 
    * @param {string} buttonName 
    */
   updateClickedButtonToExit(buttonName) {
      this.updateOpenProfilesOnExit(buttonName);
      this.setState({ clickedButtonToExit: buttonName })
   }
   /**
    * 
    * @param {string} buttonName 
    */
   updateOpenProfilesOnExit(buttonName) {
      const { sharedData, setSharedData } = this.context;
      const activeTab = this.state.activeTab
      let openProfiles = [];
      let activeTabData = sharedData.tabWiseData[activeTab];
      
      if (activeTabData && activeTabData.openProfiles)
         openProfiles = activeTabData.openProfiles;

      if (buttonName == activeTab) {
         if (activeTab !== 'second' || !this.props.appContext.state.homePageActive.status)
            openProfiles = [];            
      } else if (buttonName == "back" && openProfiles && openProfiles[openProfiles.length - 1] == this.state.pathname) {
         openProfiles.pop();
      } else if (buttonName != "back" && openProfiles && openProfiles[openProfiles.length - 1] != this.state.pathname) {
         openProfiles.push(this.state.pathname);
      }

      sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles };

      setSharedData({
         ...sharedData,
      });
   }

   checkBackNavigation() {
      const { activeTab } = this.context
      if(activeTab === 'second') {
         const { sharedData } = this.context;
         let activeTabData = sharedData.tabWiseData[activeTab]?.openProfiles;
         const { appContext } = this.props;
         const { state } = appContext;
         if(activeTabData?.length === 1 && state.homePageActive.status) {
            this.setState({ preventNavigation: true });
         } else {
            this.setState({ preventNavigation: false });
         }
      } else {
         this.setState({ preventNavigation: false });
      }
   }

   getUsernameFromPath() {
      return this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]
   }

   mainProfileColor() {

      $(".username, .usernamecl").css("color", "");
      $(".username, .usernamecl").css("color", this.state.randColor.textColor);
      $(".username, .usernamecl").css("background-color", "");
      $(".username, .usernamecl").css("background-image", `linear-gradient(${this.state.randColor.firstColor}, ${this.state.randColor.secondColor})`);
   }
   clickpop() {
      $("#one_4 .errorpop2").toggle();
   }
   setfunction(name) {
      // this.setState({otherProfiledata:[],c_make_public:"",valueCalendarOther:"",time:false,mobi:0},()=>
      // {
      const token = localStorage.getItem('token');
      if (localStorage.getItem('username') == name) {
         var id = localStorage.getItem('userid');
         this.Nextfunction(id, token);
      }
      else {
         let url = '';
         if (token) {
            url = this.state.baseUrl + '/api/user/search.php?exact_match=1&search=' + name;

         } else {
            url = this.state.baseUrl + '/api/user/Gusearch.php?exact_match=1&search=' + name;
         }
         fetch(url, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         }).then((response) => response.json())
            .then((responseJson) => {
               var id = responseJson.response[0].user_id;
               this.setState({ umid: id });
               if (token) {
                  Api.actions(id, 'follow');
               }
               this.Nextfunction(id, token);
            })

      }
      // })  
   }
   Nextfunction(id, token) {
      // let url = '';
      // if (token) {
        const url = this.state.baseUrlNode + '/api/getUserDetails?user_id=' + id;
      // } else {
      //    url = this.state.baseUrl + '/api/user/getUserDetailsGuest.php?user_id=' + id;
      // }
      // const post = this.state.savePost;
      // const userData = post.find((data) => data.user == id);
      const outputFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';
      fetch(url, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState(
               {
                  userDetails: responseJson.response,
                  cemail: responseJson.response.email,
                  cmutual: responseJson.response.mutual,
                  cscreenname: responseJson.response.screenName,
                  ctotalpost: responseJson.response.totalPost,
                  cusername: responseJson.response.username,
                  cfollowers: responseJson.response.followers,
                  crequest: responseJson.response.request,
                  cbio: responseJson.response.bio,
                  cfollowing: responseJson.response.following,
                  cprofileCover: responseJson.response.profileCover,
                  cprofileImage: responseJson.response.profileImage,
                  cuserid: responseJson.response.user_id,
                  c_userid: responseJson.response.user_id,
                  ctoken: responseJson.response.token,
                  cwebsite: responseJson.response.website,
                  schedule: responseJson.response.schedule,
                  c_isFollowing: responseJson.response.isFollowing,
                  c_isRequested: responseJson.response.isRequested,
                  time: true,
                  follower: responseJson.response.isFollowers,
                  c_make_public: responseJson.response.make_public,
                  mobi: 1,
                  profileType: responseJson.response.profileType,
               },
               () => {
                  var userObj = { ...responseJson.response };
                  addToRecents(userObj).then(() => this.child?.current?.componentDidMount())
                  // if (userData) {
                  //    const convertedDate = moment.tz(userData.date, 'DD-MM-YYYY', moment.tz.guess()).format(outputFormat);
                  //    this.setState({
                  //       otherProfiledata: userData.eventDetail, valueCalendarOther: new Date(convertedDate), profilecheckOther: userData.date,
                  //       otherMarks: userData.allEvents, other_maximum: userData.maxDate, other_minimum: userData.minDate, PreviousO: userData.previousMonthyear, NextO: userData.nextMonthYear,
                  //       other_monthyear: userData.otherMonthYear
                  //    });
                  //    return;
                  // } else {
                     this.otherUserEvent(id);
                  // }
               }
            );
         });
   }

   addTimeoutForDateChange = (callback) => {
      const now = new Date();
      const nextMidnight = new Date(
         now.getFullYear(),
         now.getMonth(),
         now.getDate() + 1,
         0, 0, 0, 0
      );

      const remainingTime = nextMidnight.getTime() - now.getTime();

      if (this.timeoutId) {
         clearTimeout(this.timeoutId);
      }

      this.setState({ updateTemporalInformation: true });

      this.timeoutId = setTimeout(() => {
         try {
            callback();
         } catch (error) {
            console.error('Error updating temporal information:', error);
         }
      }, remainingTime);

      return remainingTime;
   };

   updateTemporalInformation = () => {
      if (this.state.updateTemporalInformation) {
         this.setState({ updateTemporalInformation: false });
         this.addTimeoutForDateChange(this.updateTemporalInformation);
      }
   };

   fetchEvntList() {
      if(this.state.evntData.length >= 20) 
         return

      if (this.state.hasMore == true) {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         this.setState({ hasMore: true })
         setTimeout(() => {

            var date = Date.now() / 1000;
            var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + date;

            fetch(serverpath, {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "TimeZone": moment.tz.guess(),
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  const timeout = this.addTimeoutForDateChange(this.updateTemporalInformation);
                  const events = responseJson.response;
                  if (events.length < 20)
                     this.setState({ contat: 'on', topcontat: 'on', hasMore: false })

                  if (responseJson.message == "Event not available") {
                     $('#one_4 .loaderclass').removeClass('downscroll');
                     $('#one_4 .loaderclass').addClass('stopscroll');
                  }
                  if (events.length) {
                     this.setState({ evntData: events }, () => {
                        const centerEventIndex = Math.floor(events.length / 2)

                        const centerEventHashDivSelector = `#hashu${events[centerEventIndex].eventID}`
                        this.scrollTimelineToElementPosition(centerEventHashDivSelector);
                     })

                  }
               });
         }, 100);
      }
   }

   /**
    * will scroll the Infinite Scroll to *offsetTop* position of the element selected by given jquery selector 
    * 
    * @param {string} selector HTML Element selector 
    */
   scrollTimelineToElementPosition(selector) {
      $('#one_4 #scrollableDivOneFourProfile').scrollTop($(`#one_4 ${selector}`)[0]?.offsetTop - 10);
   }

   otherUserEvent(id) {
      const token = localStorage.getItem('token');
      let url = '';
      if (token) {
         url = this.state.baseUrl + '/api/event/profileEventDates.php?profileId=' + id;
      } else {
         url = this.state.baseUrl + '/api/event/profileEventDatesGuest.php?profileId=' + id;
      }
      fetch(url, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.response.length > 0) {
               this.setState({ otherMarks: responseJson.response.sort((a, b) => (moment(a.eventOn, 'DDMMYYYY') > moment(b.eventOn, 'DDMMYYYY')) ? 1 : -1).reverse() }, () => {
                  this.mapMonthYearToDate().then(() => this.updateMinMaxDateOfTimetables())
                  var newMinimum = this.state.otherMarks[this.state.otherMarks.length - 1].eventOn;
                  var newMaximum = this.state.otherMarks[0].eventOn;
                  var min = moment(newMinimum, 'DD/MM/YYYY');
                  var minie = new Date(min);
                  var maxo = moment(newMaximum, 'DD/MM/YYYY');
                  var me = new Date(maxo);
                  this.setState({ other_minimum: minie, other_maximum: me }, () => {
                     var array = [];
                     for (let index = 0; index < this.state.otherMarks.length; index++) {
                        var dateads = moment(this.state.otherMarks[index].eventOn, 'DD/MM/YYYY');
                        var elx = new Date(dateads);
                        var finaldate = moment(elx).format('MMYYYY');
                        array.push(finaldate);
                     }
                     this.setState({ other_monthyear: Array.from(new Set(array.reverse())), core: true }, () => {
                        this.setState({ other_monthyear: this.state.other_monthyear.reverse() }, () => {
                           var newdate = new Date();
                           var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));
                           if (idxw && idxw != -1) {
                              var fdate = moment(newdate).format('MMYYYY');
                           }
                           else {
                              var checker = this.getlatest(responseJson.response, newdate);
                              if (checker == undefined) {
                                 var metacrox = this.getless(responseJson.response, newdate)
                              }
                              else {
                                 var metacrox = checker;
                              }
                              var minyy = moment(metacrox, 'DD/MM/YYYY');
                              var minieyy = new Date(minyy);
                              var fdate = moment(minieyy).format('MMYYYY');
                           }
                           var index_val = this.state.other_monthyear.indexOf(fdate);
                           if (index_val == 0) {
                              var previous = this.state.other_monthyear[index_val + 1];
                              this.setState({ NextO: "", PreviousO: this.checkdateOther(previous) }, () => {
                              });
                           }
                           else {
                              var previous = this.state.other_monthyear[index_val + 1];
                              var next = this.state.other_monthyear[index_val - 1];
                              this.setState({ NextO: this.checkdateOther(next), PreviousO: this.checkdateOther(previous) });
                           }
                        })
                     });
                     var newdate = new Date();
                     var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));
                     if (idxw && idxw != -1) {
                        var datescolor = this.state.otherMarks[idxw].eventOn;
                        this.setState({ valueCalendarOther: newdate, profilecheckOther: datescolor });
                        var min0 = moment(datescolor, 'DD/MM/YYYY');
                        var miniex = new Date(min0);
                        // this.onCalendarOtherUser(miniex);
                     }
                     else {
                        var checker = this.getlatest(responseJson.response, newdate);
                        if (checker == undefined) {
                           var metacrox = this.getless(responseJson.response, newdate)
                        }
                        else {
                           var metacrox = checker;
                        }
                        var datescolor = metacrox;
                        var min0 = moment(metacrox, 'DD/MM/YYYY');
                        var miniex = new Date(min0);
                        this.setState({ profilecheckOther: datescolor, valueCalendarOther: miniex });
                        // this.onCalendarOtherUser(miniex);
                     }

                     const userData = this.state.timetableData.current.savePost.find((data) => data.user == id);

                     const shouldNotUpdateActiveDates = userData ? true : false
                     this.showMonthYear(miniex, shouldNotUpdateActiveDates);
                  });
               });
            }
            else {
               var olddate = new Date();
               this.setState({ other_minimum: olddate, other_maximum: olddate, valueCalendarOther: "" });
            }
         })
         .catch((error) => {
         })
   }

   updateMinMaxDateOfTimetables() {
      const { other_monthyear, monthYearDateMap } = this.state;
      const lastIndex = other_monthyear.length - 1;

      const updateSubCalendarDateRanges = (subtimetables, parent) => {
         if (!subtimetables) return undefined;

         const updatedSubtimetables = { ...subtimetables };
         const NEXT_MONTHS = ['threeMonthsAhead', 'twoMonthsAhead', 'next', 'current'];
         const PREV_MONTHS = ['threeMonthsEarlier', 'twoMonthsEarlier', 'previous', 'current'];

         let updatedMaxDate = parent?.maxDate;
         let updatedMinDate = parent?.minDate;

         const adjustMinMaxDateLimits = (months, date, comparisonDate, isNext) => {
            let dateToReturn = date;
            for (let month of months) {
               if (this.state.timetableData[month]?.activeDate) {
                  const timetable = this.state.timetableData[month];
                  const maxMonthYear = moment(comparisonDate).format("MMYYYY");
                  const currentMonthYear = moment(timetable?.activeDate).format("MMYYYY");

                  if (maxMonthYear === currentMonthYear) {
                     const subTimetables = timetable.subTimetables ? Object.values(timetable.subTimetables) : [];
                     if (subTimetables.length) {
                        const isLastSubTimetableVisible = subTimetables[isNext ? subTimetables.length - 1 : 0].isVisible === true;
                        
                        if (!isLastSubTimetableVisible) {
                           dateToReturn = moment(date)
                              .add(isNext ? 1 : -1, 'months')
                              .startOf('month')
                              .toDate();
                        }
                     }
                  }
                  break;
               }
            }
            return dateToReturn;
         }

         updatedMaxDate = adjustMinMaxDateLimits(NEXT_MONTHS, updatedMaxDate, this.state.other_maximum, true);
         updatedMinDate = adjustMinMaxDateLimits(PREV_MONTHS, updatedMinDate, this.state.other_minimum, false);

         Object.keys(subtimetables).forEach(subCalendarKey => {
            const events = updatedSubtimetables[subCalendarKey].availableEventsOnDates;
            updatedSubtimetables[subCalendarKey] = {
               ...subtimetables[subCalendarKey],
               minDate: updatedMinDate,
               maxDate: updatedMaxDate
            };
         });

         return updatedSubtimetables;
      }

      this.setState(prevState => ({
         timetableData: {
            threeMonthsEarlier: {
               ...prevState.timetableData.threeMonthsEarlier,
               minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[other_monthyear[3]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.threeMonthsEarlier.subTimetables,
                  {
                     calendar: 'threeMonthsEarlier',
                     minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[other_monthyear[3]]?.maxDate
                  }
               )
            },
            twoMonthsEarlier: {
               ...prevState.timetableData.twoMonthsEarlier,
               minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[other_monthyear[2]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.twoMonthsEarlier.subTimetables,
                  {
                     calendar: 'twoMonthsEarlier',
                     minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[other_monthyear[2]]?.maxDate
                  }
               )
            },
            previous: {
               ...prevState.timetableData.previous,
               minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[other_monthyear[1]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.previous.subTimetables,
                  {
                     calendar: 'previous',
                     minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[other_monthyear[1]]?.maxDate
                  }
               )
            },
            current: {
               ...prevState.timetableData.current,
               minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.current.subTimetables,
                  {
                     calendar: 'current',
                     minDate: monthYearDateMap[other_monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate
                  }
               )
            },
            next: {
               ...prevState.timetableData.next,
               minDate: monthYearDateMap[other_monthyear[lastIndex - 1]]?.minDate,
               maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.next.subTimetables,
                  {
                     calendar: 'next',
                     minDate: monthYearDateMap[other_monthyear[lastIndex - 1]]?.minDate,
                     maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate
                  }
               )
            },
            twoMonthsAhead: {
               ...prevState.timetableData.twoMonthsAhead,
               minDate: monthYearDateMap[other_monthyear[lastIndex - 2]]?.minDate,
               maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.twoMonthsAhead.subTimetables,
                  {
                     calendar: 'twoMonthsAhead',
                     minDate: monthYearDateMap[other_monthyear[lastIndex - 2]]?.minDate,
                     maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate
                  }
               )
            },
            threeMonthsAhead: {
               ...prevState.timetableData.threeMonthsAhead,
               minDate: monthYearDateMap[other_monthyear[lastIndex - 3]]?.minDate,
               maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.threeMonthsAhead.subTimetables,
                  {
                     calendar: 'threeMonthsAhead',
                     minDate: monthYearDateMap[other_monthyear[lastIndex - 3]]?.minDate,
                     maxDate: monthYearDateMap[other_monthyear[0]]?.maxDate
                  }
               )
            }
         }
      }));
   }

   // setTimetableData() {
   //    if(this.state.timetableData.current.activeDate) {
   //       const {previousDate, nextDate} = this.getNextPreviousOther(this.state.timetableData.current.activeDate);
   //       if(previousDate) {
   //          this.updateTimetableData({activeDate: previousDate}, 'previous');
   //       } else if (nextDate) {
   //          this.updateTimetableData({activeDate: nextDate}, 'next');
   //       }
   //    }
   // }

   // getNextPreviousOther(date) {
   //    const newDate = moment(date).format('MMYYYY');
   //    var index_val = this.state.other_monthyear.indexOf(newDate);
   //    let previousDate, nextDate = '';
   //    if (index_val == 0) {
   //       const prev = this.checkdateOther(this.state.other_monthyear[index_val + 1]);
   //       const formattedPrevDate = moment(prev, 'DD-MM-YYYY');
   //       previousDate = new Date(formattedPrevDate.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'));
   //    }
   //    else {
   //       const prev = this.checkdateOther(this.state.other_monthyear[index_val + 1]);
   //       const next = this.checkdateOther(this.state.other_monthyear[index_val - 1]);
   //       const formattedPrevDate = moment(prev, 'DD-MM-YYYY');
   //       const formattedNextDate = moment(next, 'DD-MM-YYYY');
   //       previousDate = new Date(formattedPrevDate.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'));
   //       nextDate = new Date(formattedNextDate.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'));
   //    }
   //    return {previousDate, nextDate};
   // }

   checkdateOther(date) {
      for (let index = 0; index < this.state.otherMarks.length; index++) {
         var dateads = moment(this.state.otherMarks[index].eventOn, 'DD/MM/YYYY');
         var elx = new Date(dateads);
         var finaldate = moment(elx).format('MMYYYY');
         if (date == finaldate) {
            return this.state.otherMarks[index].eventOn;
         }
      }
   }

   getless(delta, newdate) {
      for (let index = 0; index < delta.length; index++) {
         var dateadscc = "";
         var elxc = "";
         dateadscc = moment(delta[index].eventOn, 'DD/MM/YYYY');
         elxc = new Date(dateadscc);
         if (moment(elxc).format("YYYYMMDD") < moment(newdate).format("YYYYMMDD")) {
            return delta[index].eventOn;
         }
      }
   }

   getlatest(delta, newdate) {
      for (var index = delta.length - 1; index >= 0; index--) {
         var dateadscc = "";
         var elxc = "";
         dateadscc = moment(delta[index].eventOn, 'DD/MM/YYYY');
         elxc = new Date(dateadscc);
         if (moment(elxc).format("YYYYMMDD") > moment(newdate).format("YYYYMMDD")) {
            return delta[index].eventOn;
         }
      }
   }
   followusercancel2 = (e) => {
      var e = e.currentTarget.id;
      this.setState({ mobi: 0 }, () => {
         const body = { user_id: e };
         this.setState({ c_isRequested: 0 })
         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/follow/cancelFollowRequest.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
            })
            .catch((error) => {
               console.log(error);
            })
         this.setState({ mobi: 1 });
      });

   }
   blockuserpop() {
      $("#one_4 .pupup4").toggle();
      this.setState({ errortitle: "Block " + this.state.cscreenname.split(" ")[0], editerror: "Are you sure would like to block " + this.state.cscreenname + ", " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $("#one_4 .errorpop").toggle();
      });
   }
   removeuserpop() {
      $("#one_4 .pupup4").toggle();
      this.setState({ errortitle: "Remove Follower", editerror: "Are you sure would like to remove " + this.state.cscreenname + " as a follower, " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $("#one_4 .errorpop").toggle();
      });
   }
   thiredSeeLess(e) {
      e.stopPropagation();
      this.setState({seeMoreSeeLessPopup: false});
      if (this.state.evtId == "") {
         $('#four1').addClass('closeBd');

         seeLessLikeThis(this.state.cuserid)

         setTimeout(function () {
            $('#four1').removeClass('closeBd');
         }, 3000);
      }
      else {
         $('#promi' + this.state.evtId).addClass('closeBd');
         setTimeout(function () {
            $('#promi' + this.state.evtId).removeClass('closeBd');
         }.bind(this), 3000);
      }
   }
   followuser2 = (e) => {
      var play = e.currentTarget.id;
      this.setState({ mobi: 0 }, () => {
         if (this.state.c_make_public == 1) {
            this.setState({ c_isFollowing: 1 });
            var body = {
               user_id: play,
               isRequest: 1
            };
         }
         else {
            this.setState({ c_isRequested: 1 });
            var body = {
               user_id: play,
               isRequest: 0
            };

         }

         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/follow/sendFollowRequest.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "TimeZone": moment.tz.guess(),
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
               this.comonuser();
            })
            .catch((error) => {
               console.log(error);
            })
         this.setState({ mobi: 1 });
      })

   }
   changeCurrentTimeOld(seconds, players) {
      let playerd = this[`ref${players}`].getState().player.currentTime;
      this[`ref${players}`].seek(playerd + seconds);
   }
   thiredSeeMore(e) {
      e.stopPropagation();
      this.setState({seeMoreSeeLessPopup: false});
      if (this.state.evtId == "") {
         $('#five1').addClass('closeBd');

         seeMoreLikeThis(this.state.cuserid, this.state.seeMorePage)
            .then((profiles) => {
               if (profiles.length > 0)
                  this.setState({ seeMorePage: this.state.seeMorePage + 1 })
            })

         setTimeout(function () {
            $('#five1').removeClass('closeBd');
         }, 3000);
      }
      else {
         $('#promo' + this.state.evtId).addClass('closeBd');
         setTimeout(function () {
            $('#promo' + this.state.evtId).removeClass('closeBd');
         }.bind(this), 3000);
      }
   }

   comonuser() {
      // const token = localStorage.getItem('token');
      fetch(this.state.baseUrlNode + '/api/getUserDetails?user_id=' + this.state.cuserid, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({
               c_isRequested: responseJson.response.isRequested,
               c_make_public: responseJson.response.make_public, c_isFollowing: responseJson.response.isFollowing, mobi: 1
            });
         }).catch((error) => {
            console.log(error);
         })
   }
   blockuser() {
      if (this.state.errortitle == "Unfollow " + this.state.cscreenname.split(" ")[0]) {
         var e = this.state.action;
         this.setState({ mobi: 0 }, () => {
            const body = { user_id: e };
            this.setState({ c_isRequested: 0, c_isFollowing: 0 }, () => {
               const token = localStorage.getItem('token');
               axios.post(this.state.baseUrl + '/api/follow/unFollow.php', body, {
                  headers: {
                     Accept: "application/json",
                     "Content-Type": "application/json",
                     "Authorization": "Bearer " + token,
                  },
               })
                  .then((res) => {
                     this.comonuser();
                  })
                  .catch((error) => {
                     console.log(error);
                  })
            });
         })
      }
      else {
         this.setState({ mobi: 0 }, () => {
            if (this.state.errortitle == "Remove Follower") {
               const body = { user_id: this.state.cuserid }
               const token = localStorage.getItem('token');
               axios.post(this.state.baseUrl + '/api/follow/removeFollower.php', body, {
                  headers: {
                     Accept: "application/json",
                     "Content-Type": "application/json",
                     "Authorization": "Bearer " + token,
                  },
               })
                  .then((res) => {
                     this.comonuser();
                  }).catch((error) => {
                     console.log(error);
                  })
            }
            else {
               const body = { profileId: this.state.cuserid }
               const token = localStorage.getItem('token');
               axios.post(this.state.baseUrl + '/api/user/blockUser.php', body, {
                  headers: {
                     Accept: "application/json",
                     "Content-Type": "application/json",
                     "Authorization": "Bearer " + token,
                  },
               })
                  .then((res) => {
                     this.comonuser();
                  }).catch((error) => {
                     console.log(error);
                  })
            }
         })
      }
   }

   updateTimetableData = (data, calendarView, subTimetableKey = null) => {
      this.setState((prevState) => {
         if (subTimetableKey && prevState?.timetableData[calendarView]?.subTimetables) {
            return {
               timetableData: {
                  ...prevState.timetableData,
                  [calendarView]: {
                     ...prevState.timetableData[calendarView],
                     subTimetables: {
                        ...prevState?.timetableData[calendarView].subTimetables,
                        [subTimetableKey]: {
                           ...prevState?.timetableData[calendarView].subTimetables[subTimetableKey],
                           ...data,
                        },
                     },
                  },
               },
            };
         } else {
            return {
               timetableData: {
                  ...prevState.timetableData,
                  [calendarView]: {
                     ...prevState.timetableData[calendarView],
                     ...data,
                  },
               },
            };
         }
      });
   }
  
   updateStatesFromTimetable = (data) => {
      this.setState({
         currentMonthYear: data.activeDate,
         valueCalendarOther: data.activeDate,
         otherMarks: data.allEvents,
         other_minimum: data.minDate,
         other_maximum: data.maxDate,
         other_monthyear: data.otherMonthYear,
      })
   }

   convertToDate = (activeDate) => {
      return activeDate?.eventOn ? moment(activeDate.eventOn, "DD-MM-YYYY").toDate() : undefined;
   }

   getActiveDatesOfNextPrevMonths(date, monthDateMapping) { 
      const activeDates = {
         threeMonthsAhead: undefined,
         twoMonthsAhead: undefined,
         next: undefined,
         current: undefined,
         prev: undefined,
         twoMonthsEarlier: undefined,
         threeMonthsEarlier: undefined
      };

      let currentMonthIndex = monthDateMapping.findIndex(el => el.isActiveMonth);
      let timetableToBeActive = undefined;
      let visibleCount = 1;

      const processDateAtIndex = (index, month) => {
         const currentDate = moment();
         const date = monthDateMapping[currentMonthIndex + index]?.date;
         const dateObj = moment(date);

         if (dateObj && month === 'current' && !timetableToBeActive) {
            if (dateObj.isBefore(currentDate, 'day')) {
               const availableEvents = getActiveDatesOfTheMonth(date, this.state.otherMarks);

               if (availableEvents && availableEvents.length <= 2) {
                  let timetableStore = []; // To store all timetables
                  let monthCount = 1;

                  for (let idx = -1; idx > -5; idx--) {
                     const prevMonthDate = monthDateMapping[currentMonthIndex + idx]?.date;
                     if (!prevMonthDate) break;

                     const prevMonthEvents = getActiveDatesOfTheMonth(prevMonthDate, this.state.otherMarks);
                     if (!prevMonthEvents) continue;

                     if (prevMonthEvents.length >= 5) {
                        const subTimetables = handleSubCalendarData(prevMonthEvents);
                        if (subTimetables && Object.keys(subTimetables).length) {
                           Object.values(subTimetables).forEach(sub => timetableStore.push(sub.activeDate));
                           if (monthCount === 4) {
                              currentMonthIndex = currentMonthIndex + idx;
                           }
                           monthCount += Object.keys(subTimetables).length;
                        }
                     } else {
                        timetableStore.push(prevMonthDate);
                        if (monthCount === 4) {
                           currentMonthIndex = currentMonthIndex + idx;
                        }                    
                        monthCount++;
                     }
                  }

                  // Get the fifth-to-last timetable
                  if (timetableStore.length) {
                     const fifthLastTimetable = timetableStore[3];
                     timetableToBeActive = fifthLastTimetable;
                     this.getMonthYear(fifthLastTimetable, true);
                     return {
                        date: fifthLastTimetable,
                        processAgain: true,
                     };
                  }
               }
            }
         }

         if (!date || visibleCount > 4) return undefined;

         const availableEvents = getActiveDatesOfTheMonth(date, this.state.otherMarks);
         const newDate = determineActiveDateForCurrentMonth(date, availableEvents, this.state.otherMarks);
         const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;         

         if (subTimetables && Object.keys(subTimetables).length) {
            const calendars = Object.values(subTimetables);

            const iterateCalendars = (month === 'prev' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier')
               ? calendars.reverse() // Start from the last calendar for previous months
               : calendars; // Start from the first calendar for other months

            if (month === 'current') {
               let activeIndex
               if (timetableToBeActive) {
                  activeIndex = calendars.findIndex(el => moment(el.activeDate).format('DD/MM/YYYY') === moment(timetableToBeActive).format('DD/MM/YYYY'));
               } else {
                  activeIndex = calendars.findIndex(el => {
                     const eventDates = el.availableEventsOnDates.map(event => moment(event.eventOn, "DD-MM-YYYY"));
   
                     if (eventDates.length === 0) return false;
   
                     const minDate = moment.min(eventDates);
                     const maxDate = moment.max(eventDates);
   
                     return moment(newDate).isBetween(minDate, maxDate, 'day', '[]');
                  });
               }
               
               let selectedIndex = activeIndex >= 0 ? activeIndex : 0;
               calendars[selectedIndex].isActive = true;
               
               iterateCalendars.slice(selectedIndex).forEach(calendar => {
                  if (visibleCount > 4) return;
                  calendar['isVisible'] = true;
                  visibleCount += 1;
               });
            } else {
               iterateCalendars.forEach((calendar) => {
                  if (visibleCount > 4) return;
                  calendar['isVisible'] = true;
                  visibleCount += 1;
               });
            }
         } else {
            visibleCount += 1;
         }

         if (date) {
            activeDates[month] = {
               activeDate: newDate,
               availableEventsOnDates: availableEvents,
               subTimetables: subTimetables
            }
         }
         return newDate;
      };

      let currentDate = processDateAtIndex(0, 'current');
      if (currentDate && currentDate?.processAgain) {      
         currentDate = processDateAtIndex(0, 'current');
      }
      let nextMonthDate = processDateAtIndex(1, 'next');
      let twoMonthsAheadDate = processDateAtIndex(2, 'twoMonthsAhead');
      let threeMonthsAheadDate = processDateAtIndex(3, 'threeMonthsAhead');
      let prevMonthDate = null, twoMonthsEarlierDate = null, threeMonthsEarlierDate = null;

      let pastMonthsNeeded = 0;
      if (!threeMonthsAheadDate) pastMonthsNeeded = 1;
      if (!twoMonthsAheadDate) pastMonthsNeeded = 2;
      if (!nextMonthDate) pastMonthsNeeded = 3;


      if (pastMonthsNeeded > 0) {
         prevMonthDate = processDateAtIndex(-1, 'prev');
         if (pastMonthsNeeded > 1) {
            twoMonthsEarlierDate = processDateAtIndex(-2, 'twoMonthsEarlier');
            if (pastMonthsNeeded > 2) {
               threeMonthsEarlierDate = processDateAtIndex(-3, 'threeMonthsEarlier');
            }
         }
      }

      const updatedActiveDates = addNextAndPrevDates(activeDates, this.state.other_monthyear, this.state.monthYearDateMap);
      return updatedActiveDates;
   }

   fetchEvntList2() {
      if (this.state.loadthired == false) {
         // let currentHeight =
         //    $("#one_4 #scrollableDivOneFourProfile")[0].scrollHeight -
         //    $("#one_4 #scrollableDivOneFourProfile").height();

         $('#one_4 .loaderclass').removeClass('removescrolling');
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         this.setState({ hasMore: true, loaddocument: true, items: Array.from({ length: 10 }) })
         var time = Math.floor(new Date() / 1000);
         setTimeout(() => {
            $('#one_4 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
            let scrollId = ""
            if (this.state.inverse == false) {
               var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + this.state.evntData[this.state.evntData.length - 1].postDate + '&eventType=0&eventID=' + this.state.evntData[this.state.evntData.length - 1].eventID;
               scrollId = this.state.evntData[this.state.evntData.length - 1].eventID;

            }
            if (this.state.inverse == true) {
               var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + this.state.evntData[0].postDate + '&eventType=1&eventID=' + this.state.evntData[0].eventID;
               scrollId = this.state.evntData[0].eventID;
            }
            fetch(serverpath, {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "TimeZone": moment.tz.guess(),
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {

                  if (responseJson.response.length < 20) {
                     if (this.state.inverse)
                        this.setState({ topcontat: 'on' });
                     else
                        this.setState({ contat: 'on' });
                  }
                  if (responseJson.response.length > 0) {
                     var datas = responseJson.response;
                     if (this.state.inverse == true) {

                        for (var i = datas.length - 1; i >= 0; i--) {
                           this.state.evntData.unshift(datas[i]);
                        }
                     }
                     else {
                        for (var i = datas.length - 1; i >= 0; i--) {
                           this.state.evntData.push(datas[i]);
                        }
                     }
                     if (this.state.inverse == false) {

                        $('#one_4 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                        setTimeout(() => {
                           $('#one_4 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                        }, 100)
                     }
                     if (this.state.inverse == true) {

                        $('#one_4 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                        setTimeout(() => {
                           $('#one_4 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                        }, 100)
                     }
                     // this.setState({ loaddocument: false }, () => {
                     if (this.state.evntData.length > 20) {
                        this.setState({ items: this.state.items.concat(Array.from({ length: 20 })) }, () => {
                        });
                     }
                     // setTimeout(() => {
                     this.setState({ loadthired: false });
                     
                     if (this.state.inverse) {
                        const scrollSelector = `#hashu${scrollId}`
                        this.scrollTimelineToElementPosition(scrollSelector)
                     }

                     // }, 100);
                     // });
                  }
                  else {
                     $('#one_4 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                     setTimeout(() => {
                        $('#one_4 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                     }, 100)
                  }
                  this.setState({ loaddocument: false });
               });
         }, 100);
      }
   }
   onscrolldocument() {
      var pxcelate = $('#one_4 #scrollableDivOneFourProfile')[0].scrollHeight;
      var px = $("#one_4 #scrollableDivOneFourProfile").scrollTop();
      if (px < 50) {
         if (this.state.topcontat == "of") {
            this.setState({ inverse: true, loadthired: false }, () => {
               if (px == 0) {
                  this.timeout = setTimeout(() => {
                     this.fetchEvntList2();
                  }, 100);
                  setTimeout(() => {
                     if (this.timeout) {
                        clearTimeout(this.timeout)
                        this.timeout = null
                     }
                  }, 100);
               }
            });
            // $('#one_4 .loaderclass').removeClass('bottomscrolls closeCd');
            // $('#one_4 .loaderclass').addClass('topscrolls');
            $('#one_4 .contantf').addClass('closeCd');
         }
         if (this.state.topcontat == "on") {
            $('#one_4 .contantf').removeClass('closeCd');
            // $('#one_4 .loaderclass').addClass('closeCd');
         }
         else {
            $('#one_4 .contantf').addClass('closeCd');
         }
      }
      else {
         if (this.state.contat == "of") {
            this.setState({ inverse: false, loadthired: false }, () => {

               if (Math.round(px + $("#one_4 #scrollableDivOneFourProfile").height()) == pxcelate) {

                  // $('#one_4 .loaderclass').removeClass('topscrolls closeCd');
                  // $('#one_4 .loaderclass').addClass('bottomscrolls'); 
                  this.timeout = setTimeout(() => {
                     this.fetchEvntList2();
                  }, 100);
                  setTimeout(() => {
                     if (this.timeout) {
                        clearTimeout(this.timeout)
                        this.timeout = null
                     }
                  }, 100);
               }
            });
         }
         if (this.state.contat == "on" && Math.round(px + $("#one_4 #scrollableDivOneFourProfile").height()) == pxcelate) {
            $('#one_4 .contantf').removeClass('closeCd');
            // $('#one_4 .loaderclass').addClass('closeCd');
         }
         else {
            $('#one_4 .contantf').addClass('closeCd');
         }
      }
   }

   getNextForDate(dates) {
      // this.showMonthYear(dates)
      // const newDate = moment(dates).format('MMYYYY');
      // var index_val = this.state.other_monthyear.indexOf(newDate);
      // if (index_val == 0) {
      //    var previous = this.state.other_monthyear[index_val + 1];
      //    this.setState({ NextO: "", PreviousO: this.checkdateOther(previous) });
      // }
      // else {
      //    var previous = this.state.other_monthyear[index_val + 1];
      //    var next = this.state.other_monthyear[index_val - 1];
      //    this.setState({ NextO: this.checkdateOther(next), PreviousO: this.checkdateOther(previous) });
      // }
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);
      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   unfollowuser2 = (e) => {
      this.setState({
         action: e.currentTarget.id, errortitle: "Unfollow " + this.state.cscreenname.split(" ")[0]
         , editerror: "Are you sure you would like to unfollow " + this.state.cscreenname + ", " + localStorage.getItem("screenname").split(" ")[0] + "?"
      }, () => {
         $("#one_4 .errorpop").toggle();
      })
   }
   likepost(e) {
      // $("#" + e.currentTarget.id).toggleClass("thumbs-down");
      // var hascc = $('div').hasClass('thumbs-down');
      // if (hascc == true) {
      //    setTimeout(function () {
      //       $('.thumbs-down').removeClass('thumbs-down');
      //    }, 1500);
      // }
      // var evtid = e.currentTarget.id.replace('crox', '');
      // const body = {
      //    user_id: localStorage.getItem('userid'),
      //    eventID: evtid,
      // }
      // const token = localStorage.getItem('token');
      // axios.post(this.state.baseUrl + '/api/event/addOrRemoveLike.php', body, {
      //    headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //       "Authorization": "Bearer " + token,
      //    },
      // })
      //    .then((res) => {
      //       console.log('res');
      //    });
   }
   collectMentions(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      var hash = value.match(/(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more || hash) {
         if (more !== null && more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/' + more[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
         if (hash !== null && hash.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < hash.length; index++) {
                  $("." + hash[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/tag/' + hash[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }

   /**
    * 
    * @param {String} jstatus 
    * @returns 
    */
   fetchafterfilter(jstatus) {
      this.collectMentions(jstatus);
      var regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
      const replaced2 = jstatus.replace(regex, value => {
         const link = getLinkElement(value)
         return `<a href=${"https://" + value} target='_blank'>${link}</a>`
      });
      //  var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
      var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
      var replaced7 = replaced2.replace(mention_preg, value => `<a class=${value.substring(1)}>${value}</a>`);
      var regex2 = /!+([a-zA-Z0-9_]+)/ig;
      var replaced3 = replaced7.replace(regex2, value => `<span>${value}</span>`);
      var replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return replaced;
   }
   setStorage() {
      // localStorage.setItem("userpoint", "other");
      // localStorage.setItem("backid", "front");
   }
   gobackpage() {
      this.updateSharedDataInContext(); // updating the data in context

      const { sharedData } = this.context;
      let activeTabData = sharedData.tabWiseData[this.state.activeTab];
      const { appContext } = this.props;
      const { state, actions } = appContext;

      const entry = JSON.parse(localStorage.getItem('entry'));

      // if (this.props.visitedPaths.length > 0) {
      const prevPaths = this.props.visitedPaths;
      if (this.props.location.pathname.includes(prevPaths[prevPaths.length - 1]) && prevPaths[prevPaths.length - 1] === this.props.location.pathname) {
         prevPaths.pop()
      }
      this.props.setVisitedPaths(prevPaths);
      localStorage.setItem("backid", "back");

      if (this.state.activeTab == 'second' && activeTabData.openProfiles?.length == 1 && state.homePageActive.status) {
         this.props.history.push(`/${entry[0]?.username}`);
      } else if (this.state.activeTab == 'second' && activeTabData.openProfiles?.length == 1 && !state.homePageActive.status) {
         this.props.history.push("/");
         actions.setHomePageActive({
            status: true,
            activeHomePageSubspace: 1
         })
         this.updateClickedButtonToExit("back")
      } else {
         this.props.history.goBack();
         this.updateClickedButtonToExit("back")
      }
   }

   handleInteraction = (event) => {
      if (this.isTouchDevice) {
         event.preventDefault(); // Prevent double tap behavior on iOS
      }

      // Call the navigation function immediately
      this.gobackpage();
   };

   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   showMonthYear(showDate, shouldNotUpdateActiveDates = false) {
      const monthsShort = {
         '01': "Jan", '02': 'Feb', '03': 'Mar', '04': 'Apr',
         '05': 'May', '06': 'Jun', '07': 'Jul', '08': 'Aug',
         '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'
      };
      this.setState({ currentMonthYear: showDate, valueCalendarOther: showDate }, () => {
         const dateActive = showDate;
         const dateIndex = moment(dateActive).format("MMYYYY");
         const sortedArray = this.getSurroundingMonths(dateIndex);
         const result = sortedArray
            .map(({ month, year, originalIndex }, index) => ({
               date: `${month < 10 ? '0' : ''}${month}${year}`,
               originalIndex,
               display: index < 5
            }))
            .sort((a, b) => a.originalIndex - b.originalIndex);

         const activeIndex = result.findIndex(item => item.date === dateIndex);
         const mappedArray = result.map(dateObj => {
            const { date, display } = dateObj;
            const month = date.substr(0, 2);
            const monthName = monthsShort[month];
            return {
               short: monthName,
               date: this.state.monthYearDateMap[date].maxDate,
               isActiveMonth: date === dateIndex,
               display
            };
         });

         const leftSideArray = mappedArray.filter(map => map.display);
         this.updateEarliestDatesByYear(this.state.other_monthyear, this.state.monthYearDateMap);

         // If active dates should not update, update currentMonthYear and leftSideArray only.
         if (shouldNotUpdateActiveDates) {
            this.setState({
               leftSideArray: leftSideArray
            });
            return;
         }

         // Get the active dates for each timetable section.
         const {
            threeMonthsEarlier,
            twoMonthsEarlier,
            prev,
            current,
            next,
            twoMonthsAhead,
            threeMonthsAhead
         } = this.getActiveDatesOfNextPrevMonths(showDate, mappedArray);

         this.setState((prevState) => {
            const intermediateTimetableData = {
               threeMonthsEarlier: {
                  ...prevState.timetableData.threeMonthsEarlier,
                  activeDate: threeMonthsEarlier?.activeDate,
                  availableEventsOnDates: threeMonthsEarlier?.availableEventsOnDates,
                  subTimetables: threeMonthsEarlier?.subTimetables,
                  nextDate: threeMonthsEarlier?.nextDate || undefined,
                  prevDate: threeMonthsEarlier?.prevDate || undefined,
               },
               twoMonthsEarlier: {
                  ...prevState.timetableData.twoMonthsEarlier,
                  activeDate: twoMonthsEarlier?.activeDate,
                  availableEventsOnDates: twoMonthsEarlier?.availableEventsOnDates,
                  subTimetables: twoMonthsEarlier?.subTimetables,
                  nextDate: twoMonthsEarlier?.nextDate || undefined,
                  prevDate: twoMonthsEarlier?.prevDate || undefined,
               },
               previous: {
                  ...prevState.timetableData.previous,
                  activeDate: prev?.activeDate,
                  availableEventsOnDates: prev?.availableEventsOnDates,
                  subTimetables: prev?.subTimetables,
                  nextDate: prev?.nextDate || undefined,
                  prevDate: prev?.prevDate || undefined,
               },
               current: {
                  ...prevState.timetableData.current,
                  activeDate: current?.activeDate,
                  availableEventsOnDates: current?.availableEventsOnDates,
                  subTimetables: current?.subTimetables,
                  nextDate: current?.nextDate || undefined,
                  prevDate: current?.prevDate || undefined,
               },
               next: {
                  ...prevState.timetableData.next,
                  activeDate: next?.activeDate,
                  availableEventsOnDates: next?.availableEventsOnDates,
                  subTimetables: next?.subTimetables,
                  nextDate: next?.nextDate || undefined,
                  prevDate: next?.prevDate || undefined,
               },
               twoMonthsAhead: {
                  ...prevState.timetableData.twoMonthsAhead,
                  activeDate: twoMonthsAhead?.activeDate,
                  availableEventsOnDates: twoMonthsAhead?.availableEventsOnDates,
                  subTimetables: twoMonthsAhead?.subTimetables,
                  nextDate: twoMonthsAhead?.nextDate || undefined,
                  prevDate: twoMonthsAhead?.prevDate || undefined,
               },
               threeMonthsAhead: {
                  ...prevState.timetableData.threeMonthsAhead,
                  activeDate: threeMonthsAhead?.activeDate,
                  availableEventsOnDates: threeMonthsAhead?.availableEventsOnDates,
                  subTimetables: threeMonthsAhead?.subTimetables,
                  nextDate: threeMonthsAhead?.nextDate || undefined,
                  prevDate: threeMonthsAhead?.prevDate || undefined,
               },
            };

            const updatedTimetableData = determineAndUpdateActiveDatesForTimetables(
               intermediateTimetableData,
               prevState.otherMarks
            );

            return {
               currentMonthYear: showDate,
               leftSideArray: leftSideArray,
               timetableData: updatedTimetableData
            };
         }, () => {
            this.updateOpenedProfilesInContext();
            this.updateMinMaxDateOfTimetables();
         });
      });
   }

   // getSubtimetablesData = (events) => {
   //    if (!Array.isArray(events) || events?.length === 0 || events?.length > 9) {
   //       return undefined;
   //    }
   //    return handleSubCalendarData(events);
   // };

   ensureDisplayedMonthsLimit = (timetableData) => {
      const updatedTimetableData = { ...timetableData };

      const MONTHS = ['current', 'next', 'twoMonthsAhead', 'threeMonthsAhead', 'previous', 'twoMonthsEarlier', 'threeMonthsEarlier'];
      let visibleCount = 0;

      const getNewDateForMonth = (month, prevMonth) => {
         const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';
         let newDate = undefined;

         if (updatedTimetableData[prevMonth]?.nextDate) {
            newDate = updatedTimetableData[prevMonth].nextDate;
         } else if (prevMonth === 'threeMonthsAhead') {
            newDate = updatedTimetableData['current']?.prevDate;
         } else if (isPrevious) {
            newDate = updatedTimetableData[prevMonth]?.prevDate;
         }

         return newDate;
      };

      MONTHS.forEach((month, index) => {
         // if (visibleCount >= 4) return;
         const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';

         if (updatedTimetableData[month]?.activeDate) {
            if (updatedTimetableData[month].subTimetables) {
               const calendars = Object.values(updatedTimetableData[month].subTimetables);
               const iterateCalendars = isPrevious
                  ? calendars.reverse()
                  : calendars;

               let activeSubTimetableIndex = iterateCalendars.findIndex((calendar) => calendar.isActive === true);               
               iterateCalendars.forEach((calendar, index) => {
                  if (calendar.isVisible) {
                     visibleCount += 1;
                  } else if (!calendar.isVisible) {
                     if (month !== 'current') {
                        calendar['isVisible'] = true;
                        visibleCount += 1;
                     } else {
                        if (index > activeSubTimetableIndex) {
                           calendar['isVisible'] = true;
                           visibleCount += 1;
                        }
                     }
                  }
               });
            } else {
               visibleCount += 1;
            }
         } else {
            if (month === 'previous') {
               const subTimetables = updatedTimetableData['current'].subTimetables
                  ? Object.values(updatedTimetableData['current'].subTimetables)
                  : [];

               if (subTimetables?.length) {
                  const activeIdx = subTimetables.findIndex((calendar) => calendar.isActive === true);
                  if (activeIdx > 0) {
                     const iterateCalendars = subTimetables.reverse();
                     iterateCalendars.forEach((calendar) => {
                        if (visibleCount < 4) {
                           if (!calendar.isVisible) {
                              calendar['isVisible'] = true;
                              visibleCount += 1;
                           }
                        }
                     })
                  }
               }
            }

            const prevMonth = MONTHS[index - 1];
            const newDate = getNewDateForMonth(month, prevMonth);
            if (!newDate || visibleCount >= 4) return;

            const availableEvents = getActiveDatesOfTheMonth(newDate, this.state.otherMarks);
            // const selectedDate = determineDateToBeSelectForMonths(newDate, availableEvents, this.state.otherMarks);
            const selectedDate = newDate;
            const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

            const calendars = subTimetables ? Object.values(subTimetables) : [];
            if (calendars?.length) {
               const iterateCalendars = isPrevious
                  ? calendars.reverse()
                  : calendars;

               iterateCalendars.forEach((calendar) => {
                  if (visibleCount < 4) {
                     calendar['isVisible'] = true;
                     visibleCount += 1;
                  }
               });
            } else {
               visibleCount += 1;
            }

            updatedTimetableData[month].activeDate = selectedDate;
            updatedTimetableData[month].availableEventsOnDates = availableEvents;
            updatedTimetableData[month].subTimetables = subTimetables;
         }
      });

      // If we have more than 4 timetables, start removing the extra ones
      if (visibleCount > 4) {
         const reverseMonths = MONTHS.reverse();

         reverseMonths.forEach((month) => {
            if (visibleCount <= 4) return;

            const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';

            if (updatedTimetableData[month]?.activeDate) {
               const calendars = updatedTimetableData[month].subTimetables ? Object.values(updatedTimetableData[month].subTimetables) : [];

               if (calendars?.length) {
                  const iterateCalendars = !isPrevious ? calendars.reverse() : calendars;

                  iterateCalendars.forEach((calendar) => {
                     if (calendar.isVisible && visibleCount > 4) {
                        calendar.isVisible = false;
                        visibleCount -= 1;
                     }
                  });

                  if (calendars.every((calendar) => !calendar.isVisible)) {
                     updatedTimetableData[month].subTimetables = undefined;
                     updatedTimetableData[month].activeDate = undefined;
                     updatedTimetableData[month].availableEventsOnDates = [];
                  }
               }
            }

            if (!updatedTimetableData[month]?.subTimetables && updatedTimetableData[month]?.activeDate) {
               updatedTimetableData[month].activeDate = undefined;
               updatedTimetableData[month].availableEventsOnDates = [];
               visibleCount -= 1;
            }
         });
      }

      return updatedTimetableData;
   };  
   
  
   shiftActiveCalendar(action) {

      //function to update the active month in profiles page header
      const updateActiveMonth = (timetableData) => {
         const newActiveDate = timetableData.current.activeDate;

         const newMonthYear = moment(newActiveDate).format('MMYYYY');
         const existingMonthYear = moment(this.state.currentMonthYear).format('MMYYYY');

         const shouldNotUpdateActiveDates = newMonthYear !== existingMonthYear;

         if (shouldNotUpdateActiveDates) {
            this.showMonthYear(newActiveDate, shouldNotUpdateActiveDates);
         } else {
            this.setState({ currentMonthYear: newActiveDate, valueCalendarOther: newActiveDate });
         }
      }

      // function to shift to next calendar
      const shiftToNextCalendar = () => {
         const { current, next } = this.state.timetableData;

         const subTimetables = current.subTimetables
            ? Object.entries(current.subTimetables)
            : [];
         const activeIndex = subTimetables.length
            ? subTimetables.findIndex(([_, timetable]) => timetable.isActive === true)
            : -1;

         const hasSubtimetable =
            subTimetables.length && activeIndex !== subTimetables.length - 1;

         let updatedTimetableData = { ...this.state.timetableData };

         if (hasSubtimetable) {
            updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(
               current.subTimetables,
               activeIndex + 2,
               activeIndex + 1
            );
         } else {
            updatedTimetableData.current = unsetTimetableData(updatedTimetableData.current);

            updatedTimetableData = shiftMonthsForward(updatedTimetableData);
            const currentSubTimetables = updatedTimetableData.current.subTimetables;

            if (currentSubTimetables) {
               updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(currentSubTimetables, 1);
            }
         }
         updatedTimetableData = this.ensureDisplayedMonthsLimit(updatedTimetableData);
         updatedTimetableData = addNextAndPrevDates(updatedTimetableData, this.state.other_monthyear, this.state.monthYearDateMap);
         updatedTimetableData = determineAndUpdateActiveDatesForTimetables(updatedTimetableData);

         this.setState({ timetableData: updatedTimetableData }, () => {
            updateActiveMonth(updatedTimetableData);
         });
      };

      // function to shift to previous calendar
      const shiftToPreviousCalendar = () => {
         const { current, next } = this.state.timetableData;

         const subTimetables = current.subTimetables
            ? Object.entries(current.subTimetables)
            : [];
         const activeIndex = subTimetables.length
            ? subTimetables.findIndex(([_, timetable]) => timetable.isActive === true)
            : -1;

         const hasSubtimetable =
            subTimetables.length && activeIndex !== 0;

         let updatedTimetableData = { ...this.state.timetableData };

         if (hasSubtimetable) {
            updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(
               current.subTimetables,
               activeIndex
            );
         } else {
            updatedTimetableData = shiftMonthsBackward(updatedTimetableData);

            updatedTimetableData.current = unsetTimetableData(updatedTimetableData.current);
            const newDate = updatedTimetableData.next.prevDate;

            const availableEvents = getActiveDatesOfTheMonth(newDate, this.state.otherMarks);
            // const selectedDate = determineDateToBeSelectForMonths(newDate, availableEvents, this.state.otherMarks);
            const selectedDate = newDate;
            const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

            if (subTimetables) {
               updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(subTimetables, Object.values(subTimetables)?.length);
            }

            updatedTimetableData.current.activeDate = selectedDate;
            updatedTimetableData.current.availableEventsOnDates = availableEvents;
         }

         updatedTimetableData = this.ensureDisplayedMonthsLimit(updatedTimetableData);
         updatedTimetableData = addNextAndPrevDates(updatedTimetableData, this.state.other_monthyear, this.state.monthYearDateMap);
         updatedTimetableData = determineAndUpdateActiveDatesForTimetables(updatedTimetableData);

         this.setState({ timetableData: updatedTimetableData }, () => {
            updateActiveMonth(updatedTimetableData);
         });
      }

      // call the function based on the action
      if (action === "next") {
         shiftToNextCalendar();
      } else {
         shiftToPreviousCalendar();
      }

      this.updateMinMaxDateOfTimetables();
   }

   updateActiveDatesOfTimetables(newActiveDate, action, calendar) {

      // call shiftActiveCalendar function to shift calendar based on the action
      this.shiftActiveCalendar(action);
   }

   getClosestValues = (activeIndex, array) => {
      const parseMonthYear = (value) => {
         const year = parseInt(value.slice(2, 6), 10);
         const month = parseInt(value.slice(0, 2), 10);
         return { year, month };
      };
      const activeDate = parseMonthYear(activeIndex);
      const parsedArray = array.map((value, index) => ({ ...parseMonthYear(value), originalIndex: index }));
      const sortedArray = parsedArray.sort((a, b) => {
         const diffA = Math.abs((a.year - activeDate.year) * 12 + (a.month - activeDate.month));
         const diffB = Math.abs((b.year - activeDate.year) * 12 + (b.month - activeDate.month));
         return diffA - diffB;
      });
      return sortedArray;
   };
   getSurroundingMonths = (activeMonth) => {
      let months = [...this.state.other_monthyear];
      months.reverse();
      const activeIndex = months[months.indexOf(activeMonth)];
      const surroundingMonths = this.getClosestValues(activeIndex, months);
      return surroundingMonths;
   }
   mapMonthYearToDate = async () => {
      let lastMY = ""
      let lastMYDate = ""
      let monthYearDateMap = {}
      this.state.otherMarks.forEach((dateObj) => {
         var min = moment(dateObj.eventOn, "DD/MM/YYYY");
         var minie = new Date(min);
         const currentMY = moment(minie).format("MMYYYY")
         if (currentMY !== lastMY) {
            monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
            monthYearDateMap[currentMY] = { ...monthYearDateMap[lastMY], maxDate: minie }
         }
         lastMY = currentMY
         lastMYDate = minie
      })
      monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
      this.setState({ monthYearDateMap: { ...monthYearDateMap } })
   }
   getMonthYear(date, shouldNotUpdateTimetables = false) {
      if (!date) return;
      if (moment(this.state.valueCalendarOther).format('MMYYYY') === moment(date).format('MMYYYY')) return;
      
      const updatedDate = moment(date).toDate();
      this.setState({ valueCalendarOther: updatedDate });
      this.showMonthYear(updatedDate, shouldNotUpdateTimetables);
   }
   getDateFullName(date) {
      if (date) {
         const month = moment(date).format('MMMM');
         const year = moment(date).format('YYYY');
         return month + ' ' + year;
      }
   }
   godate() {
      $('#one_4 #listuser').removeClass('active');
      $('#one_4 #user').addClass('active');
      $('#one_4').removeClass('text-left profile-pag');
      $('#one_4').addClass('screen1');
      $('#one_4 #one2list').removeClass('active show');
      $('#one_4 #one2date').addClass('active show');
   }
   godocument() {
      $('#one_4 #user').removeClass('active');
      $('#one_4 #listuser').addClass('active');
      $('#one_4').removeClass('screen1');
      $('#one_4').addClass('text-left profile-pag');
      $('#one_4 #one2date').removeClass('active show');
      $('#one_4 #one2list').addClass('active show');
      this.setState({ fistname: 0 });
      this.fetchEvntList();
   }

   componentWillUnmount() {
      document.title = "Timesort"
      this.updateSharedDataInContext();
      if (this.timeoutId)
         clearTimeout(this.timeoutId);

      document.removeEventListener('click', this.handleClickOfUsers);
      document.removeEventListener('mouseover', this.handleMouseOver);
      window.removeEventListener('resize', this.handleResize);
   }

   setShouldUpdateOnSamePath = (val) => {
      this.setState({ shouldUpdateOnSamePath: val })
   }

   handleYearClick(date) {
      if (date) {
         if (moment(this.state.currentMonthYear).format('MMYYYY') === moment(date).format('MMYYYY')) return;
         const formattedDate = moment(date).toDate();
         this.showMonthYear(formattedDate);
      }
   }
  
   renderYearTree(data) {
      const rows = [];
      for (let i = 0; i < data.length; i += 4) {
         rows.push(data.slice(i, i + 4));
      }

      return (
         <div className="yearTreeContainer">
            {rows.map((row, rowIndex) => (
               <div
                  key={rowIndex}
                  className="yearTreeRow"
               >
                  {row.map((item) => (
                     <div
                        key={item.year}
                        className="yearTreeItem"
                        onClick={() => this.handleYearClick(item?.date || null)}
                     >
                        {item.year}
                     </div>
                  ))}
               </div>
            ))}
         </div>
      )
   }

   updateEarliestDatesByYear(monthYearArray, monthYearDateMap) {
      if (!monthYearArray || !monthYearDateMap || this.state.earliestDatesByYear) return;

      const groups = monthYearArray.reduce((acc, key) => {
         const entry = monthYearDateMap[key];
         if (!entry) return acc;

         const year = parseInt(key.slice(-4), 10);
         const dateObj = new Date(entry.minDate);

         if (!acc[year] || dateObj < acc[year].minDate) {
            acc[year] = { dateStr: entry.minDate, minDate: dateObj };
         }
         return acc;
      }, {});

      const result = Object.keys(groups)
         .map(year => ({ year: parseInt(year, 10), date: groups[year].dateStr }))
         .sort((a, b) => a.year - b.year);

      this.setState({ earliestDatesByYear: result });
   }
    
   render() {

      $(".katexContentBlock .katex a").attr("target", "_blank");
      $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
      if (this.state.cscreenname) {
         //  document.title = "Timesort: " + this.state.cscreenname
         // document.title = "Timesort \u2013 " + this.state.cscreenname;
         document.title = "Timesort \u2014          " + this.state.cscreenname;
      }
      return (
         //<div className="newscreen1 newscreen01">
         //<div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback abc" : "screen1 active fades nabc"}  id="one_4">
         <div className={`${this.props.pos} screen1`} id="one_4">
            <div className="top newbg" id="myHeader">
               <section className="header">
                  <div className="row">
                     <div className=" header_left pr-0">
                        <ul className="nav nav-tabs noteee">
                           <li 
                           onClick={this.handleInteraction} 
                           {...(this.isTouchDevice ? { onTouchStart: this.handleInteraction } : {})}>
                              <a data-toggle="tab" className={`${!this.state.preventNavigation ? 'linkback' : 'linkback blockNavigation'}`}>
                                 <RevisitSvg className="backin" />
                                 {this.state.preventNavigation ? <BlockNavigation class="blockedIcon" /> : ''}
                              </a>
                              <p onClick={!this.state.preventNavigation ? this.gobackpage.bind(this) : () => { }} className={`hoverIconInfo ${this.state.preventNavigation ? 'line-through' : '' }`}>Revisit</p>
                           </li>
                           <li>
                              <a className="link active" onClick={this.godate.bind(this)} id="user" data-toggle="tab" >
                                 <CalendarSvg />
                              </a>
                              <p onClick={this.godate.bind(this)} id="user" className='hoverIconInfo'>Timetables</p>
                           </li>
                           <li>
                              <a data-toggle="tab" className="" onClick={this.godocument.bind(this)} id="listuser" >
                                 <TimelineSvg />
                              </a>
                              <p onClick={this.godocument.bind(this)} id="listuser" className='hoverIconInfo'>Bidirectionally-traversed timeline</p>
                           </li>
                        </ul>
                     </div>
                     <div className=" monthsBlock">
                        <ul>
                           {
                              this.state.leftSideArray &&
                              this.state.leftSideArray.map((item) =>
                                 <li>
                                    <p className={`dateInfo ${item.isActiveMonth ? "active" : ""}`} onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                    <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                 </li>
                              )
                           }
                           {
                              this.state.rightSideArray &&
                              this.state.rightSideArray.map((item) =>
                                 <li>
                                    <p onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                    <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                 </li>
                              )
                           }
                        </ul>
                     </div>

                     {/* <div className=" header_center pl-0 pr-0 text-center">
                        <h3><a href="#">{this.state.cscreenname}</a></h3>
                     </div> */}

                     <div className=" header_right pl-0">
                        <ul className="uld">
                           {this.state.schedule ? <li className="more abort" ><Link to={`/external-link?url=${modifyHref(this.state.schedule)}`}> <DepartSvg /> <span>{modifyWebsiteName(this.state.schedule)}{modifyWebsiteName(this.state.schedule)?.endsWith("/") && <label className="hdots">...</label>}</span></Link></li> : <li className="more abort" ></li>}
                           <li className="more" onClick={() => this.setState({seeMoreSeeLessPopup: true})}>
                              <FilterSvg />
                              <div className='hoverIconInfo'>
                                 <p onClick={this.thiredSeeMore.bind(this)}>see more like this</p>
                                 <p onClick={this.thiredSeeLess.bind(this)}>see less like this</p>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
               </section>
               {/* userpoint  */}
               {this.state.cbio && this.state.cbio !== null && this.state.cwebsite ? <section className="userpoint seconduser">
                  {this.state.cbio && this.state.cbio !== null ? <h3 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(this.state.cbio) }}></h3> : <h3></h3>}
                  {this.state.cwebsite ? <p><a href={"https://" + this.state.cwebsite}>{this.state.cwebsite ? this.state.cwebsite : ""}</a></p> : ""}
               </section> : ""}
            </div>
            <div className="tab-content tab-content4">
               {this.state.time == true ?
                  <>
                     <div id="one2date" className="tab-pane in active show">
                        <div className="flex-center">
                           {
                              (() => {
                                 const beforeActiveSubTimetables = [];
                                 const mainTimetables = ['current', 'next', 'twoMonthsAhead', 'threeMonthsAhead', 'previous', 'twoMonthsEarlier', 'threeMonthsEarlier']
                                    .flatMap((month) => {
                                       const monthData = this.state.timetableData[month];
                                       const subTimetables = monthData?.subTimetables;

                                       if (subTimetables && Object.keys(subTimetables)?.length > 0) {
                                          const entries = Object.entries(subTimetables);
                                          const isCurrentMonth = month === "current";
                                          let activeAndAfter = entries.filter(([_, subData]) => subData.isVisible);
                                          let beforeActive = [];

                                          

                                          if (isCurrentMonth) {
                                             const activeIndex = entries.findIndex(([_, subData]) => subData.isActive);
                                             
                                             activeAndAfter = activeIndex !== -1
                                                ? entries.slice(activeIndex).filter(([_, subData]) => subData.isVisible)
                                                : [];

                                             beforeActive = activeIndex !== -1
                                                ? entries.slice(0, activeIndex).filter(([_, subData]) => subData.isVisible)
                                                : entries.filter(([_, subData]) => subData.isVisible);

                                             beforeActive.forEach(([subKey, subData],index) => {
                                                
                                                beforeActiveSubTimetables.push(
                                                   <Timetable
                                                      key={`${month}-${subKey}-beforeActive`}
                                                      selfProfile={false}
                                                      isScreenVisible={this.props.isScreenVisible}
                                                      subspaceNumber={1}
                                                      calendar={`${month}-${subKey}`}
                                                      userDetails={this.state.userDetails}
                                                      timetableData={subData}
                                                      states={{
                                                         events: this.state.otherMarks,
                                                         otherMinimum: this.state.other_minimum,
                                                         otherMaximum: this.state.other_maximum,
                                                         otherMonthYear: this.state.other_monthyear,
                                                      }}
                                                      updateTimetableData={(data) => this.updateTimetableData(data, month, subKey)}
                                                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                                   />
                                                );
                                                
                                             });
                                          }

                                          return activeAndAfter.map(([subKey, subData]) => (
                                             <Timetable
                                                key={`${month}-${subKey}`}
                                                selfProfile={false}
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar={`${month}-${subKey}`}
                                                userDetails={this.state.userDetails}
                                                timetableData={subData}
                                                states={{
                                                   events: this.state.otherMarks,
                                                   otherMinimum: this.state.other_minimum,
                                                   otherMaximum: this.state.other_maximum,
                                                   otherMonthYear: this.state.other_monthyear,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, month, subKey)}
                                                updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                             />
                                          ));
                                       } else {
                                          return (
                                             <Timetable
                                                key={month}
                                                selfProfile={false}
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar={month}
                                                userDetails={this.state.userDetails}
                                                timetableData={monthData}
                                                states={{
                                                   events: this.state.otherMarks,
                                                   otherMinimum: this.state.other_minimum,
                                                   otherMaximum: this.state.other_maximum,
                                                   otherMonthYear: this.state.other_monthyear,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, month)}
                                                updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                             />
                                          );
                                       }
                                    });

                                 return [...mainTimetables, ...beforeActiveSubTimetables];
                              })()}
                        </div>
                     <div id="five1" class="likeaddto"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                     <div class="likeaddtop" id="four1"><img class="w-100" src="../../images/Erase.png" alt="image" /></div>
                     </div>

                     <div id="one2list" className="tab-pane fade">
                        <div class={this.state.evntData.length > 0 ? "loaderclass closeCdff h" : "loaderclass onscroll"}><img src="../../images/newgif.gif" alt="loading" /></div>
                        {/* <div class={this.state.contat == "of" ? "loaderclass contantf closeCd h" : "loaderclass contantf"}>No More post Available</div> */}
                        <div className={"sectionone changable" + ((!this.state.cbio || !this.state.cwebsite) ? " emptyBioWebsite" : "")}>
                           <div
                              id="scrollableDivOneFourProfile"
                              className={this.state.evntData.length > 0 ? this.state.loaddocument == true ? "scrollClass newscreen1 " : "scrollClass newscreen1" : "scrollClass newscreen1"}
                              style={{
                                 overflow: 'auto',
                                 display: 'flex !important',
                                 flexDirection: 'row',
                              }}
                           >
                              {(this.state.updateTemporalInformation || !this.state.updateTemporalInformation) && 
                              <InfiniteScroll
                                 className={this.state.evntData.length > 0 ? "topbuttom_scroll" : ""}
                                 dataLength={this.state.evntData.length}
                                 style={{ display: 'flex', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 hasMore={true}
                                 onScroll={this.onscrolldocument.bind(this)}

                                 loader={
                                    <div className={`loaderclass ${this.state.inverse ? "topscrolls" : "bottomscrolls"} ${this.state.loaddocument ? "" : "closeCd"}`}>
                                       <img
                                          src="../../images/newgif.gif"
                                          alt="loading"
                                       />


                                    </div>}
                                 scrollableTarget="scrollableDivOneFourProfile"
                              >
                                 <div className="mb-2 timeline-wrapper">
                                    {this.state.evntData.map((j, i) => {
                                       return (
                                          <div className="timeline-event-box">
                                             <span id={'hashu' + j.eventID} className={j.eventID}></span>
                                             <div id={"crox" + j.eventID} className="postlike" onDoubleClick={this.likepost.bind(this)}>
                                                <div className="userin rmrf">
                                                   <h1 className='new-heading-tex-780' dangerouslySetInnerHTML={{ __html: formatDate(getdateformat(j.postedOn, j.postedOn2, j.timezone)) }}></h1>
                                                   <h5>
                                                      {j?.link ? (
                                                         <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                                            <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 1) }} />
                                                         </a>
                                                         ) : (
                                                            <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 1) }} />
                                                      )}
                                                   </h5>
                                                </div>
                                                <div className="post ">
                                                   {(() => {
                                                      if (j.mediaType == "video") {
                                                         return (
                                                            <div id={'Video' + j.eventID} class="video-div">
                                                               <CustomVideoPlayer
                                                                  ref={(el) => (this[`ref${i}`] = el)}
                                                                  type="video/mp4"
                                                                  src={j.eventImage.startsWith("http")? j.eventImage : process.env.REACT_APP_BASE_URL + j.eventImage}
                                                                  autoPlay={false}
                                                                  onClose={() => { }}
                                                                  className={"video-react-has-started"}
                                                                  onPause={() => {}}
                                                                  onRewind={() => this.changeCurrentTimeNew(-10, i)}
                                                                  onForward={() => this.changeCurrentTimeNew(10, i)}
                                                               />
                                                               {/* <Player
                                                                  ref={player => {
                                                                     this[`ref${i}`] = player;
                                                                  }}
                                                                  autoPlay={false}
                                                                  className="video-react-has-started"
                                                                  controls={false}
                                                                  playsInline
                                                                  disablePictureInPicture>
                                                                  <source src={j.eventImage.startsWith("http")? j.eventImage : process.env.REACT_APP_BASE_URL + j.eventImage} type="video/mp4" />
                                                                  <ControlBar disableDefaultControls={true} autoHide={true} className="my-class" />
                                                               </Player> */}
                                                               {/* <ul class="videoulkap pb-3">
                                                                  <li><Button data-id={"player" + j.id} className={-10} onClick={() => this.changeCurrentTimeNew(-10, i)} ><img class="img1" src="../../images/Backwards.png" />
                                                                     <span>10</span></Button>
                                                                  </li>
                                                                  <li><img class="img2" src="../../images/pause.png" /></li>
                                                                  <li><Button data-id={j.id} className={10} onClick={() => this.changeCurrentTimeNew(10, i)} ><img class="img3" src="../../images/Forwards.png" />
                                                                     <span>10</span></Button>
                                                                  </li>
                                                               </ul> */}
                                                            </div>);
                                                      }
                                                      else if (j.mediaType == "image") {
                                                         return (
                                                            <img className="w-100 mt-1" src={this.state.baseUrl + j.eventImage} alt="image" />
                                                         );
                                                      }
                                                   }
                                                   )()}
                                                   <div className="hearti">
                                                      <img className="w-100" src="../../images/cklick.png" alt="image" />
                                                   </div>
                                                </div>
                                             </div>
                                             <ul className={j.mediaType == null ? "videoul mt3 status" : "videoul mt3"} >
                                                <li>
                                                </li>

                                                {(() => {
                                                   if (j.withwho != null) {
                                                      return (<li><img src="../../images/b.png" alt="icon" />
                                                         {(() => {
                                                            if (j.withwho != null) {
                                                               return (
                                                                  j.withwho.map((k, l) => {
                                                                     return (
                                                                        <span><a href="#"><Link to={{
                                                                           pathname: `/${k.username}`,
                                                                           state: { id: k.user_id }
                                                                        }} onClick={this.setStorage.bind(this)}
                                                                        >{k.screenName}</Link></a><span>, </span></span>
                                                                     );
                                                                  })
                                                               )
                                                            }
                                                            else {
                                                               return (
                                                                  <a href="#"></a>
                                                               )
                                                            }
                                                         }
                                                         )()}
                                                      </li>
                                                      )
                                                   }
                                                }
                                                )()}
                                                {(() => {
                                                   if (j.likedByUsers.length > 0) {
                                                      return (<li>
                                                         <img src="../../images/c.png" alt="icon" />
                                                         {(() => {
                                                            if (j.likedByUsers.length > 0) {
                                                               return (
                                                                  j.likedByUsers.map((q, t) => {
                                                                     return (<span><a href="#"><Link to={{
                                                                        pathname: `/${q.username}`,
                                                                        state: { id: q.user_id }
                                                                     }} onClick={this.setStorage.bind(this)}
                                                                     >{q.screenName}</Link></a><span>, </span></span>
                                                                     );
                                                                  })
                                                               )
                                                            }
                                                         }
                                                         )()}
                                                      </li>
                                                      )
                                                   }
                                                }
                                                )()}
                                                {(() => {
                                                   if (j.likedByUsers.length > 0) {
                                                      return (<li>
                                                         <img src="../../images/d.png" alt="icon" />
                                                         {(() => {
                                                            if (j.commentByUsers.length > 0) {
                                                               return (
                                                                  j.commentByUsers.map((m, index) => {
                                                                     return (
                                                                        <span><a href="#"><Link to={{
                                                                           pathname: `/${m.username}`,
                                                                           state: { id: m.user_id }
                                                                        }} onClick={this.setStorage.bind(this)}
                                                                        >{m.screenName}</Link></a><span>, </span></span>
                                                                     );
                                                                  })
                                                               )
                                                            }
                                                         }
                                                         )()}
                                                      </li>
                                                      )
                                                   }
                                                }
                                                )()}
                                             </ul>
                                          </div>)

                                    }
                                    )
                                    }</div>
                              </InfiniteScroll>}
                           </div>
                        </div>
                     </div></>
                  : <div className="loaderclass">
                     <img
                        src="../../images/newgif.gif"
                        alt="loading"
                     />
                  </div>
               }
               {
                  (this.state.screenWidth > 1540 && this.state.screenWidth <= 1700) &&
                  (this.state.earliestDatesByYear && this.renderYearTree(this.state.earliestDatesByYear))
               }
            </div>
            {localStorage.getItem('token') 
               ? <Footer {...this.props} ref={this.child} {...this.footerPropsForUpdateOnSamePath} updateClickedButtonToExit={this.updateClickedButtonToExit.bind(this)} /> 
               : <GuestFooter {...this.props} ref={this.child} {...this.footerPropsForUpdateOnSamePath} updateClickedButtonToExit={this.updateClickedButtonToExit.bind(this)} />
            }

            <div className={`op pupup4 ${this.state.seeMoreSeeLessPopup ? 'zIndex' : ''}`}>
               <div className="more h-100" onClick={() => this.setState({seeMoreSeeLessPopup: false})}></div>
               <ul>
                  <li><a className="more" onClick={this.thiredSeeMore.bind(this)}><img src="../../images/More.png" /> see more like this</a></li>
                  <li><a className="more" onClick={this.thiredSeeLess.bind(this)}><img src="../../images/Erase.png" /> see less like this</a></li>
                  <button className=" more upbtn" onClick={() => this.setState({seeMoreSeeLessPopup: false})}>Cancel</button>
               </ul>
            </div>
            <div className="op errorpop mainpop">
               <div className="errorbtn error h-100"></div>
               <ul>
                  <li className="lipost">{this.state.errortitle}</li>
                  <li>{this.state.editerror}</li>
                  <div className="newpop">
                     <button className="upbtn errorbtn">CANCEL</button>
                     <button className="upbtn errorbtn" onClick={this.blockuser.bind(this)}>YES</button>
                  </div>
               </ul>
            </div>

            <div className="op errorpop2">
               <div className="errorbtn2 error h-100"></div>
               <ul>
                  <li>{this.state.error}</li>

                  <button className="upbtn errorbtn2" onClick={this.clickpop.bind(this)}>ok</button>
               </ul>
            </div>

         </div>
      );
   };
};



export default Users;
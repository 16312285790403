import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { saveTemporalInfo } from '../../Services/api';
import { getdateformat } from '../../utils/dateFormatterForTI/dateFormatter';
import { formatDate as formatDateKatex } from '../../utils/math';
import { fetchTeXCompiledCaption } from '../../utils/post/manageCaptions';
import { DateDifferenceContainer, handleDateDifferenceContainer } from "../../utils/profiles/dateDifference";
import { handleMarginsOfTimetablesParent, updateStylingOfUserCalendar } from '../../utils/profiles/handleCalendarStyling';
import VideoPlayer from "../VideoPlayer";
import { updateActiveDatesBasedOnTileColors } from '../../utils/timetableHelper/timetableActiveDatesUpdater';
import { truncateExcludingLinks } from '../../utils/profiles/shared';
import { ReactComponent as DownwardArrow } from '../../utils/svg/Down-facing-arrow.svg';

const Timetable = ({ selfProfile, isScreenVisible, subspaceNumber, calendar, userDetails, timetableData, states, updateTimetableData, updateStatesFromTimetable, updateActiveDatesOfTimetable, editDeletePost, props }) => {
  const containerRef = useRef(null);
  const playerRefs = useRef({});
  const calendarRefs = useRef({});
  const [activeDate, setActiveDate] = useState();
  const [prevActiveDate, setPrevActiveDate] = useState();
  const [profileData, setProfileData] = useState();
  const [displayedProfileData, setDisplayedProfileData] = useState();
  const [showAllData, setShowAllData] = useState(false);
  const [expandedEventIds, setExpandedEventIds] = useState([]);
  const [prevDate, setPrevDate] = useState('');
  const [nextDate, setNextDate] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [savePopupKey, setSavePopupKey] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [freshLoad, setFreshLoad] = useState(true);
  const timeoutRefLable = useRef(null);
  const timeoutRefCalendar = useRef(null);
  const timeoutRef = useRef(null);
  const temporalInfoRefs = useRef({});
  const isCompressedCalender = timetableData?.availableEventsOnDates?.length < 16;
  const userProfileType = localStorage.getItem('profileType');
  let activeTimeoutId = null;

  useEffect(() => {
    if (timetableData?.activeDate !== activeDate) {
      setPrevActiveDate(activeDate);
      setActiveDate(timetableData?.activeDate);
    }
    if (timetableData.profileData && timetableData.profileData !== profileData) {
      const data = showAllData ? timetableData.profileData : timetableData.profileData.slice(0, 1);
      setProfileData(timetableData.profileData);
      setDisplayedProfileData(data);
    }
  }, [timetableData])

  useEffect(() => {
    if (!activeDate) return;

    updateCalendarStyling();

    const shouldFetchEventsWith = refreshActiveDateBasedOnTiles();

    if (shouldFetchEventsWith && moment(activeDate).format("DD-MM-YYYY") !== moment(shouldFetchEventsWith).format("DD-MM-YYYY")) {
      updateTimetableData({ activeDate: shouldFetchEventsWith, activeDateTileColorUpdationFlag: true });
      return
    }

    fetchEventData(activeDate);
  }, [activeDate]);

  useEffect(() => {
    if (timetableData.activeDate) {
      getNextPrevDates(timetableData.activeDate);
    }
  }, [timetableData.activeDate])

  useEffect(() => {
    if (showAllData) {
      setDisplayedProfileData(profileData);
    }
  }, [showAllData]);

  useEffect(() => {
    if (isScreenVisible) {
      let prevWidth = window.innerWidth;

      const handleResize = () => {
        const currentWidth = window.innerWidth;
        if (currentWidth !== prevWidth) {
          updateMarginsOfTimetablesParent(timetableData.availableEventsOnDates);
          prevWidth = currentWidth;
        }
      };

      if (isScreenVisible && containerRef.current) {
        containerRef.current.addEventListener("resize", handleResize);
        containerRef.current.addEventListener("click", handleProfileCalendarClick);
      }

      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("resize", handleResize);
          containerRef.current.removeEventListener("click", handleProfileCalendarClick);
        }
      }
    }
  }, [isScreenVisible, containerRef.current]);

  useEffect(() => {
    return () => {
      if (activeTimeoutId) {
        clearTimeout(activeTimeoutId);
      }
    };
  }, []);

  useEffect(() => {
    updateMarginsOfTimetablesParent(timetableData.availableEventsOnDates);
  }, [profileData])

  const addTimeoutForDateChange = (callback, responseData) => {
    const now = new Date();
    const filteredData = responseData.filter(item => {
      const postedOnDate = item.postedOn ? new Date(item.postedOn * 1000) : null;
      const postedOn2Date = item.postedOn2 ? new Date(item.postedOn2 * 1000) : null;

      const daysDifferencePostedOn = postedOnDate
        ? Math.floor((now.getTime() - postedOnDate.getTime()) / (1000 * 3600 * 24))
        : Infinity;

      const daysDifferencePostedOn2 = postedOn2Date
        ? Math.floor((now.getTime() - postedOn2Date.getTime()) / (1000 * 3600 * 24))
        : Infinity;

      return daysDifferencePostedOn <= 7 || daysDifferencePostedOn2 <= 7;
    });

    if (filteredData.length === 0) return 0;

    const nextMidnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0, 0, 0, 0
    );

    const remainingTime = nextMidnight.getTime() - now.getTime();

    if (activeTimeoutId) {
      clearTimeout(activeTimeoutId);
    }

    activeTimeoutId = setTimeout(() => {
      try {
        callback(responseData);
      } catch (error) {
        console.error('Error updating temporal information:', error);
      }
    }, remainingTime);

    return remainingTime;
  };

  const updateTemporalInformation = (responseData) => {
    try {
      responseData.forEach((item) => {
        const ref = temporalInfoRefs.current[item.eventID];
        if (ref) {
          const updatedContent = formatDateKatex(
            getdateformat(item.postedOn, item.postedOn2, moment.tz.guess())
          );

          if (ref.innerHTML !== updatedContent) {
            ref.innerHTML = updatedContent;
          }
        }
      });

      if (responseData && responseData.length > 0) {
        addTimeoutForDateChange(updateTemporalInformation, responseData);
      }
    } catch (error) {
      console.error('Error in updating temporal information:', error);
    }
  };

  const updateCalendarData = (data) => {
    if (data) {
      updateTimetableData(data);
    }
  }

  const updateCalendarStyling = () => {
    const calendarRef = calendarRefs.current;

    //initially update the calendar styling according to available temporal information
    if (calendarRef) {
      updateStylingOfUserCalendar(
        calendarRef,
        activeDate ? activeDate : null,
        {
          valueCalendarOther: activeDate,
          availableEventsOnDates: timetableData.availableEventsOnDates,
          appendedTiles: timetableData.appendedTiles,
          allEventsList: states.events,
        },
        updateCalendarData,
      );
    } else {
      setTimeout(() => {
        updateCalendarStyling();
      }, 1);
    }
  }

  // function to update the active date based on tile colors
  const refreshActiveDateBasedOnTiles = () => {
    const calendarRef = calendarRefs.current;
    if (!calendarRef) {
      setTimeout(refreshActiveDateBasedOnTiles, 100);
      return;
    }

    const isMonthChanged = freshLoad || moment(prevActiveDate).format('MMYYYY') !== moment(activeDate).format('MMYYYY');

    if (isMonthChanged) {
      const result = updateActiveDatesBasedOnTileColors(
        calendarRef,
        activeDate,
        timetableData?.availableEventsOnDates,
        timetableData?.activeDateTileColorUpdationFlag
      );

      setFreshLoad(false);
      return result;
    }

    return null;
  };

  const updateMarginsOfTimetablesParent = (availableEvents) => {
    const calendarRef = calendarRefs.current;

    if (calendarRef && availableEvents?.length) {
      handleMarginsOfTimetablesParent(calendarRef, availableEvents);
    }
  }

  const changeCurrentTimeOld = (seconds, playerId) => {
    const player = playerRefs.current[playerId];
    if (player) {
      const currentTime = player.getState().player.currentTime;
      player.seek(currentTime + seconds);
    }
  };

  const SaveTemporalInformation = async (post, popupKey) => {
    // Initially make the popup and key empty
    if (openPopup) {
      setOpenPopup(false);
      setSavePopupKey("");
      await new Promise((resolve) => setTimeout(resolve, 300));
    }

    const token = localStorage.getItem('token');
    const profileType = localStorage.getItem('profileType');

    if (!post?.eventID || profileType === 'producer') return;

    setSavePopupKey(popupKey);

    if (!token) {
      let savedPosts = JSON.parse(localStorage.getItem('savedTemporalInfo')) || [];

      const existingIndex = savedPosts.findIndex(savedPost => savedPost.eventID === post.eventID);

      if (existingIndex !== -1) {
        setIsSaved(false);
        savedPosts.splice(existingIndex, 1);
      } else {
        setIsSaved(true);
        savedPosts.push(post);
      }

      localStorage.setItem('savedTemporalInfo', JSON.stringify(savedPosts)); // Save new array to local storage
    } else {
      const result = await saveTemporalInfo(post?.eventID);
      result.message === 'Event saved successfully' ? setIsSaved(true) : setIsSaved(false);
    }

    // Clear any existing timeout before setting a new one
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setOpenPopup(true);
    timeoutRef.current = setTimeout(() => {
      setOpenPopup(false);
      setSavePopupKey("");
      timeoutRef.current = null;
    }, 3000);
  };

  const checkdateOther = (date) => {
    for (let index = 0; index < states.events.length; index++) {
      var dateads = stringToDate(states.events[index].eventOn, 'DD/MM/YYYY');
      var elx = new Date(dateads);
      var finaldate = moment(elx).format('MMYYYY');
      if (date == finaldate) {
        return states.events[index].eventOn;
      }
    }
  }

  const getNextPrevDates = (dates) => {
    const nextDate = timetableData?.nextDate ? moment(timetableData.nextDate).format("DD-MM-YYYY") : '';
    const prevDate = timetableData?.prevDate ? moment(timetableData.prevDate).format("DD-MM-YYYY") : '';
    setNextDate(nextDate);
    setPrevDate(prevDate);

    return { nextDate, prevDate };
  }

  const onCalendarOtherUser = (e) => {
    // updateCalendarStyling(e); //update the styling according to available temporal information

    if (!timeoutRefCalendar.current) {
      timeoutRefCalendar.current = setTimeout(() => {
        const date = moment(e).format('DD-MM-YYYY');
        if (timetableData?.availableEventsOnDates && timetableData?.availableEventsOnDates?.find(x => x.eventOn === date)) {
          updateTimetableData({ activeDate: e }); // update the new active date
        }
        timeoutRefCalendar.current = null;
      }, 50);
    }
  }

  const fetchEventData = (date) => {
    // check if the date is already present in the savePost
    const formattedDate = moment(date).format('DD-MM-YYYY');
    const savePost = timetableData.savePost;
    if (savePost && savePost[0]?.date === formattedDate) {
      const data = showAllData ? savePost[0]?.eventDetail : savePost[0]?.eventDetail.slice(0, 1);
      setProfileData(savePost[0]?.eventDetail);
      setDisplayedProfileData(data);
      return;
    }
    if (savePost) {
      const data = showAllData ? savePost[0]?.eventDetail : savePost[0]?.eventDetail.slice(0, 1);
      setDisplayedProfileData(data);
      setProfileData(savePost[0]?.eventDetail);
    }

    // process new data and update the state
    const token = localStorage.getItem('token');
    const userId = userDetails.user_id;

    let url = '';
    if (token) {
      url = process.env.REACT_APP_BASE_URL + '/api/event/profileEventDateWise.php?profileId=' + userId + '&date=' + formattedDate;
    } else {
      url = process.env.REACT_APP_BASE_URL + '/api/event/profileEventDateWiseGuest.php?profileId=' + userId + '&date=' + formattedDate;
    }
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "TimeZone": moment.tz.guess(),
        "Authorization": "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const response = responseJson.response?.length ? responseJson.response : [];
        if (response.length) {
          addTimeoutForDateChange(updateTemporalInformation, response);
        }

        updateTimetableData({ profileData: responseJson.response });
        const nextPrevDate = getNextPrevDates(date);

        const post = timetableData.savePost;
        if (post) {
          const opendUser = userDetails.user_id;
          const isUserPresent = post.findIndex((data) => data.user == opendUser);

          if (isUserPresent !== -1) {
            const updatedSavePost = {
              savePost: post.map((data) => {
                if (data.user === opendUser) {
                  return {
                    ...data,
                    date: formattedDate,
                    eventDetail: responseJson.response,
                    nextMonthYear: nextPrevDate.nextDate,
                    previousMonthyear: nextPrevDate.prevDate,
                  };
                } else {
                  return data;
                }
              })
            }
            updateTimetableData(updatedSavePost);
          }
          else {
            const data = {
              savePost: [{
                date: formattedDate,
                user: userDetails.user_id,
                eventDetail: responseJson.response,
                allEvents: states.events,
                minDate: states.otherMinimum,
                maxDate: states.otherMaximum,
                previousMonthyear: prevDate,
                nextMonthYear: nextDate,
                otherMonthYear: states.otherMonthYear
              }]
            }
            updateTimetableData(data);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
  }

  const handleLabelChange = (action, value) => {
    if (!timeoutRefLable.current) {
      timeoutRefLable.current = setTimeout(() => {
        const currentMonth = formatDate(activeDate, "MMYYYY")
        const newMonth = formatDate(value, "MMYYYY")

        let updateAction = action
        let updateDate

        if (action === 'next') {
          updateDate = timetableData?.nextDate;
        } else if (action === 'prev') {
          updateDate = timetableData?.prevDate;
        }
        else if (action == 'onChange' && newMonth != currentMonth) {
          const change = value.getTime() - activeDate.getTime();

          if (!change)
            return

          updateAction = 'prev';
          if (change > 0)
            updateAction = 'next';

          updateDate = value
        }

        if (updateDate) {
          updateActiveDatesOfTimetable(updateDate, updateAction);
          // onCalendarOtherUser(updateDate);
          getNextPrevDates(updateDate);
        }
        timeoutRefLable.current = null;
      }, 50); // timeout to remove the glitch between tiles updation
    }
  }


  const handleProfileCalendarClick = (event) => {
    const target = event.target;

    if (target.matches("button.react-calendar__tile.react-calendar__month-view__days__day") || target.matches("button.react-calendar__navigation__next-button") || target.matches("button.react-calendar__navigation__prev-button")) {
      target.classList.add("clicked");
      setTimeout(() => {
        target.classList.remove("clicked");
      }, 1000);
    } else if (target.tagName === "ABBR") {
      const parentButton = target.closest("button.react-calendar__tile.react-calendar__month-view__days__day");
      if (parentButton) {
        parentButton.classList.add("clicked");
        setTimeout(() => {
          parentButton.classList.remove("clicked");
        }, 1000);
      }
    } else if (target && target.classList.contains("text-ellipsis")) {
      const eventID = target.getAttribute("data-eventid"); // Get event ID from dataset
      setExpandedEventIds((prev) => {
        if (!prev.includes(eventID)) {
          return [...prev, eventID]; 
        }
        return prev;
      });
    }
  };

  const tileClassName = useMemo(() => {
    return ({ activeStartDate, date }) => {
      if (states.events.length > 0) {
        const formattedDate = formatDate(date, "DD-MM-YYYY");
        const event = timetableData.availableEventsOnDates.find(x => x.eventOn === formattedDate);

        if (formattedDate === formatDate(activeDate, "DD-MM-YYYY") && timetableData.availableEventsOnDates.length !== 1) {
          return 'orange';
        }

        let isActiveMonth = activeStartDate.getMonth() == date.getMonth()
        let displayAlternateTiles = userDetails.alternate_tiles && isActiveMonth
        let className = ""

        if (displayAlternateTiles && !event?.tile_type) {
          let dateInNumber = date.getDate()

          let dividend = dateInNumber

          if (isCompressedCalender) {
            const isDatePresent = timetableData.availableEventsOnDates.findIndex((obj) => obj?.eventOn === formattedDate);
            dividend = isDatePresent
          }
          className = dividend % 2 === 0 ? 'highlight' : 'darkblue';
        }
        // if (calendar == 'current' && subspaceNumber == 1)
        // console.log(date.getDate());

        if (event) {
          if (displayAlternateTiles) {
            return event?.tile_type || className
          }
          if (event.importantEvents) {
            return 'darkblue';
          }

          if (event.dateCount === 1) {
            return 'highlight';
          }

          if (event.dateCount !== 1) {
            return 'darkblue';
          }

          return 'yellow';
        } else {
          return 'blocklight' + (isCompressedCalender ? " dateOff" : '');
        }
      }
      return '';
    };
  }, [activeDate]);

  return (
    <>
      {activeDate && <div ref={containerRef} className={`sectionone sectionone_div ${calendar}`}>
        <div className={(!userDetails?.bio || !userDetails?.website) ? "inkap emptybio prin" : "inkap"}>
          <div className="inkapil">
            <div>
              <div class="table" ref={calendarRefs}>
                <Calendar
                  onChange={(e) => onCalendarOtherUser(e)} // timeout to remove the glitch between tiles updation
                  activeStartDate={activeDate}
                  locale="en-US" // Set locale to US English
                  firstDayOfWeek={0}
                  tileContent={({ date, view }) => {
                    const isActiveDate = view === "month" &&
                      moment(date).format("DD-MM-YYYY") === moment(activeDate).format("DD-MM-YYYY") &&
                      timetableData?.availableEventsOnDates?.length === 1;

                    return isActiveDate ? <DownwardArrow className="activeDateIndicator" /> : null;
                  }}
                  minDate={timetableData.minDate}
                  maxDate={timetableData.maxDate}
                  onActiveStartDateChange={({ action, value }) => handleLabelChange(action, value)} // timeout to remove the glitch between tiles updation
                  tileClassName={tileClassName}
                  className="compressedCalendar"
                />
              </div></div>
          </div>
        </div>
        {userDetails?.isFollowing == 1 || userDetails?.make_public == 1 && <div className="mb-2">
          {displayedProfileData && displayedProfileData?.length > 0 ? displayedProfileData.map((j, x) => {
            return (
              <div
                className="temporal-information-block"
                key={`key-${x}`}
              >
                <div class="likeaddto" id={"promo" + j.eventID}>
                  <img
                    class="w-100"
                    src="../../images/More.png"
                    alt="image"
                  />
                </div>
                <div class="likeaddto" id={"promi" + j.eventID}>
                  <img
                    class="w-100"
                    src="../../images/Erase.png"
                    alt="image"
                  />
                </div>
                <div id={"crox" + j.eventID} className="postlike">
                  <div className="userin rmrf">
                    <h1
                      className={`new-heading-tex-789 ${userProfileType === "producer" &&
                        "disableSaveInfo"
                        }`}
                      onMouseOver={(e) =>
                        handleDateDifferenceContainer(
                          e,
                          subspaceNumber
                        )
                      }
                    >
                      <div
                        style={{ width: "fit-content" }}
                        ref={(el) =>
                          (temporalInfoRefs.current[j.eventID] = el)
                        }
                        onClick={() =>
                          SaveTemporalInformation(j, `key-${x}`)
                        }
                        dangerouslySetInnerHTML={{
                          __html: formatDateKatex(
                            getdateformat(
                              j.postedOn,
                              j.postedOn2,
                              j.timezone
                            )
                          ),
                        }}
                      ></div>
                      {openPopup && savePopupKey === `key-${x}` && (
                        <div className="TIActionStatus">
                          <p>{isSaved ? "Saved" : "Unsaved"}</p>
                        </div>
                      )}
                    </h1>
                    <DateDifferenceContainer />
                    <h5>
                      <div className="katexParentBlock">
                        {j?.link ? (
                          <div
                            id="parentLinkFromLatex"
                            className='parentLinkFromLatex'
                            data-href={j?.link}
                            target={subspaceNumber == 1 ? "_self" : "_blank"}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: (() => {
                                  const { text: caption, isTrimmed } = expandedEventIds.includes(String(j.eventID))
                                    ? { text: j.status, isTrimmed: false }
                                    : truncateExcludingLinks(j.status);
                                  return fetchTeXCompiledCaption(caption, subspaceNumber, isTrimmed, j.eventID);
                                })(),
                              }}
                            />
                            <p className="hoveredViews" style={{ marginTop: !showAllData && profileData.length > 1 ? "20px" : "5px" }}>
                              {`${j.views || 0
                                } ${j?.views === 1 ? "view" : "views"} since ${getdateformat(
                                  j.created_at,
                                  null,
                                  j.timezone
                                )}`}
                            </p>
                          </div>
                        ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: (() => {
                                  const { text: caption, isTrimmed } = expandedEventIds.includes(String(j.eventID))
                                    ? { text: j.status, isTrimmed: false }
                                    : truncateExcludingLinks(j.status);
                                  return fetchTeXCompiledCaption(caption, subspaceNumber, isTrimmed, j.eventID);
                                })(),
                              }}
                            />
                        )}
                        {selfProfile && (
                          <a
                            className="editDeleteButton"
                            href="javascript:void(0)"
                            id={j.eventID}
                            onClick={(e) => editDeletePost(e, j)}
                          >
                            <img
                              className="option ml-3"
                              src="../../images/pencil.png"
                              align="icon"
                            />
                          </a>
                        )}
                      </div>
                      {(!showAllData && profileData.length > 1) && (
                        <div
                          className="expand-more"
                          onClick={() => {
                            setShowAllData(true);
                          }}
                        >
                          ...
                        </div>
                      )}
                    </h5>
                  </div>
                  <div className="post ">
                    {(() => {
                      if (j.mediaType == "video") {
                        return (
                          <div id={"Video" + j.eventID} class="video-div">
                            <VideoPlayer
                              updateMarginsOfTimetablesParent={updateMarginsOfTimetablesParent}
                              timetableData={timetableData}
                              id={"Video" + j.eventID}
                              url={j.eventImage}
                            >
                              {/* <ControlBar autoHide={false} className="my-class" /> */}
                            </VideoPlayer>
                          </div>
                        );
                      } else if (j.mediaType == "image") {
                        return (
                          <img
                            className="w-100 mt-1"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              j.eventImage
                            }
                            alt="image"
                          />
                        );
                      }
                    })()}
                    <div className="hearti">
                      <img
                        className="w-100"
                        src="../../images/cklick.png"
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
            : ""}
        </div>}
      </div>
      }
    </>
  )
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.timetableData === nextProps.timetableData;
}

export default React.memo(Timetable, areEqual);

export const weekDays = (dayNumber) => {
  if (dayNumber == 0) {
    return "Sunday";
  }
  if (dayNumber == 6) {
    return "Saturday";
  }
  if (dayNumber == 5) {
    return "Friday";
  }
  if (dayNumber == 4) {
    return "Thursday";
  }
  if (dayNumber == 3) {
    return "Wednesday";
  }
  if (dayNumber == 2) {
    return "Tuesday";
  }
  if (dayNumber == 1) {
    return "Monday";
  }
}

export const stringToDate = (dateString, format) => moment(dateString, format)

export const formatDate = (date, format) => moment(date).format(format)
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomPopUp from "../../CustomPopup";
import { MyContext } from "../../context";
import { removeProfileDataFromStorage } from "../../utils/profiles/manageProfilesList";

export default function Login({updateView,showPath,parentPathName, history, location}) {
  const [openPopup, setOpenPopup] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const context = useContext(MyContext)
  const pathname = location.pathname

  const popupData = [
    {
      heading: "Invalid credentials",
      message: 'Provide valid login credentials.',
      onClick: () => {
        closePopup();
      },
    },
  ];

  const { sharedData } = context

  let defaultValues = {
    email: "",
    password: ""
  }

  if (sharedData) {
    if (sharedData.pageWiseData[parentPathName] && sharedData.pageWiseData[parentPathName][pathname]) {
      defaultValues = {
        email: sharedData.pageWiseData[parentPathName][pathname].email,
        password: sharedData.pageWiseData[parentPathName][pathname].password
      }
    }
  }

  // const emailRef = useRef(defaultValues.email);
  // const passwordRef = useRef(defaultValues.password);

  // function validateForm() {

  //   return email.length > 0 && password.length > 0;

  // }
// useEffect(()=>{return ()=>{
//   emailRef.current = email
//   passwordRef.current = password
// }},[email, password])

  function handleSubmit(event) {
    event.preventDefault();

    if (email == '' || password == '') {
      setOpenPopup(true);
      return;
    }
    
    const body = {
      email: email,
      password: password,
      deviceType: "android",
      deviceToken:
        "cHoLreb1SGacDSQdDS-yFP:APA91bHDXlU8BgbbbuHyMIYjMhRC2OQK3KIs1BbuUSqoIp9IZsrn2xX7m2l06MEdVUbgkqet962BA61SD2vqpv47-B1jtxNxAGgYyo7BwqE_uS-jrQXcsYqMi1hNRfnBi99AYXuXRKCA",
      deviceId: "62406169083a90ad67",
    };
    axios
      .post(process.env.REACT_APP_BASE_URL_NODE + "/api/login", body)
      .then((res) => {
        if (res.data.status === "success") {
          localStorage.setItem("timeToken", res.data.token);
          localStorage.setItem("email", res.data.data.email);
          localStorage.setItem("screenname", res.data.data.screenName);
          localStorage.setItem("totalpost", res.data.data.totalPost);
          localStorage.setItem("username", res.data.data.username);
          localStorage.setItem("followers", res.data.data.followers);
          localStorage.setItem("request", res.data.data.request);
          localStorage.setItem("bio", res.data.data.bio);
          localStorage.setItem("following", res.data.data.following);
          localStorage.setItem("profileCover", res.data.data.profileCover);
          localStorage.setItem("profileImage", res.data.data.profileImage);
          localStorage.setItem("userid", res.data.data.user_id);
          localStorage.setItem("make_public", res.data.data.make_public);
          localStorage.setItem("website", res.data.data.website);
          localStorage.setItem("profileType", res.data.data.profileType);

          axios
            .post(process.env.REACT_APP_BASE_URL + "/api/user/login.php", body)
            .then((res) => {
              localStorage.setItem("totalpost", res.data.response.totalPost);
              localStorage.setItem("token", res.data.response.token);
              var first = localStorage.getItem("screenname").split(" ")[0];
              var firstn = first?.charAt(0);

              if (localStorage.getItem("screenname")?.split(" ")[1]) {
                var second = localStorage.getItem("screenname").split(" ")[1];
                var secondn = second?.charAt(0);
                localStorage.setItem(
                  "staticname",
                  firstn + (secondn ? secondn : "")
                );
              } else {
                localStorage.setItem("staticname", firstn);
              }
              removeProfileDataFromStorage()
              history.push("/");
              window.location.reload();
            })
            .catch((error) => {
              setInvalidLogin(true);
              console.log("err msg:", error.message);
            });
        }
      })
      .catch((error) => {
        setInvalidLogin(true);
        console.log("err msg:", error.message);
      });
  }

  useEffect(() => {

    return () => {
      updateSharedDataInContext()
    }
  }, [])
  function updateSharedDataInContext() {
    const { sharedData, setSharedData } = context
    sharedData.pageWiseData[parentPathName] = {
      ...sharedData.pageWiseData[parentPathName],
      [pathname]: { email: email, password: password }
    }

    setSharedData({ ...sharedData });
  }

  function closePopup () {
    setInvalidLogin(false);
  }
  return (
      <>
        <form className="form-signin login" onSubmit={handleSubmit}>
           <h3>Login</h3>
           <div className="form-group">
           <label>identifier or email</label>
          <input
            name="email"
            autocomplete="off"
            autocorrect="off"
            autoCapitalize="none"
            id="inputEmail"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            placeholder="identifier or email"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevents form submission
                document.getElementById("inputPassword").focus();
              }
            }}
          />
           </div>
        <div className="form-group mb-0">
          <label>password</label>
          <input
            type="password"
            name="password"
            id="inputPassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            placeholder="password"
          />
           </div>
           {/* <p className="mb-2 text-right"><a className="link"  data-toggle="tab" href="#oneforgot">forgot password?</a></p> */}
           <button className="btn btn-lg btn-block" type="submit">Login</button>
           <h6 className="text-center">
           { showPath ?
               <Link to={'/sign-up'} onClick={updateView} className="link" data-toggle="tab" >Sign Up</Link>
            :  <Link to={'/sign-up'}  className="link" data-toggle="tab">
                  Sign up
               </Link>
            }
           </h6>
        </form>
        {invalidLogin && (
          <CustomPopUp
            isOpen={invalidLogin}
            onClose={() => setInvalidLogin(false)}
            popup={popupData[0]}
            multipleButton={false}
          />
        )}
        {openPopup && (
          <CustomPopUp
            isOpen={openPopup}
            onClose={() => setOpenPopup(false)}
            popup={{
              heading: "Unfulfilled fields",
              message: 'Fill in all fields.',
              onClick: () => {
                setOpenPopup(false);
              },
            }}
            multipleButton={false}
          />
        )} 
      </>

  );

}

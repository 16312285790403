import moment from "moment";

// Helper functions to get closest date based on available events (first priority is from future if not present then from past)
const findClosestEventDate = (events, allEvents) => {
    const currentDate = moment();
    const today = currentDate.clone().startOf('day');
    let futureEvent = null;
    let pastEvent = null;
    let closestFutureDiff = Infinity;
    let closestPastDiff = Infinity;

    events.forEach((event) => {
        const eventDate = moment(event.eventOn, "DD-MM-YYYY").startOf('day');
        const diffToToday = eventDate.diff(today, 'days');

        if (diffToToday >= 0 && diffToToday < closestFutureDiff) {
            closestFutureDiff = diffToToday;
            futureEvent = event;
        }
        if (diffToToday < 0 && Math.abs(diffToToday) < closestPastDiff) {
            closestPastDiff = Math.abs(diffToToday);
            pastEvent = event;
        }
    });

    // If no future event in current month, check allEvents
    if (!futureEvent && allEvents?.length) {
        findClosestEventDate(allEvents);
    }

    return futureEvent
        ? moment(futureEvent.eventOn, "DD-MM-YYYY").toDate()
        : pastEvent
            ? moment(pastEvent.eventOn, "DD-MM-YYYY").toDate()
            : null;
};

// Helper functions to get active date for the past month based on available tiles inside that month
const findEventDateForPastMonth = (availableEvents) => {
    let eventToBeSelect = null;
    // "highlight" is considered as light blue and "darkblue" as dark blue
    const highlightedTiles = availableEvents.filter(event => event.tile_type === "highlight")?.length || 0;
    const darkblueTiles = availableEvents.length - highlightedTiles;

    if (highlightedTiles > darkblueTiles) {
        eventToBeSelect = availableEvents.find(event => event.tile_type === "highlight");
    } else if (highlightedTiles < darkblueTiles) {
        eventToBeSelect = availableEvents.find(event => event.tile_type === "darkblue");
    } else {
        const highlightEvents = availableEvents.filter(event => event.tile_type === "highlight");

        eventToBeSelect = highlightEvents[Math.floor(Math.random() * highlightEvents.length)];
    }

    return eventToBeSelect
        ? moment(eventToBeSelect.eventOn, "DD-MM-YYYY").toDate()
        : null;
};

// Helper function to get active date for the immediate next month which is to select the first light-blue tile from the available events else select earliest event
const findEventDateForImmediateFutureMonth = (availableEvents) => {
    const lightBlueEvents = availableEvents.filter(event => event.tile_type === "highlight");
    if (lightBlueEvents.length > 0) {
        const firstLightBlue = lightBlueEvents.sort((a, b) => {
            return moment(a.eventOn, "DD-MM-YYYY") - moment(b.eventOn, "DD-MM-YYYY");
        })[0];
        return firstLightBlue
            ? moment(firstLightBlue.eventOn, "DD-MM-YYYY").toDate()
            : null;
    }

    // Fallback: if no light-blue event exists, return the earliest event from the month.
    const firstEventOfMonth = availableEvents.sort((a, b) => {
        return moment(a.eventOn, "DD-MM-YYYY") - moment(b.eventOn, "DD-MM-YYYY");
    })[0];
    return firstEventOfMonth
        ? moment(firstEventOfMonth.eventOn, "DD-MM-YYYY").toDate()
        : null;
};

// Helper function for non-immediate future, we choose the date here in the same way as for past months
const findEventDateForNonImmediateFutureMonth = (availableEvents) => {
    return findEventDateForPastMonth(availableEvents);
};

// Main function to determine and update the active dates for the timetable
export const determineAndUpdateActiveDatesForTimetables = (timetableData, allEvents) => {
    if (!timetableData) return undefined;

    const currentDate = moment();

    // Create a new month entry where each timetable data (or its subtimetables) is treated as single/separate month entry
    const monthEntries = [];
    Object.values(timetableData).forEach(timetable => {
        if (timetable.subTimetables && Object.keys(timetable.subTimetables).length > 0) {
            Object.keys(timetable.subTimetables).forEach(subKey => {
                const subCal = timetable.subTimetables[subKey];
                if (subCal.availableEventsOnDates && subCal.availableEventsOnDates.length > 0) {
                    monthEntries.push({
                        type: 'sub',
                        parent: timetable,
                        key: subKey,
                        availableEvents: subCal.availableEventsOnDates,
                        activeDate: subCal.activeDate
                    });
                }
            });
        } else if (timetable.availableEventsOnDates && timetable.availableEventsOnDates.length > 0) {
            monthEntries.push({
                type: 'main',
                parent: timetable,
                availableEvents: timetable.availableEventsOnDates,
                activeDate: timetable.activeDate
            });
        }
    });

    // Determine the category based on the range of events
    monthEntries.forEach(entry => {
        const events = entry.availableEvents;
        if (events && events.length > 0) {
            const sortedDates = events
                .map(e => moment(e.eventOn, "DD-MM-YYYY"))
                .sort((a, b) => a - b);
            entry.minEventDate = sortedDates[0];
            entry.maxEventDate = sortedDates[sortedDates.length - 1];
        } else {
            entry.minEventDate = null;
            entry.maxEventDate = null;
        }

        if (entry.minEventDate && entry.maxEventDate) {
            if (currentDate.isBetween(entry.minEventDate, entry.maxEventDate, null, '[]')) {
                entry.category = 'current';
            } else if (currentDate.isAfter(entry.maxEventDate)) {
                entry.category = 'past';
            } else if (currentDate.isBefore(entry.minEventDate)) {
                entry.category = 'future';
            }
        } else {
            entry.category = 'unknown';
        }
    });

    // Among future entries mark the one with the immediate future and all as non-immediate future entries
    const futureEntries = monthEntries.filter(entry => entry.category === 'future');
    if (futureEntries.length > 0) {
        futureEntries.sort((a, b) => a.minEventDate - b.minEventDate);
        futureEntries[0].category = 'immediateFuture';
        for (let i = 1; i < futureEntries.length; i++) {
            futureEntries[i].category = 'nonImmediateFuture';
        }
    }

    // Update the active dates based on the category
    monthEntries.forEach(entry => {
        const events = entry.availableEvents;
        // const activeMonthYear = moment(entry?.activeDate).format("MMYYYY");
        // const currentMonthYear = moment(new Date()).format("MMYYYY");
        let updatedActiveDate = null;

        if (events && (events.length === 2 || events.length === 4)) {
            updatedActiveDate = moment(events[1].eventOn, "DD-MM-YYYY").toDate();
        } else {
            switch (entry.category) {
                case 'current':
                    updatedActiveDate = findClosestEventDate(events, allEvents);
                    break;
                case 'past':
                    updatedActiveDate = findEventDateForPastMonth(events);
                    break;
                case 'immediateFuture':
                    updatedActiveDate = findEventDateForImmediateFutureMonth(events);
                    break;
                case 'nonImmediateFuture':
                    updatedActiveDate = findEventDateForNonImmediateFutureMonth(events);
                    break;
                default:
                    updatedActiveDate = null;
            }
        }

        // Update the original timetable object.
        if (entry.type === 'sub') {
            if (!entry.parent.subTimetables[entry.key].activeDateUpdationFlag) {
                entry.parent.subTimetables[entry.key].activeDate = updatedActiveDate;
                entry.parent.subTimetables[entry.key].activeDateUpdationFlag = true;
            }
        } else {
            if (!entry.parent.activeDateUpdationFlag) {
                entry.parent.activeDate = updatedActiveDate;
                entry.parent.activeDateUpdationFlag = true;
            }
        }
    });

    return timetableData;
};


export const updateActiveDatesBasedOnTileColors = (calendarRef, activeDate, availableEvents, activeDateTileColorUpdationFlag = false) => {
    if (calendarRef) {
        // const activeMonthYear = moment(activeDate).format("MMYYYY");
        // const currentMonthYear = moment(new Date()).format("MMYYYY");

        // if (activeMonthYear === currentMonthYear) {
        //     return null;
        // };

        const tilesContainer = calendarRef.querySelector('.react-calendar__month-view__days');
        const tilesList = tilesContainer?.querySelectorAll('.react-calendar__tile');

        const currentDate = moment();
        const eventDates = availableEvents.map(event => moment(event.eventOn, "DD-MM-YYYY"));

        // Find the min and max date in the array
        const minDate = moment.min(eventDates);
        const maxDate = moment.max(eventDates);
        const isCurrentDateInRange = currentDate.isBetween(minDate, maxDate, null, "[]");
        
        if (!tilesList || isCurrentDateInRange) return null;

        const visibleTiles = Array.from(tilesList).filter(tile => !tile.classList.contains('dateOff'));

        const getColorCode = (tile, index, tilesArray) => {
            if (tile.classList.contains('react-calendar__month-view__days__day--neighboringMonth')) {
                return 'g';
            } else if (tile.classList.contains('darkblue')) {
                return 'd';
            } else if (tile.classList.contains('highlight')) {
                return 'l';
            } else if (tile.classList.contains('orange')) {
                const prevTile = tilesArray[index - 1];
                const nextTile = tilesArray[index + 1];

                if (prevTile?.classList.contains('highlight')) {
                    return 'd';
                }
                if (prevTile?.classList.contains('react-calendar__month-view__days__day--neighboringMonth')) {
                    if (nextTile?.classList.contains('highlight')) {
                        return 'd';
                    }
                    return 'l';
                }
                return 'l';
            } else {
                return '';
            }
        };

        const colorPattern = visibleTiles.map((tile, index) => getColorCode(tile, index, visibleTiles)).join('');

        const selectionRules = {
            'gldldg': 4,
            'gldldl': 4,
            'gdldld': 6,
            'gdldlg': 3,
            'ggdldl': 4,
            'ldldgg': 3,
            'ldldlg': 1,
            'dldlgg': 4,
            'ggldld': 3,
            'ldldld': 3,
            'dldldl': 1,
            'gldldldlg': 6,
        };

        let selectedTileCount = 0;
        Object.entries(selectionRules).some(([pattern, count]) => {
            if (colorPattern.startsWith(pattern)) {
                selectedTileCount = count;
                return true;
            }
            return false;
        });

        if (selectedTileCount) {
            const selectedTile = visibleTiles[selectedTileCount - 1];
            const abbrElement = selectedTile.querySelector('abbr');
            const ariaLabel = abbrElement?.getAttribute('aria-label');

            if (ariaLabel) {
                const dateObject = new Date(ariaLabel);
                if (moment(dateObject).format('DD-MM-YYYY') !== moment(activeDate).format('DD-MM-YYYY')) {
                    if (!activeDateTileColorUpdationFlag) {
                        return dateObject;
                    }
                }
                return null;
            } else {
                return null;
            }
        }
    }
};
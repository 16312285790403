import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { ControlBar, Player } from 'video-react';
import { fetchSavedTemporalInfo } from '../../Services/api';
import { MyContext } from '../../context';
import { getdateformat } from '../../utils/dateFormatterForTI/dateFormatter';
import { formatDate } from '../../utils/math';
import { fetchTeXCompiledCaption, modifyHref } from '../../utils/post/manageCaptions';
import { ReactComponent as TimelineSvg } from '../../utils/svg/Bidirectionally-traversed-timeline.svg';
import { ReactComponent as CalendarSvg } from '../../utils/svg/Calendar.svg';
import { ReactComponent as DepartSvg } from '../../utils/svg/Depart.svg';
import { formatUnixTimestamp } from '../../utils/time';
import {
    getActiveDatesOfTheMonth, getUniqueDatesOfTheMonth, getActiveDateBasedOnImportantEvent,
    handleSubCalendarData, unsetTimetableData, shiftMonthsBackward, shiftMonthsForward, addNextAndPrevDates, updateVisibilityOfSubTimetables
} from '../../utils/timetableHelper/timetableHelper';
import Footer from '../Footer';
import GuestFooter from '../GuestFooter';
import BookmarkTimetable from '../Timetable/BookmarkTimetable';
import CustomVideoPlayer from '../customVideoPlayer/customVideoPlayer';

export const GRADIENT_COLORS = [
    { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
    { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
    { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
    { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
    { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
    { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];

class SavedTemporalInfo extends React.Component {
    static contextType = MyContext
    constructor(props) {
        super(props);
        let UserName = null;
        const params = this.props.location.pathname.split('/');
        UserName = params[params.length - 1];
        this.state = {
            Username: UserName,
            randColor: {},
            cscreenname: "",
            cbio: "",
            cwebsite: "",
            eventsList: [],
            other_minimum: "",
            other_maximum: "",
            evntData: [],
            contat: "of",
            topcontat: "of",
            baseUrl: process.env.REACT_APP_BASE_URL,
            baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
            time: false,
            monthYearList: [],
            monthYearWithDatesList: {},
            umid: "",
            hasMore: true,
            pathname: this.props.location.pathname,
            clickedButtonToExit: "",
            activeTab: "",
            isTimelineActive: false,
            leftSideArray: [],
            monthYearDateMap: {},
            preventNavigation: false,
            shouldUpdateOnSamePath: false,
            timetableData: {
                threeMonthsEarlier: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: [],
                    subTimetables: {}
                },
                twoMonthsEarlier: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: [],
                    subTimetables: {}
                },
                previous: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: [],
                    subTimetables: {}
                },
                current: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: [],
                    subTimetables: {}
                },
                next: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: [],
                    subTimetables: {}
                },
                twoMonthsAhead: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: [],
                    subTimetables: {}
                },
                threeMonthsAhead: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: [],
                    subTimetables: {}
                }
            },
        };
        this.child = React.createRef();
        this.calendarRef = React.createRef();
        this.footerPropsForUpdateOnSamePath = {
            isParentUserProfile: true,
            setShouldUpdateOnSamePath: this.setShouldUpdateOnSamePath
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { appContext } = this.props;
        const { state } = appContext;
        // check when the homepage status will update
        if (prevProps.appContext.state !== state) {
            this.checkBackNavigation();
        }

        if (this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== "" && this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== undefined) {
            if (this.state.Username !== this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]) {
                var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
                this.setState({ randColor: random_color, Username: this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] });
            }
        }
        const { activeTab, sharedData } = this.context
        if (this.state.shouldUpdateOnSamePath) {
            if (activeTab !== prevState.activeTab)
                this.setState({ activeTab })

            this.props.setVisitedPaths([...sharedData.tabWiseData[activeTab].openProfiles])
            this.checkBackNavigation();
            this.setShouldUpdateOnSamePath(false)
        }
    }
    handleMouseOver() {
        $(".katexContentBlock .katex a").attr("target", "_blank");
        $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
    }
    componentDidMount() {
        const { sharedData, activeTab } = this.context;

        if (sharedData) {
            if (sharedData.pageWiseData['saved'])
                this.setState({ ...sharedData.pageWiseData['saved'] })
        }

        this.getSavedTemporalInfo({freshLoad: true});

        this.setState({ activeTab: activeTab, preventNavigation: false });
        localStorage.setItem("footer", "second");
        document.addEventListener('mouseover', this.handleMouseOver);
    }

    updateSharedDataInContext() {
        const { sharedData, setSharedData } = this.context

        sharedData.pageWiseData[this.state.pathname] = this.state

        setSharedData({
            ...sharedData,
            lastViewedUser: this.state,
        });
    }

    async getSavedTemporalInfo(obj) {
        const token = localStorage.getItem('token');
        if (!token) {
            const savedInfo = JSON.parse(localStorage.getItem('savedTemporalInfo'));
            if (!savedInfo?.length && obj?.freshLoad) {
                this.setState({ time: true });
                return
            }
            const { sortedEvents, monthYearArray, monthYearWithDatesObject } = this.createEventList(savedInfo);
            this.setState({ eventsList: sortedEvents, monthYearList: monthYearArray, monthYearWithDatesList: monthYearWithDatesObject }, () => {
                this.mapMonthYearToDate().then(() => this.setActiveDatesOfTimetables());
            });
        } else {
            const savedInfo = await fetchSavedTemporalInfo();
            if (!savedInfo?.length) {
                this.setState({ time: true });
                return
            }
            const { sortedEvents, monthYearArray, monthYearWithDatesObject } = this.createEventList(savedInfo);
            this.setState({ eventsList: sortedEvents, monthYearList: monthYearArray, monthYearWithDatesList: monthYearWithDatesObject }, () => {
                this.mapMonthYearToDate().then(() => this.setActiveDatesOfTimetables());
            });
        }
    }

    createEventList(savedPosts) {
        const eventMap = {};
        const currentDate = moment();

        if (savedPosts?.length > 0) {
            savedPosts.forEach(post => {
                const dates = post.postedOn2 ? [post.postedOn, post.postedOn2] : [post.postedOn];

                dates.forEach(date => {
                    const eventOn = formatUnixTimestamp(date, post.timezone)[0].format("DD-MM-YYYY");
                    const userKey = post.screenName || post.eventBy;
                    const mapKey = `${eventOn}_${userKey}`;

                    const timeDiff = currentDate.diff(moment.unix(date), 'seconds');

                    if (!eventMap[mapKey]) {
                        eventMap[mapKey] = {
                            eventOn,
                            eventBy: post.eventBy,
                            dateCount: 0,
                            TIMEDIFF: timeDiff,
                            priority: 1,
                            importantEvents: 0
                        };
                    }

                    eventMap[mapKey].dateCount += 1;

                    if (post.isImportant) {
                        eventMap[mapKey].importantEvents += 1;
                    }
                });
            });
            const eventList = Object.values(eventMap).sort((a, b) => (moment(a.eventOn, "DDMMYYYY") > moment(b.eventOn, "DDMMYYYY")) ? 1 : -1).reverse();
            const monthYearArray = [];
            const monthYearWithDatesObject = {};

            const sortedEvents = eventList
                .sort((a, b) => {
                    const dateA = moment(a.eventOn, "DD-MM-YYYY");
                    const dateB = moment(b.eventOn, "DD-MM-YYYY");

                    if (dateB.year() !== dateA.year()) {
                        return dateB.year() - dateA.year();
                    }

                    return dateB.month() - dateA.month();
                })
                .map(event => {
                    const date = moment(event.eventOn, "DD-MM-YYYY");
                    const monthYear = date.format("MMYYYY");

                    if (!monthYearArray.includes(monthYear)) {
                        monthYearArray.push(monthYear);
                        monthYearWithDatesObject[monthYear] = date.toDate();
                    }

                    return event;
                });

            this.setState({
                other_minimum: moment(sortedEvents[sortedEvents.length - 1].eventOn, "DD-MM-YYYY").toDate(),
                other_maximum: moment(sortedEvents[0].eventOn, "DD-MM-YYYY").toDate()
            });

            return { sortedEvents, monthYearArray, monthYearWithDatesObject };
        } else {
            return { sortedEvents: [], monthYearArray: [], monthYearWithDatesObject: [] };
        }
    }

    setActiveDatesOfTimetables(date) {
        const eventList = this.state.eventsList;
        const monthYears = this.state.monthYearList;
        let nearestEventDate = null;
        let nearestEventMonthYear = null;

        if (!eventList.length)
            return

        if (date) {
            nearestEventDate = date;
            nearestEventMonthYear = moment(date).format("MMYYYY");
        } else {
            const nearestEvent = eventList.reduce((closest, event) => {
                return Math.abs(event.TIMEDIFF) < Math.abs(closest.TIMEDIFF) ? event : closest;
            });
            nearestEventDate = moment(nearestEvent.eventOn, "DD-MM-YYYY").toDate();
            nearestEventMonthYear = moment(nearestEventDate).format("MMYYYY");
        }
        const monthDateMapping = this.setShowMonths(nearestEventDate); // to set the months to show on header
        const { threeMonthsEarlier, twoMonthsEarlier, prev, current, next, twoMonthsAhead, threeMonthsAhead } = this.getActiveDatesOfNextPrevMonths(date, monthDateMapping);
        
        this.setState(prevState => ({
            timetableData: {
                threeMonthsEarlier: {
                    ...prevState.timetableData.threeMonthsEarlier,
                    activeDate: threeMonthsEarlier?.activeDate,
                    availableEventsOnActiveDate: threeMonthsEarlier?.activeDate ? this.getEventsOfActiveDate(threeMonthsEarlier?.activeDate) : [],
                    availableEventsOnDates: threeMonthsEarlier?.availableEventsOnDates,
                    subTimetables: threeMonthsEarlier?.subTimetables,
                    nextDate: threeMonthsEarlier?.nextDate || undefined,
                    prevDate: threeMonthsEarlier?.prevDate || undefined
                },
                twoMonthsEarlier: {
                    ...prevState.timetableData.twoMonthsEarlier,
                    activeDate: twoMonthsEarlier?.activeDate,
                    availableEventsOnActiveDate: twoMonthsEarlier?.activeDate ? this.getEventsOfActiveDate(twoMonthsEarlier?.activeDate) : [],
                    availableEventsOnDates: twoMonthsEarlier?.availableEventsOnDates,
                    subTimetables: twoMonthsEarlier?.subTimetables,
                    nextDate: twoMonthsEarlier?.nextDate || undefined,
                    prevDate: twoMonthsEarlier?.prevDate || undefined
                },
                previous: {
                    ...prevState.timetableData.previous,
                    activeDate: prev?.activeDate,
                    availableEventsOnActiveDate: prev?.activeDate ? this.getEventsOfActiveDate(prev?.activeDate) : [],
                    availableEventsOnDates: prev?.availableEventsOnDates,
                    subTimetables: prev?.subTimetables,
                    nextDate: prev?.nextDate || undefined,
                    prevDate: prev?.prevDate || undefined
                },
                current: {
                    ...prevState.timetableData.current,
                    activeDate: current?.activeDate,
                    availableEventsOnActiveDate: current?.activeDate ? this.getEventsOfActiveDate(current?.activeDate) : [],
                    availableEventsOnDates: current?.availableEventsOnDates,
                    subTimetables: current?.subTimetables,
                    nextDate: current?.nextDate || undefined,
                    prevDate: current?.prevDate || undefined
                },
                next: {
                    ...prevState.timetableData.next,
                    activeDate: next?.activeDate,
                    availableEventsOnActiveDate: next?.activeDate ? this.getEventsOfActiveDate(next?.activeDate) : [],
                    availableEventsOnDates: next?.availableEventsOnDates,
                    subTimetables: next?.subTimetables,
                    nextDate: next?.nextDate || undefined,
                    prevDate: next?.prevDate || undefined
                },
                twoMonthsAhead: {
                    ...prevState.timetableData.twoMonthsAhead,
                    activeDate: twoMonthsAhead?.activeDate,
                    availableEventsOnActiveDate: twoMonthsAhead?.activeDate ? this.getEventsOfActiveDate(twoMonthsAhead?.activeDate) : [],
                    availableEventsOnDates: twoMonthsAhead?.availableEventsOnDates,
                    subTimetables: twoMonthsAhead?.subTimetables,
                    nextDate: twoMonthsAhead?.nextDate || undefined,
                    prevDate: twoMonthsAhead?.prevDate || undefined
                },
                threeMonthsAhead: {
                    ...prevState.timetableData.threeMonthsAhead,
                    activeDate: threeMonthsAhead?.activeDate,
                    availableEventsOnActiveDate: threeMonthsAhead?.activeDate ? this.getEventsOfActiveDate(threeMonthsAhead?.activeDate) : [],
                    availableEventsOnDates: threeMonthsAhead?.availableEventsOnDates,
                    subTimetables: threeMonthsAhead?.subTimetables,
                    nextDate: threeMonthsAhead?.nextDate || undefined,
                    prevDate: threeMonthsAhead?.prevDate || undefined
                }
            }
        }), () => {
            this.updateMinMaxDateOfTimetables();
            this.setState({ time: true });
        })
    }

    setShowMonths(dateActive) {
        const monthsShort = {
            '01': "Jan",
            '02': 'Feb',
            '03': 'Mar',
            '04': 'Apr',
            '05': 'May',
            '06': 'Jun',
            '07': 'Jul',
            '08': 'Aug',
            '09': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec'
        };
        const dateIndex = moment(dateActive).format("MMYYYY")
        const sortedArray = this.getSurroundingMonths(dateIndex);
        const result = sortedArray.map(({ month, year, originalIndex }, index) => {
            return {
                date: `${month < 10 ? '0' : ''}${month}${year}`,
                originalIndex,
                display: index < 5
            }
        })
            .sort((a, b) => a.originalIndex - b.originalIndex);
        const activeIndex = result.indexOf(dateIndex);
        const mappedArray = result.map(dateObj => {
            let { date, display } = dateObj;
            const month = date.substr(0, 2);
            const year = date.substr(4, 2);
            const monthName = monthsShort[month];
            const currentDate = new Date();
            const currentYear = moment(currentDate).format('YYYY');
            const MY = `${monthName}`

            return { short: MY, date: this.state.monthYearDateMap[date]?.maxDate, isActiveMonth: date === dateIndex, display }
        });
    
        this.setState({ leftSideArray: mappedArray.filter(map => map.display) });
        return mappedArray;
    }

    getClosestValues = (activeIndex, array) => {
        const parseMonthYear = (value) => {
            const year = parseInt(value.slice(2, 6), 10);
            const month = parseInt(value.slice(0, 2), 10);
            return { year, month };
        };
        const activeDate = parseMonthYear(activeIndex);
        const parsedArray = array.map((value, index) => ({ ...parseMonthYear(value), originalIndex: index }));
        const sortedArray = parsedArray.sort((a, b) => {
            const diffA = Math.abs((a.year - activeDate.year) * 12 + (a.month - activeDate.month));
            const diffB = Math.abs((b.year - activeDate.year) * 12 + (b.month - activeDate.month));
            return diffA - diffB;
        });
        return sortedArray;
    };

    getSurroundingMonths = (activeMonth) => {
        let months = [...this.state.monthYearList];
        months.reverse();
        const activeIndex = months[months.indexOf(activeMonth)];
        const surroundingMonths = this.getClosestValues(activeIndex, months);
        return surroundingMonths;
    }

    mapMonthYearToDate = async () => {
        let lastMY = ""
        let lastMYDate = ""
        let monthYearDateMap = {}
        this.state.eventsList.forEach((dateObj) => {
            var min = moment(dateObj.eventOn, "DD/MM/YYYY");
            var minie = new Date(min);
            const currentMY = moment(minie).format("MMYYYY")
            if (currentMY !== lastMY) {
                monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
                monthYearDateMap[currentMY] = { ...monthYearDateMap[lastMY], maxDate: minie }
            }
            lastMY = currentMY
            lastMYDate = minie
        })
        monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
        this.setState({ monthYearDateMap: { ...monthYearDateMap } }, () => {
            this.updateMinMaxDateOfTimetables();
        })
    }

    updateMinMaxDateOfTimetables() {
        const { monthYearList, monthYearDateMap } = this.state;
        const lastIndex = monthYearList.length - 1;

        const updateSubCalendarDateRanges = (subtimetables, parent) => {
            if (!subtimetables) return undefined;

            const updatedSubtimetables = { ...subtimetables };
            const NEXT_MONTHS = ['threeMonthsAhead', 'twoMonthsAhead', 'next', 'current'];
            const PREV_MONTHS = ['threeMonthsEarlier', 'twoMonthsEarlier', 'previous', 'current'];

            let updatedMaxDate = parent?.maxDate;
            let updatedMinDate = parent?.minDate;

            const adjustMinMaxDateLimits = (months, date, comparisonDate, isNext) => {
                let dateToReturn = date;
                for (let month of months) {
                    if (this.state.timetableData[month]?.activeDate) {
                        const timetable = this.state.timetableData[month];
                        const maxMonthYear = moment(comparisonDate).format("MMYYYY");
                        const currentMonthYear = moment(timetable?.activeDate).format("MMYYYY");

                        if (maxMonthYear === currentMonthYear) {
                            const subTimetables = timetable.subTimetables ? Object.values(timetable.subTimetables) : [];
                            if (subTimetables.length) {
                                const isLastSubTimetableVisible = subTimetables[isNext ? subTimetables.length - 1 : 0].isVisible === true;

                                if (!isLastSubTimetableVisible) {
                                    dateToReturn = moment(date)
                                        .add(isNext ? 1 : -1, 'months')
                                        .startOf('month')
                                        .toDate();
                                }
                            }
                        }
                        break;
                    }
                }
                return dateToReturn;
            }

            updatedMaxDate = adjustMinMaxDateLimits(NEXT_MONTHS, updatedMaxDate, this.state.other_maximum, true);
            updatedMinDate = adjustMinMaxDateLimits(PREV_MONTHS, updatedMinDate, this.state.other_minimum, false);

            Object.keys(subtimetables).forEach(subCalendarKey => {
                const events = updatedSubtimetables[subCalendarKey].availableEventsOnDates;
                updatedSubtimetables[subCalendarKey] = {
                    ...subtimetables[subCalendarKey],
                    minDate: updatedMinDate,
                    maxDate: updatedMaxDate
                };
            });

            return updatedSubtimetables;
        }

        this.setState(prevState => ({
            timetableData: {
                threeMonthsEarlier: {
                    ...prevState.timetableData.threeMonthsEarlier,
                    minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[monthYearList[3]]?.maxDate || undefined,
                    subTimetables: updateSubCalendarDateRanges(
                        prevState.timetableData.threeMonthsEarlier.subTimetables,
                        {
                            calendar: 'threeMonthsEarlier',
                            minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                            maxDate: monthYearDateMap[monthYearList[3]]?.maxDate || undefined
                        }
                    )
                },
                twoMonthsEarlier: {
                    ...prevState.timetableData.twoMonthsEarlier,
                    minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[monthYearList[2]]?.maxDate || undefined,
                    subTimetables: updateSubCalendarDateRanges(
                        prevState.timetableData.twoMonthsEarlier.subTimetables,
                        {
                            calendar: 'twoMonthsEarlier',
                            minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                            maxDate: monthYearDateMap[monthYearList[2]]?.maxDate || undefined
                        }
                    )
                },
                previous: {
                    ...prevState.timetableData.previous,
                    minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[monthYearList[1]]?.maxDate || undefined,
                    subTimetables: updateSubCalendarDateRanges(
                        prevState.timetableData.previous.subTimetables,
                        {
                            calendar: 'previous',
                            minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                            maxDate: monthYearDateMap[monthYearList[1]]?.maxDate || undefined
                        }
                    )
                },
                current: {
                    ...prevState.timetableData.current,
                    minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined,
                    subTimetables: updateSubCalendarDateRanges(
                        prevState.timetableData.current.subTimetables,
                        {
                            calendar: 'current',
                            minDate: monthYearDateMap[monthYearList[monthYearList.length - 1]]?.minDate || undefined,
                            maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined
                        }
                    )
                },
                next: {
                    ...prevState.timetableData.next,
                    minDate: monthYearDateMap[monthYearList[monthYearList.length - 2]]?.minDate || undefined,
                    maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined,
                    subTimetables: updateSubCalendarDateRanges(
                        prevState.timetableData.next.subTimetables,
                        {
                            calendar: 'next',
                            minDate: monthYearDateMap[monthYearList[monthYearList.length - 2]]?.minDate || undefined,
                            maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined
                        }
                    )
                },
                twoMonthsAhead: {
                    ...prevState.timetableData.twoMonthsAhead,
                    minDate: monthYearDateMap[monthYearList[monthYearList.length - 3]]?.minDate || undefined,
                    maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined,
                    subTimetables: updateSubCalendarDateRanges(
                        prevState.timetableData.twoMonthsAhead.subTimetables,
                        {
                            calendar: 'twoMonthsAhead',
                            minDate: monthYearDateMap[monthYearList[monthYearList.length - 3]]?.minDate || undefined,
                            maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined
                        }
                    )
                },
                threeMonthsAhead: {
                    ...prevState.timetableData.threeMonthsAhead,
                    minDate: monthYearDateMap[monthYearList[monthYearList.length - 4]]?.minDate || undefined,
                    maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined,
                    subTimetables: updateSubCalendarDateRanges(
                        prevState.timetableData.threeMonthsAhead.subTimetables,
                        {
                            calendar: 'threeMonthsAhead',
                            minDate: monthYearDateMap[monthYearList[monthYearList.length - 4]]?.minDate || undefined,
                            maxDate: monthYearDateMap[monthYearList[0]]?.maxDate || undefined
                        }
                    )
                }
            }
        }));
    }


    /**
     * 
     * @param {string} buttonName 
     */
    updateClickedButtonToExit(buttonName) {
        this.setState({ clickedButtonToExit: buttonName })
    }

    checkBackNavigation() {
        const { activeTab } = this.context
        if (activeTab === 'second') {
            const { sharedData } = this.context;
            let activeTabData = sharedData.tabWiseData[activeTab]?.openProfiles;
            const { appContext } = this.props;
            const { state } = appContext;
            if (activeTabData?.length === 1 && state.homePageActive.status) {
                this.setState({ preventNavigation: true });
            } else {
                this.setState({ preventNavigation: false });
            }
        } else {
            this.setState({ preventNavigation: false });
        }
    }

    mainProfileColor() {
        $(".username, .usernamecl").css("color", "");
        $(".username, .usernamecl").css("color", this.state.randColor.textColor);
        $(".username, .usernamecl").css("background-color", "");
        $(".username, .usernamecl").css("background-image", `linear-gradient(${this.state.randColor.firstColor}, ${this.state.randColor.secondColor})`);
    }

    fetchEvntList() {
        if (this.state.evntData.length >= 20)
            return

        if (this.state.hasMore == true) {
            const variable = localStorage.getItem('userid');
            const token = localStorage.getItem('token');
            this.setState({ hasMore: true })
            setTimeout(() => {

                var date = Date.now() / 1000;
                var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + date;

                fetch(serverpath, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "TimeZone": moment.tz.guess(),
                        "Authorization": "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        const events = responseJson.response;
                        if (events.length < 20)
                            this.setState({ contat: 'on', topcontat: 'on', hasMore: false })

                        if (responseJson.message == "Event not available") {
                            $('#one_14 .loaderclass').removeClass('downscroll');
                            $('#one_14 .loaderclass').addClass('stopscroll');
                        }
                        if (events.length) {
                            this.setState({ evntData: events }, () => {
                                const centerEventIndex = Math.floor(events.length / 2)

                                const centerEventHashDivSelector = `#hashu${events[centerEventIndex].eventID}`
                                this.scrollTimelineToElementPosition(centerEventHashDivSelector);
                            })

                        }
                    });
            }, 100);
        }
    }

    /**
     * will scroll the Infinite Scroll to *offsetTop* position of the element selected by given jquery selector 
     * 
     * @param {string} selector HTML Element selector 
     */
    scrollTimelineToElementPosition(selector) {
        $('#one_14 #scrollableDivOneFourProfile').scrollTop($(`#one_14 ${selector}`)[0]?.offsetTop - 10);
    }

    updateTimetableData = (data, calendarView, subTimetableKey = null) => {
        this.setState((prevState) => {
            if (subTimetableKey && prevState?.timetableData[calendarView]?.subTimetables) {
                return {
                    timetableData: {
                        ...prevState.timetableData,
                        [calendarView]: {
                            ...prevState.timetableData[calendarView],
                            subTimetables: {
                                ...prevState?.timetableData[calendarView].subTimetables,
                                [subTimetableKey]: {
                                    ...prevState?.timetableData[calendarView].subTimetables[subTimetableKey],
                                    ...data,
                                },
                            },
                        },
                    },
                };
            } else {
                return {
                    timetableData: {
                        ...prevState.timetableData,
                        [calendarView]: {
                            ...prevState.timetableData[calendarView],
                            ...data,
                        },
                    },
                };
            }
        });
    }

    convertToDate = (activeDate) => {
        return activeDate?.eventOn ? moment(activeDate.eventOn, "DD-MM-YYYY").toDate() : undefined;
    }

    getActiveDatesOfNextPrevMonths(date, monthDateMapping) {
        const activeDates = {
            threeMonthsAhead: undefined,
            twoMonthsAhead: undefined,
            next: undefined,
            current: undefined,
            prev: undefined,
            twoMonthsEarlier: undefined,
            threeMonthsEarlier: undefined
        };

        const currentMonthIndex = monthDateMapping.findIndex(el => el.isActiveMonth);
        let visibleCount = 1;

        const processDateAtIndex = (index, month) => {
            const date = monthDateMapping[currentMonthIndex + index]?.date;

            if (!date || visibleCount > 4) return undefined;

            const availableEvents = getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(date, this.state.eventsList))
            const newDate = this.convertToDate(getActiveDateBasedOnImportantEvent(date, availableEvents));
            const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

            if (subTimetables && Object.keys(subTimetables).length) {
                const calendars = Object.values(subTimetables);

                calendars.forEach((calendar) => {
                    calendar['availableEventsOnActiveDate'] = calendar?.activeDate ? this.getEventsOfActiveDate(calendar?.activeDate) : [];
                })

                const iterateCalendars = (month === 'prev' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier')
                    ? calendars.reverse() // Start from the last calendar for previous months
                    : calendars; // Start from the first calendar for other months

                if (month === 'current') {
                    const shouldActiveIndex = calendars.findIndex(el => moment(el.activeDate).format("DD/MM/YYYY") === moment(newDate).format("DD/MM/YYYY"));
                    const activeIndex = shouldActiveIndex >= 0 ? shouldActiveIndex : 0;

                    // Set the active calendar
                    calendars[activeIndex]['isActive'] = true;

                    iterateCalendars.slice(activeIndex).forEach((calendar) => {
                        if (visibleCount > 4) return;
                        calendar['isVisible'] = true;
                        visibleCount += 1;
                    });
                } else {
                    iterateCalendars.forEach((calendar) => {
                        if (visibleCount > 4) return;
                        calendar['isVisible'] = true;
                        visibleCount += 1;
                    });
                }
            } else {
                visibleCount += 1;
            }

            if (date) {
                activeDates[month] = {
                    activeDate: newDate,
                    availableEventsOnDates: availableEvents,
                    subTimetables: subTimetables
                }
            }
            return newDate;
        };

        let currentDate = processDateAtIndex(0, 'current');
        let nextMonthDate = processDateAtIndex(1, 'next');
        let twoMonthsAheadDate = processDateAtIndex(2, 'twoMonthsAhead');
        let threeMonthsAheadDate = processDateAtIndex(3, 'threeMonthsAhead');
        let prevMonthDate = null, twoMonthsEarlierDate = null, threeMonthsEarlierDate = null;

        let pastMonthsNeeded = 0;
        if (!threeMonthsAheadDate) pastMonthsNeeded = 1;
        if (!twoMonthsAheadDate) pastMonthsNeeded = 2;
        if (!nextMonthDate) pastMonthsNeeded = 3;


        if (pastMonthsNeeded > 0) {
            prevMonthDate = processDateAtIndex(-1, 'prev');
            if (pastMonthsNeeded > 1) {
                twoMonthsEarlierDate = processDateAtIndex(-2, 'twoMonthsEarlier');
                if (pastMonthsNeeded > 2) {
                    threeMonthsEarlierDate = processDateAtIndex(-3, 'threeMonthsEarlier');
                }
            }
        }
      
        const updatedActiveDates = addNextAndPrevDates(activeDates, this.state.monthYearList, this.state.monthYearDateMap);
        return updatedActiveDates;
    }

    getEventsOfActiveDate(date) {
        const monthYear1 = moment(date).format('DD-MM-YYYY');
        let eventsOfThisDate = [];
        if (this.state.eventsList && date) {
            this.state.eventsList?.map((item) => {
                monthYear1 === item?.eventOn ? eventsOfThisDate.push(item) : eventsOfThisDate = eventsOfThisDate;
            })
        }
        return eventsOfThisDate;
    }

    fetchEvntList2() {
        if (this.state.loadthired == false) {
            // let currentHeight =
            //    $("#one_14 #scrollableDivOneFourProfile")[0].scrollHeight -
            //    $("#one_14 #scrollableDivOneFourProfile").height();

            $('#one_14 .loaderclass').removeClass('removescrolling');
            const variable = localStorage.getItem('userid');
            const token = localStorage.getItem('token');
            this.setState({ hasMore: true, loaddocument: true, items: Array.from({ length: 10 }) })
            var time = Math.floor(new Date() / 1000);
            setTimeout(() => {
                $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                let scrollId = ""
                if (this.state.inverse == false) {
                    var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + this.state.evntData[this.state.evntData.length - 1].postDate + '&eventType=0&eventID=' + this.state.evntData[this.state.evntData.length - 1].eventID;
                    scrollId = this.state.evntData[this.state.evntData.length - 1].eventID;

                }
                if (this.state.inverse == true) {
                    var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + this.state.evntData[0].postDate + '&eventType=1&eventID=' + this.state.evntData[0].eventID;
                    scrollId = this.state.evntData[0].eventID;
                }
                fetch(serverpath, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "TimeZone": moment.tz.guess(),
                        "Authorization": "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then((responseJson) => {

                        if (responseJson.response.length < 20) {
                            if (this.state.inverse)
                                this.setState({ topcontat: 'on' });
                            else
                                this.setState({ contat: 'on' });
                        }
                        if (responseJson.response.length > 0) {
                            var datas = responseJson.response;
                            if (this.state.inverse == true) {

                                for (var i = datas.length - 1; i >= 0; i--) {
                                    this.state.evntData.unshift(datas[i]);
                                }
                            }
                            else {
                                for (var i = datas.length - 1; i >= 0; i--) {
                                    this.state.evntData.push(datas[i]);
                                }
                            }
                            if (this.state.inverse == false) {

                                $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                                setTimeout(() => {
                                    $('#one_14 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                                }, 100)
                            }
                            if (this.state.inverse == true) {

                                $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                                setTimeout(() => {
                                    $('#one_14 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                                }, 100)
                            }
                            // this.setState({ loaddocument: false }, () => {
                            if (this.state.evntData.length > 20) {
                                this.setState({ items: this.state.items.concat(Array.from({ length: 20 })) }, () => {
                                });
                            }
                            // setTimeout(() => {
                            this.setState({ loadthired: false });

                            if (this.state.inverse) {
                                const scrollSelector = `#hashu${scrollId}`
                                this.scrollTimelineToElementPosition(scrollSelector)
                            }

                            // }, 100);
                            // });
                        }
                        else {
                            $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                            setTimeout(() => {
                                $('#one_14 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                            }, 100)
                        }
                        this.setState({ loaddocument: false });
                    });
            }, 100);
        }
    }

    onscrolldocument() {
        var pxcelate = $('#one_14 #scrollableDivOneFourProfile')[0].scrollHeight;
        var px = $("#one_14 #scrollableDivOneFourProfile").scrollTop();
        if (px < 50) {
            if (this.state.topcontat == "of") {
                this.setState({ inverse: true, loadthired: false }, () => {
                    if (px == 0) {
                        this.timeout = setTimeout(() => {
                            this.fetchEvntList2();
                        }, 100);
                        setTimeout(() => {
                            if (this.timeout) {
                                clearTimeout(this.timeout)
                                this.timeout = null
                            }
                        }, 100);
                    }
                });
                $('#one_14 .contantf').addClass('closeCd');
            }
            if (this.state.topcontat == "on") {
                $('#one_14 .contantf').removeClass('closeCd');
                // $('#one_14 .loaderclass').addClass('closeCd');
            }
            else {
                $('#one_14 .contantf').addClass('closeCd');
            }
        }
        else {
            if (this.state.contat == "of") {
                this.setState({ inverse: false, loadthired: false }, () => {

                    if (Math.round(px + $("#one_14 #scrollableDivOneFourProfile").height()) == pxcelate) {

                        // $('#one_14 .loaderclass').removeClass('topscrolls closeCd');
                        // $('#one_14 .loaderclass').addClass('bottomscrolls'); 
                        this.timeout = setTimeout(() => {
                            this.fetchEvntList2();
                        }, 100);
                        setTimeout(() => {
                            if (this.timeout) {
                                clearTimeout(this.timeout)
                                this.timeout = null
                            }
                        }, 100);
                    }
                });
            }
            if (this.state.contat == "on" && Math.round(px + $("#one_14 #scrollableDivOneFourProfile").height()) == pxcelate) {
                $('#one_14 .contantf').removeClass('closeCd');
                // $('#one_14 .loaderclass').addClass('closeCd');
            }
            else {
                $('#one_14 .contantf').addClass('closeCd');
            }
        }
    }

    ensureDisplayedMonthsLimit = (timetableData) => {
        const updatedTimetableData = { ...timetableData };

        const MONTHS = ['current', 'next', 'twoMonthsAhead', 'threeMonthsAhead', 'previous', 'twoMonthsEarlier', 'threeMonthsEarlier'];
        let visibleCount = 0;

        const getNewDateForMonth = (month, prevMonth) => {
            const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';
            let newDate = undefined;

            if (updatedTimetableData[prevMonth]?.nextDate) {
                newDate = updatedTimetableData[prevMonth].nextDate;
            } else if (prevMonth === 'threeMonthsAhead') {
                newDate = updatedTimetableData['current']?.prevDate;
            } else if (isPrevious) {
                newDate = updatedTimetableData[prevMonth]?.prevDate;
            }

            return newDate;
        };

        MONTHS.forEach((month, index) => {
            // if (visibleCount >= 4) return;
            const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';

            if (updatedTimetableData[month]?.activeDate) {
                if (updatedTimetableData[month].subTimetables) {
                    const calendars = Object.values(updatedTimetableData[month].subTimetables);
                    const iterateCalendars = isPrevious
                        ? calendars.reverse()
                        : calendars;

                    let activeSubTimetableIndex = iterateCalendars.findIndex((calendar) => calendar.isActive === true);
                    iterateCalendars.forEach((calendar, index) => {
                        if (calendar.isVisible) {
                            visibleCount += 1;
                        } else if (!calendar.isVisible) {
                            if (month !== 'current') {
                                calendar['isVisible'] = true;
                                visibleCount += 1;
                            } else {
                                if (index > activeSubTimetableIndex) {
                                    calendar['isVisible'] = true;
                                    visibleCount += 1;
                                }
                            }
                        }
                    });
                } else {
                    visibleCount += 1;
                }
            } else {
                if (month === 'previous') {
                    const subTimetables = updatedTimetableData['current'].subTimetables
                        ? Object.values(updatedTimetableData['current'].subTimetables)
                        : [];

                    if (subTimetables?.length) {
                        const activeIdx = subTimetables.findIndex((calendar) => calendar.isActive === true);
                        if (activeIdx > 0) {
                            const iterateCalendars = subTimetables.reverse();
                            iterateCalendars.forEach((calendar) => {
                                if (visibleCount < 4) {
                                    if (!calendar.isVisible) {
                                        calendar['isVisible'] = true;
                                        visibleCount += 1;
                                    }
                                }
                            })
                        }
                    }
                }

                const prevMonth = MONTHS[index - 1];
                const newDate = getNewDateForMonth(month, prevMonth);
                if (!newDate || visibleCount >= 4) return;

                const availableEvents = getActiveDatesOfTheMonth(newDate, this.state.eventsList);
                const selectedDate = this.convertToDate(getActiveDateBasedOnImportantEvent(newDate, availableEvents));
                const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

                const calendars = subTimetables ? Object.values(subTimetables) : [];

                calendars.forEach((calendar) => {
                    calendar['availableEventsOnActiveDate'] = calendar?.activeDate ? this.getEventsOfActiveDate(calendar?.activeDate) : [];
                })

                if (calendars?.length) {
                    const iterateCalendars = isPrevious
                        ? calendars.reverse()
                        : calendars;

                    iterateCalendars.forEach((calendar) => {
                        if (visibleCount < 4) {
                            calendar['isVisible'] = true;
                            visibleCount += 1;
                        }
                    });
                } else {
                    visibleCount += 1;
                }

                updatedTimetableData[month].activeDate = selectedDate;
                updatedTimetableData[month].availableEventsOnDates = availableEvents;
                updatedTimetableData[month].subTimetables = subTimetables;
            }
        });

        // If we have more than 4 timetables, start removing the extra ones
        if (visibleCount > 4) {
            const reverseMonths = MONTHS.reverse();

            reverseMonths.forEach((month) => {
                if (visibleCount <= 4) return;

                const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';

                if (updatedTimetableData[month]?.activeDate) {
                    const calendars = updatedTimetableData[month].subTimetables ? Object.values(updatedTimetableData[month].subTimetables) : [];

                    if (calendars?.length) {
                        const iterateCalendars = !isPrevious ? calendars.reverse() : calendars;

                        iterateCalendars.forEach((calendar) => {
                            if (calendar.isVisible && visibleCount > 4) {
                                calendar.isVisible = false;
                                visibleCount -= 1;
                            }
                        });

                        if (calendars.every((calendar) => !calendar.isVisible)) {
                            updatedTimetableData[month].subTimetables = undefined;
                            updatedTimetableData[month].activeDate = undefined;
                            updatedTimetableData[month].availableEventsOnDates = [];
                        }
                    }
                }

                if (!updatedTimetableData[month]?.subTimetables && updatedTimetableData[month]?.activeDate) {
                    updatedTimetableData[month].activeDate = undefined;
                    updatedTimetableData[month].availableEventsOnDates = [];
                    visibleCount -= 1;
                }
            });
        }

        return updatedTimetableData;
    };  

    shiftActiveCalendar(action) {

        //function to update the active month in profiles page header
        const updateActiveMonth = (timetableData) => {
            const newActiveDate = timetableData.current.activeDate;
            console.log("newActiveDate", newActiveDate);

            const newMonthYear = moment(newActiveDate).format('MMYYYY');
            const existingMonthYear = moment(this.state.currentMonthYear).format('MMYYYY');

            const shouldNotUpdateActiveDates = newMonthYear !== existingMonthYear;

            if (shouldNotUpdateActiveDates) {
                this.setShowMonths(newActiveDate);
            } else {
                this.setState({ currentMonthYear: newActiveDate });
            }
        }

        // function to shift to next calendar
        const shiftToNextCalendar = () => {
            const { current, next } = this.state.timetableData;

            const subTimetables = current.subTimetables
                ? Object.entries(current.subTimetables)
                : [];
            const activeIndex = subTimetables.length
                ? subTimetables.findIndex(([_, timetable]) => timetable.isActive === true)
                : -1;

            const hasSubtimetable =
                subTimetables.length && activeIndex !== subTimetables.length - 1;

            let updatedTimetableData = { ...this.state.timetableData };

            if (hasSubtimetable) {
                updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(
                    current.subTimetables,
                    activeIndex + 2,
                    activeIndex + 1
                );
            } else {
                updatedTimetableData.current = unsetTimetableData(updatedTimetableData.current);

                updatedTimetableData = shiftMonthsForward(updatedTimetableData);
                const currentSubTimetables = updatedTimetableData.current.subTimetables;

                if (currentSubTimetables) {
                    updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(currentSubTimetables, 1);
                }
            }
            updatedTimetableData = this.ensureDisplayedMonthsLimit(updatedTimetableData);
            updatedTimetableData = addNextAndPrevDates(updatedTimetableData, this.state.monthYearList, this.state.monthYearDateMap);

            this.setState({ timetableData: updatedTimetableData }, () => {
                updateActiveMonth(updatedTimetableData);
            });
        };

        // function to shift to previous calendar
        const shiftToPreviousCalendar = () => {
            const { current, next } = this.state.timetableData;

            const subTimetables = current.subTimetables
                ? Object.entries(current.subTimetables)
                : [];
            const activeIndex = subTimetables.length
                ? subTimetables.findIndex(([_, timetable]) => timetable.isActive === true)
                : -1;

            const hasSubtimetable =
                subTimetables.length && activeIndex !== 0;            

            let updatedTimetableData = { ...this.state.timetableData };

            if (hasSubtimetable) {
                updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(
                    current.subTimetables,
                    activeIndex
                );
            } else {
                updatedTimetableData = shiftMonthsBackward(updatedTimetableData);

                updatedTimetableData.current = unsetTimetableData(updatedTimetableData.current);
                const newDate = updatedTimetableData.next.prevDate;

                const availableEvents = getActiveDatesOfTheMonth(newDate, this.state.eventsList);
                const selectedDate = this.convertToDate(getActiveDateBasedOnImportantEvent(newDate, availableEvents));
                const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

                if (subTimetables) {
                    updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(subTimetables, Object.values(subTimetables)?.length);
                }

                updatedTimetableData.current.activeDate = selectedDate;
                updatedTimetableData.current.availableEventsOnDates = availableEvents;
            }

            updatedTimetableData = this.ensureDisplayedMonthsLimit(updatedTimetableData);
            updatedTimetableData = addNextAndPrevDates(updatedTimetableData, this.state.monthYearList, this.state.monthYearDateMap);

            this.setState({ timetableData: updatedTimetableData }, () => {
                updateActiveMonth(updatedTimetableData);
            });
        }

        // call the function based on the action
        if (action === "next") {
            shiftToNextCalendar();
        } else {
            shiftToPreviousCalendar();
        }

        this.updateMinMaxDateOfTimetables();
    }

    updateActiveDatesOfTimetables(newActiveDate, action, calendar) {

        // call shiftActiveCalendar function to shift calendar based on the action
        this.shiftActiveCalendar(action);
    }

    getMonthYear(maxDate) {
        var mydate = maxDate;
        var min = moment(mydate, "DD/MM/YYYY");
        var minie = new Date(min);
        this.setActiveDatesOfTimetables(minie);
    }

    getDateFullName(date) {
        if (date) {
            const month = moment(date).format('MMMM');
            const year = moment(date).format('YYYY');
            return month + ' ' + year;
        }
    }

    godate() {
        $('#one_14 #listuser').removeClass('active');
        $('#one_14 #user').addClass('active');
        $('#one_14').removeClass('text-left profile-pag');
        $('#one_14').addClass('screen1');
        $('#one_14 #one2list').removeClass('active show');
        $('#one_14 #one2date').addClass('active show');
        this.setState({ isTimelineActive: false });
    }

    godocument() {
        $('#one_14 #user').removeClass('active');
        $('#one_14 #listuser').addClass('active');
        $('#one_14').removeClass('screen1');
        $('#one_14').addClass('text-left profile-pag');
        $('#one_14 #one2date').removeClass('active show');
        $('#one_14 #one2list').addClass('active show');
        this.setState({ fistname: 0, isTimelineActive: true });
        this.fetchEvntList();
    }

    componentWillUnmount() {
        document.title = "Timesort"
        this.updateSharedDataInContext();
    }

    setShouldUpdateOnSamePath = (val) => {
        this.setState({ shouldUpdateOnSamePath: val })
    }

    render() {

        $(".katexContentBlock .katex a").attr("target", "_blank");
        $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
        if (this.state.cscreenname) {
            document.title = "Timesort \u2014          " + this.state.cscreenname;
        }

        return (
            <div className={`${this.props.pos} screen1 savedTimetables`} id="one_14">
                <div className="top newbg" id="myHeader">
                    <section className="header">
                        <div className="row">
                            <div className=" header_left pr-0">
                                <ul className="nav nav-tabs noteee">
                                    <li>
                                        <a className="link active" onClick={this.godate.bind(this)} id="user" data-toggle="tab" >
                                            <CalendarSvg />
                                        </a>
                                        <p onClick={this.godate.bind(this)} id="user" className='hoverIconInfo'>Timetables</p>
                                    </li>
                                    <li>
                                        <a data-toggle="tab" className="" onClick={this.godocument.bind(this)} id="listuser" >
                                            <TimelineSvg />
                                        </a>
                                        <p onClick={this.godocument.bind(this)} id="listuser" className='hoverIconInfo'>Bidirectionally-traversed timeline</p>
                                    </li>
                                </ul>
                            </div>
                            <div className=" monthsBlock">
                                <ul>
                                    {
                                        this.state.leftSideArray &&
                                        this.state.leftSideArray.map((item) =>
                                            <li>
                                                <p className={`dateInfo ${item.isActiveMonth ? "active" : ""}`} onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                                <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                            </li>
                                        )
                                    }
                                    {
                                        this.state.rightSideArray &&
                                        this.state.rightSideArray.map((item) =>
                                            <li>
                                                <p onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                                <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className=" header_right pl-0">
                                <ul className="uld">
                                    <li className="more abort" ><Link to={"/settings"}> <DepartSvg /> <span>{"settings"}</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>
                <div className="tab-content tab-content4">
                    {this.state.time == true ?
                        <>
                            {this.state.eventsList?.length ? (
                                <div id="one2date" className="tab-pane in active show">
                                    <div className="flex-center">
                                        {(() => {
                                            const beforeActiveSubBookmarks = [];
                                            const mainBookmarks = ['current', 'next', 'twoMonthsAhead', 'threeMonthsAhead', 'previous', 'twoMonthsEarlier', 'threeMonthsEarlier']
                                                .flatMap((month) => {
                                                    const monthData = this.state.timetableData[month];
                                                    const subBookmarks = monthData?.subTimetables;

                                                    if (!monthData?.activeDate) {
                                                        return []; // Skip rendering if no activeDate for the month
                                                    }

                                                    if (subBookmarks && Object.keys(subBookmarks)?.length > 0) {
                                                        const entries = Object.entries(subBookmarks);
                                                        const isCurrentMonth = month === "current";
                                                        let activeAndAfter = entries.filter(([_, subData]) => subData.isVisible);
                                                        let beforeActive = [];

                                                        if (isCurrentMonth) {
                                                            const activeIndex = entries.findIndex(([_, subData]) => subData.isActive);

                                                            activeAndAfter = activeIndex !== -1
                                                                ? entries.slice(activeIndex).filter(([_, subData]) => subData.isVisible)
                                                                : [];

                                                            beforeActive = activeIndex !== -1
                                                                ? entries.slice(0, activeIndex).filter(([_, subData]) => subData.isVisible)
                                                                : entries.filter(([_, subData]) => subData.isVisible);

                                                            beforeActive.forEach(([subKey, subData]) => {
                                                                beforeActiveSubBookmarks.push(
                                                                    <BookmarkTimetable
                                                                        key={`${month}-${subKey}-beforeActive`}
                                                                        isScreenVisible={this.props.isScreenVisible}
                                                                        subspaceNumber={1}
                                                                        calendar={`${month}-${subKey}`}
                                                                        timetableData={subData}
                                                                        states={{
                                                                            events: this.state.eventsList,
                                                                            otherMinimum: this.state.other_minimum,
                                                                            otherMaximum: this.state.other_maximum,
                                                                            otherMonthYear: this.state.monthYearList,
                                                                        }}
                                                                        updateTimetableData={(data) => this.updateTimetableData(data, month, subKey)}
                                                                        updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                                                        getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                                                    />
                                                                );
                                                            });
                                                        }

                                                        return activeAndAfter.map(([subKey, subData]) => (
                                                            <BookmarkTimetable
                                                                key={`${month}-${subKey}`}
                                                                isScreenVisible={this.props.isScreenVisible}
                                                                subspaceNumber={1}
                                                                calendar={`${month}-${subKey}`}
                                                                timetableData={subData}
                                                                states={{
                                                                    events: this.state.eventsList,
                                                                    otherMinimum: this.state.other_minimum,
                                                                    otherMaximum: this.state.other_maximum,
                                                                    otherMonthYear: this.state.monthYearList,
                                                                }}
                                                                updateTimetableData={(data) => this.updateTimetableData(data, month, subKey)}
                                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                                            />
                                                        ));
                                                    } else {
                                                        return (
                                                            <BookmarkTimetable
                                                                key={month}
                                                                isScreenVisible={this.props.isScreenVisible}
                                                                subspaceNumber={1}
                                                                calendar={month}
                                                                timetableData={monthData}
                                                                states={{
                                                                    events: this.state.eventsList,
                                                                    otherMinimum: this.state.other_minimum,
                                                                    otherMaximum: this.state.other_maximum,
                                                                    otherMonthYear: this.state.monthYearList,
                                                                }}
                                                                updateTimetableData={(data) => this.updateTimetableData(data, month)}
                                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                                            />
                                                        );
                                                    }
                                                });

                                            return [...mainBookmarks, ...beforeActiveSubBookmarks];
                                        })()}
                                    </div>
                                    <div id="five1" class="likeaddto"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                    <div class="likeaddtop" id="four1"><img class="w-100" src="../../images/Erase.png" alt="image" /></div>
                                </div>
                            ) : (
                                !this.state.isTimelineActive && (
                                    <div className="notfound">
                                        <p>&ndash;</p>
                                    </div>
                                )
                            )}

                            <div id="one2list" className="tab-pane fade">
                                <div class={this.state.evntData.length > 0 ? "loaderclass closeCdff h" : "loaderclass onscroll"}><img src="../../images/newgif.gif" alt="loading" /></div>
                                {/* <div class={this.state.contat == "of" ? "loaderclass contantf closeCd h" : "loaderclass contantf"}>No More post Available</div> */}
                                <div className={"sectionone changable" + ((!this.state.cbio || !this.state.cwebsite) ? " emptyBioWebsite" : "")}>
                                    <div
                                        id="scrollableDivOneFourProfile"
                                        className={this.state.evntData.length > 0 ? this.state.loaddocument == true ? "scrollClass newscreen1 " : "scrollClass newscreen1" : "scrollClass newscreen1"}
                                        style={{
                                            overflow: 'auto',
                                            display: 'flex !important',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <InfiniteScroll
                                            className={this.state.evntData.length > 0 ? "topbuttom_scroll" : ""}
                                            dataLength={this.state.evntData.length}
                                            style={{ display: 'flex', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                            hasMore={true}
                                            onScroll={this.onscrolldocument.bind(this)}

                                            loader={
                                                <div className={`loaderclass ${this.state.inverse ? "topscrolls" : "bottomscrolls"} ${this.state.loaddocument ? "" : "closeCd"}`}>
                                                    <img
                                                        src="../../images/newgif.gif"
                                                        alt="loading"
                                                    />


                                                </div>}
                                            scrollableTarget="scrollableDivOneFourProfile"
                                        >
                                            <div className="mb-2 timeline-wrapper">
                                                {this.state.evntData.map((j, i) => {
                                                    return (
                                                        <div className="timeline-event-box">
                                                            <span id={'hashu' + j.eventID} className={j.eventID}></span>
                                                            <div id={"crox" + j.eventID} className="postlike">
                                                                <div className="userin rmrf">
                                                                    <h1 className='new-heading-tex-780' dangerouslySetInnerHTML={{ __html: formatDate(getdateformat(j.postedOn, j.postedOn2, j.timezone)) }}></h1>
                                                                    <h5>
                                                                        {j?.link ? (
                                                                            <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                                                                <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 1) }} />
                                                                            </a>
                                                                        ) : (
                                                                            <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 1) }} />
                                                                        )}
                                                                    </h5>
                                                                </div>
                                                                <div className="post ">
                                                                    {(() => {
                                                                        if (j.mediaType == "video") {
                                                                            return (
                                                                                <div id={'Video' + j.eventID} class="video-div">
                                                                                    <CustomVideoPlayer
                                                                                        ref={(el) => (this[`ref${i}`] = el)}
                                                                                        type="video/mp4"
                                                                                        src={j.eventImage.startsWith("http") ? j.eventImage : process.env.REACT_APP_BASE_URL + j.eventImage}
                                                                                        autoPlay={false}
                                                                                        onClose={() => { }}
                                                                                        className={"video-react-has-started"}
                                                                                        onPause={() => {}}
                                                                                        onRewind={() => this.changeCurrentTimeNew(-10, i)}
                                                                                        onForward={() => this.changeCurrentTimeNew(10, i)}
                                                                                    />
                                                                                    {/* <Player
                                                                                        ref={player => {
                                                                                            this[`ref${i}`] = player;
                                                                                        }}
                                                                                        autoPlay={false}
                                                                                        className="video-react-has-started"
                                                                                        controls={false}
                                                                                        playsInline
                                                                                        disablePictureInPicture>
                                                                                        <source src={j.eventImage.startsWith("http") ? j.eventImage : process.env.REACT_APP_BASE_URL + j.eventImage} type="video/mp4" />
                                                                                        <ControlBar disableDefaultControls={true} autoHide={true} className="my-class" />
                                                                                    </Player> */}
                                                                                    {/* <ul class="videoulkap pb-3">
                                                                                        <li><Button data-id={"player" + j.id} className={-10} onClick={() => this.changeCurrentTimeNew(-10, i)} ><img class="img1" src="../../images/Backwards.png" />
                                                                                            <span>10</span></Button>
                                                                                        </li>
                                                                                        <li><img class="img2" src="../../images/pause.png" /></li>
                                                                                        <li><Button data-id={j.id} className={10} onClick={() => this.changeCurrentTimeNew(10, i)} ><img class="img3" src="../../images/Forwards.png" />
                                                                                            <span>10</span></Button>
                                                                                        </li>
                                                                                    </ul> */}
                                                                                </div>);
                                                                        }
                                                                        else if (j.mediaType == "image") {
                                                                            return (
                                                                                <img className="w-100 mt-1" src={this.state.baseUrl + j.eventImage} alt="image" />
                                                                            );
                                                                        }
                                                                    }
                                                                    )()}
                                                                    <div className="hearti">
                                                                        <img className="w-100" src="../../images/cklick.png" alt="image" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className={j.mediaType == null ? "videoul mt3 status" : "videoul mt3"} >
                                                                <li>
                                                                </li>

                                                                {(() => {
                                                                    if (j.withwho != null) {
                                                                        return (<li><img src="../../images/b.png" alt="icon" />
                                                                            {(() => {
                                                                                if (j.withwho != null) {
                                                                                    return (
                                                                                        j.withwho.map((k, l) => {
                                                                                            return (
                                                                                                <span><a href="#"><Link to={{
                                                                                                    pathname: `/${k.username}`,
                                                                                                    state: { id: k.user_id }
                                                                                                }}
                                                                                                >{k.screenName}</Link></a><span>, </span></span>
                                                                                            );
                                                                                        })
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <a href="#"></a>
                                                                                    )
                                                                                }
                                                                            }
                                                                            )()}
                                                                        </li>
                                                                        )
                                                                    }
                                                                }
                                                                )()}
                                                                {(() => {
                                                                    if (j.likedByUsers.length > 0) {
                                                                        return (<li>
                                                                            <img src="../../images/c.png" alt="icon" />
                                                                            {(() => {
                                                                                if (j.likedByUsers.length > 0) {
                                                                                    return (
                                                                                        j.likedByUsers.map((q, t) => {
                                                                                            return (<span><a href="#"><Link to={{
                                                                                                pathname: `/${q.username}`,
                                                                                                state: { id: q.user_id }
                                                                                            }}
                                                                                            >{q.screenName}</Link></a><span>, </span></span>
                                                                                            );
                                                                                        })
                                                                                    )
                                                                                }
                                                                            }
                                                                            )()}
                                                                        </li>
                                                                        )
                                                                    }
                                                                }
                                                                )()}
                                                                {(() => {
                                                                    if (j.likedByUsers.length > 0) {
                                                                        return (<li>
                                                                            <img src="../../images/d.png" alt="icon" />
                                                                            {(() => {
                                                                                if (j.commentByUsers.length > 0) {
                                                                                    return (
                                                                                        j.commentByUsers.map((m, index) => {
                                                                                            return (
                                                                                                <span><a href="#"><Link to={{
                                                                                                    pathname: `/${m.username}`,
                                                                                                    state: { id: m.user_id }
                                                                                                }}
                                                                                                >{m.screenName}</Link></a><span>, </span></span>
                                                                                            );
                                                                                        })
                                                                                    )
                                                                                }
                                                                            }
                                                                            )()}
                                                                        </li>
                                                                        )
                                                                    }
                                                                }
                                                                )()}
                                                            </ul>
                                                        </div>)

                                                }
                                                )
                                                }</div>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div></>
                        : <div className="loaderclass">
                            <img
                                src="../../images/newgif.gif"
                                alt="loading"
                            />
                        </div>

                    }
                </div>
                {localStorage.getItem('token')
                    ? <Footer {...this.props} ref={this.child} {...this.footerPropsForUpdateOnSamePath} updateClickedButtonToExit={this.updateClickedButtonToExit.bind(this)} />
                    : <GuestFooter {...this.props} ref={this.child} {...this.footerPropsForUpdateOnSamePath} updateClickedButtonToExit={this.updateClickedButtonToExit.bind(this)} />
                }
            </div>
        );
    };
};

export default SavedTemporalInfo;
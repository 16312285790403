import romans from "romans"

export function getRomanCountOfSubspacesInStack(tabWiseData, stack) {
    const length = tabWiseData?.[stack]?.openProfiles?.length || 0

    return length > 3 ? romans.romanize(length).toLowerCase() : ""
}

export function formatRomanCount(roman) {
    if (/^x+ix$/.test(roman)) {
        const match = roman.match(/^(x+)/);
        const prefixCount = match ? match[0].length : 0;
        const subscript = prefixCount + 1;
        return `ix<sub>${subscript}</sub>`;
    }

    const counts = { 'i': 0, 'v': 0, 'x': 0 };
    for (let char of roman) {
        if (counts.hasOwnProperty(char)) {
            counts[char]++;
        }
    }
    let formatted = roman.replace(/i+|v+|x+/g, (match) => {
        let baseChar = match[0];
        let count = counts[baseChar];
        return count > 1 ? `${baseChar}<sub>${count}</sub>` : baseChar;
    });
    return formatted;
}  
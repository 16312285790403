import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { userListForLogin, usersListForGuets } from '../../Services/api';
import { getProfilesList } from '../../utils/profiles/manageProfilesList';
import { showInitials } from '../../utils/profiles/shared.js';
import InfiniteScroll from "react-infinite-scroll-component";
import { removeDuplicateObjectsFromArray } from '../../utils/arrayHelper';
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class FirstSideBar extends React.Component {
   static propTypes = {
      match: 'tap',
      location: "Dashbord",
      history: "new"
   }
   constructor(props) {
      super(props);
      this.state = {
         baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
         record: [], token: false, newRecord: [], page: 2, hasMoreUser: true, hasNewRecords: false, entry: []
      }
   }

   getLocalState = () => {
      const entry = localStorage.getItem("entry");
      if (this.state.hasNewRecords || (entry && JSON.parse(entry).length && JSON.stringify(this.state.entry) !== JSON.stringify(JSON.parse(entry)))) {
         const localStorageData = JSON.parse(entry);
         const updatedData = removeDuplicateObjectsFromArray([...localStorageData, ...this.state.newRecord]);
         this.setState({ record: updatedData, hasNewRecords: false, entry: localStorageData }, () => {
            //this.mainProfileColor();
         });
      }
   }

   componentDidMount = async () => {
      //  localStorage.clear();
      if (this.props.isSidebarVisible) {
         this.intervalLocalStaorageChange = setInterval(this.getLocalState, 100);
      }
      getProfilesList().then((res) => {
         this.setState({ record: [...res], });
      })
      this.setState({ entry: JSON.parse(localStorage.getItem("entry")) });
      // let token = localStorage.getItem('token');
      // if(token && token!==null){
      //    //this.nonGestUser();
      //    let data = await userListForLogin(1);
      //       this.setState({record:data},()=>{
      //       //this.mainProfileColor();
      //    });
      // }else{
      //    //this.GestUser();
      //    let data = await usersListForGuets(1);
      //       this.setState({record:data},()=>{
      //       //this.mainProfileColor();
      //    });
      // }


      // setTimeout(() => {
      // if(JSON.parse(localStorage.getItem("entry")) <= 0 | JSON.parse(localStorage.getItem("entry")) == null)
      // {
      // const token = localStorage.getItem('token');
      // let url=this.state.baseUrl+'/api/group/dummyusers.php';
      // if(token && token!==null){
      //    url=this.state.baseUrl+'/api/group/user.php';
      //    this.setState({token:true});
      // }
      // fetch(url,{
      // method: "GET",
      // headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      // "Authorization": "Bearer " + token,
      // },     
      // })
      // .then((response) => response.json())
      // .then((responseJson) =>{
      // this.setState({record:responseJson.response},()=>{
      //    //this.mainProfileColor();
      // });
      // // localStorage.setItem("entry", JSON.stringify(this.state.record));
      // }).catch((error)=>{
      // console.log(error);
      // })
      // }
      // else{
      // this.setState({record:JSON.parse(localStorage.getItem("entry"))},()=>
      // {
      //    //this.mainProfileColor();
      // });
      // }
      // }, 3000);
      //this.interval = setInterval(() => this.newfunction(), 1000);
   }

   // nonGestUser(){
   //    const token = localStorage.getItem('timeToken');
   //    fetch(this.state.baseUrlNode+'/api/recomended',{
   //       method: "GET",
   //       headers: {
   //       "x-access-token":token,
   //       Accept: "application/json",
   //       "Content-Type": "application/json",
   //       },     
   //       })
   //       .then((response) => response.json())
   //       .then((responseJson) =>{

   //          this.setState({record:responseJson.data},()=>{
   //             console.log('recognize12',this.state.record);
   //             //this.mainProfileColor();
   //          });
   //          localStorage.setItem("entry", JSON.stringify(responseJson.data));
   //       }).catch((error)=>{
   //          console.log('error ################################',error.message);
   //       });
   // }

   // GestUser(){
   //    fetch(this.state.baseUrlNode+'/api/guestRecomended',{
   //       method: "GET", 
   //       })
   //       .then((response) => response.json())
   //       .then((responseJson) =>{
   //          this.setState({record:responseJson.data},()=>{
   //             this.mainProfileColor()
   //          });
   //          localStorage.setItem("entry", JSON.stringify(responseJson.data));
   //       }).catch((error)=>{
   //          console.log('error ################################',error.message);
   //       })
   // }

   componentWillUnmount() {
      // clearInterval(this.interval);
      clearInterval(this.intervalLocalStaorageChange);

   }

   newfunction() {
      if (window.location.href.split('/')[window.location.href.split('/').length - 2] == "Users") {
         if (localStorage.getItem("entry") && localStorage.getItem("entry") != null && JSON.stringify(this.state.record) !== JSON.stringify(JSON.parse(localStorage.getItem("entry")).length > 0 && JSON.parse(localStorage.getItem("entry")) !== null)) {
            this.setState({ record: JSON.parse(localStorage.getItem("entry")) }, () => {
               //this.mainProfileColor();
            });
         }
      }
   }

   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);
      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   mainProfileColor() {
      // console.log('this.state.record',this.state.record);
      for (let index = 0; index < this.state.record.length; index++) {
         var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
         $(".charactercolor" + index).css("color", "");
         $(".charactercolor" + index).css("color", random_color.textColor);
         $(".charactercolor" + index).css("background-color", "");
         $(".charactercolor" + index).css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
      }
   }
   moveVisitedProfileToStartOfRecords(user) {
      const data = [...this.state.record];
      data.unshift(user);

      // Remove duplicates based on a key
      const key = 'user_id';
      const arrayUniqueByKey = [
         ...new Map(
            data.map(item => [item[key], item])
         ).values()
      ];

      // Update local storage
      localStorage.setItem('entry', JSON.stringify(arrayUniqueByKey));

      // Update the state only if needed
      if (JSON.stringify(this.state.record) !== JSON.stringify(arrayUniqueByKey)) {
         this.setState({ record: arrayUniqueByKey }, () => {
            //this.mainProfileColor();
         });
      }
   }

   setStorage(user) {
      const { appContext, location } = this.props;
      const { state, actions } = appContext;

      if (state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 1 && location?.pathname == '/') {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         });
      }

      localStorage.setItem("backid", "front");
      this.props.setVisitedPaths((prevPaths) => [...prevPaths, user.username]);
   }

   handleMouseOver = (event) => {
      const targetElement = event.target;
      const elementRect = targetElement?.getBoundingClientRect();
      const elementY = elementRect?.top + window?.scrollY;
      const tooltip = targetElement?.querySelector(".profileInfo");
      const tooltip_p = targetElement?.querySelector(".profile");
      const tooltipRect = tooltip_p?.getBoundingClientRect();
      const elementX = tooltipRect?.left + window.scrollX; 

      if (tooltip) {
         const paddingY = window?.innerWidth < 767 ? 15 : 16;
         tooltip.style.top = `${elementY + targetElement?.offsetHeight - 1}px`;
         tooltip.style.left = `${0}px`;
         tooltip.style.maxWidth = 'max-content';
         tooltip.style.padding = `${paddingY}px ${elementX}px`;
      }
   };
   fetchMoreUserData = () => {
      this.fetchMoreUser(this.state.page);
   };
   async fetchMoreUser(page) {
      const token = localStorage.getItem('timeToken');
      if (token) {
         try {
            await userListForLogin(page).then((res) => {
               if (res.length > 0) {
                  this.setState((prevState) => ({
                     newRecord: [...prevState.newRecord, ...res],
                     page: page + 1,
                     hasNewRecords: true,
                  }), () => {
                     if (res.length < 256) {
                        this.setState({ hasMoreUser: false });
                     }
                  });
               } else {
                  this.setState({ hasMoreUser: false });
               }
            })
         } catch (error) {
            console.log("Error users fetching", error);
         }
      } else {
         try {
            await usersListForGuets(page).then((res) => {
               if (res.length > 0) {
                  this.setState((prevState) => ({
                     newRecord: [...prevState.newRecord, ...res],
                     page: page + 1,
                     hasNewRecords: true,
                  }), () => {
                     if (res.length < 256) {
                        this.setState({ hasMoreUser: false });
                     }
                  });
               } else {
                  this.setState({ hasMoreUser: false });
               }
            })
         } catch (error) {
            console.log("Error users fetching", error);
         }
      }
   }
   renderUserGroups = () => {
      const allUsers = this.state.record;
      const Allelements = [];
      const upperGroupSize = 24;
      const groupSize = 8;
      const elementsPerSubgroup = 4

      for (let g = 0; g < Math.ceil(allUsers.length / upperGroupSize); g++) {
         const users = allUsers.slice(g * upperGroupSize, (g + 1) * upperGroupSize);
         const userGroups = [];
         let subgroupSize;

         for (let i = 0; i < users.length; i += subgroupSize) {
            let higherLevelGroupClass = '';

            if (i === 0) {
               subgroupSize = groupSize;
               higherLevelGroupClass = 'firstGroupside';
            } else if (i == 16) {
               subgroupSize = groupSize;
               higherLevelGroupClass = 'lastGroupside';
            } else {
               subgroupSize = groupSize;
               higherLevelGroupClass = 'middleGroupside';
            }

            const group = users.slice(i, i + subgroupSize);
            const parentDivs = [];

            for (let j = 0; j < group.length; j += elementsPerSubgroup) {
               const subgroup = group.slice(j, j + elementsPerSubgroup);
               const parentDiv = (
                  <div className={`parentDivside group-${i / elementsPerSubgroup}`} key={i + j}>
                     {subgroup.map((p, k) => (
                        <li key={j}>
                           <Link
                              to={{
                                 pathname: `/${p.username}`,
                                 state: { id: p.user_id }
                              }}
                              className="link"
                              onClick={() => {
                                 this.moveVisitedProfileToStartOfRecords(p);
                                 this.setStorage(p);
                              }}
                              onMouseOver={this.handleMouseOver}
                           >
                              {/* {p.profileImage !== "" ? (
                                 <img src={this.state.baseUrl + p.profileImage} alt="profile" />
                              ) : (""
                                 <span className={`charactercolor${i}`}>
                                    <div className="profile_initials">
                                       {this.fetchmakeprofile(p.screenName)}
                                    </div>
                                 </span>
                              )} */}
                              <p className='profile'>{showInitials(p.screenName)}

                                 <p className='profileInfo' data-fullname={p.screenName}>{p.screenName}</p>
                              </p>
                           </Link>
                        </li>
                     ))}
                  </div>
               );

               parentDivs.push(parentDiv);
            }

            userGroups.push(
               <div className={`higherLevelGroup ${higherLevelGroupClass}`} key={i / 32}>
                  {parentDivs}
               </div>
            );
            // console.log({ higherLevelGroupClass, isLastGroup, parentDivs, userGroups });
         }
         Allelements.push(
            <div className='rowBlock'>
               {userGroups}
            </div>
         );
      }
      return Allelements;
   };



   render() {
      return (
         <div className="sidebar sidebar1">
            <ul id='scrollableSidebarLeft'>
               <InfiniteScroll
                  dataLength={this.state.record.length}
                  next={this.fetchMoreUserData}
                  hasMore={this.state.hasMoreUser}
                  loader={<div className="userlistloader"><img src="../../images/newgif.gif" alt="loading" /></div>}
                  scrollableTarget="scrollableSidebarLeft">
                  {
                     this.renderUserGroups()
                  }
               </InfiniteScroll>
               {/* {this.state.record ?
         this.state.record.map((p, i) => {
         console.log(this.state.record.length, p ,'this.state.record.length');
              if(i<30)
              {
           return(
            <li>{<Link to={{
               pathname: `/${p.username}`,
               state: {id:p.user_id}
               }} class="link" onClick={()=>{
                  this.moveVisitedProfileToStartOfRecords(p);
                  this.setStorage(p)
               }}
               >{p.profileImage!="" ? <img src={this.state.baseUrl+p.profileImage} align="images"/> 
               : <span className={"charactercolor"+i}>
                  <div className="profile_initials"> */}
               {/* {this.fetchmakeprofile(p.screenName)} */}
               {/* </div></span> }
                  <p>{p.screenName}</p></Link>}</li>
         ) }
      })
            
 : ""} */}


            </ul>
         </div>
      );
   };
};
export default FirstSideBar;
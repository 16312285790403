import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { ControlBar, Player } from 'video-react';
import CustomPopUp, { InvalidUsernameError } from '../CustomPopup/index.js';
import { fetchSortedUser, switchAccount, userListForLogin, usersListForGuets } from '../Services/api.js';
import FormSubmitLoader from '../components/FormSubmitLoader/index.js';
import Timetable from '../components/Timetable/Timetable.js';
import UserProfile from '../components/commonfile/UserProfile.js';
import Changepasswordc from '../components/commonfile/changepasswordc.js';
import { MyContext } from '../context/index.js';
import { sortArrayOfObjects } from '../utils/arrayHelper/index.js';
import { ADD_POST, CHANGE_PASSWORD, EDIT_POST, LOGIN_SIGNUP, ORGANIZATIONS, SELF_PROFILE, SETTINGS, USER_PROFILE } from '../utils/constants/componentNames.js';
import { getdateformat } from '../utils/dateFormatterForTI/dateFormatter.js';
import { getGrammarContainer, handleGrammarContainer, removeGrammarContainer } from '../utils/footer/handleGrammarContainer.js';
import { getDualTooltipContainer, getTooltipContainer, handleDualTooltipContainer, handleTooltipContainer } from '../utils/footer/handleTooltipContainer.js';
import { getRomanCountOfSubspacesInStack, formatRomanCount } from '../utils/footer/romanCountOfSubspacesInStack.js';
import { getTabWithMostOpenProfilesInStack, getTabwiseOpenProfileScreenNames } from '../utils/footer/tabWithMostOpenProfilesInStack.js';
import { convertToTeX, formatDate, formatOrganizationName, formatSearchResult } from '../utils/math/index.js';
import { fetchTeXCompiledCaption, modifyHref, modifyWebsiteName } from '../utils/post/manageCaptions.js';
import { getListBySortingType, getProfilesList, removeProfileDataFromStorage } from '../utils/profiles/manageProfilesList';
import { searchProfile } from '../utils/profiles/searchProfiles.js';
import { showInitials } from '../utils/profiles/shared.js';
import { ReactComponent as TimelineSvg } from '../utils/svg/Bidirectionally-traversed-timeline.svg';
import { ReactComponent as CalendarSvg } from '../utils/svg/Calendar.svg';
import { ReactComponent as DepartSvg } from '../utils/svg/Depart.svg';
import { ReactComponent as DownwardArrow } from '../utils/svg/Down-facing-arrow.svg';
import { ReactComponent as HandShakeSvg } from '../utils/svg/Handshake.svg';
import { ReactComponent as NewsPaperSvg } from '../utils/svg/Newspaper.svg';
import { ReactComponent as RevisitSvg } from '../utils/svg/Revisit.svg';
import { ReactComponent as ZZSvg } from '../utils/svg/ZZ.svg';
import { formatUnixTimestamp } from "../utils/time/index.js";
import { determineDateToBeSelectForMonths, getActiveDatesOfTheMonth } from "../utils/timetableHelper/timetableHelper.js";
import { handleClickOfProfiles } from "../utils/profiles/clickHandler.js";

export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];

// import Footer from '../components/Footer';
export const DATE_LATEST_POST = "YYYY-MM-DD";
export const DATE_TIME_LATEST_POST = "YYYY-MM-DDTHH:mm";
export const USERNAME_REGEX = /^[a-zA-Z0-9_-]+s*$/;
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const PATTERN_REGEX_URL = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/;

const usernameRegex = /^[a-zA-Z]+(?:-[a-zA-Z]+)*$/;
//
export const PATTERN_REGEX_ADDRESS = /(?:.*^|\s+)(?:a:)([A-Za-z0-9#_,./&"'+-]|[.\s])+.:?/;
const style = {
   height: 30,
   border: "1px solid green",
   margin: 6,
   padding: 8
};

const pageState = {
   background: "background",
   foreground: "foreground",
   terminate: "terminate",
};

class Screen2 extends React.Component {
   static contextType = MyContext;
   timeoutId = null;
   constructor(props) {
      super(props);
      this.contentEditable = React.createRef();
      this.state = {
         showSignUp: false,
         suggestionsList: [],
         placetext: "",
         selectedDate: "", editpostOn: 0, editpostOn2: 0, editlike: [], editcomment: [], editcount: 0,
         firstDate: "", colorIcon: "blur",
         postType2: "", recomended: [], editevent: "", activitySortedUsers: [], temporallySortedUsers: [],
         searchinguser: [], slectedVar: "", slectedConst: "", errortitle: "",
         searchloader: false, maximum: "", minimum: "", monthyear: [], Previousd: "", Nextd: "", core: true,
         imageVideoUrl2: "", evntData: [], loaddocument: false, backid: "", changewithwho: false,
         selectedHour: "", inverse: true, static: "", exp: false,
         selectedMinutes: "", reco: [],
         selectedMode: "",
         hashTagstext: "",
         timedata2: "",
         imageVideoUrl: "",
         postType: "",
         filewithpath: "",
         editerror: "",
         posttext: "",
         posttext2: "",
         eventid: "",
         mutual: [],
         selectedtype: 'everyone',
         editstatus: "", editeventImage: "", editmediaType: "", isVideoUploading: false,
         editwhocansee: "", selectedtypefinal: 'everyone', editwhocanseemeta: [], editwithwho: [],
         editheight: "", editwidth: "", editinclude_in_feed: "", editIsImportant: "",
         tagtype: "",
         cbio: "",
         email: '',
         password: '',
         full: '',
         username: '',
         profilecheck: "",
         profiledata: [],
         marks: [],
         signemail: '',
         withwhodata: [],
         valuedataprofile: new Date(),
         signpassword: '',
         items: Array.from({ length: 40 }),
         hasMoreUser: true,
         page: 2,
         searchPerameter: "",
         checked: "",
         checked2: "",
         editerror2: "",
         unexceptdata: [],
         exceptdata: [],
         cwebsite: "",
         inputValue: '',
         activeTab: 'second',
         baseUrl: process.env.REACT_APP_BASE_URL, f1: {}, f2: {}, f3: {}, s1: {}, s2: {}, s3: {}, t1: {}, t2: {}, t3: {}, activeProfile: '', destinationInfo: '', screenWidth: window.innerWidth,
         loadthired: false,
         tags: [],
         invalidLoginPopup: false, logoutPopup: false, blankFieldsPopup: false,
         openDeleteInformationPopup: false,
         signupFailedPopup: false,
         showSignUpLoader: false,
         signupFailedErrorMsg: "", textEntryPopup: false, selectDatePopup: false,
         hasMore: true,
         datasetup: [], baseUrlNode: process.env.REACT_APP_BASE_URL_NODE, topcontat: "of", contat: "of", isImportant: false,
         hourObj: {}, secondHourObj: {}, secondDate: "", secondSelectedHour: '',
         secondTimedata2: '', secondSelectedMode: '', secondMode: '', secondSelectedMinutes: "",
         seeMorePage: 1, savePost: [], tabWiseData: { first: { openProfiles: [] }, second: { openProfiles: [] }, third: { openProfiles: [] }, },
         freshLoad: true,
         displayUserId: 0,
         leftSideArray: [],
         rightSideArray: [],
         currentMonthYear: "",
         monthYearDateMap: {},
         isValidUsername: false, accountType: localStorage.getItem("profileType"), switchAccountPopup: false,
         e: "",
         userProfileComponents: [
            {
               state: pageState.terminate,
               comp: (pos) => <UserProfile
                  {...this.props}
                  pos={pos + " 1"}
                  user_id={this.state.displayUserId}
                  resetUserId={() => this.setState({ displayUserId: 0 })}
                  subspace={{ number: 2, inWords: "two", class: "else" }}
                  callParentFunction={this.callFuntion.bind(this)}
               />
            }, {
               state: pageState.terminate,
               comp: (pos) => <UserProfile
                  {...this.props}
                  pos={pos + " 2"}
                  user_id={this.state.displayUserId}
                  resetUserId={() => this.setState({ displayUserId: 0 })}
                  subspace={{ number: 2, inWords: "two", class: "else" }}
                  callParentFunction={this.callFuntion.bind(this)}
               />
            }
         ],
         display: {
            [SELF_PROFILE]: false,
            [LOGIN_SIGNUP]: false,
            [ORGANIZATIONS]: false,
            [USER_PROFILE]: false,
            [SETTINGS]: false,
            [CHANGE_PASSWORD]: false,
            [ADD_POST]: false,
            [EDIT_POST]: false,
         },
         tabWithMostOpenProfiles: '', openProfilesInStack: '', numeralOpenProfilesInStack: '',
         tabwiseStackInfo: {
            first: { lengthInRoman: "" },
            second: { lengthInRoman: "" },
            third: { lengthInRoman: "" }
         }, folder1: {}, folder2: {}, folder3: {},
         userDetails: {},
         timetableData: {
            threeMonthsEarlier: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate: undefined,
               maxDate: undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: { data: [], appendedMonth: null },
               savePost: []
            },
            twoMonthsEarlier: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate: undefined,
               maxDate: undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: { data: [], appendedMonth: null },
               savePost: []
            },
            previous: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate: undefined,
               maxDate: undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: { data: [], appendedMonth: null },
               savePost: []
            },
            current: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate: undefined,
               maxDate: undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: { data: [], appendedMonth: null },
               savePost: []
            },
            next: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate: undefined,
               maxDate: undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: { data: [], appendedMonth: null },
               savePost: []
            },
            twoMonthsAhead: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate: undefined,
               maxDate: undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: { data: [], appendedMonth: null },
               savePost: []
            },
            threeMonthsAhead: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate: undefined,
               maxDate: undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: { data: [], appendedMonth: null },
               savePost: []
            }
         },
         editDeletePopup: false,
         deletePost: {date: undefined, calendar: ""},
         updateTemporalInformation: false,
      };
      this.userBadgeRef = React.createRef();
      this.calendarRef = React.createRef();
      this.onChangedataprofile = this.onChangedataprofile.bind(this);
      this.weekDays = this.weekDays.bind(this);
      this.onCalendarPostAdd = this.onCalendarPostAdd.bind(this);
      this.onCalendarPostEdit = this.onCalendarPostEdit.bind(this);
      this.fetchMoreUserData = this.fetchMoreUserData.bind(this);
      this.updateImage = this.updateImage.bind(this);
   }

   displayElementInDOM(element) {

      return new Promise((resolve) => {
         let elements = Object.entries(this.state.display)

         let activeElement = elements.find(el => el[1])?.[0]

         if (activeElement == element) return resolve()

         this.setState({ display: { ...this.state.display, [element]: true } }, () => {
            resolve()
            if (activeElement)
               setTimeout(() =>
                  this.removeElementFromDOM(activeElement)
                  , 600);
         })
      })
   }

   removeElementFromDOM(element) {
      this.setState({ display: { ...this.state.display, [element]: false } })
   }

   addClassActive = (activeTab, tabData) => {
      const oldActiveTab = this.state.activeTab

      this.setState({ activeTab: activeTab });
      this.context.setActiveTab(activeTab);

      this.showInitialsOfOpenProfilesOnTab();
      if (oldActiveTab === activeTab) {
         const { sharedData } = this.context;
         const openProfile = sharedData.tabWiseData[activeTab]?.openProfiles;
         const { appContext } = this.props;
         const { state, actions } = appContext;

         if (tabData.function == "fetchFilterUser" && activeTab == 'second' && state.homePageActive.status) {
            const entry = JSON.parse(localStorage.getItem('entry'));
            const currentProfile = this.state.datasetup[this.state.datasetup.length - 1]?.data?.ids;

            if (currentProfile == entry[0]?.user_id && openProfile.length == 1) {
               return true;
            } else {
               this.closeAllOpenedProfilesFromActiveTab(activeTab).then(() => this.showInitialsOfOpenProfilesOnTab());
               if (state.homePageActive.activeHomePageSubspace !== 2) {
                  this.gouserprofile({ ids: entry[0]?.user_id });
               }
               return true;
            }
         }
         this.closeAllOpenedProfilesFromActiveTab(activeTab).then(() => this.showInitialsOfOpenProfilesOnTab())
      }
      else if (this.activeTabHasOpenProfiles(activeTab)) {
         this.displayOpenProfiles(tabData.function, this.context.sharedData.tabWiseData[activeTab].openProfiles, activeTab)
         return true
      }
      return false
   };

   // setSubscript() {
   //    // console.log("hi");
   //    if ($(" .newscreen2 .footer-menu li").is(".active")) {
   //       var subElement = $(".newscreen2 .footer-menu li a sub");
   //       var downwardArrowComponent = <DownwardArrow />;
   //       subElement.append(downwardArrowComponent);
   //    } else {
   //       $(".newscreen2 .footer-menu li a sub").html(``)
   //    }
   // }

   openUserProfile(id, tab) {
      const { appContext } = this.props;
      const { state, actions } = appContext;

      var e = { ids: id };
      const { sharedData } = this.context;
      const openProfile = sharedData.tabWiseData[tab].openProfiles;

      if (this.state.activeTab !== tab) {
         this.addProfilePathsToHistory({ e, openProfile, tab });
      } else {
         this.context.setActiveTab(tab);
         this.setState({ activeTab: tab });
         this.gouserprofile(e);
      }

      if (tab !== 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 2) {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         })
      }
   }

   addProfilePathsToHistory(e) {
      const tab = e?.tab;
      const openProfiles = e?.openProfile;
      const user = e?.e.ids;
      const profileToBeOpen = { ids: e?.e.ids };
      const token = localStorage.getItem('token');

      this.context.setActiveTab(tab);
      this.setState({ activeTab: tab });

      var dataToBeAddFirst = { function: "fetchFilterUser", data: { back: "back", tab: 'second' } };
      const tabToPushFirst = tab === 'first' && !token ? dataToBeAddFirst = { function: "gotosetting", data: { back: "back", tab: 'first' } } :
         tab === 'first' && token ? dataToBeAddFirst = { function: "goProfile", data: { back: "back", tab: 'first' } } :
            tab === 'second' ? dataToBeAddFirst = { function: "fetchFilterUser", data: { back: "back", tab: 'second' } } :
               tab === 'third' && !token ? dataToBeAddFirst = { function: "goToPost", data: { back: "back", tab: 'third' } } :
                  tab === 'third' && token ? dataToBeAddFirst = { function: "goToPost", data: { back: "back", tab: 'third' } } :
                     dataToBeAddFirst = { function: "fetchFilterUser", data: { back: "back", tab: 'second' } };

      this.state.datasetup.push(tabToPushFirst);
      this.setState({ datasetup: this.state.datasetup });

      if (openProfiles?.length > 0) {
         if (openProfiles[openProfiles?.length - 1] == user) {
            openProfiles.slice(0, openProfiles.length - 1).forEach(id => {
               let addUserHistory = { function: "gouserprofile", data: { ids: id, back: "back", tab: tab } };
               this.state.datasetup.push(addUserHistory);
            });
            this.setState({ datasetup: this.state.datasetup });
            this.gouserprofile(profileToBeOpen);
         } else {
            openProfiles.forEach(id => {
               let addUserHistory = { function: "gouserprofile", data: { ids: id, back: "back", tab: tab } };
               this.state.datasetup.push(addUserHistory);
            });
            this.setState({ datasetup: this.state.datasetup });
            this.gouserprofile(profileToBeOpen);
         }
      }
   }

   async showInitialsOfOpenProfilesOnTab() {
      const tabWiseData = this.context.sharedData.tabWiseData;

      this.updateFolderWiseData()

      if (!localStorage.entry)
         return await new Promise((resolve) => setTimeout(() => resolve(this.showInitialsOfOpenProfilesOnTab()), 600))

      this.updateSubspaceStackIndicator(tabWiseData)

      const entry = JSON.parse(localStorage.entry);

      const getUserInfo = (id) => {
         const name = entry.find((user) => user.user_id == id)?.screenName;
         const data = { userId: id, screenName: name };
         return data;
      }

      const showData = (profiles, tab) => {
         if (!profiles || !profiles.openProfiles) {
            return {};
         }

         const dataLength = profiles?.openProfiles.length;
         var result = {};

         if (profiles.openProfiles && profiles.openProfiles.length > 0) {
            const openProfiles = profiles.openProfiles;
            result[`${tab}1`] = openProfiles.length === 1 ? getUserInfo(openProfiles[dataLength - 1]) : openProfiles.length === 2 ? getUserInfo(openProfiles[dataLength - 2]) : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 3]) : {};
            result[`${tab}2`] = (openProfiles.length === 1 || openProfiles.length === 0) ? {} : openProfiles.length === 2 ? getUserInfo(openProfiles[dataLength - 1]) : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 2]) : {};
            result[`${tab}3`] = (openProfiles.length === 1 || openProfiles.length === 2) ? {} : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 1]) : {};
         }

         return result;
      };
      const firstTab = showData(tabWiseData["first"], 'F');
      const secondTab = showData(tabWiseData["second"], 'S');
      const thirdTab = showData(tabWiseData["third"], 'T');
      const data = { 'firstTab': firstTab, 'secondTab': secondTab, 'thirdTab': thirdTab }
      this.setState({
         f1: data['firstTab']?.['F1'] || {},
         f2: data['firstTab']?.['F2'] || {},
         f3: data['firstTab']?.['F3'] || {},
         s1: data['secondTab']?.['S1'] || {},
         s2: data['secondTab']?.['S2'] || {},
         s3: data['secondTab']?.['S3'] || {},
         t1: data['thirdTab']?.['T1'] || {},
         t2: data['thirdTab']?.['T2'] || {},
         t3: data['thirdTab']?.['T3'] || {},
      });
   }

   handleScreenDifferentiatorMouseOver(e, position) {
      this.getFolderWiseData();
      setTimeout(() => {
         handleDualTooltipContainer(e, position);
      }, 1);
   }


   async updateSubspaceStackIndicator(tabWiseData) {
      const { tab, openProfilesInStack } = getTabWithMostOpenProfilesInStack(tabWiseData)

      this.setState({ tabWithMostOpenProfiles: tab, openProfilesInStack });
      const numeralStack = openProfilesInStack === 'one' ? 'i' : openProfilesInStack === 'two' ? 'ii' : openProfilesInStack === 'three' ? 'iii' : '';
      this.setState({ numeralOpenProfilesInStack: numeralStack });

      const folderData = this.props.appContext.state.folderData;

      const tabwiseOpenProfileScreenNames = getTabwiseOpenProfileScreenNames(tabWiseData);
      const folder1TabwiseOpenProfileScreenNames = folderData?.folder1 && getTabwiseOpenProfileScreenNames(folderData?.folder1);
      const folder3TabwiseOpenProfileScreenNames = folderData?.folder3 && getTabwiseOpenProfileScreenNames(folderData?.folder3);

      this.setState({
         tabwiseStackInfo: {
            first: { ...this.state.tabwiseStackInfo["first"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "first"), organizations: [...tabwiseOpenProfileScreenNames["first"] || []] },
            second: { ...this.state.tabwiseStackInfo["second"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "second"), organizations: [...tabwiseOpenProfileScreenNames["second"] || []] },
            third: { ...this.state.tabwiseStackInfo["third"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "third"), organizations: [...tabwiseOpenProfileScreenNames["third"] || []] },
         }
      })

   }

   updateFolderWiseData() {
      const { state, actions } = this.props.appContext;
      const { setFolderData } = actions;

      const { sharedData } = this.context;
      const tabWiseData = sharedData.tabWiseData;
      let keys = Object.keys(tabWiseData)
      let values = Object.values(tabWiseData)
      let data = {}

      values.forEach((val, ind) => {
         data[keys[ind]] = val
      })

      setFolderData((fData) => { return { ...fData, folder2: JSON.parse(JSON.stringify(data)) } })
   }


   addDefaultOpenProfiles() {

      if (!this.context) {
         setTimeout(() => this.addDefaultOpenProfiles(), 100)
         return
      }
      const { sharedData, setSharedData } = this.context;

      if (!sharedData.freshLoad)
         return

      if (!localStorage.getItem('entry')) {
         setTimeout(() => this.addDefaultOpenProfiles(), 600)
         return
      }

      const userProfiles = JSON.parse(localStorage.getItem('entry'))
      const defautlProfilesForFirstTabIndexes = [3, 4, 0, 1, 2]; //32154
      const defautlProfilesForSecondTabIndexes = [4, 3, 2, 1, 0]; //12345
      const defautlProfilesForThirdTabIndexes = [0, 1, 2, 3, 4]; //54321

      let openProfiles = []


      if (localStorage.getItem("profileType") !== 'consumer') {
         defautlProfilesForFirstTabIndexes.forEach(ind => {
            openProfiles.push(userProfiles[ind].user_id)
         });

         sharedData.tabWiseData["first"] = { ...sharedData.tabWiseData["first"], openProfiles };
         openProfiles = [];
      }

      defautlProfilesForSecondTabIndexes.forEach(ind => {
         openProfiles.push(userProfiles[ind].user_id)
      });

      sharedData.tabWiseData["second"] = { ...sharedData.tabWiseData["second"], openProfiles };
      openProfiles = [];

      defautlProfilesForThirdTabIndexes.forEach(ind => {
         openProfiles.push(userProfiles[ind].user_id)
      });

      sharedData.tabWiseData["third"] = { ...sharedData.tabWiseData["third"], openProfiles };

      setSharedData({ ...sharedData, freshLoad: false })

      this.showInitialsOfOpenProfilesOnTab();
   }

   updateImage() {
      this.setState({ screenWidth: window.innerWidth });
   }

   // determineDestination(tab) {
   //    const token = localStorage.getItem('token');
   //    const pathToRedirect = () => {
   //       const path = tab === 'first' && !token ? 'Settings' : tab === 'first' && token ? 'Self' :
   //                    tab === 'second' ? 'Temporal information' : 
   //                    tab === 'third' && this.state.profileType === 'consumer' ? 'Settings' : 'Enter'; 
   //       return path;
   //    } 

   //    let activeStack = '';
   //    if(tab == 'first') {
   //       activeStack = this.state.f3?.screenName || this.state.f2?.screenName || this.state.f1?.screenName || pathToRedirect()
   //    } else if (tab == 'second') {
   //       activeStack = this.state.s3?.screenName || this.state.s2?.screenName || this.state.s1?.screenName || pathToRedirect()
   //    } else if (tab == 'third') {
   //       activeStack = this.state.t3?.screenName || this.state.t2?.screenName || this.state.t1?.screenName || pathToRedirect()
   //    }
   //    this.setState({destinationInfo: activeStack});
   // }

   componentWillReceiveProps(nextProps) {
      if (nextProps.abc) {
         const { appContext } = this.props;
         const { state, actions } = appContext;

         if (this.state.activeTab === 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 2) {
            actions.setHomePageActive({
               status: false,
               activeHomePageSubspace: null,
            });
         }
         var e = {};
         if (localStorage.getItem('userid') && localStorage.getItem('userid') !== null) {
            e = { ids: nextProps.abc };
         }
         else {
            e = { auth: 'empty', ids: nextProps.abc };
         }
         this.gouserprofile(e);
         this.props.resetAbc()
      }
      // this.getFolderWiseData();
   }

   getFolderWiseData() {
      const folderData = this.props.appContext.state.folderData;

      const folder1TabwiseOpenProfileScreenNames = folderData?.folder1 && getTabwiseOpenProfileScreenNames(folderData?.folder1);
      const folder2TabwiseOpenProfileScreenNames = folderData?.folder2 && getTabwiseOpenProfileScreenNames(folderData?.folder2);
      const folder3TabwiseOpenProfileScreenNames = folderData?.folder3 && getTabwiseOpenProfileScreenNames(folderData?.folder3);

      this.setState({ folder1: folder1TabwiseOpenProfileScreenNames, folder2: folder2TabwiseOpenProfileScreenNames, folder3: folder3TabwiseOpenProfileScreenNames });
   }


   componentDidMount() {
      // console.log(this.props.detect);
      document.addEventListener('mouseover', this.handleMouseOver);
      document.addEventListener('click', this.handleClickOfScreen2Users);
      if (localStorage.getItem('token') && localStorage.getItem('token') !== null && localStorage.getItem('token') !== "") {        

         $(".newscreen2 .errorbtn").click(function () {
            $(".newscreen2 .errorpop2").toggle();
         });
         $(".newscreen2 .remove").click(function () {
            $(".newscreen2 .pupupsecond3").toggle();
         });

         $(".newscreen2 .remove").click(function () {
            $(".newscreen2 .pupupsecond3").toggle();
         });
         localStorage.setItem("footer", "home");
         localStorage.setItem("backid", "back");
         localStorage.setItem("noti", "back")
         localStorage.setItem('moveid', "");
         this.setname();

      } else {
         $('#two_3').addClass('active');
      }

      this.addDefaultOpenProfiles()

      // setTimeout(() => {
      // if (this.props.isScreenVisible) {
         this.opendefaultuser();
      // } else {
      //    this.fetchFilterUser();
      // }
      // },200);
      const websiteValue = localStorage.getItem('website');
      this.setState({ cwebsite: websiteValue, activeProfile: localStorage.getItem('screenname') })
      window.addEventListener('resize', this.updateImage);
   };

   handleClickOfScreen2Users = (event) => {
      handleClickOfProfiles(event, this.props, this.props.setVisitedPaths);
   };

   handleMouseOver() {
      $(".katexContentBlock .katex a").attr("target", "_blank");
      $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
   }

   componentWillUnmount() {
      clearInterval(this.interval);
      window.addEventListener('resize', this.updateImage);
      if (this.timeoutId)
         clearTimeout(this.timeoutId);
      document.removeEventListener('click', this.handleClickOfScreen2Users);
   }

   openpop() {
      $(".newscreen2 .pupupsecond3").toggle();
   }

   async gotosetting() {
      const { appContext } = this.props;
      const { state, actions } = appContext;

      if (state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 2) {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         })
      }

      const activeTab = (localStorage.getItem("profileType") == "consumer") ? 'third' : 'first';
      const tabData = { function: "gotosetting" }
      const shouldExit = this.addClassActive(activeTab, tabData)

      if (shouldExit) return

      await this.displayElementInDOM(SETTINGS)

      this.animationscript('#two_12');
      //  $('.else').removeClass('active fades');
      //  $('#two_12').addClass('active fades');
   }


   edit1() {
      $('#two_16 .pupup6').toggle();
      $('#two_16 .pupup6').addClass('zIndex');
   }

   fetchMoreUserData = () => {
      this.fetchMoreUser(this.state.page);
   };
   async fetchMoreUser(page) {
      const token = localStorage.getItem('timeToken');
      if (token) {
         let newData = await userListForLogin(page);
         if (newData.length > 0) {
            this.setState((prevState) => ({
               recomended: [...prevState.recomended, ...newData],
               page: page + 1,
            }), () => {
               //this.mainProfileColordynamic();
            });
         }
         if (newData.length == 0 || newData.length < 60) {
            this.setState({ hasMoreUser: false });
         }
      } else {
         let newData = await usersListForGuets(page);
         if (newData.length > 0) {
            this.setState((prevState) => ({
               recomended: [...prevState.recomended, ...newData],
               page: page + 1,
            }), () => {
               //this.mainProfileColordynamic();
            });

         }
         if (newData.length == 0 || newData.length < 60) {
            this.setState({ hasMoreUser: false });
         }
      }

      // initially filter data for temporally/activity
      // this.getSortedUser('temporally', this.state.recomended);
      // this.getSortedUser('activity', this.state.recomended);
   }

   async opendefaultuser() {
      const { sharedData } = this.context;
      let activeTabData = sharedData.tabWiseData['second']?.openProfiles;
      if (!activeTabData) {
         return setTimeout(() => {
            this.opendefaultuser();
         }, 1000)
      }
      const user_id = activeTabData[activeTabData?.length - 1];
      activeTabData.pop();

      var datasetups = { function: "fetchFilterUser", data: { back: "back", tab: this.state.activeTab } };
      this.state.datasetup.push(datasetups);

      activeTabData.forEach(id => {
         const datasetups = { function: "gouserprofile", data: { back: "back", ids: id, tab: this.state.activeTab } };
         this.state.datasetup.push(datasetups);
      })

      this.setState({ datasetup: this.state.datasetup });
      // const mostVisitedProfiles = localStorage.getItem("entry");
      this.gouserprofile({ ids: user_id });
   }
   async fetchFilterUser(e) {
      const tabData = { function: "fetchFilterUser" }
      const shouldExit = this.addClassActive('second', tabData)

      const { appContext } = this.props;
      const { state, actions } = appContext;
      const { sharedData } = this.context;
      let activeTabData = sharedData.tabWiseData['second'];


      if (shouldExit) return

      await this.displayElementInDOM(ORGANIZATIONS)

      // $(".else").removeClass("active fades");
      if (e && e.back == "back") {
         this.animationlinkback('#two_3');
         $("#two_3").addClass("active fadesback");
         this.context.setActiveTab(e?.tab);
         this.setState({ activeTab: e?.tab })
         this.state.datasetup.pop();
         this.setState({ datasetup: this.state.datasetup });
         if (this.props.isScreenVisible && !activeTabData.openProfiles?.length && !state.homePageActive.status) {
            actions.setHomePageActive({
               status: true,
               activeHomePageSubspace: 2
            })
         }
      }
      else {
         const entry = JSON.parse(localStorage.getItem('entry'));

         //$("#two_3").addClass("active fades");
         if (state.homePageActive.status && !activeTabData.openProfile?.length) {
            var dataToAdd = { function: "fetchFilterUser", data: { back: "back", tab: 'second' } };
            this.state.datasetup.push(dataToAdd);
            this.setState({ datasetup: this.state.datasetup });
            this.gouserprofile({ ids: entry[0]?.user_id });
            this.animationscript('#two_4');
         } else {
            this.animationscript('#two_3');
            if (this.props.isScreenVisible) {
               actions.setHomePageActive({
                  status: true,
                  activeHomePageSubspace: 2,
               })
               setTimeout(() => {
                  var dataopen = { function: "fetchFilterUser", data: { back: "back", tab: 'second' } };
                  this.state.datasetup.push(dataopen);
                  this.setState({ datasetup: this.state.datasetup });
               }, 1000);
            }
         }
      }
      //localStorage.setItem("footer","second");
      if (JSON.parse(localStorage.getItem("entry")) > 0 | JSON.parse(localStorage.getItem("entry")) !== null) {
         this.setState({ recomended: JSON.parse(localStorage.getItem("entry")) }, () => {
            //this.mainProfileColordynamic();
         });

         // initially filter data for temporally/activity
         // this.getSortedUser('temporally', JSON.parse(localStorage.getItem("entry")));
         // this.getSortedUser('activity', JSON.parse(localStorage.getItem("entry")));
      }
      const token = localStorage.getItem('timeToken');
      setTimeout(() => {
         getProfilesList().then((res) => {
            if (res.length > 0) {
               this.setState((prevState) => ({
                  recomended: [...res],
                  hasMoreUser: true
               }), () => {
                  //this.mainProfileColordynamic();
               });

               // initially filter data for temporally/activity
               // this.getSortedUser('temporally', res);
               // this.getSortedUser('activity', res);
            }
            if (res.length == 0 || res.length < 60) {
               this.setState({ hasMoreUser: false });
            }
         })
      }, 1500)

      // if(JSON.parse(localStorage.getItem("entry")) <= 0 | JSON.parse(localStorage.getItem("entry")) == null)
      // {
      // const token = localStorage.getItem('token');
      // let url=this.state.baseUrl+'/api/group/dummyusers.php';
      // if(token){
      //    url=this.state.baseUrl+'/api/group/user.php';
      // }
      // fetch(url,{
      // method: "GET",
      // headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      // "Authorization": "Bearer " + token,
      // },     
      // })
      // .then((response) => response.json())
      // .then((responseJson) =>{
      // this.setState({recomended:responseJson.response},()=>
      // {
      //    //this.mainProfileColor();
      // });
      // localStorage.setItem("entry", JSON.stringify(this.state.recomended));
      // }).catch((error)=>{
      // console.log(error);
      // })
      // }
      // else
      // {
      //  this.setState({recomended:[],recomended:JSON.parse(localStorage.getItem("entry"))},()=>
      //  {
      //    //this.mainProfileColor();
      //  });
      // }
      // fetch(this.state.baseUrlNode+'/api/recomended',{
      //    method: "GET",
      //    headers: {
      //    "x-access-token":localStorage.getItem("timeToken"),
      //    Accept: "application/json",
      //    "Content-Type": "application/json",
      //    },     
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //       this.setState({reco:responseJson.data});
      //       //console.log('responseJson ################################',responseJson.data);
      //    }).catch((error)=>{
      //       console.log('error ################################',error.message);
      //    });

   }
   cancelwhopop() {
      $('#two_10 .pupup6, #two_16 .pupup6, #two_17 .pupup6').toggle();
      $('#two_10 .pupup6, #two_16 .pupup6, #two_17 .pupup6').removeClass('zIndex');
   }
   shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
         const j = Math.floor(Math.random() * (i + 1));
         [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
   }
   async getSortedUser(sortingType, users) {
      const newUserList = users ? users : JSON.parse(localStorage.getItem('entry'));
      const result = await fetchSortedUser(sortingType, newUserList);
      if (sortingType == 'temporally') {
         this.setState({ temporallySortedUsers: result });
      } else if (sortingType == 'activity') {
         this.setState({ activitySortedUsers: result });
      }
   }

   filterUser(data) {
      if (this.state.slectedVar == "") {
         return data;
      }
      else if (this.state.slectedVar == "mutual") {
         const list = getListBySortingType({ sortingType: 'Mutuality' });
         return list;
      }
      else if (this.state.slectedVar == "alpha") {
         return data.sort((a, b) => a.screenName.localeCompare(b.screenName));
      }
      else if (this.state.slectedVar == "popular") {
         return sortArrayOfObjects(data, 'total_visits', false)
      }
      else if (this.state.slectedVar == "flip") {
         return data.reverse();
      }
      else if (this.state.slectedVar == "shuffle") {
         const shuffledData = this.shuffleArray(data);
         return shuffledData;
      }
      else if (this.state.slectedVar == "temporal") {
         return sortArrayOfObjects(data, 'eventStats.totalEventsIn3Months', false)
      }
      else if (this.state.slectedVar == "active") {
         return sortArrayOfObjects(data, 'eventStats.closestEventInSeconds', true)
      }
   }
   filterUserin(data) {
      if (this.state.slectedConst == "") {
         return data;
      }
      else if (this.state.slectedConst == "mutual") {
         return data.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse();
      }
      else if (this.state.slectedConst == "alpha") {
         return data.sort((a, b) => a.screenName.localeCompare(b.screenName));
      }
   }

   relevancySearch() {
      this.setState({ slectedVar: "", recomended: [] });
      if (JSON.parse(localStorage.getItem("entry"))) {
         this.setState({ recomended: JSON.parse(localStorage.getItem("entry")) }, () => {
         });
      }
   }
   alphabeticallySearch() {
      this.setState({ slectedVar: "alpha" }, () => {
         //this.mainProfileColor();
      });
   }
   mutualitySearch() {
      this.setState({ slectedVar: "mutual" });
   }
   PopularitySearch() {
      this.setState({ slectedVar: "popular" });
   }
   flipSearch() {
      this.setState({ slectedVar: "flip" });
   }
   PseudorandomlySearch() {
      this.setState({ slectedVar: "shuffle" });
   }
   temporallySearch() {
      this.setState({ slectedVar: "temporal" });
   }
   activitySearch() {
      this.setState({ slectedVar: "active" });
   }

   alphabeticallySearchList() {
      $(".newscreen2 .pupupsecond3").toggle();
      this.setState({ slectedConst: "alpha" });
      if (this.state.searchPerameter == "") {
         this.setState({ slectedVar: "alpha" }, () => {
            //this.mainProfileColor();
         });
      }
   }
   relevancySearchList() {
      const token = localStorage.getItem('token');
      $(".newscreen2 .pupupsecond3").toggle();
      if (this.state.searchPerameter == "") {
         this.relevancySearch()
      }
      this.setState({ slectedConst: "" });
      let url = this.state.baseUrl + '/api/user/Gusearch.php?search=' + this.state.searchPerameter + '&page=1&limit=16&user_id=' + localStorage.getItem('userid');
      if (token) {
         url = this.state.baseUrl + '/api/user/search.php?search=' + this.state.searchPerameter + '&page=1&limit=16&user_id=' + localStorage.getItem('userid');
      }
      fetch(url, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ searchinguser: responseJson.response }, () => {
               //this.mainProfileColor();
            });
         });

   }

   getPostOnDetail(timestamp, timestamp2) {
      let { hourObj, date: e, dateStr: date, hours, minutes, mode, combine } = this.getDateInfo(timestamp);
      this.setState({
         hourObj: hourObj,
         selectedDate: e,
         selectedHour: hours,
         selectedMinutes: minutes,
         selectedMode: combine,
         firstDate: date,
         mode: mode,
      });
      const timeNotDefined = combine == '12am' && minutes == '0';
      if (!timeNotDefined) {
         $("#two_16 #" + combine).addClass("timecolor");
         $("#two_16 #" + minutes).addClass("timecolor");
      }
      // console.log('here is the time1: ',combine, minutes);
      // if(combine == '12am' && minutes == '0') {
      //    console.log('here is the time without auto selction: ',combine, minutes);
      // } else {
      //    console.log('here is the time2: ',combine, minutes);
      // $("#two_16 #"+combine).addClass("timecolor");
      // $("#two_16 #"+minutes).addClass("timecolor");
      // }
      if (timestamp2) {
         let secondDateInfo = this.getDateInfo(timestamp2)
         this.setState({
            secondDate: secondDateInfo.dateStr,
            secondHourObj: secondDateInfo.hourObj,
            secondSelectedMinutes: secondDateInfo.minutes,
            secondSelectedHour: secondDateInfo,
            secondMode: secondDateInfo.mode,
            secondSelectedMode: secondDateInfo.combine
         });
         if (secondDateInfo.combine != combine)
            $("#two_16 #" + secondDateInfo.combine).addClass("timecolorsecond");

         if (secondDateInfo.minutes != minutes)
            $("#two_16 #" + secondDateInfo.minutes).addClass("timecolorsecond");
      }
   }

   getDateInfo(timestamp) {
      var hours = moment.unix(timestamp).format("h");
      var mode = moment.unix(timestamp).format("a");
      var combine = hours + mode;
      var minutes = moment.unix(timestamp).format("mm");
      var date = moment.unix(timestamp).format("YYYY-MM-DD");
      var hourObj;
      if (mode == "pm" && hours == 12) { // 12 PM issue
         hourObj = { time: `${hours}${mode}`, value: parseInt(hours) };
      } else if (hours == "12" && minutes == "00" && mode == "am") {
         hours = "";
         minutes = "";
         mode = "";
         combine = "";
         hourObj = {};
      } else {
         hourObj = { time: `${hours}${mode}`, value: mode == "pm" ? parseInt(hours) + 12 : parseInt(hours) };
      }
      var minObj = { minutes: minutes, minValue: parseInt(minutes) };
      var ds = moment(date);
      var e = new Date(ds);
      return { hourObj, date: e, dateStr: date, hours, minutes, mode, combine };
   }

   findHashtags(searchText) {
      var regexp = /\B\#\w+\b/g;
      var result = searchText.match(regexp);
      if (result) {
         const filterTags = (result) => result.filter((v, i) => result.indexOf(v) === i);
         const hashTags = filterTags(result);
         if (hashTags) {
            const tags = hashTags.join().replace(/#/gi, "");
            this.setState({ hashTagstext: tags });
            return tags;
         }
      }
      return null;
   }

   getPostedOnDates() {
      let {
         firstDate,
         hourObj,
         selectedMinutes,
         selectedHour,
         secondDate,
         secondHourObj,
         secondSelectedMinutes,
         secondSelectedHour,
      } = this.state;

      let fromDateObj = {
         dateStr: "",
         selectedHour: "",
         hourObj: {},
         selectedMinutes: "",
      };
      let toDateObj = { ...fromDateObj };

      fromDateObj.dateStr = firstDate || secondDate;
      fromDateObj.hourObj = hourObj;
      fromDateObj.selectedMinutes = selectedMinutes;
      fromDateObj.selectedHour = selectedHour;

      if (secondDate || secondHourObj.value || secondSelectedMinutes) {
         toDateObj.dateStr = secondDate || firstDate;
         toDateObj.selectedHour = secondSelectedHour;

         if (secondHourObj.value) {
            toDateObj.hourObj = secondHourObj;
         } else if (fromDateObj.dateStr == toDateObj.dateStr && secondSelectedMinutes) {
            toDateObj.hourObj = hourObj;
            toDateObj.selectedHour = selectedHour;
         }

         toDateObj.selectedMinutes = secondSelectedMinutes;
      }

      console.log({ fromDateObj, toDateObj });

      var postedOn = 0,
         postedOn2 = 0,
         newDate = "";

      if (fromDateObj.dateStr)
         var { momentString: postedOn, newDate: newDate } =
            this.getMomentAndDate(fromDateObj);

      if (toDateObj.dateStr)
         var { momentString: postedOn2 } = this.getMomentAndDate(toDateObj);

      return { postedOn, postedOn2, newDate };
   }

   getMomentAndDate({ dateStr, selectedHour, hourObj, selectedMinutes }) {

      const hour = Number(hourObj.value) < 10 ? `0${Number(hourObj.value)}` : Number(hourObj.value);
      var min = "00";
      if (selectedMinutes) {
         min = Number(selectedMinutes) < 10 ? `0${Number(selectedMinutes)}` : Number(selectedMinutes);
      }
      let postedOnString;
      if (selectedHour === "" && min === "00") {
         postedOnString = `${dateStr}`;
      } else {
         postedOnString = `${dateStr} ${hour || "00"}:${min}:00`;
      }
      let newDate;
      if (hour === "00" && min === "00") {
         newDate = moment(postedOnString).format(DATE_LATEST_POST);
      } else {
         newDate = moment(postedOnString).format(DATE_TIME_LATEST_POST);
      }
      var momentString = moment(newDate).unix();
      return { newDate, momentString };
   }

   submitPost() {
      if (this.state.posttext == "") {
         this.setState({ textEntryPopup: true });
         // this.setState({ editerror2: 'Please enter text,' + " " + localStorage.getItem('screenname').split(" ")[0] });
         // $(".newscreen2 .errorpop2").toggle();
         return;
      }
      if (this.state.firstDate == "" && this.state.secondDate == "") {
         this.setState({ selectDatePopup: true });
         // this.setState({ editerror2: 'Select the date,' + " " + localStorage.getItem('screenname').split(" ")[0] });
         // $(".newscreen2 .errorpop").toggle();
         return;
      }

      let postedOnDates = this.getPostedOnDates()
      console.log(postedOnDates);
      let { postedOn, postedOn2, newDate } = postedOnDates

      if (postedOn2 && postedOn > postedOn2)
         [postedOn, postedOn2] = [postedOn2, postedOn]

      const created_at = Math.floor(new Date() / 1000);

      var formData = new FormData();
      formData.append("status", this.state.posttext);
      formData.append("include_in_feed", this.state.checked2 ? 0 : 1);
      formData.append("isImportant", this.state.isImportant ? 1 : 0);
      formData.append("postedOn", postedOn);
      formData.append("whocansee", this.state.selectedtype);
      formData.append("hashtags", this.findHashtags(this.state.posttext));
      formData.append("created_at", created_at);
      formData.append("timezone", moment.tz.guess());

      if (postedOn2)
         formData.append("postedOn2", postedOn2);

      if (this.state.withwhodata.length > 0) {
         this.state.withwhodata.map((data, i) => {
            formData.append("withwho[]", data);
         });
      }
      if (this.state.selectedtype == 'allfollowersexcept' || this.state.selectedtype == 'particularfollowers') {
         if (this.state.exceptdata.length > 0) {
            this.state.exceptdata.map((data, i) => {
               formData.append("whocanseemeta[]", data);
            });
         }
         if (this.state.unexceptdata.length > 0) {
            this.state.unexceptdata.map((data, i) => {
               formData.append("whocanseemeta[]", data);
            });
         }
      }
      if (this.state.postType == "image" || this.state.postType == "video") {
         if (this.state.postType === "video") this.setState({ isVideoUploading: true });

         formData.append("eventImage", this.state.filewithpath, this.state.filewithpath.name);
         formData.append("mediaType", this.state.postType);
         formData.append("width", 800);
         formData.append("height", 1000);
      }
     
      for (var [key, value] of formData.entries()) { console.log('11111111111', key, value); }
      var body = formData;
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/add.php', body, {
         headers: {
            Accept: 'multipart/form-data',
            "Content-Type": 'multipart/form-data',
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            this.setState({ isVideoUploading: false });

            if (res.data.message) {
               var e = new Date(newDate);
               this.setMarks(e);
               this.setState({ display: 'selfProfile' });
               const elementToBeActive = document.getElementById('two_2');
               console.log("save elementToBeActive", elementToBeActive);
               if (elementToBeActive) {
                  elementToBeActive.classList.add('active', 'fades');
               }
               // $('#two_2').addClass('active fades');
               this.goProfile();
               $('#two_10').removeClass('active fades');

               if (this.state.timedata2)
                  $("#two_10 #" + this.state.timedata2).removeClass("timecolor")
               if (this.state.secondTimedata2)
                  $("#two_10 #" + this.state.secondTimedata2).removeClass("timecolorsecond")
               if (this.state.selectedMinutes)
                  $("#two_10 #" + this.state.selectedMinutes).removeClass("timecolor")
               if (this.state.secondSelectedMinutes)
                  $("#two_10 #" + this.state.secondSelectedMinutes).removeClass("timecolorsecond")

               this.setState({
                  firstDate: '', secondDate: '', selectedHour: '', timedata2: '', hourObj: {}, selectedMinutes: '', valueCalendar: "",
                  secondSelectedHour: '', secondTimedata2: '', secondHourObj: {}, secondSelectedMinutes: '', posttext: '', imageVideoUrl: ""
               })

               return
            }
         })
         .catch((error) => {
            this.setState({ editerror2: error.response.data.message });
            $(".newscreen2 .errorpop2").toggle();
            return;
         })
   }

   editPost() {
      if (this.state.firstDate == "" && this.state.secondDate == "") {
         this.setState({ editerror2: 'Select the date,' + " " + localStorage.getItem('screenname').split(" ")[0] });
         $(".newscreen2 .errorpop").toggle();
         return;
      }

      let postedOnDates = this.getPostedOnDates()
      console.log(postedOnDates);
      let { postedOn, postedOn2, newDate } = postedOnDates

      if (postedOn2 && postedOn > postedOn2)
         [postedOn, postedOn2] = [postedOn2, postedOn]

      var formData = new FormData();
      formData.append("eventID", this.state.editevent);
      formData.append("include_in_feed", this.state.editinclude_in_feed ? 0 : 1);
      formData.append("isImportant", this.state.editIsImportant ? 1 : 0);
      formData.append("postedOn", postedOn);
      formData.append("timezone", moment.tz.guess());

      if (postedOn2)
         formData.append("postedOn2", postedOn2);

      if (this.state.editwithwho && this.state.editwithwho.length > 0) {
         if (this.state.changewithwho == true) {
            this.state.editwithwho.map((data, i) => {
               formData.append("withwho[]", data);
            });
         }
         else if (this.state.changewithwho == false) {
            this.state.editwithwho.map((datas, i) => {
               formData.append("withwho[]", datas.user_id);
            });
         }
      }
      if (this.state.editwhocansee == 'allfollowersexcept' || this.state.editwhocansee == 'particularfollowers') {
         if (this.state.editwhocanseemeta && this.state.editwhocanseemeta.length > 0) {
            if (this.state.exp == true) {
               this.state.editwhocanseemeta.map((data, i) => {
                  formData.append("whocanseemeta[]", data);
               });
            }
            else if (this.state.exp == false) {
               this.state.editwhocanseemeta.map((datas, i) => {
                  formData.append("whocanseemeta[]", datas.user_id);
               });
            }
            formData.append("whocansee", this.state.editwhocansee);
         }
         else {
            formData.append("whocansee", "everyone");
         }
      }
      else {
         formData.append("whocansee", this.state.editwhocansee);
      }
      if (this.state.postType2 != "") {
         if (this.state.postType2 == "image" || this.state.postType2 == "video") {
            if (this.state.postType2 == "video") this.setState({ isVideoUploading: true });

            formData.append("eventImage", this.state.filewithpath, this.state.filewithpath.name);
            formData.append("mediaType", this.state.postType2);
            formData.append("width", 800);
            formData.append("height", 1000);
         }
         
      }
      formData.append("removeMedia", this.state.editeventImage == "" ? 1 : 0);
      for (var [key, value] of formData.entries()) { console.log('11111111111', key, value); }
      var body = formData;
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/edit.php', body, {
         headers: {
            Accept: 'multipart/form-data',
            "Content-Type": 'multipart/form-data',
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            this.setState({ isVideoUploading: false });
            if (res.data.message) {
               var e = new Date(newDate);
               //   $('.else').removeClass('active fades');
               this.animationscript('#two_2');
               //$('#two_2').addClass('active fades');
               this.onChangedataprofile(e);

               if (this.state.timedata2)
                  $("#two_16 #" + this.state.timedata2).removeClass("timecolor")
               if (this.state.secondTimedata2)
                  $("#two_16 #" + this.state.secondTimedata2).removeClass("timecolorsecond")
               if (this.state.selectedMinutes)
                  $("#two_16 #" + this.state.selectedMinutes).removeClass("timecolor")
               if (this.state.secondSelectedMinutes)
                  $("#two_16 #" + this.state.secondSelectedMinutes).removeClass("timecolorsecond")

               this.setState({
                  firstDate: '', secondDate: '', selectedHour: '', timedata2: '', hourObj: {}, selectedMinutes: '', valueCalendar: "",
                  secondSelectedHour: '', secondTimedata2: '', secondHourObj: {}, secondSelectedMinutes: '',
               })

               return
            }
         })
         .catch((error) => {
            this.setState({ editerror2: error.response.data.message });
            $(".newscreen2 .errorpop2").toggle();
            return;
         })
   }

   async goToPost(e) {
      // $(".else").removeClass("active fades");
      const tabData = { function: "goToPost" }
      const shouldExit = this.addClassActive('third', tabData)

      if (shouldExit) return

      let id = '#twologin';
      const token = localStorage.getItem('token');
      let userval = 'User';
      let displayPref = LOGIN_SIGNUP
      if (token) {
         userval = localStorage.getItem('screenname').split(" ")[0];
         id = '#two_10';
         displayPref = ADD_POST
      }
      await this.displayElementInDOM(displayPref)

      $("#two_10 .timecolor").removeClass("timecolor")
      $("#two_16 .timecolorsecond").removeClass("timecolorsecond")

      if (e && e.back == "back") {
         this.animationlinkback(id);
         //   $("#two_10").addClass("active fadesback");
         this.context.setActiveTab(e?.tab);
         this.setState({ activeTab: e?.tab })
         this.state.datasetup.pop();
         this.setState({ datasetup: this.state.datasetup });
      }
      else {
         //   $("#two_10").addClass("active fades");
         const { appContext } = this.props;
         const { state, actions } = appContext;

         this.animationscript(id);
         setTimeout(() => {
            if (this.state.activeTab !== 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 2) {
               actions.setHomePageActive({
                  status: false,
                  activeHomePageSubspace: null,
               })
            }
            var dataopen = { function: "goToPost", data: { back: "back", tab: this.state.activeTab } };
            this.state.datasetup.push(dataopen);
            this.setState({ datasetup: this.state.datasetup });
         }, 1000);
      }
      this.setState({ backid: id, placetext: "Upload temporal information," + " " + userval + "!" })
   }

   removeSearch() {
      // document.getElementById("lemoncals").value = "";
      this.setState({ inputValue: '' })
      this.setState({ searchinguser: [], searchPerameter: "" });
      //this.mainProfileColor();

   }

   getTime(event) {
      var item = event.target.id;
      var items = event.target.className;
      if (this.state.timedata2 === item) {
         this.setState({
            selectedHour: "",
            timedata2: "",
            hourObj: {},
         });
         $("#two_10 #" + item).removeClass("timecolor");
         return;
      }

      if (this.state.secondTimedata2 === item) {
         this.setState({
            secondSelectedHour: "",
            secondTimedata2: "",
            secondHourObj: {},
         });
         $("#two_10 #" + item).removeClass("timecolorsecond");
         return;
      }

      if (this.state.timedata2 && this.state.secondTimedata2)
         $("#two_10 #" + this.state.secondTimedata2).removeClass("timecolorsecond");


      if (item.slice(-2) == "pm" && items == 12) {
         // 12 PM issue
         var hourObj = {
            time: `${items}${item.slice(-2)}`,
            value: parseInt(items),
         };
      } else {
         var hourObj = {
            time: `${items}${item.slice(-2)}`,
            value: item.slice(-2) == "pm" ? parseInt(items) + 12 : parseInt(items),
         };
      }

      if (!this.state.selectedHour) {
         this.setState({ selectedHour: items, timedata2: item, hourObj: hourObj });
         $("#two_10 #" + item).addClass("timecolor");
      } else {
         this.setState({
            secondSelectedHour: items,
            secondTimedata2: item,
            secondHourObj: hourObj,
         });
         $("#two_10 #" + item).addClass("timecolorsecond");
      }
   }

   getTimeEdit(event) {

      var item = event.target.id;
      var items = event.target.className;
      if (this.state.selectedMode === item) {
         this.setState({
            selectedHour: "",
            selectedMode: "",
            mode: "",
            hourObj: {},
         });
         $('#two_16 #' + item).removeClass('timecolor');
         return
      }
      if (this.state.selectedMode && this.state.secondSelectedMode)
         $('#two_16 #' + this.state.secondSelectedMode).removeClass('timecolorsecond');

      if (this.state.secondSelectedMode === item) {
         this.setState({
            secondSelectedHour: '',
            secondSelectedMode: '',
            secondMode: '',
            secondHourObj: {},
         });
         $('#two_16 #' + item).removeClass('timecolorsecond');
         return
      }

      if (item.slice(-2) == "pm" && items == 12) { // 12 PM issue
         var hourObj = { time: `${items}${item.slice(-2)}`, value: parseInt(items) };
      } else {
         var hourObj = { time: `${items}${item.slice(-2)}`, value: item.slice(-2) == "pm" ? parseInt(items) + 12 : parseInt(items) };
      }

      if (!this.state.selectedHour) {
         // this.setState({selectedHour:items,timedata2:item,hourObj:hourObj});
         this.setState({ selectedHour: items, selectedMode: item, mode: item.slice(-2), hourObj: hourObj });
         $('#two_16 #' + item).addClass('timecolor');
      }
      else {
         this.setState({ secondSelectedHour: items, secondSelectedMode: item, secondMode: item.slice(-2), secondHourObj: hourObj });
         $('#two_16 #' + item).addClass('timecolorsecond');
      }
   }
   getminEdit(event) {
      var item = event.target.id;
      var items = event.target.className;
      if (this.state.selectedMinutes === item) {
         $('#two_16 #' + item).removeClass("timecolor");
         this.setState({ selectedMinutes: "" });
         return;
      }
      if (this.state.secondSelectedMinutes === item) {
         $('#two_16 #' + item).removeClass("timecolorsecond");
         this.setState({ secondSelectedMinutes: "" });
         return;
      }
      if (this.state.selectedMinutes && this.state.secondSelectedMinutes)
         $('#two_16 #' + this.state.secondSelectedMinutes).removeClass("timecolorsecond");
      if (!this.state.selectedMinutes) {
         $('#two_16 #' + item).addClass("timecolor");
         this.setState({ selectedMinutes: items });
      } else {
         $('#two_16 #' + item).addClass("timecolorsecond");
         this.setState({ secondSelectedMinutes: items });
      }
   }

   getmin(event) {
      const item = event.target.id;
      if (this.state.selectedMinutes === item) {
         $("#two_10 #" + item).removeClass("timecolor");
         this.setState({ selectedMinutes: "" });
         return;
      }
      if (this.state.secondSelectedMinutes === item) {
         $("#two_10 #" + item).removeClass("timecolorsecond");
         this.setState({ secondSelectedMinutes: "" });
         return;
      }
      if (this.state.selectedMinutes && this.state.secondSelectedMinutes)
         $("#two_10 #" + this.state.secondSelectedMinutes).removeClass("timecolorsecond");

      if (!this.state.selectedMinutes) {
         $("#two_10 #" + item).addClass("timecolor");
         this.setState({ selectedMinutes: item });
      } else {
         $("#two_10 #" + item).addClass("timecolorsecond");
         this.setState({ secondSelectedMinutes: item });
      }
      //   $('#two_10 .timecolor').removeClass('timecolor');
      //   $('#'+item).addClass('timecolor');
      //   this.setState({selectedMinutes: event.currentTarget.dataset.div_id});
   }

   onEveryoneEdit() {
      this.setState({ editwhocansee: 'everyone' });
      $("#two_10 .mores3, #two_16 .mores3").removeClass("protect");
      this.cancelwhopop();
   }
   onOnlymeEdit() {
      this.setState({ editwhocansee: 'onlyme', selectedtypefinal: 'only me' });
      $("#two_10 .mores3, #two_16 .mores3").removeClass("protect");
      this.cancelwhopop();
   }

   onEveryone() {
      this.setState({ selectedtype: 'everyone', selectedtypefinal: 'everyone' });
      $("#two_10 .mores3, #two_16 .mores3").removeClass("protect");
      this.cancelwhopop();
   }
   onOnlyme() {
      this.setState({ selectedtype: 'onlyme', selectedtypefinal: 'only me' });
      $("#two_10 .mores3, #two_16 .mores3").removeClass("protect");
      this.cancelwhopop();
   }

   onchangelable() {
      if (this.state.profilecheck) {
         $('.newscreen2 #two_2 .react-calendar__navigation__prev-button').unbind().click(function () {
            var mydate = this.state.Previousd;
            var min = moment(mydate, 'DD/MM/YYYY');
            var minie = new Date(min);
            this.showMonthYear(minie)
            this.setState({ valuedataprofile: minie }, () => {
               this.getNextPrevious(minie);
            });
         }.bind(this));
         $('.newscreen2 #two_2 .react-calendar__navigation__next-button').unbind().click(function () {
            var mydatet = this.state.Nextd;
            var minx = moment(mydatet, 'DD/MM/YYYY');
            var minief = new Date(minx);
            this.showMonthYear(minief)
            this.setState({ valuedataprofile: minief }, () => {
               this.getNextPrevious(minief);
            });
         }.bind(this));
      }
   }

   submitdelete() {
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/delete.php?eventID=' + this.state.eventid, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.success == true) {
               const {date, calendar} = this.state.deletePost;
               const availableDates = this.state.timetableData[calendar]?.availableEventsOnDates || [];
               
               if (availableDates?.length) {
                  const filteredEvents = availableDates.filter(event => event.eventOn !== moment(date).format('DD-MM-YYYY'));
                  const newDate = filteredEvents.length > 0 ? filteredEvents[0]?.eventOn : null;
                  const updatedActiveDate = moment(newDate, "DD-MM-YYYY").toDate();
   
                  this.updateTimetableData({
                     activeDate: updatedActiveDate,
                     availableEventsOnDates: filteredEvents
                  }, calendar);
                  this.setState({ deletePost: {date: undefined, calendar: ""} })
               }

               this.myprofile();
               if (this.state.profiledata.length > 1) {
                  var e = this.state.valuedataprofile;
                  this.onChangedataprofile(e)
               }
               if (this.state.profiledata.length == 1) {
                  this.goProfile();
               }
            }
         })
   }
   weekDays = (dayNumber) => {
      if (dayNumber == 0) {
         return "Sunday";
      }
      if (dayNumber == 6) {
         return "Saturday";
      }
      if (dayNumber == 5) {
         return "Friday";
      }
      if (dayNumber == 4) {
         return "Thursday";
      }
      if (dayNumber == 3) {
         return "Wednesday";
      }
      if (dayNumber == 2) {
         return "Tuesday";
      }
      if (dayNumber == 1) {
         return "Monday";
      }
   }

   editdeletepost(e, post, calendar) {
      const [date, abrivation] = formatUnixTimestamp(post?.postDate, post?.timezone);
      const activeDate = moment(date).toDate();

      this.setState({ eventid: "", editDeletePopup: true });
      this.setState({ eventid: e.currentTarget.id, deletePost: {date: activeDate, calendar: calendar} })
   }
   gotobackprofile() {
      // console.log('onepad');
      // $(".else").removeClass("active fades");
      this.animationlinkback('#two_2');
      // $("#two_2").addClass("active fades");
   }

   editPostImage = e => {
      let file = e.target.files[0];
      this.setState({ filewithpath: file });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
         var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
         if (streetaddress == "data:image") {
            this.setState({ postType2: "image" });
         }
         if (streetaddress == "data:video") {
            this.setState({ postType2: "video" });
         }
         if (this.state.postTypt2 != "") {
            this.setState({
               imageVideoUrl2: reader.result
            });
         }
      };
   }
   onImageVideoChange = e => {
      let file = e.target.files[0];
      this.setState({ filewithpath: file });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
         var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
         if (streetaddress == "data:image") {
            this.setState({ postType: "image" });
         }
         if (streetaddress == "data:video") {
            this.setState({ postType: "video" });
         }
         if (this.state.postType != "") {
            this.setState({
               imageVideoUrl: reader.result
            });
            $('.imagesscrol').addClass('srl');
            $("#two_10 .tab-content").animate({ scrollTop: $(document).height() }, 2000);
         }
      };
   }
   closeImage() {
      if (this.state.backid == "#two_16") {

         this.setState({ postType2: "", imageVideoUrl2: "", editeventImage: "", editmediaType: "" });
      }
      else {

         this.setState({ postType: "", imageVideoUrl: "" });
      }

   }


   setPostText(evt) {
      const caption = String(evt.target.value);

      this.setState({ posttext: caption });
      if (caption.length == 0) {
         $('#two_10 #labelplaceholder').removeClass('newlabel');
         $('#two_10 #labelholder').removeClass('newlabel');
         $('#two_10 #labelholderto').removeClass('newlabel');
         this.setState({ colorIcon: "blur" });
      }
      else {
         $('#two_10 #labelplaceholder').addClass('newlabel');
         $('#two_10 #labelholder').addClass('newlabel');
         $('#two_10 #labelholderto').addClass('newlabel');
         this.setState({ colorIcon: "" });
      }

   }


   userSearch(event) {
      this.setState({ searchPerameter: event.target.value, searchloader: true }, () => {
         this.timeout = setTimeout(async function () {
            // this.searchUser();
            let profiles = await searchProfile(this.state.searchPerameter, this.state.recomended)
            this.setState({ searchinguser: profiles, searchloader: false })
         }.bind(this), 300);
      });
      if (this.timeout) {
         clearTimeout(this.timeout)
         this.timeout = null
      }
   }

   gettag(e) {
      var value = e.target.id;
      if (this.state.tagtype == "hash") {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "#" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#input');
         setTimeout(function () {
            document.getElementById("input").focus();
         }, 1000);
         $('#two_10 .sugetionList').addClass('listb');
      }
      else {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "@" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#input');
         setTimeout(function () {
            document.getElementById("input").focus();
         }, 1000);
         $('#two_10 .sugetionList').addClass('listb');
      }
   }


   setIsImportant() {
      this.setState({
         isImportant: $(".newscreen2 #important").is(":checked"),
      });
      this.updateCustomCheckbox("important")
   }
   setEditIsImportant() {
      this.setState({
         editIsImportant: $(".newscreen2 #editImportant").is(":checked"),
      });
      this.updateCustomCheckbox("editImportant")
   }
   updateCustomCheckbox(selector) {
      if ($(`.newscreen2 #${selector}`).is(":checked"))
         $(`.newscreen2 #${selector} ~ .radiobtn`).html(`<img src='/images/Checkmark.png' alt='active-img' />`);
      else
         $(`.newscreen2 #${selector} ~ .radiobtn`).html(``);
   }
   onClickedit() {
      // $(".else").removeClass("active fades");
      // $("#two_17").addClass("active fades");
      this.animationscript('#two_17');
      $('.iconfirst').addClass("colorclass");
      $("#second").addClass("displaynone");

      if (localStorage.getItem('make_public') == 1) {
         this.setState({ checked: false });

      }
      else {
         this.setState({ checked: true });
      }
      this.setState({
         eusername: localStorage.getItem("username"),
         escreen: localStorage.getItem("screenname"),
         ebio: localStorage.getItem("bio")
         , eemail: localStorage.getItem("email")
         , eweb: localStorage.getItem("website")
      });
   }


   updateUserProfileComponent(callback) {
      let isForgroundNotSet = true
      this.setState({
         userProfileComponents: this.state.userProfileComponents.map((comp) => {
            if (isForgroundNotSet && comp.state !== pageState.foreground) {
               isForgroundNotSet = false
               return { ...comp, state: pageState.foreground };
            }

            if (comp.state === pageState.foreground)
               return { ...comp, state: pageState.background };

            return comp
         })
      }, () => callback())
   }

   removeUserProfileComponentFromBackground() {
      setTimeout(() =>
         this.setState({
            userProfileComponents: this.state.userProfileComponents.map((comp) => {
               if (comp.state === pageState.background)
                  return { ...comp, state: pageState.terminate };

               return comp;
            })
         }), 400);
   }

   removeUserProfileComponent() {
      setTimeout(() =>
         this.setState({
            userProfileComponents: this.state.userProfileComponents.map((comp) => {
               if (comp.state === pageState.foreground)
                  return { ...comp, state: pageState.terminate };

               return comp;
            })
         }), 400);
   }

   gouserprofile = (e) => {
      // $(".else").removeClass("active fades");

      const { appContext } = this.props;
      const { state, actions } = appContext;

      if (this.state.activeTab === 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 2) {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         });
      }

      this.displayElementInDOM(USER_PROFILE)

      this.setState({ seeMorePage: 1 })
      if (e.currentTarget && e.currentTarget !== null && e.currentTarget !== undefined) {
         var id = e.currentTarget.id;
         var gole = e.currentTarget.dataset.div_name;
      }
      else {
         var id = e.ids;
         var gole = "";
      }

      const dataSetup = this.state.datasetup

      this.updateUserProfileComponent(() => {
         if (e.back && e.back !== undefined && e.back == "back") {
            this.context.setActiveTab(e?.tab);
            this.setState({ activeTab: e?.tab })
            // if (datasetup.length > 0 && datasetup[datasetup.length - 1].function == "gouserprofile") {
            //    setTimeout(() => {
            //       this.animationlinkback('#two_4.front');
            //    }, 300);
            // }
            // else {
            this.animationlinkback('#two_4.front');
            // }
            dataSetup.pop();
            this.setState({ datasetup: dataSetup });

         }
         else {
            // if (datasetup.length > 0 && datasetup[datasetup.length - 1].function == "gouserprofile") {
            //    setTimeout(() => {
            //       this.animationscript('#two_4.front');
            //    }, 300);
            // }
            // else {
            if (e.redirect) {
               dataSetup.pop();
            }
            this.animationscript('#two_4.front');
            // }
            setTimeout(() => {
               var datasetups = { function: "gouserprofile", data: { ids: id, back: "back", tab: this.state.activeTab } };
               dataSetup.push(datasetups);
               this.setState({ datasetup: dataSetup });
            }, 1000);
         }
      })

      this.setState({ displayUserId: id })
      this.removeUserProfileComponentFromBackground()
      return
   }
   myprofile() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/user/getUserDetails.php?user_id=' + variable, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ userDetails: responseJson.response });
            localStorage.setItem("followers", responseJson.response.followers);
            localStorage.setItem("following", responseJson.response.following);
            localStorage.setItem("totalpost", responseJson.response.totalPost);
         });
   }

   setadd() {
      $('#two_10 .pupup6').toggle();
      $('#two_10 .pupup6').addClass('zIndex');
      this.setState({ backid: "#two_10" });
   }

   checkcondition(data) {
      if (data == "everyone") {
         $('#two_10 .mores3, #two_16 .mores3').removeClass('protect');
         return "everyone";
      }
      else if (data == "followers") {
         $('#two_10 .mores3, #two_16 .mores3').removeClass('protect');
         return "followers";
      }
      else if (data == "following") {
         $('#two_10 .mores3, #two_16 .mores3').addClass('protect');
         return "users I'm following";
      }
      else if (data == "onlyme") {
         $('#two_10 .mores3, #two_16 .mores3').removeClass('protect');
         return "only me";
      }
      else if (data == "particularfollowers") {
         $('#two_10 .mores3, #two_16 .mores3').addClass('protect');
         return 'only these followers...';
      }
      else if (data == "allfollowersexcept") {
         $('#two_10 .mores3, #two_16 .mores3').addClass('protect');
         return 'all followers except...';
      }
   }
   setMarks(e) {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      this.setState({ marks: [] }, () => {
         fetch(this.state.baseUrl + '/api/event/profileEventDates.php?profileId=' + variable, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "TimeZone": moment.tz.guess(),
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               this.setState({ marks: responseJson.response.sort((a, b) => (moment(a.eventOn, 'DDMMYYYY') > moment(b.eventOn, 'DDMMYYYY')) ? 1 : -1).reverse() }, () => {
                  var newMinimum = this.state.marks[this.state.marks.length - 1].eventOn;
                  var newMaximum = this.state.marks[0].eventOn;
                  var min = moment(newMinimum, 'DD/MM/YYYY');
                  var minie = new Date(min);
                  var maxo = moment(newMaximum, 'DD/MM/YYYY');
                  var me = new Date(maxo);
                  this.setState({ minimum: minie, maximum: me }, () => {
                     var array = [];
                     for (let index = 0; index < this.state.marks.length; index++) {
                        var dateads = moment(this.state.marks[index].eventOn, 'DD/MM/YYYY');
                        var elx = new Date(dateads);
                        var finaldate = moment(elx).format('MMYYYY');
                        array.push(finaldate);
                     }
                     this.setState({ monthyear: Array.from(new Set(array.reverse())), core: true }, () => {
                        this.setState({ monthyear: this.state.monthyear.reverse() }, () => {
                           var newdate = e
                           var fdate = moment(newdate).format('MMYYYY');
                           var index_val = this.state.monthyear.indexOf(fdate);
                           if (index_val == 0) {
                              var previous = this.state.monthyear[index_val + 1];
                              this.setState({ Nextd: "", Previousd: this.checkdate(previous) });
                           }
                           else {
                              var previous = this.state.monthyear[index_val + 1];
                              var next = this.state.monthyear[index_val - 1];
                              this.setState({ Nextd: this.checkdate(next), Previousd: this.checkdate(previous) });
                           }
                        })
                        this.onChangedataprofile(e);
                     });
                  });
               });
            });
      })
   }

   animationscript(id) {
      $('.newscreen2 .screen1').removeClass('fadesback');
      // $('.newscreen01 .screen1').removeClass('abc');
      let backElement = $('.newscreen2 .screen1.active')

      if (!backElement.length)
         backElement = $('.newscreen2 .screen1.back')

      backElement.addClass('screen1 aactive');

      const frontElement = $(id).addClass('active fades abc');
      this.checkAndRemoveUserElementFromBackground(frontElement, backElement)
      setTimeout(function () {
         backElement.removeClass('active aactive fades abc');
         $('.newscreen2 .screen1').removeClass('abc front');
         $(id).addClass("active fades");
      }, 400);
   }

   animationlinkback(id) {
      $('.newscreen2 .screen1').removeClass('fades');
      let backElement = $('.newscreen2 .screen1.active')

      if (!backElement.length)
         backElement = $('.newscreen2 .screen1.back')

      backElement.addClass('screen1 aactive');

      const frontElement = $(id).addClass('active fadesback nabc');
      this.checkAndRemoveUserElementFromBackground(frontElement, backElement)
      setTimeout(function () {
         backElement.removeClass('active aactive nabc fadesback');
         $('.newscreen2 .screen1').removeClass('nabc front');
         $(id).addClass("active fadesback");
      }, 500);
   }

   checkAndRemoveUserElementFromBackground(frontElement, backElement) {
      if (!frontElement.is(".front") && backElement.attr("id") === "two_4")
         this.removeUserProfileComponent()
   }

   async goProfile(e) {
      const tabData = { function: "goProfile" }
      const shouldExit = this.addClassActive('first', tabData)

      if (shouldExit) return

      await this.displayElementInDOM(SELF_PROFILE)

      //  $('.else').removeClass('active fades');
      if (e && e.back == "back") {
         //  $("#two_2").addClass("active fadesback");
         this.animationlinkback('#two_2')
         this.context.setActiveTab(e?.tab);
         this.setState({ activeTab: e?.tab })
         this.state.datasetup.pop();
         this.setState({ datasetup: this.state.datasetup });
      }
      else {
         const { appContext } = this.props;
         const { state, actions } = appContext;

         if (this.state.activeTab !== 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 2) {
            actions.setHomePageActive({
               status: false,
               activeHomePageSubspace: null,
            })
         }

         this.animationscript('#two_2');
         //  $("#two_2").addClass("active fades");
         setTimeout(() => {
            var dataopen = { function: "goProfile", data: { back: "back", tab: this.state.activeTab } };
            this.state.datasetup.push(dataopen);
            this.setState({ datasetup: this.state.datasetup });
         }, 1000);
      }

      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');

      const post = this.state.savePost;
      const userData = post.find((data) => data.user == variable);

      let displayedLastActiveDate = false

      if (userData) {
         const outputFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';

         const convertedDate = moment.tz(userData.date, 'DD-MM-YYYY', moment.tz.guess()).format(outputFormat);
         this.setState({
            profiledata: userData.eventDetail, valuedataprofile: new Date(convertedDate), profilecheck: userData.date,
            marks: userData.allEvents, maximum: userData.maxDate, minimum: userData.minDate, Previousd: userData.previousMonthyear, Nextd: userData.nextMonthYear,
            monthyear: userData.otherMonthYear
         });
         displayedLastActiveDate = true
      }

      if (this.state.e == "") {
         fetch(this.state.baseUrl + '/api/event/profileEventDates.php?profileId=' + variable, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "TimeZone": moment.tz.guess(),
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length > 0) {
                  this.setState({ marks: responseJson.response.sort((a, b) => (moment(a.eventOn, 'DDMMYYYY') > moment(b.eventOn, 'DDMMYYYY')) ? 1 : -1).reverse() }, () => {
                     var newMinimum = this.state.marks[this.state.marks.length - 1].eventOn;
                     var newMaximum = this.state.marks[0].eventOn;
                     this.mapMonthYearToDate().then(() => this.updateMinMaxDateOfTimetables());
                     var min = moment(newMinimum, 'DD/MM/YYYY');
                     var minie = new Date(min);
                     var maxo = moment(newMaximum, 'DD/MM/YYYY');
                     var me = new Date(maxo);
                     this.setState({ minimum: minie, maximum: me }, () => {
                        var array = [];
                        for (let index = 0; index < this.state.marks.length; index++) {
                           var dateads = moment(this.state.marks[index].eventOn, 'DD/MM/YYYY');
                           var elx = new Date(dateads);
                           var finaldate = moment(elx).format('MMYYYY');
                           array.push(finaldate);
                        }
                        this.setState({ monthyear: Array.from(new Set(array.reverse())), core: true }, () => {
                           this.setState({ monthyear: this.state.monthyear.reverse() }, () => {

                              if (displayedLastActiveDate && this.state.profiledata.length !== 1) return

                              var newdate = new Date();
                              var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));
                              if (idxw && idxw != -1) {
                                 var fdate = moment(newdate).format('MMYYYY');
                              }
                              else {
                                 var checker = this.getlatest(responseJson.response, newdate);
                                 if (checker == undefined) {
                                    var metacroxs = this.getless(responseJson.response, newdate)
                                 }
                                 else {
                                    var metacroxs = checker;
                                 }
                                 var minyy = moment(metacroxs, 'DD/MM/YYYY');
                                 var minieyy = new Date(minyy);
                                 var fdate = moment(minieyy).format('MMYYYY');
                              }
                              var index_val = this.state.monthyear.indexOf(fdate);
                              if (index_val == 0) {
                                 var previous = this.state.monthyear[index_val + 1];
                                 this.setState({ Nextd: "", Previousd: this.checkdate(previous) });
                              }
                              else {
                                 var previous = this.state.monthyear[index_val + 1];
                                 var next = this.state.monthyear[index_val - 1];
                                 this.setState({ Nextd: this.checkdate(next), Previousd: this.checkdate(previous) });
                              }
                           })
                        });
                     });
                  });

                  if (displayedLastActiveDate && this.state.profiledata.length !== 1) return

                  var newdate = new Date();
                  var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));
                  let fetchedDate
                  if (idxw && idxw != -1) {
                     var datescolor = this.state.marks[idxw].eventOn;
                     this.setState({ valuedataprofile: newdate, profilecheck: datescolor });
                     fetchedDate = newdate
                  }
                  else {
                     var checker = this.getlatest(responseJson.response, newdate);
                     if (checker == undefined) {
                        var metacroxs = this.getless(responseJson.response, newdate)
                     }
                     else {
                        var metacroxs = checker;
                     }
                     var datescolor = metacroxs;
                     var min0 = moment(metacroxs, 'DD/MM/YYYY');
                     var miniex = new Date(min0);
                     this.setState({ profilecheck: datescolor, valuedataprofile: miniex });
                     fetchedDate = miniex
                  }
                  this.showMonthYear(fetchedDate)
                  fetch(this.state.baseUrl + '/api/event/profileEventDateWise.php?profileId=' + variable + '&date=' + datescolor, {
                     method: "GET",
                     headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "TimeZone": moment.tz.guess(),
                        "Authorization": "Bearer " + token,
                     },
                  })
                     .then((response) => response.json())
                     .then((responseJson) => {
                        this.setState({ profiledata: [] });

                        this.updateSavedPostForLoggedInUser(variable, datescolor, responseJson);

                        this.setState({ profiledata: responseJson.response });

                        this.updateTemporalInformationStyling(); // update styling of temporal information block
                     })
                     .catch((error) => {
                        this.setState({ profiledata: [] });
                     })
               }
               else {
                  var olddate = new Date();
                  this.setState({ minimum: olddate, maximum: olddate, valuedataprofile: "" });
               }
            })
            .catch((error) => {
            })
         this.myprofile();
      }
      else {
         var map = this.state.marks;
         var datescolor = moment(this.state.e, 'DD/MM/YYYY').format('DD-MM-YYYY');
         this.setState({ valuedataprofile: this.state.e });
         fetch(this.state.baseUrl + '/api/event/profileEventDateWise.php?profileId=' + variable + '&date=' + datescolor, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "TimeZone": moment.tz.guess(),
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {

               this.setState({ profiledata: [] });

               this.updateSavedPostForLoggedInUser(variable, datescolor, responseJson);

               this.setState({ profiledata: responseJson.response });
            })
            .catch((error) => {
               console.log("goProfile22", this.state.profiledata);
            })
      }
   }

   updateMinMaxDateOfTimetables() {
      const otherMonthYear = this.state.monthyear;
      const monthYearDateMap = this.state.monthYearDateMap;
      this.setState(prevState => ({
         timetableData: {
            threeMonthsEarlier: {
               ...prevState.timetableData.threeMonthsEarlier,
               minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]].minDate,
               maxDate: monthYearDateMap[otherMonthYear[3]].maxDate,
            },
            twoMonthsEarlier: {
               ...prevState.timetableData.twoMonthsEarlier,
               minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]].minDate,
               maxDate: monthYearDateMap[otherMonthYear[2]].maxDate,
            },
            previous: {
               ...prevState.timetableData.previous,
               minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]].minDate,
               maxDate: monthYearDateMap[otherMonthYear[1]].maxDate,
            },
            current: {
               ...prevState.timetableData.current,
               minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]].minDate,
               maxDate: monthYearDateMap[otherMonthYear[0]].maxDate,
            },
            next: {
               ...prevState.timetableData.next,
               minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 2]].minDate,
               maxDate: monthYearDateMap[otherMonthYear[0]].maxDate,
            },
            twoMonthsAhead: {
               ...prevState.timetableData.twoMonthsAhead,
               minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 3]].minDate,
               maxDate: monthYearDateMap[otherMonthYear[0]].maxDate,
            },
            threeMonthsAhead: {
               ...prevState.timetableData.threeMonthsAhead,
               minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 4]].minDate,
               maxDate: monthYearDateMap[otherMonthYear[0]].maxDate,
            }
         }
      }));
   }

   updateSavedPostForLoggedInUser(variable, activeDate, responseJson) {
      const calendarActiveDateInfo = {
         user: variable,
         allEvents: this.state.marks,
         minDate: this.state.minimum,
         maxDate: this.state.maximum,
         previousMonthyear: this.state.Previousd,
         nextMonthYear: this.state.Nextd,
         otherMonthYear: this.state.monthyear
      };

      this.addPostsOfUserToSavePost(activeDate, calendarActiveDateInfo, responseJson);
   }

   showMonthYear(showDate, shouldNotUpdateActiveDates) {
      const monthsShort = {
         '01': "Jan",
         '02': 'Feb',
         '03': 'Mar',
         '04': 'Apr',
         '05': 'May',
         '06': 'Jun',
         '07': 'Jul',
         '08': 'Aug',
         '09': 'Sep',
         '10': 'Oct',
         '11': 'Nov',
         '12': 'Dec'
      };
      this.setState({ currentMonthYear: showDate }, () => {
         const dateActive = this.state.currentMonthYear
         const dateIndex = moment(dateActive).format("MMYYYY")
         const sortedArray = this.getSurroundingMonths(dateIndex);
         const result = sortedArray.map(({ month, year, originalIndex }, index) => {
            return {
               date: `${month < 10 ? '0' : ''}${month}${year}`,
               originalIndex,
               display: index < 5
            }
         })
            .sort((a, b) => a.originalIndex - b.originalIndex);
         const activeIndex = result.indexOf(dateIndex);
         const mappedArray = result.map(dateObj => {
            let { date, display } = dateObj
            const month = date.substr(0, 2);
            const year = date.substr(4, 2);
            const monthName = monthsShort[month];
            const currentDate = new Date();
            const currentYear = moment(currentDate).format('YYYY');
            // const MY = `${monthName}${date.substr(2, 4) !== currentYear ? ", '" + year : ''}`
            const MY = `${monthName}`
            return { short: MY, date: this.state.monthYearDateMap[date].maxDate, isActiveMonth: date === dateIndex, display }
         });

         if (shouldNotUpdateActiveDates)
            return

         const { threeMonthsEarlier, twoMonthsEarlier, prev, next, twoMonthsAhead, threeMonthsAhead } = this.getActiveDatesOfNextPrevMonths(showDate, mappedArray);
         
         this.setState(prevState => {
            const getAvailableEvents = (date) => getActiveDatesOfTheMonth(date, this.state.marks);

            const threeMonthsEarlierEvents = getAvailableEvents(threeMonthsEarlier);
            const twoMonthsEarlierEvents = getAvailableEvents(twoMonthsEarlier);
            const prevEvents = getAvailableEvents(prev);
            const currentEvents = getAvailableEvents(showDate);
            const nextEvents = getAvailableEvents(next);
            const twoMonthsAheadEvents = getAvailableEvents(twoMonthsAhead);
            const threeMonthsAheadEvents = getAvailableEvents(threeMonthsAhead);

            return {
               leftSideArray: mappedArray.filter(map => map.display),
               timetableData: {
                  threeMonthsEarlier: {
                     ...prevState.timetableData.threeMonthsEarlier,
                     activeDate: determineDateToBeSelectForMonths(threeMonthsEarlier, threeMonthsEarlierEvents),
                     availableEventsOnDates: threeMonthsEarlierEvents,
                  },
                  twoMonthsEarlier: {
                     ...prevState.timetableData.twoMonthsEarlier,
                     activeDate: determineDateToBeSelectForMonths(twoMonthsEarlier, twoMonthsEarlierEvents),
                     availableEventsOnDates: twoMonthsEarlierEvents,
                  },
                  previous: {
                     ...prevState.timetableData.previous,
                     activeDate: determineDateToBeSelectForMonths(prev, prevEvents),
                     availableEventsOnDates: prevEvents,
                  },
                  current: {
                     ...prevState.timetableData.current,
                     activeDate: determineDateToBeSelectForMonths(showDate, currentEvents),
                     availableEventsOnDates: currentEvents,
                  },
                  next: {
                     ...prevState.timetableData.next,
                     activeDate: determineDateToBeSelectForMonths(next, nextEvents),
                     availableEventsOnDates: nextEvents,
                  },
                  twoMonthsAhead: {
                     ...prevState.timetableData.twoMonthsAhead,
                     activeDate: determineDateToBeSelectForMonths(twoMonthsAhead, twoMonthsAheadEvents),
                     availableEventsOnDates: twoMonthsAheadEvents,
                  },
                  threeMonthsAhead: {
                     ...prevState.timetableData.threeMonthsAhead,
                     activeDate: determineDateToBeSelectForMonths(threeMonthsAhead, threeMonthsAheadEvents),
                     availableEventsOnDates: threeMonthsAheadEvents,
                  }
               }
            }
         })
      });
   }
   getClosestValues = (activeIndex, array) => {
      const parseMonthYear = (value) => {
         const year = parseInt(value.slice(2, 6), 10);
         const month = parseInt(value.slice(0, 2), 10);
         return { year, month };
      };
      const activeDate = parseMonthYear(activeIndex);
      const parsedArray = array.map((value, index) => ({ ...parseMonthYear(value), originalIndex: index }));
      const sortedArray = parsedArray.sort((a, b) => {
         const diffA = Math.abs((a.year - activeDate.year) * 12 + (a.month - activeDate.month));
         const diffB = Math.abs((b.year - activeDate.year) * 12 + (b.month - activeDate.month));
         return diffA - diffB;
      });
      return sortedArray;
   };
   getSurroundingMonths = (activeMonth) => {
      let months = [...this.state.monthyear];
      months.reverse();
      const activeIndex = months[months.indexOf(activeMonth)];
      const surroundingMonths = this.getClosestValues(activeIndex, months);
      return surroundingMonths;
   }
   mapMonthYearToDate = async () => {
      let lastMY = ""
      let lastMYDate = ""
      let monthYearDateMap = {}
      this.state.marks.forEach((dateObj) => {
         var min = moment(dateObj.eventOn, "DD/MM/YYYY");
         var minie = new Date(min);
         const currentMY = moment(minie).format("MMYYYY")
         if (currentMY !== lastMY) {
            monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
            monthYearDateMap[currentMY] = { ...monthYearDateMap[lastMY], maxDate: minie }
         }
         lastMY = currentMY
         lastMYDate = minie
      })
      monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
      this.setState({ monthYearDateMap: { ...monthYearDateMap } })
   }
   getMonthYear(maxDate) {
      var mydate = maxDate;
      var min = moment(mydate, "DD/MM/YYYY");
      // console.log(min, 'url getMonthYear min');
      var minie = new Date(min);
      this.showMonthYear(minie)
      this.setState({ valuedataprofile: minie }, () => {
         this.getNextPrevious(minie);
      });
   }
   getDateFullName(date) {
      if (date) {
         const month = moment(date).format('MMMM');
         const year = moment(date).format('YYYY');
         return month + ' ' + year;
      }
   }
   getless(delta, newdate) {
      for (let index = 0; index < delta.length; index++) {
         var dateadscc = "";
         var elxc = "";
         dateadscc = moment(delta[index].eventOn, 'DD/MM/YYYY');
         elxc = new Date(dateadscc);
         if (moment(elxc).format("YYYYMMDD") < moment(newdate).format("YYYYMMDD")) {
            return delta[index].eventOn;
         }
      }
   }

   getlatest(delta, newdate) {
      for (var index = delta.length - 1; index >= 0; index--) {
         var dateadscc = "";
         var elxc = "";
         dateadscc = moment(delta[index].eventOn, 'DD/MM/YYYY');
         elxc = new Date(dateadscc);
         if (moment(elxc).format("YYYYMMDD") > moment(newdate).format("YYYYMMDD")) {
            return delta[index].eventOn;
         }
      }
   }
   setname() {
      if (localStorage.getItem('screenname')) {
         var first = localStorage.getItem('screenname').split(" ")[0]
         var firstn = first.charAt(0);
         if (localStorage.getItem('screenname').split(" ")[1]) {
            var second = localStorage.getItem('screenname').split(" ")[1]
            var secondn = second.charAt(0);
            this.setState({ static: firstn + secondn });
         }
         else {
            this.setState({ static: firstn });
         }

      }
   }

   checkdate(date) {
      for (let index = 0; index < this.state.marks.length; index++) {
         var dateads = moment(this.state.marks[index].eventOn, 'DD/MM/YYYY');
         var elx = new Date(dateads);
         var finaldate = moment(elx).format('MMYYYY');
         if (date == finaldate) {
            return this.state.marks[index].eventOn;
         }
      }
   }

   getNextPrevious(dates) {
      var e = dates;
      this.onChangedataprofile(e);
      const newDate = moment(dates).format('MMYYYY');
      var index_val = this.state.monthyear.indexOf(newDate);
      console.log("index_val", index_val);
      if (index_val == 0) {
         var previous = this.state.monthyear[index_val + 1];
         this.setState({ Nextd: "", Previousd: this.checkdate(previous) });
      }
      else {
         var previous = this.state.monthyear[index_val + 1];
         var next = this.state.monthyear[index_val - 1];
         this.setState({ Nextd: this.checkdate(next), Previousd: this.checkdate(previous) });
      }
   }

   getNextForDate(dates) {
      this.showMonthYear(dates)
      const newDate = moment(dates).format('MMYYYY');
      var index_val = this.state.monthyear.indexOf(newDate);
      if (index_val == 0) {
         var previous = this.state.monthyear[index_val + 1];
         this.setState({ Nextd: "", Previousd: this.checkdate(previous) });
      }
      else {
         var previous = this.state.monthyear[index_val + 1];
         var next = this.state.monthyear[index_val - 1];
         this.setState({ Nextd: this.checkdate(next), Previousd: this.checkdate(previous) });
      }
   }

   handleChangeLogout() {
      $('#two_12 .errorpop2').toggle();
      window.localStorage.clear();
      window.location.reload();
   }

   addPostsOfUserToSavePost(postDate, calendarActiveDateInfo, postDetail) {
      const post = this.state.savePost;
      const opendUser = calendarActiveDateInfo.user;
      const isUserPresent = post.findIndex((data) => data.user == opendUser);

      if (isUserPresent !== -1) {
         const updatedSavePost = post.map((data) => {
            if (data.user === opendUser) {
               return {
                  ...data,
                  date: postDate,
                  eventDetail: postDetail.response,
                  nextMonthYear: calendarActiveDateInfo.nextMonthYear,
                  previousMonthyear: calendarActiveDateInfo.previousMonthyear,
               };
            } else {
               return data;
            }
         });
         this.setState({ savePost: updatedSavePost });
      }
      else {
         this.setState((prevState) => ({
            savePost: [
               ...prevState.savePost,
               {
                  date: postDate,
                  user: calendarActiveDateInfo.user,
                  eventDetail: postDetail.response,
                  allEvents: calendarActiveDateInfo.allEvents,
                  minDate: calendarActiveDateInfo.minDate,
                  maxDate: calendarActiveDateInfo.maxDate,
                  previousMonthyear: calendarActiveDateInfo.previousMonthyear,
                  nextMonthYear: calendarActiveDateInfo.nextMonthYear,
                  otherMonthYear: calendarActiveDateInfo.otherMonthYear
               },
            ],
         }));
      }
   }

   // updateCalendarData(data) {
   //    if(data) {
   //       this.setState({
   //          availableEventsOnDates: data.availableEventsOnDates,
   //          appendedTiles: {
   //             data: data.appendedTiles.data,
   //             appendedMonth: data.appendedTiles.appendedMonth,
   //          }
   //       })
   //    }
   // }

   // updateCalendarStyling (date) {
   //    const calendarRef = this.calendarRef.current;

   //    //initially update the calendar styling according to available temporal information
   //    if(calendarRef && this.state.marks.length) {
   //       updateStylingOfUserCalendar(
   //          calendarRef,
   //          date? date : null,
   //          {
   //             valueCalendarOther: this.state.valuedataprofile,
   //             availableEventsOnDates: this.state.availableEventsOnDates,
   //             appendedTiles: this.state.appendedTiles,
   //             allEventsList: this.state.marks,
   //          },
   //          this.updateCalendarData,
   //       );
   //    } else {
   //       setTimeout(() => {
   //          this.updateCalendarStyling(date);
   //       }, 1);
   //    }
   // }

   // updateTemporalInformationStyling() {
   //    const calendarRef = this.calendarRef.current;
   //    const availableEvents = this.state.availableEventsOnDates;

   //    if(calendarRef && availableEvents.length) {
   //       handleMarginsOfTimetablesParent(calendarRef, availableEvents);
   //    } else {
   //       setTimeout(() => {
   //          this.updateTemporalInformationStyling();
   //       }, 1);
   //    }
   // }

   onChangedataprofile = (e) => {
      // this.updateCalendarStyling(e); //update the styling according to available temporal information

      // const token = localStorage.getItem('token');
      // const variable = localStorage.getItem('userid');
      // var tiger = moment(e).format('DD-MM-YYYY');
      // var activeDate = new Date(tiger);

      // if (this.state.marks.find(x => x.eventOn === tiger)) {
      //    this.setState({ valuedataprofile: e });
      //    fetch(this.state.baseUrl + '/api/event/profileEventDateWise.php?profileId=' + variable + '&date=' + tiger, {
      //       method: "GET",
      //       headers: {
      //          Accept: "application/json",
      //          "Content-Type": "application/json",
      //          "TimeZone": moment.tz.guess(),
      //          "Authorization": "Bearer " + token,
      //       },
      //    })
      //       .then((response) => response.json())
      //       .then((responseJson) => {
      //          this.getNextForDate(e);
      //          this.updateSavedPostForLoggedInUser(variable, tiger, responseJson);

      //          this.setState({ profiledata: [] }, () => {
      //             this.setState({ profiledata: responseJson.response });
      //          });

      //          this.updateTemporalInformationStyling(); // update styling of temporal information block
      //       })
      //       .catch((error) => {
      //       })
      // }
   }

   updateTimetableData = (data, calendarView) => {
      if (data && ['current', 'next', 'previous', 'twoMonthsEarlier', 'twoMonthsAhead', 'threeMonthsEarlier', 'threeMonthsAhead'].includes(calendarView)) {
         this.setState((prevState) => ({
            timetableData: {
               ...prevState.timetableData,
               [calendarView]: {
                  ...prevState.timetableData[calendarView],
                  ...data,
               },
            },
         }));
      }
   };

   updateStatesFromTimetable = (data) => {
      this.setState({
         currentMonthYear: data.activeDate,
         valueCalendarOther: data.activeDate,
         marks: data.allEvents,
         minimum: data.minDate,
         maximum: data.maxDate,
         monthyear: data.otherMonthYear,
      })
   }

   updateActiveDatesOfTimetables(newActiveDate, action, calendar) {
      const getDate = (self, action) => {
         let date = this.state.timetableData[self][action + 'Date']
         if (date) {
            const formattedDate = moment(date, 'DD/MM/YYYY');
            const newDate = new Date(formattedDate);
            return newDate
         }
         else return date
      }

      let currentActiveDateNew = getDate('current', action)

      this.showMonthYear(currentActiveDateNew)
   }

   getActiveDatesOfNextPrevMonths(date, monthDateMapping) {
      let activeDates = {}

      let currentMonthIndex = monthDateMapping.findIndex(el => el.isActiveMonth)

      const getDateAtIndex = (index) => monthDateMapping[currentMonthIndex + index]?.date;

      activeDates.threeMonthsAhead = getDateAtIndex(3);
      activeDates.twoMonthsAhead = getDateAtIndex(2);
      activeDates.next = getDateAtIndex(1);
      activeDates.prev = undefined;
      activeDates.twoMonthsEarlier = undefined;
      activeDates.threeMonthsEarlier = undefined;

      if (!activeDates.threeMonthsAhead) {
         activeDates.prev = getDateAtIndex(-1);
      }
      if (!activeDates.threeMonthsAhead && !activeDates.twoMonthsAhead) {
         activeDates.prev = getDateAtIndex(-1);
         activeDates.twoMonthsEarlier = getDateAtIndex(-2);
      }
      if (!activeDates.threeMonthsAhead && !activeDates.twoMonthsAhead && !activeDates.next) {
         activeDates.prev = getDateAtIndex(-1);
         activeDates.twoMonthsEarlier = getDateAtIndex(-2);
         activeDates.threeMonthsEarlier = getDateAtIndex(-3);
      }

      return activeDates
   }

   onCalendarPostAdd = (e) => {
      var dateStr = moment(e).format('YYYY-MM-DD');
      if (this.state.firstDate === dateStr) {
         this.setState({ valueCalendar: '' });
         this.setState({ firstDate: '' });
         return
      }
      if (this.state.secondDate === dateStr) {
         this.setState({ valueCalendar: '', secondDate: '' });
         return
      }
      let selectedDate = new Date(dateStr)
      if (!this.state.firstDate) {
         if (this.state.secondDate && new Date(this.state.secondDate) < selectedDate)
            this.setState({ valueCalendar: e, firstDate: this.state.secondDate, secondDate: dateStr });
         else
            this.setState({ valueCalendar: e, firstDate: dateStr });
      }
      else if (this.state.firstDate) {
         let firstDateDate = new Date(this.state.firstDate)
         if (selectedDate > firstDateDate)
            this.setState({ secondDate: dateStr });
         else
            this.setState({ valueCalendar: "", secondDate: this.state.firstDate, firstDate: dateStr });
      }
   }
   onCalendarPostEdit = (e) => {
      var dateStr = moment(e).format('YYYY-MM-DD');
      if (this.state.firstDate === dateStr) {
         this.setState({ selectedDate: '' });
         this.setState({ firstDate: '' });
         return
      }
      if (this.state.secondDate === dateStr) {
         this.setState({ selectedDate: '', secondDate: '' });
         return
      }
      let selectedDate = new Date(dateStr)
      if (!this.state.firstDate) {
         if (this.state.secondDate && new Date(this.state.secondDate) < selectedDate)
            this.setState({ selectedDate: e, firstDate: this.state.secondDate, secondDate: dateStr });
         else
            this.setState({ selectedDate: e, firstDate: dateStr });
      }
      else if (this.state.firstDate) {
         let firstDateDate = new Date(this.state.firstDate)
         if (selectedDate > firstDateDate)
            this.setState({ secondDate: dateStr });
         else
            this.setState({ selectedDate: "", secondDate: this.state.firstDate, firstDate: dateStr });
      }
   }

   handleChangeEmail(e) {
      this.setState({ email: e.target.value });
   }

   handleChangePassword(e) {
      this.setState({ password: e.target.value });
   }
   handleChangeFull(e) {
      this.setState({ full: e.target.value });
   }
   handleChangeUserName(e) {
      const identifier = e.target.value;
      const isValid = usernameRegex.test(identifier);

      this.setState({ username: identifier, isValidUsername: isValid });
   }
   handleChangeSignEmail(e) {
      this.setState({ signemail: e.target.value });
   }
   handleChangeSignPassword(e) {
      this.setState({ signpassword: e.target.value });
   }
   changeCurrentTimeNew(seconds, players) {
      let playerd = this[`ref${players}`].getState().player.currentTime;
      this[`ref${players}`].seek(playerd + seconds);
   }

   changeCurrentTimeOld(seconds, players) {
      let playerd = this[`ref${players}`].getState().player.currentTime;
      this[`ref${players}`].seek(playerd + seconds);
   }
   changeCurrentTime(seconds) {
      return () => {
         const { player } = this.player.getState();
         this.player.seek(player.currentTime + seconds);
      };
   }

   onscrolldocument() {
      var pxcelate = $('#two_2 #scrollableDivTwoFourOwnProfile')[0].scrollHeight;
      var px = $("#two_2 #scrollableDivTwoFourOwnProfile").scrollTop();
      if (px < 50) {
         if (this.state.topcontat == "of") {
            this.setState({ inverse: true, loadthired: false }, () => {
               if (px == 0) {
                  this.timeout = setTimeout(() => {
                     this.fetchEvntListScroll();
                  }, 100);
                  setTimeout(() => {
                     if (this.timeout) {
                        clearTimeout(this.timeout)
                        this.timeout = null
                     }
                  }, 100);
               }
            });
            // $('#two_2 .loaderclass').removeClass('bottomscrolls');
            // $('#two_2 .loaderclass').addClass('topscrolls');
            $('#two_2 .contantf').addClass('closeCd');
         }
         if (this.state.topcontat == "on") {
            $('#two_2 .contantf').removeClass('closeCd');
            // $('#two_2 .topscrolls').addClass('closeCd');
         }
         else {
            $('#two_2 .contantf').addClass('closeCd');
         }
      }
      else {
         if (this.state.contat == "of") {
            this.setState({ inverse: false, loadthired: false }, () => {

               if (Math.round(px + $("#two_2 #scrollableDivTwoFourOwnProfile").height()) == pxcelate) {
                  this.timeout = setTimeout(() => {
                     this.fetchEvntListScroll();
                  }, 100);
                  setTimeout(() => {
                     if (this.timeout) {
                        clearTimeout(this.timeout)
                        this.timeout = null
                     }
                  }, 100);
               }
            });
            // $('#two_2 .loaderclass').removeClass('topscrolls');
            // $('#two_2 .loaderclass').addClass('bottomscrolls');
         }
         if (this.state.contat == "on" && Math.round(px + $("#two_2 #scrollableDivTwoFourOwnProfile").height()) == pxcelate) {
            $('#two_2 .contantf').removeClass('closeCd');
            // $('#two_2 .bottomscrolls').addClass('closeCd');
         }
         else {
            $('#two_2 .contantf').addClass('closeCd');
         }
      }
   }

   godocument() {
      $('#two_2 #date').removeClass('active');
      $('#two_2 #list').addClass('active');
      // $('#two_2').removeClass('screen1 else');
      $('#two_2').addClass('text-left profile-pag');
      $('#two_2 #onedate').removeClass('active show');
      $('#two_2 #onelist').addClass('active show');
      this.fetchEvntList();
   }

   addTimeoutForDateChange = (callback) => {
      const now = new Date();
      const nextMidnight = new Date(
         now.getFullYear(),
         now.getMonth(),
         now.getDate() + 1,
         0, 0, 0, 0
      );

      const remainingTime = nextMidnight.getTime() - now.getTime();

      if (this.timeoutId) {
         clearTimeout(this.timeoutId);
      }

      this.setState({ updateTemporalInformation: true });

      this.timeoutId = setTimeout(() => {
         try {
            callback();
         } catch (error) {
            console.error('Error updating temporal information:', error);
         }
      }, remainingTime);

      return remainingTime;
   };

   updateTemporalInformation = () => {
      if (this.state.updateTemporalInformation) {
         this.setState({ updateTemporalInformation: false });
         this.addTimeoutForDateChange(this.updateTemporalInformation);
      }
   };

   fetchEvntList() {
      if (this.state.evntData.length >= 20)
         return

      if (this.state.hasMore == true) {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         this.setState({ hasMore: true })

         setTimeout(() => {
            var date = Date.now() / 1000;
            var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + variable + '&date=' + date;

            fetch(serverpath, {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "TimeZone": moment.tz.guess(),
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  const timeout = this.addTimeoutForDateChange(this.updateTemporalInformation);
                  const events = responseJson.response;
                  if (events.length < 20)
                     this.setState({ contat: 'on', topcontat: 'on', hasMore: false })

                  if (responseJson.message == "Event not available" && this.state.inverse == false) {
                     $('#two_2 .loaderclass').removeClass('downscroll');
                     $('#two_2 .loaderclass').addClass('stopscroll');
                  }
                  if (events.length) {
                     this.setState({ evntData: events }, () => {
                        const centerEventIndex = Math.floor(events.length / 2)

                        const centerEventHashDivSelector = `#hashme${events[centerEventIndex].eventID}`
                        this.scrollTimelineToElementPosition(centerEventHashDivSelector);
                     })
                  }
               });
         }, 100);
      }
   }
   /**
 * will scroll the Infinite Scroll to *offsetTop* position of the element selected by given jquery selector 
 * 
 * @param {string} selector HTML Element selector 
 */
   scrollTimelineToElementPosition(selector) {
      $('#two_2 #scrollableDivTwoFourOwnProfile').scrollTop($(`#two_2 ${selector}`)[0].offsetTop);
   }

   fetchEvntListScroll() {
      if (this.state.loadthired == false) {
         // let currentHeight =
         //    $("#two_2 #scrollableDivTwoFourOwnProfile")[0].scrollHeight -
         //    $("#two_2 #scrollableDivTwoFourOwnProfile").height();
         $('#two_2 .loaderclass').removeClass('removescrolling');
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         this.setState({ hasMore: true, loaddocument: true, items: Array.from({ length: 10 }) })
         var time = Math.floor(new Date() / 1000);
         setTimeout(() => {
            $('#two_2 #scrollableDivTwoFourOwnProfile').addClass('scrollbarhiden');
            let scrollId = ""
            if (this.state.inverse == false) {
               var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + variable + '&date=' + this.state.evntData[this.state.evntData.length - 1].postDate + '&eventType=0&eventID=' + this.state.evntData[this.state.evntData.length - 1].eventID;
               scrollId = this.state.evntData[this.state.evntData.length - 1].eventID;
            }
            if (this.state.inverse == true) {
               var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + variable + '&date=' + this.state.evntData[0].postDate + '&eventType=1&eventID=' + this.state.evntData[0].eventID;
               scrollId = this.state.evntData[0].eventID;
            }
            fetch(serverpath, {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "TimeZone": moment.tz.guess(),
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {

                  if (responseJson.response.length < 20) {
                     if (this.state.inverse)
                        this.setState({ topcontat: 'on' });
                     else
                        this.setState({ contat: 'on' });
                  }
                  if (responseJson.response.length > 0) {
                     var datas = responseJson.response;
                     if (this.state.inverse == true) {

                        for (var i = datas.length - 1; i >= 0; i--) {
                           this.state.evntData.unshift(datas[i]);
                        }
                     }
                     else {
                        for (var i = datas.length - 1; i >= 0; i--) {
                           this.state.evntData.push(datas[i]);
                        }
                     }
                     if (this.state.inverse == false) {

                        $('#two_2 #scrollableDivTwoFourOwnProfile').addClass('scrollbarhiden');
                        setTimeout(() => {
                           $('#two_2 #scrollableDivTwoFourOwnProfile').removeClass('scrollbarhiden');
                        }, 100)
                     }
                     if (this.state.inverse == true) {

                        $('#two_2 #scrollableDivTwoFourOwnProfile').addClass('scrollbarhiden');
                        setTimeout(() => {
                           $('#two_2 #scrollableDivTwoFourOwnProfile').removeClass('scrollbarhiden');
                        }, 100)
                     }
                     // this.setState({ loaddocument: false }, () => {
                     if (this.state.evntData.length > 20) {
                        this.setState({ items: this.state.items.concat(Array.from({ length: 20 })) }, () => {
                        });
                     }
                     // setTimeout(() => {
                     this.setState({ loadthired: false });
                     if (this.state.inverse) {
                        const scrollSelector = `#hashme${scrollId}`
                        this.scrollTimelineToElementPosition(scrollSelector)
                     }
                     // }, 100);
                     // });
                  }
                  else {
                     $('#two_2 #scrollableDivTwoFourOwnProfile').addClass('scrollbarhiden');
                     setTimeout(() => {
                        $('#two_2 #scrollableDivTwoFourOwnProfile').removeClass('scrollbarhiden');
                     }, 100)
                  }
                  this.setState({ loaddocument: false });
               });
         }, 100);
      }
   }
   godate() {
      $('#two_2 #list').removeClass('active');
      $('#two_2 #date').addClass('active');
      $('#two_2').removeClass('text-left profile-pag');
      $('#two_2').addClass('screen1 else');
      $('#two_2 #onelist').removeClass('active show');
      $('#two_2 #onedate').addClass('active show');
   }

   onClick() {
      if (this.state.email == '' || this.state.password == '') {
         this.setState({ blankFieldsPopup: true });
         return
      }
      const body = {
         email: this.state.email,
         password: this.state.password,
         deviceType: "android",
         deviceToken:
            "cHoLreb1SGacDSQdDS-yFP:APA91bHDXlU8BgbbbuHyMIYjMhRC2OQK3KIs1BbuUSqoIp9IZsrn2xX7m2l06MEdVUbgkqet962BA61SD2vqpv47-B1jtxNxAGgYyo7BwqE_uS-jrQXcsYqMi1hNRfnBi99AYXuXRKCA",
         deviceId: "62406169083a90ad67",
      };
      axios
         .post(this.state.baseUrlNode + "/api/login", body)
         .then((res) => {
            if (res.data.status == "success") {
               localStorage.setItem("timeToken", res.data.token);
               localStorage.setItem("email", res.data.data.email);
               localStorage.setItem("screenname", res.data.data.screenName);
               localStorage.setItem("totalpost", res.data.data.totalPost);
               localStorage.setItem("username", res.data.data.username);
               localStorage.setItem("followers", res.data.data.followers);
               localStorage.setItem("request", res.data.data.request);
               localStorage.setItem("bio", res.data.data.bio);
               localStorage.setItem("following", res.data.data.following);
               localStorage.setItem("profileCover", res.data.data.profileCover);
               localStorage.setItem("profileImage", res.data.data.profileImage);
               localStorage.setItem("userid", res.data.data.user_id);
               localStorage.setItem("make_public", res.data.data.make_public);
               localStorage.setItem("website", res.data.data.website);
               localStorage.setItem("profileType", res.data.data.profileType);

               axios
                  .post(this.state.baseUrl + "/api/user/login.php", body)
                  .then((res) => {
                     localStorage.setItem("totalpost", res.data.response.totalPost);
                     localStorage.setItem("token", res.data.response.token);
                     var first = localStorage.getItem("screenname")?.split(" ")[0];
                     var firstn = first?.charAt(0);
                     if (localStorage.getItem("screenname")?.split(" ")[1]) {
                        var second = localStorage.getItem("screenname").split(" ")[1];
                        var secondn = second.charAt(0);
                        this.setState({ static: firstn + secondn });
                     } else {
                        this.setState({ static: firstn });
                     }
                     removeProfileDataFromStorage()
                     window.location.reload();
                  })
                  .catch((error) => {
                     this.setState({ invalidLoginPopup: true });
                     console.log("err msg:", error.message);
                  });
            }
         })
         .catch((error) => {
            this.setState({ invalidLoginPopup: true });
            console.log("err msg:", error.message);
         });
   }
   closePopup() {
      this.setState({ invalidLoginPopup: false });
   }
   onSubmit() {
      if (this.state.signemail == '' || this.state.signpassword == '' || this.state.full == '' || this.state.username == '') {
         this.setState({ blankFieldsPopup: true });
         return
      }

      if (!this.state.isValidUsername) {
         this.setState({ signupFailedPopup: true });
         return;
      }

      this.setState({ showSignUpLoader: true })

      const body = {
         email: this.state.signemail,
         password: this.state.signpassword,
         screenName: this.state.full,
         username: this.state.username,
         profileType: "consumer",
      };
      const header = new Headers();
      header.append("Access-Control-Allow-Origin", "*");
      axios
         .post(this.state.baseUrl + "/api/user/add.php", body)
         .then((res) => {
            if (res.data.code == 200) {
               this.saveDb(res.data.response);
            }
         })
         .catch((error) => {
            this.setState({ showSignUpLoader: false, signupFailedPopup: true, signupFailedErrorMsg: error?.response?.data?.message });
            console.log("SignUp failed. Error: ", error?.response?.data || error.message);
         });
   }
   saveDb(db) {
      db.password = this.state.signpassword;
      db.profileType = "consumer";
      axios
         .post(this.state.baseUrlNode + "/api/add", db)
         .then((res) => {
            if (res.data.status == "success") {
               localStorage.setItem("timeToken", res.data.token);
               localStorage.setItem("email", db.email);
               localStorage.setItem("screenname", db.screenName);
               localStorage.setItem("totalpost", db.totalPost);
               localStorage.setItem("username", db.username);
               localStorage.setItem("followers", db.followers);
               localStorage.setItem("request", db.request);
               localStorage.setItem("bio", db.bio);
               localStorage.setItem("following", db.following);
               localStorage.setItem("profileCover", db.profileCover);
               localStorage.setItem("profileImage", db.profileImage);
               localStorage.setItem("userid", db.user_id);
               localStorage.setItem("token", db.token);
               localStorage.setItem("make_public", db.make_public);
               localStorage.setItem("website", db.website);
               localStorage.setItem("profileType", "consumer");

               var first = localStorage.getItem("screenname")?.split(" ")[0];
               var firstn = first.charAt(0);
               if (localStorage.getItem("screenname")?.split(" ")[1]) {
                  var second = localStorage.getItem("screenname").split(" ")[1];
                  var secondn = second.charAt(0);
                  localStorage.setItem("staticname", firstn + secondn);
               } else {
                  localStorage.setItem("staticname", firstn);
               }
               removeProfileDataFromStorage()

               window.location.reload();
            }
         })
         .catch((error) => {
            this.setState({ signupFailedPopup: true, signupFailedErrorMsg: error?.response?.data?.message });
            console.log("SignUp failed. Error: ", error?.response?.data || error.message);
         })
         .finally(() => this.setState({ showSignUpLoader: false }))
   }

   getbioclass() {
      if (localStorage.getItem('bio') == "null" || localStorage.getItem('bio') == "" && localStorage.getItem('website') == "null" || localStorage.getItem('website') == "") {

         return "inkap blankuser";
      }
      if (localStorage.getItem('bio') === "" && localStorage.getItem('website') === "") {
         return "inkap blankuser";
      }
      if (localStorage.getItem('bio') !== "" && localStorage.getItem('bio') !== "null") {
         if (localStorage.getItem('website') !== "" && localStorage.getItem('website') !== "null") {

            return "inkap";
         }
      }

      if (localStorage.getItem('bio') !== "null" || localStorage.getItem('bio') !== "" && localStorage.getItem('webiste') == "null" || localStorage.getItem('website') == "") {
         return "inkap blankuserone";
      }
      if (localStorage.getItem('bio') == "null" || localStorage.getItem('bio') == "" && localStorage.getItem('webiste') !== "null" || localStorage.getItem('website') !== "") {
         return "inkap blankuserone";
      }
   }

   ////////////////////////////chatting//////////////////////////////////

   closeImage() {
      this.setState({ imageVideoUrl: "", postType: "", filewithpath: "" });
   }

   ////////////////////////////Ending of chatting/////////////////////// 
   //////////////////////////chatuser/////////////////////////////////////////////////////

   ///////////////////////////////End of chat user /////////////////////////////////////////////

   ///////////////////////////////NOtificaion/////////////////////////////////////////////////////////

   ///////////////////////////End oF NOtificaion ////////////////////////////////////////////////////////

   //////////////////////////Home Screen //////////////////////////////////////////////////////////////

   likepost(e) {
      $(".newscreen2  #" + e.currentTarget.id).toggleClass("thumbs-down");
      var hascc = $('div').hasClass('thumbs-down');
      if (hascc == true) {
         setTimeout(function () {
            $('.thumbs-down').removeClass('thumbs-down');
         }, 1500);
      }
      var evtid = e.currentTarget.id.replace('croxs', '');
      const body = {
         user_id: localStorage.getItem('userid'),
         eventID: evtid,
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/addOrRemoveLike.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log('res');
         });
   }

   collectMentions(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      var hash = value.match(/(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more || hash) {
         if (more !== null && more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).unbind().click(function () {
                     this.fetchuserid(more[index].substring(1));
                  }.bind(this));
               }
            }.bind(this));
         }
         if (hash !== null && hash.length > 0) {
            console.log()
            $(document).ready(function () {
               for (let index = 0; index < hash.length; index++) {
                  $("." + hash[index].substring(1)).unbind().click(function () {
                     this.mainfuncion(hash[index].substring(1))
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }

   logpop() {
      const { appContext } = this.props;
      const { state, actions } = appContext;
      if (!state.logoutPopupActive) {
         // $('#two_12 .errorpop22').toggle();
         this.setState({ logoutPopup: true });
         actions.setLogoutPopupActive(true);
      }
   }
   abortLogout() {
      const { appContext } = this.props;
      const { actions } = appContext;
      this.setState({ logoutPopup: false });
      actions.setLogoutPopupActive(false);
      // $('#two_12 .errorpop22').toggle();
   }
   fetchuserid(name) {
      fetch(this.state.baseUrl + '/api/user/search.php?exact_match=1&search=' + name, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
         },
      }).then((response) => response.json())
         .then((responseJson) => {
            var id = responseJson.response[0].user_id;
            var data = { ids: id };
            this.gouserprofile(data);
         })
   }

   ///////////////////////////////End of Home Screen///////////////////////
   /////////////////////////////// single comment/////////////////////////

   gotodelete() {
      this.setState({ editDeletePopup: false, openDeleteInformationPopup: true });
   }

   async getevent(e) {
      //$('.else').removeClass('active fades');
      this.setState({editDeletePopup: false});
      await this.displayElementInDOM(EDIT_POST)

      if (e && e.back == "back") {
         // $("#two_16").addClass("active fadesback");
         this.animationlinkback('#two_16');
         this.context.setActiveTab(e?.tab);
         this.setState({ activeTab: e?.tab })
         this.state.datasetup.pop();
         this.setState({ datasetup: this.state.datasetup });
         var id = e.ids;
      }
      else {
         //   $("#two_16").addClass("active fades");
         this.animationscript('#two_16');
         // setTimeout(() => {
         var dataopen = { function: "getevent", data: { back: "back", tab: this.state.activeTab, ids: e.target?.id } };
         this.state.datasetup.push(dataopen);
         this.setState({ datasetup: this.state.datasetup });
         // e.target?.id 1000);
         var id = e.target?.id;
      }
      $("#two_16 .timecolor").removeClass("timecolor")
      $("#two_16 .timecolorsecond").removeClass("timecolorsecond")
      this.setState({
         editstatus: "", editeventImage: "", editmediaType: "", editwhocansee: "",
         editwhocanseemeta: [], editwithwho: [], editheight: "", editwidth: "", editinclude_in_feed: "", editIsImportant: "", backid: "#two_16", exp: false, changewithwho: false
      });
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/getEventById.php?eventID=' + id, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.success == true) {
               this.setState({
                  editlike: responseJson.response[0].likedByUsers, editcount: responseJson.response[0].commentCounts,
                  editcomment: responseJson.response[0].commentByUsers, editpostOn: responseJson.response[0].postedOn, editpostOn2: responseJson.response[0].postedOn2, editevent: id,
                  editstatus: responseJson.response[0].status, editeventImage: responseJson.response[0].eventImage, editmediaType: responseJson.response[0].mediaType,
                  editwhocansee: responseJson.response[0].whocansee, editwhocanseemeta: responseJson.response[0].whocanseemeta, editwithwho: responseJson.response[0].withwho,
                  editheight: responseJson.response[0].height, editwidth: responseJson.response[0].width, editinclude_in_feed: responseJson.response[0].include_in_feed, editIsImportant: responseJson.response[0].isImportant
               }, () => {
                  this.updateCustomCheckbox("editImportant")
               });
            }
            var timestamp = responseJson.response[0].postedOn;
            var timestamp2 = responseJson.response[0].postedOn2;
            this.getPostOnDetail(timestamp, timestamp2);
         })
         .catch((error) => {
         })
      // $(".more3").click(function(){
      //   $(".pupup6").toggle();
      //});
      //   $(".errorbtn").click(function(){
      //      $(".errorpop2").toggle();
      //      });
   }

   addpostpop() {
      $('.errorpop2').toggle();
   }

   /////////////////////////////// End of single comment //////////////////////////

   ////////////////////////////// comment on Comment/////////////////////////////////

   async gochangepass() {
      // $('.else').removeClass('active fades');
      // $('#twochangepass').addClass('active fades');
      await this.displayElementInDOM(CHANGE_PASSWORD)
      this.animationscript('#twochangepassword');
   }

   /////////////////////////// End of comments///////////////////////////////////////

   ////////////////////////// Start of hasttag/////////////////////////////////////

   mainfuncion(datass) {
      // $(".else").removeClass("active fades");
      if (datass && datass.back == "back") {
         this.animationlinkback('#two_13');
         this.context.setActiveTab(datass?.tab);
         this.setState({ activeTab: datass?.tab })
         this.state.datasetup.pop();
         this.setState({ datasetup: this.state.datasetup });
         var id = datass.ids;
      }
      else {
         this.animationscript('#two_13');
         setTimeout(() => {
            var dataopen = { function: "mainfuncion", data: { back: "back", ids: datass, tab: this.state.activeTab } };
            this.state.datasetup.push(dataopen);
            this.setState({ datasetup: this.state.datasetup });
         }, 1000);
         var id = datass;
      }
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/getEventByHashtag.php?hashtag=' + id + '&page=1&limit=10', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            localStorage.setItem("across2", JSON.stringify(responseJson.response.events));
            this.setState({ tags: responseJson.response.events, totalPost: responseJson.response.totalPost, loaded: false, items: Array.from({ length: 10 }), hasMore: true });
         });
   }

   async switchProfileAcount() {
      try {
         const accountType = localStorage.getItem("profileType");
         const switchTo = accountType === "consumer" ? "producer" : "consumer";

         const switchResult = await switchAccount(switchTo);

         if (switchResult?.status) {
            localStorage.setItem("profileType", switchTo);
            window.location.href = "/";
         } else {
            this.setState({ switchAccountPopup: true });
         }
      } catch (error) {
         console.error("Error while switching profiles:", error);
         this.setState({ switchAccountPopup: true });
      }
   }

   ///////////////////////// End of hastag////////////////////////////////////////

   //////////////////////// start of like page /////////////////////////////////

   /////////////////////// end of like page ///////////////////////////////////

   //////////////////// start of report page //////////////////////////////

   ////////////////////// end of report page ///////////////////////////////

   hitgoback() {
      const { appContext } = this.props;
      const { state, actions } = appContext;

      const entry = JSON.parse(localStorage.getItem('entry'));

      const { sharedData, setSharedData } = this.context;
      let activeTabData = sharedData.tabWiseData[this.state.activeTab];
      // this.screenfunction();
      this.removeSearch();

      var myobject = this.state.datasetup[this.state.datasetup.length - 2];
      var path = myobject.function;

      let shouldReturn = false;
      if (path === 'fetchFilterUser' && this.state.activeTab == 'second' && activeTabData.openProfiles?.length == 1 && state.homePageActive.status) {
         const currentProfile = this.state.datasetup[this.state.datasetup.length - 1]?.data?.ids;
         if (currentProfile == entry[0]?.user_id) {
            return;
         } else {
            this.gouserprofile({ ids: entry[0]?.user_id, redirect: true });
            shouldReturn = true;
         }
      }
      this.closeLastOpenedProfileFromActiveTab(this.state.activeTab).then(() => this.showInitialsOfOpenProfilesOnTab())
      if (shouldReturn) {
         return
      }
      // Here is a functionality to work when home page is active or not
      var func = new Function(
         "return this." + path + "(" + JSON.stringify(myobject.data) + ")"
      ).bind(this);
      func();
   }

   handleMouseOverTwo = (event, eventData, actionType) => {
      const targetElement = event.target;
      let parentDiv = targetElement?.parentNode;
      while (!parentDiv.classList.contains('higherLevelGroup')) {
         parentDiv = parentDiv.parentNode;
      }
      if(parentDiv && eventData) {
         const linkElement = parentDiv.querySelector('.hoveredViews');
         if (linkElement) {
            if(actionType == 'leave') {
               linkElement.innerText = '';
               linkElement.style.padding = '';
               return
            }
            const formatedDate = getdateformat(eventData.created_at, null, eventData.timezone);
            linkElement.innerText = `${eventData?.views || 0} ${eventData?.views === 1 ? 'view' : 'views'} since ${formatedDate}`;
            linkElement.style.padding = '2px 5px';
         }
      }
      const elementRect = targetElement?.getBoundingClientRect();
      const elementX = elementRect?.left + window?.scrollX;
      const elementY = elementRect?.top + window?.scrollY;
      let elementR = window.innerWidth - (elementRect?.right + window.scrollX);
      const fullnameElement = targetElement?.querySelector(".fullname");
      const tooltip = targetElement?.querySelector("p");
      if (fullnameElement) {
         fullnameElement.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
         if (fullnameElement?.classList.contains("right-aligned") && fullnameElement?.classList.contains("fullName")) {
            fullnameElement.style.right = `${elementR}px`;
         } else {
            fullnameElement.style.left = `${elementX}px`;
         }
         fullnameElement.style.maxWidth = 'max-content';
      } else if (tooltip) {
         const nameElement = targetElement?.querySelector(".fullname");
         if (tooltip && tooltip?.classList.contains('fullName')) {
            tooltip.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
            if (tooltip?.classList.contains('right-aligned') && tooltip?.classList.contains('fullName')) {
               tooltip.style.right = `${elementR}px`;
            } else {
               tooltip.style.left = `${elementX}px`;
            }
            tooltip.style.maxWidth = 'max-content';
         } else if (nameElement) {
            nameElement.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
            if (targetElement?.classList.contains("right-aligned") && targetElement?.classList.contains("fullname")) {
               nameElement.style.right = `${elementR}px`;
            } else {
               nameElement.style.left = `${elementX}px`;
            }
            nameElement.style.maxWidth = 'max-content';
         }
      }
   };

   renderUserGroups = () => {
      const allUsers = this.filterUser(this.state.recomended);
      const Allelements = [];
      const upperGroupSize = 192;
      const groupSize = 64;
      const elementsPerSubgroup = 32

      // console.log("total profiles: ", allUsers.length, "required groups: ", Math.ceil(allUsers.length / 256));
      for (let g = 0; g < Math.ceil(allUsers.length / upperGroupSize); g++) {
         const users = allUsers.slice(g * upperGroupSize, (g + 1) * upperGroupSize);
         const userGroups = [];
         let subgroupSize;

         for (let i = 0; i < users.length; i += subgroupSize) {
            let higherLevelGroupClass = '';

            if (i / 64 / 2 === 0) {
               subgroupSize = groupSize; // First group with 64 elements
               higherLevelGroupClass = 'firstGroup';
               // } else if (i == 192) {
               //    subgroupSize = groupSize; // Last group with 64 elements
               //    higherLevelGroupClass = 'lastGroup';
            } else {
               subgroupSize = 128; // Middle groups with 128 elements
               higherLevelGroupClass = 'middleGroup';
            }

            const group = users.slice(i, i + subgroupSize);
            const parentDivs = [];
            const hoveredViews = (<p className='hoveredViews'></p>);

            for (let j = 0; j < group.length; j += elementsPerSubgroup) {
               const subgroup = group.slice(j, j + elementsPerSubgroup);
               const parentDiv = (
                  <div className={`parentDiv group-${i / elementsPerSubgroup}`} key={i + j}>
                     {subgroup.map((u, k) => (
                        <a className="col-3" onClick={this.gouserprofile.bind(this)} key={k} id={u?.user_id}>
                           <span
                              className="link"
                              data-id={i + j + k}
                              // id={u?.user_id}
                              data-div_name="#two_3"
                              // onClick={this.gouserprofile.bind(this)}
                              data-toggle="tab"
                              href="javascript:void(0)"
                           >
                              {u.profileImage !== "" ? (
                                 <img className="u_img" src={this.state.baseUrl + u.profileImage} align="images" />
                              ) : (
                                 ""
                              )}
                              <p onMouseOver={(e) => this.handleMouseOverTwo(e, u, 'over')}>{showInitials(u?.screenName)}
                                 <p className={`fullName ${(typeof this.props.abc == 'string') && (j == 32 || j == 96) && (k != 0 && (k % 4) != 0) ? 'right-aligned' : 'left-aligned'}`}>{u?.screenName}</p>
                              </p>
                           </span>
                        </a>
                     ))}
                  </div>
               );

               parentDivs.push(parentDiv);
            }

            userGroups.push(
               <div className={`higherLevelGroup ${higherLevelGroupClass}`} key={i / 32}>
                  {parentDivs}
                  {hoveredViews}
               </div>
            );
            // console.log({ higherLevelGroupClass, isLastGroup, parentDivs, userGroups });
         }
         Allelements.push(
            <div className='rowBlock'>
               {userGroups}
            </div>
         );
      }
      return Allelements;
   };

   addProfileToOpenProfiles(userId) {
      const { sharedData, setSharedData } = this.context;
      const activeTab = this.state.activeTab
      let openProfiles = [];
      let activeTabData = sharedData.tabWiseData[activeTab];

      if (activeTabData && activeTabData.openProfiles.length)
         openProfiles = [...activeTabData.openProfiles];

      if (openProfiles[openProfiles.length - 1] !== userId)
         openProfiles.push(userId)

      sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles };

      setSharedData({
         ...sharedData,
      });
   }

   async closeLastOpenedProfileFromActiveTab(activeTab) {
      const { sharedData, setSharedData } = this.context;
      let activeTabData = sharedData.tabWiseData[activeTab];

      activeTabData.openProfiles.pop()

      sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles: activeTabData.openProfiles };

      setSharedData({
         ...sharedData,
      });
   }

   async closeAllOpenedProfilesFromActiveTab(activeTab) {
      const { sharedData, setSharedData } = this.context;
      const dataToBeClose = sharedData.tabWiseData[activeTab]?.openProfiles;
      const dataLength = dataToBeClose?.length;

      // clear all the profiles in context
      sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles: [] };
      setSharedData({
         ...sharedData,
      });

      // removing the profiles data from the datasetup
      if (dataToBeClose && dataLength) {
         let newDataSetup = this.state.datasetup;
         for (let i = 1; i <= dataLength; i++) {
            let userData = newDataSetup[newDataSetup?.length - 1];
            if (userData?.data.ids === dataToBeClose[dataToBeClose.length - 1]) {
               newDataSetup.pop();
               dataToBeClose.pop();
            }
         }
         this.setState({ datasetup: [...newDataSetup] });
      }
   }

   activeTabHasOpenProfiles(activeTab) {
      let activeTabData = { ...this.context.sharedData.tabWiseData[activeTab] }
      return activeTabData?.openProfiles?.length
   }

   displayOpenProfiles(visitedTab, openProfiles, tab) {
      this.addUserIdsToHistory(visitedTab, openProfiles, tab);
      this.displayLastOpenedProfile(openProfiles);
   }

   displayLastOpenedProfile(openProfiles) {
      this.gouserprofile({ ids: openProfiles[openProfiles.length - 1] });
   }

   addUserIdsToHistory(visitedTab, openProfiles, tab) {
      this.state.datasetup.push({ function: visitedTab, data: { back: "back", tab: tab } });

      openProfiles.slice(0, openProfiles.length - 1).forEach(userId => {
         this.state.datasetup.push({ function: "gouserprofile", data: { ids: userId, back: "back", tab: tab } });
      });

      this.setState({ datasetup: this.state.datasetup });
   }

   callFuntion = (functionName, data) => {
      switch (functionName) {
         case "hitgoback":
            this.hitgoback()
            break;
         case "goProfile":
            this.goProfile()
            break;
         case "gotosetting":
            this.gotosetting()
            break;
         case "fetchFilterUser":
            this.fetchFilterUser(data)
            break;
         case "goToPost":
            this.goToPost(data)
            break;
         case "gouserprofile":
            this.gouserprofile(data)
            break;
         case "mainfuncion":
            this.mainfuncion(data)
            break;
         case "addProfilePathsToHistory":
            this.addProfilePathsToHistory(data)
            break;

         default:
            break;
      }
   }

   render() {

      return (

         <div className="newscreen1 newscreen2">
            {/* removed code for homepage component id: two_1 */}
            {
               this.state.display[LOGIN_SIGNUP] &&

               <div className="screen1 else" id="twologin">
                  <div className="top newbg" id="myHeader">

                     <section className="header userlogin">
                        <div className="row">
                           <div className="col-12 pl-0 pr-0 header_center text-center">
                              <h6>Timesort</h6>
                           </div>
                        </div>
                     </section>
                  </div>
                  {!this.state.showSignUp ? <>
                     <form className="form-signin login">
                        <h3>Login</h3>
                        <div className="form-group">
                           <label>identifier or email</label>
                           <input type="text" name="email" id="inputEmail" value={this.state.email}
                              onChange={this.handleChangeEmail.bind(this)} className="form-control" placeholder="identifier or email" required="" />
                        </div>
                        <div className="form-group mb-0">
                           <label>password</label>
                           <input type="password" name="password" id="inputPassword" value={this.state.password} onChange={this.handleChangePassword.bind(this)} className="form-control" placeholder="password" required="" />
                        </div>
                        {/* <p className="mb-2 text-right"><a className="link" data-toggle="tab" href="javascript:void(0)">forgot password?</a></p> */}
                        <button className="btn btn-lg btn-block" onClick={this.onClick.bind(this)} type="button">Login</button>
                        <h6 className="text-center"><a className="link" onClick={() => this.setState({ showSignUp: true })} href="javascript:void(0)" data-toggle="tab" >Sign Up</a></h6>
                     </form>
                  </>
                     : <>
                        <form className="form-signin login signup">
                           <h3>Sign up</h3>
                           <div className="form-group" >
                              <label>full name</label>
                              <input type="text" name="fullname" className="form-control" onChange={this.handleChangeFull.bind(this)} value={this.state.full} placeholder="full name" />
                           </div>
                           <div className="form-group">
                              <label>identifier</label>
                              <input type="text" name="username" id="username" className="form-control" onChange={this.handleChangeUserName.bind(this)} value={this.state.username} placeholder="identifier" />
                           </div>
                           <div className="form-group">
                              <label>email</label>
                              <input type="email" name="email" className="form-control" onChange={this.handleChangeSignEmail.bind(this)} value={this.state.signemail} placeholder="email" />
                           </div>
                           <div className="form-group mb-0">
                              <label>password</label>
                              <input type="password" name="password" id="inputPassword" onChange={this.handleChangeSignPassword.bind(this)} value={this.state.signpassword} className="form-control" placeholder="password" required="" />
                           </div>
                           <button className="btn btn-lg btn-block" onClick={this.onSubmit.bind(this)} type="button">Sign up</button>
                           <p>By signing up, you agree to Timesort's <Link to={'/tos'} target='_blank' rel="noopener noreferrer">Terms of Service</Link> & <Link to={'/privacy-policy'} target='_blank' rel="noopener noreferrer">Privacy Policy</Link>.</p>
                           <h6 className="text-center"><a className="link" to="/" data-toggle="tab" onClick={() => this.setState({ showSignUp: false })} href="javascript:void(0)">Login</a></h6>
                        </form>
                     </>}
                  {localStorage.getItem('userid') ? (localStorage.getItem('profileType') === 'consumer') ? <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 ">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center justify-center nav">
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button onClick={this.fetchFilterUser.bind(this)} className={`bottomMenuBtn`}><div><CalendarSvg width={23} /><sub></sub></div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.gotosetting.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer> : <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 ">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center nav justify-center">
                           <li className={this.state.activeTab === 'first' ? 'active left' : ''} >
                              {
                                 this.state.tabwiseStackInfo.first.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={this.goProfile.bind(this)} ><div><NewsPaperSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                 {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                 {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer> : <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 ">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center nav justify-center">
                           <li className={this.state.activeTab === 'first' ? 'active left' : ''}>
                              {
                                 this.state.tabwiseStackInfo.first.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={this.gotosetting.bind(this)} ><div><NewsPaperSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                 {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                 {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}  >
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer>}
                  {this.state.invalidLoginPopup && (
                     <CustomPopUp
                        isOpen={this.state.invalidLoginPopup}
                        onClose={() => this.setState({ invalidLoginPopup: false })}
                        popup={{
                           heading: "Invalid credentials",
                           message: 'Provide valid login credentials.',
                           onClick: () => {
                              this.closePopup();
                           },
                        }}
                        multipleButton={false}
                     />
                  )}
                  {this.state.blankFieldsPopup && (
                     <CustomPopUp
                        isOpen={this.state.blankFieldsPopup}
                        onClose={() => this.setState({ blankFieldsPopup: false })}
                        popup={{
                           heading: "Unfulfilled fields",
                           message: 'Fill in all fields.',
                           onClick: () => {
                              this.setState({ blankFieldsPopup: false });
                           },
                        }}
                        multipleButton={false}
                     />
                  )}
                  {this.state.signupFailedPopup && (
                     <CustomPopUp
                        isOpen={this.state.signupFailedPopup}
                        onClose={() => this.setState({ signupFailedPopup: false })}
                        popup={{
                           heading: "Sign-up failure",
                           message: this.state.isValidUsername ?
                              "One or more fields are ill-formed."
                              : <InvalidUsernameError />,
                           onClick: () => {
                              this.setState({ signupFailedPopup: false, signupFailedErrorMsg: '' });
                           },
                        }}
                        multipleButton={false}
                     />
                  )}
                  {
                     this.state.showSignUpLoader && <FormSubmitLoader />
                  }
               </div>
            }

            {
               this.state.display[SELF_PROFILE] &&

               <div className="screen1 else" id="two_2">
                  <div className="top newbg" id="myHeader">
                     {/* <!-- --------- header ---------------> */}
                     <section className="header">
                        <div className="row">
                           <div className=" header_left pr-0">
                              <ul className="nav nav-tabs noteee">
                                 <li>
                                    <a className="active" onClick={this.godate.bind(this)} id="date" data-toggle="tab" >
                                       <CalendarSvg />
                                    </a>
                                    <p onClick={this.godate.bind(this)} id="date" className='hoverIconInfo'>Timetables</p>
                                 </li>
                                 <li className={this.state.activeTab === 'third' ? 'active' : ''}  >
                                    <a data-toggle="tab" className="" onClick={this.godocument.bind(this)} id="list" >
                                       <TimelineSvg />
                                    </a>
                                    <p onClick={this.godocument.bind(this)} id="list" className='hoverIconInfo'>Bidirectionally-traversed timeline</p>
                                 </li>
                              </ul>
                           </div>
                           <div className=" monthsBlock">
                              <ul>
                                 {
                                    this.state.leftSideArray &&
                                    this.state.leftSideArray.map((item) =>
                                       <li>
                                          <p className={`dateInfo ${item.isActiveMonth && "active"}`} onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                          <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                       </li>
                                    )
                                 }
                                 {
                                    this.state.rightSideArray &&
                                    this.state.rightSideArray.map((item) =>
                                       <li>
                                          <p onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                          <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                       </li>
                                    )
                                 }
                              </ul>
                           </div>

                           {/* <div className=" pl-0 pr-0 header_center text-center">
                           <h3><a href="javascript:void(0)">{localStorage.getItem('screenname')}</a></h3>
                        </div> */}

                           <div className=" header_right pl-0">
                              <ul className="uld">
                                 {/* <li><a className="link" onClick={this.onClickedit.bind(this)} data-toggle="tab" href="javascript:void(0)"><img src="../../images/editUser.png" align="img"/></a></li> */}
                                 {this.state.cwebsite ? <li className="more abort" ><a href={modifyHref(this.state.cwebsite)} target='_blank'>
                                    <DepartSvg />
                                    <span className='lowercase-text' >{modifyWebsiteName(this.state.cwebsite)}{modifyWebsiteName(this.state.cwebsite)?.endsWith("/") && <label className="hdots">...</label>}</span>
                                 </a>
                                 </li> : <li className="more abort" ></li>}
                                 <li className='bg-none' ><a className="link" data-toggle="tab" onClick={this.gotosetting.bind(this)}><img className='right-arrow' src="../../images/Settings_directive.png" align="img" /></a></li>
                              </ul>
                           </div>
                        </div>
                     </section>
                     {/* <!-- --------- userpoint ---------------> */}
                     {/*   removed userpoint */}
                  </div>
                  <div className="tab-content tab-content1">
                     {/* <!--------------- sectionone ------------------> */}
                     <div id="onedate" className="tab-pane fade active show">
                        <div className="flex-center">
                           {
                              this.state.timetableData.current?.activeDate ? <Timetable
                                 selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                 isScreenVisible={this.props.isScreenVisible}
                                 subspaceNumber={2}
                                 calendar='current'
                                 userDetails={this.state.userDetails}
                                 timetableData={this.state.timetableData.current}
                                 states={{
                                    events: this.state.marks,
                                    otherMinimum: this.state.minimum,
                                    otherMaximum: this.state.maximum,
                                    otherMonthYear: this.state.monthyear,
                                 }}
                                 updateTimetableData={(data) => this.updateTimetableData(data, 'current')}
                                 updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                 updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'current')}
                                 editDeletePost={(e, post) => this.editdeletepost(e, post, 'current')}
                              /> : ''
                           }
                           {
                              this.state.timetableData.next?.activeDate ? <Timetable
                                 selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                 isScreenVisible={this.props.isScreenVisible}
                                 subspaceNumber={2}
                                 calendar='next'
                                 userDetails={this.state.userDetails}
                                 timetableData={this.state.timetableData.next}
                                 states={{
                                    events: this.state.marks,
                                    otherMinimum: this.state.minimum,
                                    otherMaximum: this.state.maximum,
                                    otherMonthYear: this.state.monthyear,
                                 }}
                                 updateTimetableData={(data) => this.updateTimetableData(data, 'next')}
                                 updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                 updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'next')}
                                 editDeletePost={(e, post) => this.editdeletepost(e, post, 'next')}
                              /> : ''
                           }
                           {
                              this.state.timetableData.twoMonthsAhead?.activeDate ? <Timetable
                                 selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                 isScreenVisible={this.props.isScreenVisible}
                                 subspaceNumber={2}
                                 calendar='twoMonthsAhead'
                                 userDetails={this.state.userDetails}
                                 timetableData={this.state.timetableData.twoMonthsAhead}
                                 states={{
                                    events: this.state.marks,
                                    otherMinimum: this.state.minimum,
                                    otherMaximum: this.state.maximum,
                                    otherMonthYear: this.state.monthyear,
                                 }}
                                 updateTimetableData={(data) => this.updateTimetableData(data, 'twoMonthsAhead')}
                                 updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                 updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'twoMonthsAhead')}
                                 editDeletePost={(e, post) => this.editdeletepost(e, post, 'twoMonthsAhead')}
                              /> : ''
                           }
                           {
                              this.state.timetableData.threeMonthsAhead?.activeDate ? <Timetable
                                 selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                 isScreenVisible={this.props.isScreenVisible}
                                 subspaceNumber={2}
                                 calendar='threeMonthsAhead'
                                 userDetails={this.state.userDetails}
                                 timetableData={this.state.timetableData.threeMonthsAhead}
                                 states={{
                                    events: this.state.marks,
                                    otherMinimum: this.state.minimum,
                                    otherMaximum: this.state.maximum,
                                    otherMonthYear: this.state.monthyear,
                                 }}
                                 updateTimetableData={(data) => this.updateTimetableData(data, 'threeMonthsAhead')}
                                 updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                 updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'threeMonthsAhead')}
                                 editDeletePost={(e, post) => this.editdeletepost(e, post, 'threeMonthsAhead')}
                              /> : ''
                           }
                           {
                              this.state.timetableData.previous?.activeDate ? <Timetable
                                 selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                 isScreenVisible={this.props.isScreenVisible}
                                 subspaceNumber={2}
                                 calendar='previous'
                                 userDetails={this.state.userDetails}
                                 timetableData={this.state.timetableData.previous}
                                 states={{
                                    events: this.state.marks,
                                    otherMinimum: this.state.minimum,
                                    otherMaximum: this.state.maximum,
                                    otherMonthYear: this.state.monthyear,
                                 }}
                                 updateTimetableData={(data) => this.updateTimetableData(data, 'previous')}
                                 updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                 updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'previous')}
                                 editDeletePost={(e, post) => this.editdeletepost(e, post, 'previous')}
                              /> : ''
                           }
                           {
                              this.state.timetableData.twoMonthsEarlier?.activeDate ? <Timetable
                                 selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                 isScreenVisible={this.props.isScreenVisible}
                                 subspaceNumber={2}
                                 calendar='twoMonthsEarlier'
                                 userDetails={this.state.userDetails}
                                 timetableData={this.state.timetableData.twoMonthsEarlier}
                                 states={{
                                    events: this.state.marks,
                                    otherMinimum: this.state.minimum,
                                    otherMaximum: this.state.maximum,
                                    otherMonthYear: this.state.monthyear,
                                 }}
                                 updateTimetableData={(data) => this.updateTimetableData(data, 'twoMonthsEarlier')}
                                 updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                 updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'twoMonthsEarlier')}
                                 editDeletePost={(e, post) => this.editdeletepost(e, post, 'twoMonthsEarlier')}
                              /> : ''
                           }
                           {
                              this.state.timetableData.threeMonthsEarlier?.activeDate ? <Timetable
                                 selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                 isScreenVisible={this.props.isScreenVisible}
                                 subspaceNumber={2}
                                 calendar='threeMonthsEarlier'
                                 userDetails={this.state.userDetails}
                                 timetableData={this.state.timetableData.threeMonthsEarlier}
                                 states={{
                                    events: this.state.marks,
                                    otherMinimum: this.state.minimum,
                                    otherMaximum: this.state.maximum,
                                    otherMonthYear: this.state.monthyear,
                                 }}
                                 updateTimetableData={(data) => this.updateTimetableData(data, 'threeMonthsEarlier')}
                                 updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                 updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'threeMonthsEarlier')}
                                 editDeletePost={(e, post) => this.editdeletepost(e, post, 'threeMonthsEarlier')}
                              /> : ''
                           }
                        </div>
                     </div>
                     <div id="onelist" className="tab-pane fade">
                        <div class={this.state.evntData.length > 0 ? "loaderclass closeCdff" : "loaderclass onscroll"}><img src="images/newgif.gif" alt="loading" /></div>
                        {/* <div class={this.state.contat == "of" ? "loaderclass contantf closeCd" : "loaderclass contantf"}>No More post Available</div> */}
                        <div className={"sectionone changable" +
                           (!this.state.cbio || !this.state.cwebsite
                              ? " emptyBioWebsite"
                              : "")}
                        >
                           <div
                              id="scrollableDivTwoFourOwnProfile"
                              className={this.state.evntData.length > 0 ? this.state.loaddocument == true ? "scrollClass newscreen1 else" : "scrollClass newscreen1 else" : "scrollClass newscreen1 else"}
                              style={{

                                 overflow: 'auto',
                                 display: 'flex !important',
                                 flexDirection: 'row',
                                 // margintop: 47,
                              }}
                           >
                              {(this.state.updateTemporalInformation || !this.state.updateTemporalInformation) && 
                              <InfiniteScroll
                                 dataLength={this.state.evntData.length}
                                 // next={this.fetchEvntList.bind(this)}
                                 className={this.state.evntData.length > 0 ? "topbuttom_scroll" : ""}
                                 style={{ display: 'flex', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                 inverse={this.state.inverse}
                                 hasMore={true}
                                 onScroll={this.onscrolldocument.bind(this)}
                                 loader={
                                    <div className={`loaderclass ${this.state.inverse ? "topscrolls" : "bottomscrolls"} ${this.state.loaddocument ? "" : "closeCd"}`}>
                                       <img
                                          src="../../images/newgif.gif"
                                          alt="loading"
                                       />
                                    </div>}
                                 scrollableTarget="scrollableDivTwoFourOwnProfile"
                              >
                                 <div className="mb-2 timeline-wrapper">

                                    {this.state.evntData.map((j, i) => {
                                       return (
                                          <div className="timeline-event-box">
                                             <span
                                                id={"hashme" + j.eventID}
                                                className={j.eventID}
                                             ></span>

                                             <div id={"croxs" + j.eventID} className="postlike" onDoubleClick={this.likepost.bind(this)}>
                                                <div className="userin rmrf">
                                                   {/* <a href="javascript:void(0)">{j.profileImage != "" ? <img src={this.state.baseUrl + j.profileImage} align="images" /> : <span className="username">{this.state.static}</span>}</a> */}
                                                   <h1 className='new-heading-tex-780' dangerouslySetInnerHTML={{ __html: formatDate(getdateformat(j.postedOn, j.postedOn2, j.timezone)) }}></h1>
                                                   <h5>
                                                      {/* {j?.link ? (
                                                         <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                                            <div className='userLinkWrapper'>
                                                            <img src="../../images/UD.png"></img>
                                                            <div className="UserLinkDetails">
                                                               <p><span className='userLinkSource'>Source:</span> {j?.source}<br/></p>
                                                               <p className='userLinkMessage'>Verify the correctness of temporal information via the original source, if you plan to act on it</p>
                                                            </div>
                                                            </div>
                                                         </a>
                                                      ) : ''} */}
                                                      {j?.link ? (
                                                         <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                                            <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 2) }} />
                                                         </a>
                                                      ) : (
                                                         <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 2) }} />
                                                      )}
                                                   </h5>
                                                   {/* {(() => {
const regex = /(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
const text = j.status;
const replaced2 = text.replace(regex, value => {
   const link = getLinkElement(value)
   return `<a href=${"https://"+value} target='_blank'>${link}</a>`
});
const regex2 = /!+([a-zA-Z0-9_]+)/ig;
const replaced3 = replaced2.replace(regex2, value => `<span>${value}</span>`);
const replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, '<br>');
return(<h5 dangerouslySetInnerHTML={{__html: replaced}}>
</h5>);
})()} */}
                                                </div>
                                                <div className="post ">
                                                   {(() => {
                                                      if (j.mediaType == "video") {
                                                         return (
                                                            <div id={'Video' + j.eventID} class="video-div">
                                                               <Player
                                                                  ref={player => {
                                                                     this[`ref${i}`] = player;
                                                                  }}
                                                                  autoPlay={false}
                                                                  className="video-react-has-started"
                                                                  controls={false}
                                                                  playsInline
                                                                  disablePictureInPicture>
                                                                  <source src={this.state.baseUrl + j.eventImage} type="video/mp4" />
                                                                  <ControlBar disableDefaultControls={true} autoHide={false} className="my-class" />
                                                               </Player>
                                                               <ul class="videoulkap pb-3">
                                                                  <li><Button data-id={"player" + j.id} className={-10} onClick={() => this.changeCurrentTimeNew(-10, i)} ><img class="img1" src="../../images/Backwards.png" />
                                                                     <span>10</span></Button>
                                                                  </li>
                                                                  <li><img class="img2" src="../../images/pause.png" /></li>
                                                                  <li><Button data-id={j.id} className={10} onClick={() => this.changeCurrentTimeNew(10, i)} ><img class="img3" src="../../images/Forwards.png" />
                                                                     <span>10</span></Button>
                                                                  </li>
                                                               </ul>
                                                            </div>);
                                                      }
                                                      else if (j.mediaType == "image") {
                                                         return (
                                                            <img className="w-100 mt-1" src={this.state.baseUrl + j.eventImage} alt="image" />
                                                         );
                                                      }
                                                   }
                                                   )()}
                                                   <div className="heartitwo">
                                                      <img className="w-100" src="../../images/cklick.png" alt="image" />
                                                   </div>
                                                </div>
                                             </div>
                                             <ul className={j.mediaType == null ? "videoul mt3 status" : "videoul mt3"} >
                                                <li>
                                                   {/*{(() => {
                                                if (j.commentCounts > 0) {
                                                   return (<a className="link link2k" id={j.eventID} href="javascript:void(0)" data-toggle="tab" onClick={this.sigleProfile.bind(this)}>
                                                      <img src="../../images/comment.png" Style="height:20px;"
                                                         alt="icon" /><NumberFormat value={j.commentCounts} displayType={'text'} thousandSeparator={true} /></a>)
                                                }
                                                else {
                                                   return (<a className="link" id={j.eventID} href="javascript:void(0)" onClick={this.sigleProfile.bind(this)} data-toggle="tab" >
                                                      <img src="../../images/comment.png" Style="height:20px;" alt="icon" /></a>)
                                                }
                                             })()} */}
                                                   <a className="upbutton" id={j.eventID} href="javascript:void(0)" onClick={this.editdeletepost.bind(this)}><img className="option ml-3" src="../../images/pencil.png"
                                                      align="icon" /></a></li>
                                                {(() => {
                                                   if (j.withwho != null) {
                                                      return (<li><img src="../../images/b.png" alt="icon" />
                                                         {(() => {
                                                            if (j.withwho != null) {
                                                               return (
                                                                  j.withwho.map((k, l) => {
                                                                     return (
                                                                        <span><a id={k.user_id} href="javascript:void(0)" onClick={this.gouserprofile.bind(this)}>{k.screenName}</a><span>, </span></span>
                                                                     );
                                                                  })
                                                               )
                                                            }
                                                            else {
                                                               return (
                                                                  <a href="javascript:void(0)"></a>
                                                               )
                                                            }
                                                         }
                                                         )()}
                                                      </li>
                                                      )
                                                   }
                                                }
                                                )()}
                                                {(() => {
                                                   if (j.likedByUsers.length > 0) {
                                                      return (<li>
                                                         <img src="../../images/c.png" alt="icon" />
                                                         {(() => {
                                                            if (j.likedByUsers.length > 0) {
                                                               return (
                                                                  j.likedByUsers.map((q, t) => {
                                                                     return (<span><a id={q.user_id} href="javascript:void(0)" onClick={this.gouserprofile.bind(this)}>{q.screenName}</a><span>, </span></span>
                                                                     );
                                                                  })
                                                               )
                                                            }
                                                         }
                                                         )()}
                                                      </li>
                                                      )
                                                   }
                                                }
                                                )()}
                                                {(() => {
                                                   if (j.likedByUsers.length > 0) {
                                                      return (<li>
                                                         <img src="../../images/d.png" alt="icon" />
                                                         {(() => {
                                                            if (j.commentByUsers.length > 0) {
                                                               return (
                                                                  j.commentByUsers.map((m, index) => {
                                                                     return (
                                                                        <span><a id={m.user_id} href="javascript:void(0)" onClick={this.gouserprofile.bind(this)}>{m.screenName}</a><span>, </span></span>
                                                                     );
                                                                  })
                                                               )
                                                            }
                                                         }
                                                         )()}
                                                      </li>
                                                      )
                                                   }
                                                }
                                                )()}
                                             </ul>

                                          </div>
                                       )
                                    }
                                    )
                                    }
                                 </div>
                              </InfiniteScroll>}
                           </div>
                        </div>
                     </div>
                  </div>

                  {(localStorage.getItem('profileType') === 'consumer') ? <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 ">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <div className="user-badge">
                           <div ref={this.userBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName(this.state.activeProfile, this.userBadgeRef.current) }} />
                        </div>
                        <ul className="flex align-center justify-center nav">
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} /></div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.gotosetting.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer> : <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 calendarScreen ">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <div className="user-badge">
                           <div ref={this.userBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName(this.state.activeProfile, this.userBadgeRef.current) }} />
                        </div>
                        <ul className="flex align-center nav justify-center">
                           <li className={this.state.activeTab === 'first' ? 'active left' : ''}>
                              {
                                 this.state.tabwiseStackInfo.first.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={this.goProfile.bind(this)} ><div><NewsPaperSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                 {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                 {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer>}

                  {/* <div className="op pupup1">
      <div className="upbutton h-100"></div>
      <ul>
         <li><a className="link upbutton" onClick={this.setedit.bind(this)} ><img src="../../images/edt.png"/> Edit</a></li>
         <li><a href="javascript:void(0)" className="upbutton"><img src="../../images/dlt.png"/> Delete</a></li>
         <button className="upbtn upbutton">Cancel</button>
      </ul>
   </div> */}
                  <div className={`op editpop2 ${this.state.editDeletePopup ? 'zIndex' : ''}`}>
                     <div className="upbutton2 h-100"></div>
                     <ul>
                        <li><a className="upbutton2 link" id={this.state.eventid} onClick={this.getevent.bind(this)} ><img src="../../images/edt.png" /> Edit</a></li>
                        <li><a className="upbutton2" onClick={this.gotodelete.bind(this)}><img src="../../images/dlt.png" /> Delete</a></li>
                        <button onClick={() => this.setState({editDeletePopup: false})} className="upbtn upbutton2">Cancel</button>
                     </ul>
                  </div>
                  {this.state.openDeleteInformationPopup && (
                     <CustomPopUp
                        isOpen={this.state.openDeleteInformationPopup}
                        onClose={() => this.setState({ openDeleteInformationPopup: false })}
                        popup={{
                           heading: "Temporal information deletion",
                           message: `Are you sure you would like to delete this temporal information, ${localStorage.getItem('screenname').split(" ")[0] + '?'}`,
                           onClick: () => {
                              this.submitdelete()
                           },
                        }}
                        multipleButton={true}
                     />
                  )}
               </div>
            }

            {
               this.state.display[ORGANIZATIONS] &&

               <div className="screen1 else" id="two_3">
                  <div className="top" id="myHeader">
                     {/* header  */}
                     <section className="header1">
                        <div className="search">
                           <form className="form-inline mt-md-0">
                              <input className="form-control" id="lemoncals" spellCheck="false" type="text" onKeyUp={this.userSearch.bind(this)} placeholder={localStorage.getItem('screenname') ? "Search Timesort, " + localStorage.getItem('screenname').split(" ")[0] + "!" : "Search Timesort"} aria-
                                 label="Search" value={this.state.inputValue} onChange={(e) => this.setState({ inputValue: e.target.value })} />
                              {(() => {
                                 if (this.state.searchPerameter != "") {
                                    return (<span className="btn closeBtn" onClick={() => { this.setState({ searchPerameter: "", inputValue: "" }) }} dangerouslySetInnerHTML={{ __html: convertToTeX('\\times') }}></span>);
                                 }
                                 // else
                                 // {
                                 // return(<span className="btn"><span>\(\bullet\)</span></span>);
                                 // }
                              })()}
                           </form>
                           {/* <img className="remove" onClick={this.openpop.bind(this)} src="../../images/Filter.png"/> */}
                        </div>
                        {this.state.searchloader == false ? this.state.searchPerameter != "" && this.state.searchinguser.length <= 0 ? <div className="loaderclass notfound">
                           <p>&ndash;</p>
                        </div> : "" :
                           this.state.searchinguser.length <= 0 ?
                              <div className="loaderclass userloder">
                                 <img
                                    src="../../images/newgif.gif"
                                    alt="loading"
                                 />
                              </div> : ""

                        }
                        {/* removed mutuality search*/}
                     </section>
                  </div>
                  {/* {
     this.state.searchloader == true &&  this.state.searchPerameter != "" && this.state.searchinguser.length>0 ?
     <div className="loaderclass userlodertop">
         <img
             src="../../images/newgif.gif"
             alt="loading"
          /> 
          </div>:""
   } */}
                  {
                     this.state.searchPerameter != "" && this.state.searchinguser.length > 0 && this.state.searchloader == true ?
                        <div className="loaderclass userlodertop">
                           <img
                              src="../../images/newgif.gif"
                              alt="loading"
                           />
                        </div> : ""
                  }

                  <div className={this.state.searchPerameter == "" ? "tab-content tab-content3 searchlst kapil" : "tab-content tab-content3 searchlst kapil aftersearch"} id='scrollableDivTwoThree'>
                     {
                        (
                           () => {
                              const searchLenghth = this.filterUserin(this.state.searchinguser).length;

                              const leftSearchColumn = this.state.searchinguser.slice(0, Math.ceil(searchLenghth / 2));
                              const rightSearchColumn = this.state.searchinguser.slice(Math.ceil(searchLenghth / 2), searchLenghth);

                              return this.state.searchPerameter !== "" ? (
                                 // <div className="row py-0 my-0">{this.filterUserin(this.state.searchinguser).map((p, i) => {
                                 <div className="row py-0 my-0">
                                    <div className="col-6 leftSearchColumn" >
                                       {leftSearchColumn.map((p, i) => {
                                          return (

                                             <a id={p.user_id} onClick={this.gouserprofile.bind(this)}>
                                                <div className="userin" id={p.user_id} data-div_name="#two_3">

                                                   {/* <span class="imgg">
                                       {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage}
                                          align="images" />
                                          : <span className="multiuser2">{this.fetchmakeprofile(p.screenName)}</span>}
                                    </span> */}
                                                   <h6 dangerouslySetInnerHTML={{ __html: formatSearchResult(p.screenName) }}></h6>
                                                   {/* <p >{p.mutual} mutual</p> */}
                                                </div>
                                             </a>


                                          )

                                       })
                                       }
                                    </div>
                                    <div className="col-6 rightSearchColumn" >
                                       {rightSearchColumn.map((p, i) => {
                                          return (

                                             <a id={p.user_id} onClick={this.gouserprofile.bind(this)}>
                                                <div className="userin" id={p.user_id} data-div_name="#two_3">

                                                   {/* <span class="imgg">
                                       {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage}
                                          align="images" />
                                          : <span className="multiuser2">{this.fetchmakeprofile(p.screenName)}</span>}
                                    </span> */}
                                                   <h6 dangerouslySetInnerHTML={{ __html: formatSearchResult(p.screenName) }}></h6>
                                                   {/* <p >{p.mutual} mutual</p> */}
                                                </div>
                                             </a>


                                          )

                                       })
                                       }
                                    </div>
                                 </div>

                              ) :
                                 this.state.recomended?.length > 0 ?
                                    <div>
                                       <div className="userlist">
                                          {/* <h2 className="mt-0">Explore</h2> */}
                                          <InfiniteScroll
                                             dataLength={this.state.recomended?.length - 8}
                                             next={this.fetchMoreUserData}
                                             hasMore={this.state.hasMoreUser}
                                             loader={<div className="userlistloader"><img src="../../images/newgif.gif" alt="loading" /></div>}
                                             scrollableTarget="scrollableDivTwoThree">
                                             <div className="row mainUserList ">
                                                <div className="sortingBtnWrapper">
                                                   <div className="sortingBtn">
                                                      <a onClick={this.relevancySearch.bind(this)} href="javascript:void(0)">R</a>
                                                      <p onClick={this.relevancySearch.bind(this)} className='sortingBtnInfo'>Relevancy</p>
                                                   </div>
                                                   <div className="sortingBtn">
                                                      <a onClick={this.temporallySearch.bind(this)} href="javascript:void(0)">T</a>
                                                      <p onClick={this.temporallySearch.bind(this)} className='sortingBtnInfo'>Temporally</p>
                                                   </div>
                                                   <div className="sortingBtn">
                                                      <a onClick={this.mutualitySearch.bind(this)} href="javascript:void(0)">M</a>
                                                      <p onClick={this.mutualitySearch.bind(this)} className='sortingBtnInfo'>Mutuality</p>
                                                   </div>
                                                   <div className="sortingBtn">
                                                      <a onClick={this.activitySearch.bind(this)} href="javascript:void(0)">A</a>
                                                      <p onClick={this.activitySearch.bind(this)} className='sortingBtnInfo'>Activity</p>
                                                   </div>
                                                   <div className="sortingBtn">
                                                      <a onClick={this.alphabeticallySearch.bind(this)} href="javascript:void(0)">A</a>
                                                      <p onClick={this.alphabeticallySearch.bind(this)} className='sortingBtnInfo'>Alphabetically</p>
                                                   </div>
                                                   <div className="sortingBtn">
                                                      <a onClick={this.PseudorandomlySearch.bind(this)} href="javascript:void(0)">P</a>
                                                      <p onClick={this.PseudorandomlySearch.bind(this)} className='sortingBtnInfo'>Pseudorandomly</p>
                                                   </div>
                                                   <div className="sortingBtn">
                                                      <a onClick={this.flipSearch.bind(this)} href="javascript:void(0)">F</a>
                                                      <p onClick={this.flipSearch.bind(this)} className='sortingBtnInfo'>Flip</p>
                                                   </div>
                                                   <div className="sortingBtn">
                                                      <a onClick={this.PopularitySearch.bind(this)} href="javascript:void(0)">P</a>
                                                      <p onClick={this.PopularitySearch.bind(this)} className='sortingBtnInfo'>Popularity</p>
                                                   </div>
                                                </div>
                                                {
                                                   this.renderUserGroups()
                                                }
                                             </div>
                                          </InfiniteScroll>
                                       </div>
                                       {/* <div className="userlist">
             <h2 className="mt-0">Recommended</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=12 && i<=23)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-div_name="#two_3" onClick={this.gouserprofile.bind(this)} data-toggle="tab" href="javascript:void(0)">
                    
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className="multiuser2" >
               <div className="profile_initials">
                  {this.fetchmakeprofile(u.screenName)}
               </div>
               </span>}
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div> */}
                                       {/* {this.state.reco.length>0 ? <div className="userlist">
         <h2 className="mt-0">Top Recommended</h2>
         <div className="row ">
           { this.filterUser(this.state.reco).map((u,i)=>{
            return(
            <div className="col-3">
               <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3" data-toggle="tab" >
               <Link to={{
               pathname: `/${u.username}`,
               state: {id:u.user_id}
               }}  
               >
               {u.profileImage && u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className="multiuser">
               <div className="profile_initials">
                  {this.fetchmakeprofile(u.screenName)}
               </div>
               </span>}</Link>
                  <p>{u.screenName}</p>
               </a>
            </div>
            )
            
            })
         }
         </div>
         </div>:""} */}
                                       {/* <div className="userlist">
             <h2 className="mt-0">More</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=24 && i<=35)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3" onClick={this.gouserprofile.bind(this)} data-toggle="tab" href="javascript:void(0)">
             
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser2"+i} >
             <div className="profile_initials">
                {this.fetchmakeprofile(u.screenName)}
             </div>
             </span>}
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div>
                 <div className="userlist">
             <h2 className="mt-0">More</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=36 && i<=59)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} onClick={this.gouserprofile.bind(this)} data-toggle="tab" href="javascript:void(0)" >
                  
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser2"+i}>
             <div className="profile_initials">
                {this.fetchmakeprofile(u.screenName)}
             </div>
             </span>}
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div>          */}
                                    </div>
                                    : <div className="loaderclass">
                                       <img
                                          src="../../images/newgif.gif"
                                          alt="loading"
                                       />
                                    </div>
                           })()}
                  </div>
                  {localStorage.getItem('userid') ? (localStorage.getItem('profileType') === 'consumer') ? <footer className="ant-layout-footer">
                     <div className="footer-menu menu1">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center justify-center nav">
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.gotosetting.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer> : <footer className="ant-layout-footer">
                     <div className="footer-menu menu1  homescreenFooter">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center nav justify-center">
                           <li className={this.state.activeTab === 'first' ? 'active left' : ''}>
                              {
                                 this.state.tabwiseStackInfo.first.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={this.goProfile.bind(this)} ><div><NewsPaperSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                 {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                 {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer> : <footer className="ant-layout-footer">
                     <div className="footer-menu menu1">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center nav justify-center">
                           <li className={this.state.activeTab === 'first' ? 'active left' : ''}>
                              {
                                 this.state.tabwiseStackInfo.first.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={this.gotosetting.bind(this)} ><div><NewsPaperSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                 {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                 {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer>}

                  <div className="op pupupsecond3">
                     <div className="remove h-100" onClick={this.openpop.bind(this)}></div>
                     <ul>
                        <h5 className="text-center">sort</h5>
                        <li><a onClick={this.relevancySearchList.bind(this)} className="remove"><img src="../../images/More.png" /> relevancy</a></li>
                        <li><a onClick={this.alphabeticallySearchList.bind(this)} className="remove"><img src="../../images/az.png" /> alphabetically</a></li>
                        <button onClick={this.openpop.bind(this)} className="upbtn remove">Cancel</button>
                     </ul>
                  </div>
               </div>
            }
            {
               this.state.display[USER_PROFILE] &&

               this.state.userProfileComponents.map(comp => {
                  if (comp.state === pageState.foreground)
                     return comp.comp("front")
                  else if (comp.state === pageState.background)
                     return comp.comp("back")
               })
            }

            {/* removed code of Aboutus component id: two_about*/}
            {
               this.state.display[CHANGE_PASSWORD] &&

               <Changepasswordc
                  parentclass={'newscreen2'}
                  backid={'two_12'}
                  mainclass={'screen1 else'}
                  mainid={'twochangepassword'}
               />
            }


            {/* moved code of this section to backups/two_4_userprofile.js and replace with UserProfile component */}


            {/* removed code of Notification component id: two_5*/}


            {/* 
<!--************************ screen1 chatting *************************--> */}

            {/* removed code of Chatting component id: two_89*/}


            {/* 
<!--************************ screen1 else 7 *************************--> */}
            {/* removed code of Following component id: two_7*/}


            {/* <!--************************ screen1 else 8 *************************--> */}
            {/* removed code of Followers component id: two_8*/}


            {/* <!--************************ screen1 else 9 *************************--> */}
            {/* removed code of Mutual component id: two_9*/}


            {/* <!--************************ screen1 else 10 *************************--> */}
            {
               this.state.display[ADD_POST] &&

               <div className="screen1 else" id="two_10">
                  <div className="tab-content calendar_content pt-0">
                     <div className="add_to">
                        <h5>Upload temporal information</h5>
                        <div className="textaread textarea">
                           <textarea
                              id="input"
                              className="textarea"
                              ref={(textareaRef) => (this[`ref${8943}`] = textareaRef)}
                              value={this.state.posttext}
                              onChange={this.setPostText.bind(this)}
                           />
                           <label id="labelplaceholder" >{this.state.placetext}</label>
                        </div>
                     </div>
                     <div className="sugetionList listb">
                        <ul className="sugetionul">
                           {(() => {
                              if (this.state.suggestionsList.length > 0) {
                                 return (
                                    this.state.suggestionsList.map((c, i) => {
                                       if (this.state.tagtype == "hash") {
                                          return (<li className="sugetionli" id={c.hashtag} onClick={this.gettag.bind(this)}>{'#' + c.hashtag}</li>)
                                       }
                                       else {
                                          return (<li className="sugetionli" id={c.username} onClick={this.gettag.bind(this)}>{"@" + c.username}</li>)
                                       }
                                    })
                                 )
                              }
                           })()}
                        </ul>
                     </div>

                     <div className="sectionone calendar_test newcalendar">
                        <div className="inkap">
                           <div className="inkapil">
                              <Calendar
                                 locale="en-US" // Set locale to US English
                                 firstDayOfWeek={0}
                                 onChange={this.onCalendarPostAdd}
                                 value={this.state.valueCalendar}
                                 className="upload-calendar"
                                 tileClassName={({ date, view }) => {
                                    const dateStr = moment(date).format("YYYY-MM-DD");
                                    if (dateStr == this.state.firstDate)
                                       return "react-calendar__tile--active";
                                    if (dateStr == this.state.secondDate)
                                       return "pinkSecond";
                                 }}
                              />
                           </div>
                        </div>
                        <div className="inkap">
                           <div className="inkapil hours-minutes__block">
                              {/* <!-- <h6 className="text-center"><a href="javascript:void(0)"><img className="float-left"
                  src="../../images/arrow1.png"></a> Oct, 2020 <a href="javascript:void(0)"><img className="float-right"
                  src="../../images/arrow2.png"></a></h6> --> */}
                              <table className="table table-bordered text-center mb-0 hddak">
                                 <thead>
                                    <tr>
                                       <th>Hour</th>
                                       <th>Minute</th>
                                    </tr>
                                 </thead>
                              </table>
                              <table className="table table-bordered text-center mb-1">
                                 <tbody>
                                    <tr>
                                       <td onClick={this.getTime.bind(this)} data-div_id="12pm" id="12pm" className="12">12pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="6pm" id="6pm" className="6">6pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="12am" id="12am" className="0">12am</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="6" id="6am" className="6">6am</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="0" id="0" className="0">0</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="5" id="5" className="5">5</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTime.bind(this)} data-div_id="1pm" id="1pm" className="1">1pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="7pm" id="7pm" className="7">7pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="1pm" id="1am" className="1">1am</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="7pm" id="7am" className="7">7am</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="10" id="10" className="10">10</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="15" id="15" className="15">15</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTime.bind(this)} data-div_id="2pm" id="2pm" className="2">2pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="8pm" id="8pm" className="8">8pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="2am" id="2am" className="2">2am</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="8am" id="8am" className="8">8am</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="20m" id="20" className="20">20</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="25m" id="25" className="25">25</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTime.bind(this)} data-div_id="3pm" id="3pm" className="3">3pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="9pm" id="9pm" className="9">9pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="3am" id="3am" className="3">3am</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="9pm" id="9am" className="9">9am</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="30" id="30" className="30">30</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="35" id="35" className="35">35</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTime.bind(this)} data-div_id="4pm" id="4pm" className="4">4pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="10pm" id="10pm" className="10">10pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="4am" id="4am" className="4">4am</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="10am" id="10am" className="10">10am</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="40" id="40" className="40">40</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="35" id="45" className="45">45</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTime.bind(this)} data-div_id="5pm" id="5pm" className="5">5pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="11pm" id="11pm" className="11">11pm</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="5am" id="5am" className="5">5am</td>
                                       <td onClick={this.getTime.bind(this)} data-div_id="11am" id="11am" className="11">11am</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="50" id="50" className="50">50</td>
                                       <td onClick={this.getmin.bind(this)} data-div_id="55" id="55" className="55">55</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div className="optional optional2">
                        <div className='d-flex'>
                           <h3>Optional</h3>
                        </div>
                        <div className="row">
                           <div className="col-4 mb-2 pr-2">
                              <div className='flex-1'> <p className='col-4'>Visibility</p> <span onClick={this.setadd.bind(this)} className="col-6 mores3 box-border">{this.state.selectedtypefinal}<img src="../../images/down-facing_arrow.png" /></span></div>
                           </div>
                           <div className="col-4 mb-2 pr-2">
                              <label className="fille mb-0">
                                 <span className='box-border'>add photo or video
                                    <input type="file" id="file" onChange={this.onImageVideoChange.bind(this)} name="myfile" />
                                    <img src="../../images/newspaper.png" alt="image" />
                                 </span>
                              </label>
                           </div>
                           <div className="col-3 mb-2 text-right">
                              <label className="mb-0">
                                 <label className="checkcontainer"><input type="checkbox" id="important" onChange={this.setIsImportant.bind(this)} name="checkbox" /><span className="radiobtn text-center"></span>important</label>
                              </label>
                           </div>

                           {/*   <div className="col-6 mb-2 pl-2">
                     <a className="with_who link" onClick={this.editwithwho.bind(this)}> with who?</a>
                  </div> */}

                        </div>
                        <h6>
                           <a className="btn post mb-2 box-border" onClick={this.submitPost.bind(this)}>Upload</a>
                        </h6>
                        <div class={`col-12 mb-2 pl-2 imagesscrol ${this.state.imageVideoUrl ? '' : 'd-none'}`}>
                           {(() => {
                              if (this.state.postType == "image") {
                                 return (<div className="imagesDiv"><div className="btncrose" onClick={this.closeImage.bind(this)}><img class="closeImgae" src="../../images/closeImage.png" /></div><img id="blah2" class="w-100" src={this.state.imageVideoUrl} alt="your image" /></div>);
                              }
                              if (this.state.postType == "video") {
                                 return (<div className="imagesDiv"><div class="video-div">

                                    <Player
                                       ref={player => {
                                          this.player = player;
                                       }}
                                       autoPlay={false}
                                       className="video-react-has-started"
                                       controls={false}
                                       playsInline
                                       disablePictureInPicture
                                    >
                                       <div className="btncrose" onClick={this.closeImage.bind(this)} ><img class="closeImgae" src="../../images/closeImage.png" /></div>
                                       <source src={this.state.imageVideoUrl} type="video/mp4" />
                                       <ControlBar disableDefaultControls={true} autoHide={false} className="my-class" />
                                    </Player>


                                    <ul class="videoulkap pb-3">
                                       <li><Button onClick={this.changeCurrentTime(-10).bind(this)} ><img class="img1" src="../../images/Backwards.png" />
                                          <span>10</span></Button>
                                       </li>
                                       <li><img class="img2" src="../../images/pause.png" /></li>
                                       <li><Button onClick={this.changeCurrentTime(10).bind(this)} ><img class="img3" src="../../images/Forwards.png" />
                                          <span>10</span></Button>
                                       </li>
                                    </ul>
                                 </div> </div>)
                              }
                           })()}

                        </div>
                     </div>
                  </div>
                  {(localStorage.getItem('profileType') === 'consumer') ? <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 ">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center justify-center nav">
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} /></div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''} >
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.gotosetting.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer> : <footer className="ant-layout-footer">
                     <div className="footer-menu menu1  uploadFooter">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center nav justify-center">
                           <li className={this.state.activeTab === 'first' ? 'active left' : ''}  >
                              {
                                 this.state.tabwiseStackInfo.first.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={this.goProfile.bind(this)} ><div><NewsPaperSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                 {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                 {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'second' ? 'active' : ''} >
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''} >
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer>}
                  <div className="op pupup6">
                     <div onClick={this.cancelwhopop.bind(this)} className="mores3 h-100"></div>
                     <ul>
                        <li><a href="javascript:void(0)" className="mores3" onClick={this.onEveryone.bind(this)} ><img src="../../images/everyone.png" /> everyone</a></li>
                        <li><a href="javascript:void(0)" className="mores3" onClick={this.onOnlyme.bind(this)}><img src="../../images/onlyme.png" /> only me</a></li>
                        <button onClick={this.cancelwhopop.bind(this)} className="upbtn mores3">Cancel</button>
                     </ul>
                  </div>
                  {/* <div className="op errorpop2">
                     <div className="errorbtn error h-100"></div>
                     <ul>
                        <li>{this.state.editerror2}</li>

                        <button className="upbtn" onClick={this.addpostpop.bind(this)}>ok</button>
                     </ul>
                  </div> */}
                  {this.state.textEntryPopup && (
                     <CustomPopUp
                        isOpen={this.state.textEntryPopup}
                        onClose={() => this.setState({ textEntryPopup: false })}
                        popup={{
                           heading: "Text entry",
                           message: `Enter some text, ${localStorage.getItem('screenname').split(" ")[0]}.`,
                           onClick: () => {
                              this.setState({ textEntryPopup: false });
                           },
                        }}
                        multipleButton={false}
                     />
                  )}
                  {this.state.selectDatePopup && (
                     <CustomPopUp
                        isOpen={this.state.selectDatePopup}
                        onClose={() => this.setState({ selectDatePopup: false })}
                        popup={{
                           heading: "Date selection",
                           message: `Select the date, ${localStorage.getItem('screenname').split(" ")[0]}.`,
                           onClick: () => {
                              this.setState({ selectDatePopup: false });
                           },
                        }}
                        multipleButton={false}
                     />
                  )}
                  {this.state.isVideoUploading && (
                     <CustomPopUp
                        isOpen={this.state.isVideoUploading}
                        onClose={() => this.setState({ isVideoUploading: false })}
                        popup={{
                           heading: "Video uploading",
                           message: "Your video is uploading; it will take some time to appear on your timetable.",
                           onClick: () => {
                              this.setState({ isVideoUploading: false });
                           },
                        }}
                        multipleButton={false}
                     />
                  )}
               </div>
            }




            {/* <!--************************ screen1 else 11 *************************--> */}
            {/* removed code of WithWho component id: two_11*/}


            {/* 
<!--************************ screen1 else 12 *************************--> */}
            {
               this.state.display[SETTINGS] &&

               <div className="screen1 else" id="two_12">
                  <div className="top" id="myHeader">
                     <section className="header headernoti2 settingHeader">
                        <div className="row">
                           {/* <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li defaultStyle="margin-left: 6px;"><a className="linkback" onClick={this.gotobackprofile.bind(this)} data-toggle="tab" href="javascript:void(0)"><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div> */}
                           <div className="col-4 header_center pl-0 pr-0 text-center">
                              <h6>settings</h6>
                           </div>
                           {/* <div className="col-4 header_right pl-0">
                           <ul className="uld">
                           </ul>
                        </div> */}
                        </div>
                     </section>
                  </div>
                  <div className="setting">
                     <div className="tab-content setting_content">
                        <ul>
                           {/* <li><a target="_blank" href="/how-it-works" id="aboutusco" className="link" ><img src="images/settting_about_tartun.png" /> About Timesort </a></li> */}
                           {localStorage.getItem('token') ? (
                              <li><a href="javascript:void(0)" onClick={this.switchProfileAcount.bind(this)} id="uploadRequest" ><img src="images/Request-to-upload temporal-information.png" />{this.state.accountType == "consumer" ? "Change to uploading account" : "Change to private account"}</a></li>
                           ) : ""}
                           <li><a target="_blank" href="/tos" id="termsco" ><img src="images/setting_t_n_c.png" />Terms of Service</a></li>
                           <li><a target="_blank" href="/privacy-policy" id="privacyco" ><img src="images/setting_privacy_policy.png" /> Privacy Policy </a></li>
                           {localStorage.getItem('token') ? (
                              <li><a className="link" onClick={this.gochangepass.bind(this)}><img src="images/setting_change password.png" /> Change Password </a></li>
                           ) : ''}
                           {/* <li><a href="mailto:info@timesort.com?subject=Human Resources"><img src="images/setting_hr.png" /> Human Resources </a></li>
                           <li><a href="mailto:info@timesort.com?subject=Invite People to Timesort"><img src="images/setting_invite_people.png" /> Invite People to Timesort </a></li> */}
                           <li><a href="/claim-existing-account"><img src="images/onlyme.png" /> Claim existing account </a></li>
                           <li><a href="/media" target='_blank'><img src="images/setting_press.png" /> Press </a></li>
                           <li><a href="/business" target='_blank'><img src="images/setting_business.png" /> Business </a></li>
                           <li><a href="/assistance" target='_blank'><img src="images/setting_help.png" /> Help </a></li>
                           {/* <li><a href="mailto:info@timesort.com?subject=Request"><img src="images/setting_request.png" /> Request </a></li> */}
                           {localStorage.getItem('token') ? (
                              <li onClick={this.logpop.bind(this)}><a className="link" href="javascript:void(0)"><img src="../../images/setting_logout.png" /> Logout </a></li>
                           ) : ''}
                        </ul>
                     </div>
                  </div>
                  {(localStorage.getItem('profileType') === 'consumer') ? <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 ">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center justify-center nav">
                           {/* <li className={this.state.activeTab === 'second' ? 'active' : ''}  >
                  <a onClick={this.fetchFilterUser.bind(this)}><img src="../../images/square.png"/>
                  <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                              {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
                  </a>
                  <div className='footer-initials-group'>
                     {this.state.s1 ? <span className='initial-item'>{showInitials(this.state.s1)}</span> : ''}
                     {this.state.s2 ? <span className='initial-item initial-delimiter'>{showInitials(this.state.s2)}</span> : ''}
                     {this.state.s3 ? <span className='initial-item initial-delimiter'>{showInitials(this.state.s3)}</span> : ''}
                  </div>
                  </li>
               <li className={this.state.activeTab === 'third' ? 'active' : ''}  ><a data-toggle="tab" onClick={this.gotosetting.bind(this)} ><img src="../../images/plus.png"/>
               <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                              {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
               </a></li> */}
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} /></div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>

                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.gotosetting.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer> : <footer className="ant-layout-footer">
                     <div className="footer-menu menu1 settingFooter">
                        <div className='screenDifferentiator-container left-differentiator'>
                           <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                              <ZZSvg />
                              <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                           </div>
                        </div>
                        <ul className="flex align-center nav justify-center">
                           {/* <li className={this.state.activeTab === 'first' ? 'active left' : ''}   ><a  onClick={localStorage.getItem('token') ? this.goProfile.bind(this) : this.gotosetting.bind(this)}  ><img src="../../images/calendar.png"/> 
                           <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                              {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
                           </a></li>
               <li className={this.state.activeTab === 'second' ? 'active' : ''}  >
                  <a onClick={this.fetchFilterUser.bind(this)}><img src="../../images/square.png"/>
                  <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                              {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
                  </a>
                  <div className='footer-initials-group'>
                     {this.state.s1 ? <span className='initial-item'>{showInitials(this.state.s1)}</span> : ''}
                     {this.state.s2 ? <span className='initial-item initial-delimiter'>{showInitials(this.state.s2)}</span> : ''}
                     {this.state.s3 ? <span className='initial-item initial-delimiter'>{showInitials(this.state.s3)}</span> : ''}
                  </div>
                  </li>
               <li className={this.state.activeTab === 'third' ? 'active' : ''}  ><a data-toggle="tab" onClick={this.goToPost.bind(this)} ><img src="../../images/plus.png"/>
               <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                              {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                              <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                           </sub>
               </a></li> */}


                           <li className={this.state.activeTab === 'first' ? 'active left' : ''}>
                              {
                                 this.state.tabwiseStackInfo.first.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} onClick={localStorage.getItem('token') ? this.goProfile.bind(this) : this.gotosetting.bind(this)}  ><div><NewsPaperSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                 {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                 {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.second.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }
                              <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 <p className='destinationInfo'>{this.state.destinationInfo}</p>
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                 {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                 {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                              </div>
                           </li>
                           <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                              {
                                 this.state.tabwiseStackInfo.third.lengthInRoman &&
                                 <div className='subspace-stack-indicator'>
                                    <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                    <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                       <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                    </div>
                                 </div>
                              }

                              <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                 <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                    {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                    <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                 </sub>
                              </div>
                                 {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                              </button>
                              <div className='footer-initials-group'>
                                 {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                 {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                 {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}                           </div>
                           </li>
                        </ul>

                        {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                        {this.state.screenWidth > 2149 ?
                           <div className='screenDifferentiator-container right-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                              </div>
                           </div> : ''}
                     </div>
                  </footer>}
                  {/* <div className="op errorpop22 mainpop">
                     <div className="errorbtn2 error h-100" onClick={this.abortLogout.bind(this)}></div>
                     <ul>
                        <li className="lipost">Logout</li>
                           <li><p>{localStorage.getItem('screenname') && localStorage.getItem('screenname') !== null ? "Are you sure you would like to logout, " + localStorage.getItem('screenname').split(" ")[0] + "?" : ""}</p></li>
                        <div className="newpop2">
                           <button className="upbtn errorbtn2" onClick={this.abortLogout.bind(this)}>CANCEL</button>
                           <button className="upbtn errorbtn2" onClick={this.handleChangeLogout.bind(this)}>YES</button>
                        </div>
                     </ul>
                  </div> */}
                  {this.state.logoutPopup && (
                     <CustomPopUp
                        isOpen={this.state.logoutPopup}
                        onClose={() => this.abortLogout()}
                        popup={{
                           heading: "Logout",
                           message: `Are you sure you would like to logout, ${localStorage.getItem('screenname').split(" ")[0]}?`,
                           onClick: () => {
                              this.handleChangeLogout();
                           },
                        }}
                        multipleButton={true}
                     />
                  )}
                     {this.state.switchAccountPopup && (
                        <CustomPopUp
                           isOpen={this.state.switchAccountPopup}
                           onClose={() => this.setState({ switchAccountPopup: false })}
                           popup={{
                              heading: "Switch Account failure",
                              message: "Request to Switch account failed",
                              onClick: () => {
                                 this.setState({ switchAccountPopup: false });
                              },
                           }}
                           multipleButton={false}
                        />
                     )}
               </div>
            }

            {/* ************************* chat users ************************* */}

            {/* removed code of chat users component id: twotest_71*/}


            {/* <!--************************ screen1 else 13 *************************--> */}



            {/* <!--************************ screen1 else 14 *************************--> */}
            {/* removed code of unknown component id: two_14*/}


            {/* <!--************************ screen1 else 15 *************************--> */}




            {/* <!--************************ screen1 else 16 *************************--> */}
            {
               this.state.display[EDIT_POST] &&

               <div className="screen1 else" id="two_16">
                  <div className="top" id="myHeader">
                     <section className="header headeredit">
                        <div className="row">
                           <div className="col-4 header_left pr-0">
                              <ul className="nav nav-tabs">
                                 <li onClick={this.hitgoback.bind(this)}>
                                    <a className="linkback" data-toggle="tab" ><RevisitSvg /></a>
                                    <p class="hoverIconInfo">Revisit</p>
                                 </li>
                              </ul>
                           </div>
                           <div className="header_center pl-0 pr-0 text-center">
                              <h6>edit temporal information</h6>
                           </div>
                           {/* <div className="col-4 header_right pl-0">
            </div> */}
                        </div>
                     </section>
                  </div>
                  <div className="tab-content  pt-0">
                     <div className="sectionone calendar_edit">
                        <div className="inkap">
                           <div className="inkapil">
                              {/* <!-- <h6 className="text-center"><a href="javascript:void(0)"><img className="float-left"
                  src="../../images/arrow1.png"/></a> Oct, 2020 <a href="javascript:void(0)"><img className="float-right"
                  src="../../images/arrow2.png"/></a></h6> --> */}
                              <Calendar
                                 locale="en-US" // Set locale to US English
                                 firstDayOfWeek={0}
                                 onChange={this.onCalendarPostEdit}
                                 className="edit-calendar"
                                 value={this.state.selectedDate}
                                 tileClassName={({ date, view }) => {
                                    const dateStr = moment(date).format("YYYY-MM-DD")

                                    if (dateStr == this.state.firstDate)
                                       return 'react-calendar__tile--active'
                                    else if (dateStr == this.state.secondDate)
                                       return 'pinkSecond'

                                 }}
                              />
                           </div>
                        </div>
                        <div className="inkap">
                           <div className="inkapil hours-minutes__block">
                              {/* <!-- <h6 className="text-center"><a href="javascript:void(0)"><img className="float-left"
                  src="../../images/arrow1.png"></a> Oct, 2020 <a href="javascript:void(0)"><img className="float-right"
                  src="../../images/arrow2.png"></a></h6> --> */}
                              <table className="table table-bordered text-center mb-0 hddak">
                                 <thead>
                                    <tr>
                                       <th>Hour</th>
                                       <th>Minute</th>
                                    </tr>
                                 </thead>
                              </table>
                              <table className="table table-bordered text-center mb-1">
                                 <tbody>
                                    <tr>
                                       <td onClick={this.getTimeEdit.bind(this)} id="12pm" className="12">12pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="6pm" className="6">6pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="12am" className="0">12am</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="6am" className="6">6am</td>
                                       <td onClick={this.getminEdit.bind(this)} id="0" className="0">0</td>
                                       <td onClick={this.getminEdit.bind(this)} id="5" className="5">5</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTimeEdit.bind(this)} id="1pm" className="1">1pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="7pm" className="7">7pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="1am" className="1">1am</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="7am" className="7">7am</td>
                                       <td onClick={this.getminEdit.bind(this)} id="10" className="10">10</td>
                                       <td onClick={this.getminEdit.bind(this)} id="15" className="15">15</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTimeEdit.bind(this)} id="2pm" className="2">2pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="8pm" className="8">8pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="2am" className="2">2am</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="8am" className="8">8am</td>
                                       <td onClick={this.getminEdit.bind(this)} id="20" className="20">20</td>
                                       <td onClick={this.getminEdit.bind(this)} id="25" className="25">25</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTimeEdit.bind(this)} id="3pm" className="3">3pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="9pm" className="9">9pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="3am" className="3">3am</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="9am" className="9">9am</td>
                                       <td onClick={this.getminEdit.bind(this)} id="30" className="30">30</td>
                                       <td onClick={this.getminEdit.bind(this)} id="35" className="35">35</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTimeEdit.bind(this)} id="4pm" className="4">4pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="10pm" className="10">10pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="4am" className="4">4am</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="10am" className="10">10am</td>
                                       <td onClick={this.getminEdit.bind(this)} id="40" className="40">40</td>
                                       <td onClick={this.getminEdit.bind(this)} id="45" className="45">45</td>
                                    </tr>
                                    <tr>
                                       <td onClick={this.getTimeEdit.bind(this)} id="5pm" className="5">5pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="11pm" className="11">11pm</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="5am" className="5">5am</td>
                                       <td onClick={this.getTimeEdit.bind(this)} id="11am" className="11">11am</td>
                                       <td onClick={this.getminEdit.bind(this)} id="50" className="50">50</td>
                                       <td onClick={this.getminEdit.bind(this)} id="55" className="55">55</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div className="optional optional2">
                        <div className='d-flex'>
                           <h3>Optional</h3>
                        </div>
                        <div className="row">
                           <div className="col-4 mb-2 pr-2">
                              <div className='flex-1'><p className='col-4'>Visibility </p> <span onClick={this.edit1.bind(this)} className="col-6 mores3 box-border">{this.checkcondition(this.state.editwhocansee)}<img src="../../images/down-facing_arrow.png" /></span></div>
                           </div>
                           <div className="col-4 mb-2 pr-2">
                              <label className="fille mb-0">
                                 <span className='box-border'>Browse
                                    <input type='file' onChange={this.editPostImage.bind(this)} />
                                    <img src="../../images/newspaper.png" />
                                 </span>
                              </label>
                           </div>
                           <div className="col-3 mb-2 text-right">
                              <label className="mb-0">
                                 <label className="checkcontainer"><input type="checkbox" id="editImportant" onChange={this.setEditIsImportant.bind(this)} name="checkbox" checked={this.state.editIsImportant == 1 ? true : false} /><span className="radiobtn text-center"></span>important</label>
                              </label>
                           </div>
                        </div>
                        <h6>
                           <a className="btn post mb-2 box-border" onClick={this.editPost.bind(this)}>Update</a>
                        </h6>
                        {/* <div className="col-4 ">
               <a className="with_who link" onClick={this.editPost.bind(this)}> update</a>
            </div> */}
                        {/*  <div className="row browser browser2">
           
            <div className="col-4">
               <a className="with_who link" onClick={this.editwithwho.bind(this)}  > with who?</a>
            </div>
           
         </div> */}
                        <div className="col-12 mb-2 pl-2">

                           {
                              this.state.imageVideoUrl2 != "" ? this.state.postType2 == "image" ?
                                 this.state.imageVideoUrl2 ? <div className="imagesDiv"><div className="btncrose" onClick={this.closeImage.bind(this)}><img class="closeImgae" src="../../images/closeImage.png" /></div><img id="blah2" class="w-100" src={this.state.imageVideoUrl2} alt="your image" /></div> : "" :
                                 this.state.imageVideoUrl2 ?
                                    <div className="imagesDiv"><div class="video-div">

                                       <Player
                                          ref={player => {
                                             this.player = player;
                                          }}
                                          autoPlay={false}
                                          className="video-react-has-started"
                                          controls={false}
                                          playsInline
                                          disablePictureInPicture
                                       >
                                          <div className="btncrose" onClick={this.closeImage.bind(this)} ><img class="closeImgae" src="../../images/closeImage.png" /></div>
                                          <source src={this.state.imageVideoUrl2} type="video/mp4" />
                                          <ControlBar disableDefaultControls={true} autoHide={false} className="my-class" />
                                       </Player>


                                       <ul class="videoulkap pb-3">
                                          <li><Button onClick={this.changeCurrentTime(-10).bind(this)} ><img class="img1" src="../../images/Backwards.png" />
                                             <span>10</span></Button>
                                          </li>
                                          <li><img class="img2" src="../../images/pause.png" /></li>
                                          <li><Button onClick={this.changeCurrentTime(10).bind(this)} ><img class="img3" src="../../images/Forwards.png" />
                                             <span>10</span></Button>
                                          </li>
                                       </ul>
                                    </div> </div> : ""

                                 : this.state.editmediaType == "image" ? this.state.editeventImage ? <div className="imagesDiv"><div className="btncrose" onClick={this.closeImage.bind(this)}><img class="closeImgae" src="../../images/closeImage.png" /></div><img id="blah2" class="w-100" src={this.state.baseUrl + this.state.editeventImage} alt="your image" /></div>
                                    : "" : this.state.editeventImage ? <div className="imagesDiv"><div class="video-div">

                                       <Player
                                          ref={player => {
                                             this.player = player;
                                          }}
                                          autoPlay={false}
                                          className="video-react-has-started"
                                          controls={false}
                                          playsInline
                                          disablePictureInPicture
                                       >
                                          <div className="btncrose" onClick={this.closeImage.bind(this)} ><img class="closeImgae" src="../../images/closeImage.png" /></div>
                                          <source src={this.state.baseUrl + this.state.editeventImage} type="video/mp4" />
                                          <ControlBar disableDefaultControls={true} autoHide={false} className="my-class" />
                                       </Player>
                                       <ul class="videoulkap pb-3">
                                          <li><Button onClick={this.changeCurrentTime(-10).bind(this)} ><img class="img1" src="../../images/Backwards.png" />
                                             <span>10</span></Button>
                                          </li>
                                          <li><img class="img2" src="../../images/pause.png" /></li>
                                          <li><Button onClick={this.changeCurrentTime(10).bind(this)} ><img class="img3" src="../../images/Forwards.png" />
                                             <span>10</span></Button>
                                          </li>
                                       </ul>
                                    </div> </div>
                                    : ""}
                        </div>
                     </div>
                  </div>
                  {(localStorage.getItem('profileType') === 'consumer') ?
                     <footer className="ant-layout-footer">
                        <div className="footer-menu menu1 ">
                           <div className='screenDifferentiator-container left-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                              </div>
                           </div>
                           <ul className="flex align-center justify-center nav">
                              <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                                 {
                                    this.state.tabwiseStackInfo.second.lengthInRoman &&
                                    <div className='subspace-stack-indicator'>
                                       <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                       <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                          <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                       </div>
                                    </div>
                                 }
                                 <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                    <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                       {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                       <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                    </sub>
                                 </div>
                                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                    <p className='destinationInfo'>{this.state.destinationInfo}</p>
                                 </button>
                                 <div className='footer-initials-group'>
                                    {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                    {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                    {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                                 </div>
                              </li>
                              <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                                 {
                                    this.state.tabwiseStackInfo.third.lengthInRoman &&
                                    <div className='subspace-stack-indicator'>
                                       <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                       <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                          <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                       </div>
                                    </div>
                                 }

                                 <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.gotosetting.bind(this)} ><div><HandShakeSvg width={43} />
                                    <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                       {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                       <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                    </sub>
                                 </div>
                                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 </button>
                                 <div className='footer-initials-group'>
                                    {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                    {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                    {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}
                                 </div>
                              </li>
                           </ul>

                           {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                           {this.state.screenWidth > 2149 ?
                              <div className='screenDifferentiator-container right-differentiator'>
                                 <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                    <ZZSvg />
                                    <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                                 </div>
                              </div> : ''}
                        </div>

                     </footer> :

                     <footer className="ant-layout-footer">
                        <div className="footer-menu menu1 ">
                           <div className='screenDifferentiator-container left-differentiator'>
                              <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                                 <ZZSvg />
                                 <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                              </div>
                           </div>
                           <ul className="flex align-center nav justify-center">
                              <li className={this.state.activeTab === 'first' ? 'active left' : ''} >
                                 {
                                    this.state.tabwiseStackInfo.first.lengthInRoman &&
                                    <div className='subspace-stack-indicator'>
                                       <span onClick={(event) => handleGrammarContainer(event, 2, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                                       <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                          <div className="grammar-container">{getGrammarContainer("ii", "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                                       </div>
                                    </div>
                                 }

                                 <button className={`bottomMenuBtn`} onClick={this.goProfile.bind(this)} ><div><NewsPaperSvg width={23} />
                                    <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                       {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                                       <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                    </sub>
                                 </div>
                                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 </button>
                                 <div className='footer-initials-group'>
                                    {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                                    {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                                    {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                                 </div>
                              </li>
                              <li className={this.state.activeTab === 'second' ? 'active' : ''}>
                                 {
                                    this.state.tabwiseStackInfo.second.lengthInRoman &&
                                    <div className='subspace-stack-indicator'>
                                       <span onClick={(event) => handleGrammarContainer(event, 2, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                                       <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                          <div className="grammar-container">{getGrammarContainer("ii", "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                                       </div>
                                    </div>
                                 }
                                 <button className={`bottomMenuBtn`} onClick={this.fetchFilterUser.bind(this)}><div><CalendarSvg width={23} />
                                    <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                       {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                                       <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                    </sub>
                                 </div>
                                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                    <p className='destinationInfo'>{this.state.destinationInfo}</p>
                                 </button>
                                 <div className='footer-initials-group'>
                                    {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                                    {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                                    {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}
                                 </div>
                              </li>
                              <li className={this.state.activeTab === 'third' ? 'active' : ''}>
                                 {
                                    this.state.tabwiseStackInfo.third.lengthInRoman &&
                                    <div className='subspace-stack-indicator'>
                                       <span onClick={(event) => handleGrammarContainer(event, 2, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                                       <div onClick={(event) => removeGrammarContainer(event)} className='grammar-container-cover'>
                                          <div className="grammar-container">{getGrammarContainer("ii", "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                                       </div>
                                    </div>
                                 }

                                 <button className={`bottomMenuBtn`} data-toggle="tab" onClick={this.goToPost.bind(this)} ><div><HandShakeSvg width={43} />
                                    <sub onMouseOver={(e) => handleTooltipContainer(e, 2)}>
                                       {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                                       <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                                    </sub>
                                 </div>
                                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                                 </button>
                                 <div className='footer-initials-group'>
                                    {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                                    {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                                    {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}
                                 </div>
                              </li>
                           </ul>

                           {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer (event,2)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                        <div class="grammar-container">
                           <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                           <div class="grammar-table">
                              <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>ii</span></span>)</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                              <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                           </div>
                        </div></div> : ''} */}

                           {this.state.screenWidth > 2149 ?
                              <div className='screenDifferentiator-container right-differentiator'>
                                 <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                                    <ZZSvg />
                                    <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                                 </div>
                              </div> : ''}
                        </div>
                     </footer>}
                  <div className="op pupup6">
                     <div onClick={this.cancelwhopop.bind(this)} className="mores3 h-100"></div>
                     <ul>
                        <li><a href="javascript:void(0)" className="mores3" onClick={this.onEveryoneEdit.bind(this)} ><img src="../../images/everyone.png" /> everyone</a></li>
                        <li><a href="javascript:void(0)" className="mores3" onClick={this.onOnlymeEdit.bind(this)}><img src="../../images/onlyme.png" /> only me</a></li>
                        <button onClick={this.cancelwhopop.bind(this)} className="mores3 upbtn">Cancel</button>
                     </ul>
                  </div>
                  {this.state.isVideoUploading && (
                     <CustomPopUp
                        isOpen={this.state.isVideoUploading}
                        onClose={() => this.setState({ isVideoUploading: false })}
                        popup={{
                           heading: "Video uploading",
                           message: "Your video is uploading; it will take some time to appear on your timetable.",
                           onClick: () => {
                              this.setState({ isVideoUploading: false });
                           },
                        }}
                        multipleButton={false}
                     />
                  )}
               </div>
            }




            {/* <!--************************ screen1 else 17 *************************--> */}
            {/* removed code of EditProfile component id: two_17*/}


            {/* <!--************************ screen1 else 18  *************************--> */}
            {/* removed code of Report component id: two_18*/}


            {/* <!--************************ screen1 else 19 *************************--> */}
            {/* removed code of All Followers except component id: two_19*/}


            {/* 
<!--************************ screen1 else 20 *************************--> */}
            {/* removed code of only these followers component id: two_20*/}


            {/* 
<!--************************ screen1 else 24 *************************--> */}

            {/* 
<!--************************ screen1 else 52 *************************--> */}

            {/* removed code of Selected Image component id: two_52*/}

            {/* 
<!--************************ following *************************--> */}
            {/* removed code of My Following component id: twotest_7*/}


            {/* <!--************************ followers *************************--> */}
            {/* removed code of My Followers component id: twotest_8*/}


            {/*
<!--************************ screen1 else 15 *************************--> */}

            {/* removed code of SinglePage component id: two_15*/}

            {/* 
<!--************************ screen1 else 22 *************************--> */}

            {/* removed code of CommentOn component id: two_22*/}

            {/* 
<!--************************ screen1 else 13 *************************--> */}

            {/* removed code of tag component id: two_13*/}

            {/* 
<!--************************ screen1 else 80 *************************--> */}

            {/* removed code of likes component id: two_80*/}

            {/* 
<!--************************ screen1 else 118 *************************--> */}

            {/* removed code of report component id: two_118*/}
         </div>

      );
   };
};


export default Screen2;


import React, { Component } from 'react';
import $ from 'jquery';
import axios from "axios";
import { Link  } from "react-router-dom";
import { ReactComponent as RevisitSvg } from '../../utils/svg/Revisit.svg';


class Changepasswordc extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
      baseUrl:process.env.REACT_APP_BASE_URL,
      }
   }


changepassword(){
	if(this.state.newpassword==this.state.conformpassword)
	{
      const body={current_password:this.state.currentpassword,
      new_password:this.state.newpassword,
      confirm_password:this.state.conformpassword}
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl+'/api/user/changepassword.php',body, {
         headers: {
        Accept: "application/json",
       "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
       },
        })
        .then((res) => {
   		if(res.data.message=="Password has been changed successfully")
   		{
   			alert("Password has been changed successfully");
   			this.setState({newpassword:"",conformpassword:"",currentpassword:""});
   		}
   		else
   		{
   			alert("Current Password Not Matched");
   		}
 		})
    .catch((error) => {
      this.setState({
        error: error.message
      });
      alert('Request failure');
   }) 
}
else
{
	alert("Password Mismatch");
}
}
getcurrent=(e)=>{
	this.setState({currentpassword:e.target.value});
}
getnew=(e)=>{
	this.setState({newpassword:e.target.value});
}
getconform=(e)=>{
	this.setState({conformpassword:e.target.value});
}
gochangeback()
{
   $('.'+this.props.parentclass+' .screen1').removeClass('fades');
   var dd = $('.'+this.props.parentclass+' .screen1.active').attr('id');
   $('.'+this.props.parentclass+' #'+dd).addClass('screen1 aactive');
   $('#'+this.props.backid).addClass('active fadesback nabc');
}

render()
{
    return(<>
 <div className={this.props.mainclass}  id={this.props.mainid}>
    <div className="top newbg" id="myHeader">
       {/* <!-- --------- header ---------------> */}
       <section className="header userlogin">
          <div className="row">
             <div className="col-2 header_left pr-0">
                <ul className="nav nav-tabs">
                   <li onClick={this.gochangeback.bind(this)}>
                     <a className="linkback" data-toggle="tab">
                        <RevisitSvg />
                     </a>
                     <p onClick={this.gochangeback.bind(this)} className="hoverIconInfo" >Revisit</p>
                   </li>
                </ul>
             </div>
             <div className="col-8 header_center pl-0 pr-0 text-center">
                <h6>change password</h6>
             </div>
             <div className="col-2 header_right pl-0">
             </div>
          </div>
       </section>
    </div>
    <form className="form-signin login changepassword">
       <div className="form-group mb-0">
          <input type="password" name="currentpassword" onChange={this.getcurrent.bind(this)}  className="form-control" placeholder="current password" required=""/>
       </div>
       <div className="form-group mb-0">
          <input type="password"  name="newpassword" onChange={this.getnew.bind(this)}   className="form-control" placeholder="new password" required=""/>
       </div>
       <div className="form-group mb-0">
          <input type="password" name="conformpassword" onChange={this.getconform.bind(this)}  className="form-control" placeholder="confirm new password" required=""/>
       </div>
       <button className="btn btn-lg btn-block" onClick={this.changepassword.bind(this)} type="button">change password</button>
    </form>
 </div></>)
}}
export default Changepasswordc;
import moment from "moment";

/**
 * Given a date and an event list, return an array of events that occurred within the same month as the given date.
 * @param {Date} date The date to compare with.
 * @param {Array} eventList The list of events.
 * @returns {Array} An array of events that occurred in the same month as the given date.
 */
export const getActiveDatesOfTheMonth = (date, eventList) => {
    const monthYear1 = moment(date).format('MMMM YYYY');
    let datesOfThisMonth = [];
    if (eventList) {
        eventList?.forEach((item, index) => {
            const formattedDate = moment(item?.eventOn, "DD-MM-YYYY").format("MMMM YYYY");
            if (monthYear1 === formattedDate) {
                datesOfThisMonth.push(item)
            }
            else datesOfThisMonth = datesOfThisMonth;
        })

        // sort the array
        datesOfThisMonth.sort((a, b) => {
            return moment(a.eventOn, "DD-MM-YYYY").toDate() - moment(b.eventOn, "DD-MM-YYYY").toDate();
        });

        // add the tile type alternately
        if (datesOfThisMonth?.length) {
            // If exactly 4 events, start with "darkblue"; otherwise, start with "highlight"
            const useDarkBlueFirst = [1, 2, 4].includes(datesOfThisMonth.length);
            datesOfThisMonth.forEach((item, index) => {
                item.tile_type = index % 2 === 0
                    ? (useDarkBlueFirst ? "darkblue" : "highlight")
                    : (useDarkBlueFirst ? "highlight" : "darkblue");
            });
        }       
    }
    return datesOfThisMonth;
}

/**
 * Given an array of events, return an array of events where each event's date is unique.
 * If there are multiple events on the same date, only the first event is included in the output.
 * @param {Array} events The array of events.
 * @returns {Array} An array of events with unique dates.
 */
export const getUniqueDatesOfTheMonth = (events) => {
    const uniqueEvents = [];
    const seenDates = new Set();

    events.forEach((event) => {
        const eventDate = event.eventOn;
        if (!seenDates.has(eventDate)) {
            seenDates.add(eventDate);
            uniqueEvents.push(event);
        }
    });

    return uniqueEvents;
};

/**
 * Return the active date of the month based on important events.
 * The logic is to choose the date which has more important events.
 * If there's a tie, the first date is chosen.
 * 
 * @param {Date} date - the date to check
 * @param {object[]} availableEvents - list of events on the date
 * @returns {object} - the active date object
 */
export const getActiveDateBasedOnImportantEvent = (date, availableEvents) => {
    if (!date) return undefined;

    if (!availableEvents?.length) return undefined;

    const impCount = availableEvents.reduce((count, el) =>
        count + (el.importantEvents ? 1 : 0), 0);

    const hasMoreImportantEvents = impCount > (availableEvents.length - impCount);
    return availableEvents.find(el =>
        el.importantEvents === (hasMoreImportantEvents ? 1 : 0));
};

export const determineDateToBeSelectForMonths = (date, availableEvents, allEvents = []) => {
    if (!date) return undefined;

    const currentDate = moment();
    const givenDate = moment(date);
    let activeDate = date;

    if (givenDate.isSame(currentDate, 'month')) {
        const today = currentDate.startOf('day');
        let futureEvent = null;
        let pastEvent = null;

        let closestFutureDiff = Infinity;
        let closestPastDiff = Infinity;

        const findClosestEvent = (events) => {
            events.forEach((event) => {
                const eventDate = moment(event.eventOn, "DD-MM-YYYY").startOf('day');
                const diffToToday = eventDate.diff(today, 'days'); // Difference in days

                if (diffToToday >= 0 && diffToToday < closestFutureDiff) {
                    closestFutureDiff = diffToToday;
                    futureEvent = event;
                }

                if (diffToToday < 0 && Math.abs(diffToToday) < closestPastDiff) {
                    closestPastDiff = Math.abs(diffToToday);
                    pastEvent = event;
                }
            });
        };

        // Check events for the current month first
        findClosestEvent(availableEvents);

        // If no future event in current month, check allEvents
        if (!futureEvent && allEvents?.length) {
            findClosestEvent(allEvents);
        }

        activeDate = futureEvent
            ? moment(futureEvent.eventOn, "DD-MM-YYYY").toDate()
            : pastEvent
                ? moment(pastEvent.eventOn, "DD-MM-YYYY").toDate()
                : null;
    } else if (givenDate.isBefore(currentDate, 'month')) {
        let eventToBeSelect = null;
        let highlightedTiles = availableEvents.filter(event => event.tile_type === "highlight")?.length;
        let darkblueTiles = availableEvents.length - highlightedTiles;

        if (highlightedTiles > darkblueTiles) {
            eventToBeSelect = availableEvents.find(event => event.tile_type === "highlight");
        } else if (highlightedTiles < darkblueTiles) {
            eventToBeSelect = availableEvents.find(event => event.tile_type === "darkblue");
        } else {
            const highlightEvents = availableEvents.filter(event => event.tile_type === "highlight");
            eventToBeSelect = highlightEvents[Math.floor(Math.random() * highlightEvents.length)];
        }

        activeDate = moment(eventToBeSelect?.eventOn, "DD-MM-YYYY").toDate();
    } else if (givenDate.isAfter(currentDate, 'month')) {
        const firstEventOfMonth = availableEvents.sort((a, b) => {
            const dateA = moment(a.eventOn, "DD-MM-YYYY");
            const dateB = moment(b.eventOn, "DD-MM-YYYY");
            return dateA - dateB;
        })[0];
        activeDate = moment(firstEventOfMonth?.eventOn, "DD-MM-YYYY").toDate();
    }
    return activeDate
}

export const determineActiveDateForCurrentMonth = (date, availableEvents, allEvents = []) => {
    if (!date) return undefined;

    const currentDate = moment();
    const givenDate = moment(date);

    // If the given date is not in the current month, return it unchanged.
    if (!givenDate.isSame(currentDate, 'month')) {
        return date;
    }

    const today = currentDate.clone().startOf('day');
    let futureEvent = null;
    let pastEvent = null;
    let closestFutureDiff = Infinity;
    let closestPastDiff = Infinity;

    const findClosestEvent = (events) => {
        events.forEach((event) => {
            const eventDate = moment(event.eventOn, "DD-MM-YYYY").startOf('day');
            const diffToToday = eventDate.diff(today, 'days');

            if (diffToToday >= 0 && diffToToday < closestFutureDiff) {
                closestFutureDiff = diffToToday;
                futureEvent = event;
            }

            if (diffToToday < 0 && Math.abs(diffToToday) < closestPastDiff) {
                closestPastDiff = Math.abs(diffToToday);
                pastEvent = event;
            }
        });
    };

    // Check events for the current month
    findClosestEvent(availableEvents);

    // If no future event is found in availableEvents, then check allEvents
    if (!futureEvent && allEvents?.length) {
        findClosestEvent(allEvents);
    }

    // Determine active date: prefer a future event if available, else a past event, or default to the provided date.
    const activeDate = futureEvent
        ? moment(futureEvent.eventOn, "DD-MM-YYYY").toDate()
        : pastEvent
            ? moment(pastEvent.eventOn, "DD-MM-YYYY").toDate()
            : date;

    return activeDate;
};  

export const handleSubCalendarData = (events) => {
    const sortedEvents = events.sort(
        (a, b) =>
            moment(a.eventOn, "DD-MM-YYYY").toDate() -
            moment(b.eventOn, "DD-MM-YYYY").toDate()
    );

    const totalEvents = sortedEvents.length;
    if (totalEvents < 5 || totalEvents > 31) return {};

    const distributionMap = {
        5: [0, 1],
        6: [0, 2],
        7: [0, 3],
        8: [0, 4],
        9: [0, 2, 5],
        10: [0, 3, 6],
        11: [0, 3, 7],
        12: [0, 4, 8],
        13: [0, 3, 6, 9],
        14: [0, 3, 6, 10],
        15: [0, 3, 7, 11],
        16: [0, 4, 8, 12],
        17: [0, 3, 6, 9, 13],
        18: [0, 3, 6, 10, 14],
        19: [0, 3, 7, 11, 15],
        20: [0, 4, 8, 12, 16],
        21: [0, 3, 6, 9, 13, 17],
        22: [0, 3, 6, 10, 14, 18],
        23: [0, 3, 7, 11, 15, 19],
        24: [0, 4, 8, 12, 16, 20],
        25: [0, 3, 6, 9, 13, 17, 21],
        26: [0, 3, 6, 10, 14, 18, 22],
        27: [0, 3, 7, 11, 15, 19, 23],
        28: [0, 4, 8, 12, 16, 20, 24],
        29: [0, 3, 6, 9, 13, 17, 21, 25],
        30: [0, 3, 6, 10, 14, 18, 22, 26],
        31: [0, 3, 7, 11, 15, 19, 23, 27]
    };

    const distribution = distributionMap[totalEvents];
    let subCalendars = {};

    const createSubCalendar = (eventsSubset, subCalendarId) => {
        const date = moment(eventsSubset[0]?.eventOn, "DD-MM-YYYY").toDate();
        const copiedEvents = eventsSubset.map(event => ({ ...event }));

        // Apply alternating tile types
        if (copiedEvents?.length) {
            const useDarkBlueFirst = copiedEvents.length === 4;
            copiedEvents.forEach((item, index) => {
                item.tile_type = index % 2 === 0
                    ? (useDarkBlueFirst ? "darkblue" : "highlight")
                    : (useDarkBlueFirst ? "highlight" : "darkblue");
            });
        }

        subCalendars[subCalendarId] = {
            activeDate: date,
            availableEventsOnDates: copiedEvents,
            isVisible: false,
            isActive: false,
            appendedTiles: { data: [], appendedMonth: null },
            savePost: [],
            profileData: [],
            minDate: undefined,
            maxDate: undefined,
        };
    };

    distribution.forEach((startIndex, idx) => {
        const subCalendarEvents = sortedEvents.slice(startIndex, startIndex + 4);
        createSubCalendar(subCalendarEvents, `subCalendar${idx + 1}`);
    });

    return subCalendars;
};

// function to clear the existing timetable data
export const unsetTimetableData = (timetableData) => {
    if (timetableData) {
        timetableData.activeDate = undefined;
        timetableData.availableEventsOnDates = [];
        timetableData.subTimetables = undefined;
        timetableData.minDate = undefined;
        timetableData.maxDate = undefined;
        timetableData.nextDate = undefined;
        timetableData.prevDate = undefined;
        timetableData.activeDateTileColorUpdationFlag = false;
        timetableData.activeDateUpdationFlag = false;
    }

    return timetableData;
}

// function to shift the timetable data structure forward by one month 
export const shiftMonthsForward = (timetableData) => {
    const updatedTimetableData = { ...timetableData };

    updatedTimetableData.threeMonthsEarlier = { ...timetableData.twoMonthsEarlier };
    updatedTimetableData.twoMonthsEarlier = { ...timetableData.previous };
    updatedTimetableData.previous = { ...timetableData.current };
    updatedTimetableData.current = { ...timetableData.next };
    updatedTimetableData.next = { ...timetableData.twoMonthsAhead };
    updatedTimetableData.twoMonthsAhead = { ...timetableData.threeMonthsAhead };
    updatedTimetableData.threeMonthsAhead = { ...unsetTimetableData(updatedTimetableData.threeMonthsAhead) }

    return updatedTimetableData;
};

// function to shifts the timetable data structure backward by one month
export const shiftMonthsBackward = (timetableData) => {
    const updatedTimetableData = { ...timetableData };

    updatedTimetableData.next = { ...timetableData.current }
    updatedTimetableData.twoMonthsAhead = { ...timetableData.next }
    updatedTimetableData.threeMonthsAhead = { ...timetableData.twoMonthsAhead }
    updatedTimetableData.previous = { ...timetableData.twoMonthsEarlier };
    updatedTimetableData.twoMonthsEarlier = { ...timetableData.threeMonthsEarlier };

    return updatedTimetableData;
}

// function to update the visibility of subTimetables
export const updateVisibilityOfSubTimetables = (subTimetables, activeIdx, inactiveIdx) => {
    if (subTimetables) {
        // if (activeIdx && inactiveIdx) {
        //     subTimetables[`subCalendar${activeIdx}`].profileData = subTimetables[`subCalendar${inactiveIdx}`].profileData;
        //     subTimetables[`subCalendar${activeIdx}`].savePost = subTimetables[`subCalendar${inactiveIdx}`].savePost;
        // }
        if (activeIdx) {
            subTimetables[`subCalendar${activeIdx}`].isActive = true;
            subTimetables[`subCalendar${activeIdx}`].isVisible = true;
        }
        if (inactiveIdx) {
            subTimetables[`subCalendar${inactiveIdx}`].isActive = false;
            subTimetables[`subCalendar${inactiveIdx}`].isVisible = false;
        }
        return subTimetables;
    }
}

// function to add the next/previous dates to the timetable data and its subtimetables
export const addNextAndPrevDates = (timetable, monthYearList = [], monthYearDateMap = {}) => {

    // check the monthYearList and monthYearDateMap variable
    if (!monthYearList?.length || !Object.keys(monthYearDateMap)?.length) return timetable;
    
    const months = [
        'threeMonthsEarlier',
        'twoMonthsEarlier',
        'previous',
        'current',
        'next',
        'twoMonthsAhead',
        'threeMonthsAhead'
    ];
    let firstMonth = true;

    const getNextPrevDate = (val, action) => {
        const monthYear = moment(val).format("MMYYYY");
        const activeIndex = monthYearList.indexOf(monthYear);
        const dateMap = monthYearList[action === 'prev' ? activeIndex + 1 : activeIndex - 1];
        return monthYearDateMap[dateMap]?.maxDate;
    };

    const getBoundaryDate = (index, direction) => {
        const boundaryMonth = direction === "prev" ? months[index - 1] : months[index + 1];
        const boundaryTimetable = timetable[boundaryMonth];

        if (boundaryTimetable?.subTimetables) {
            const subTimetables = Object.values(boundaryTimetable.subTimetables);
            return direction === "prev"
                ? subTimetables[subTimetables.length - 1]?.activeDate
                : subTimetables[0]?.activeDate;
        } else {
            return boundaryTimetable?.activeDate;
        }
    };

    const updateSubTimetables = (subTimetables, index, isFirstMonth) => {
        Object.values(subTimetables).forEach((sub, subIndex) => {
            const subPrevDate =
                subIndex > 0
                    ? Object.values(subTimetables)[subIndex - 1]?.activeDate
                    : isFirstMonth
                        ? getNextPrevDate(sub.activeDate, "prev")
                        : getBoundaryDate(index, "prev");

            const subNextDate =
                subIndex < Object.values(subTimetables).length - 1
                    ? Object.values(subTimetables)[subIndex + 1]?.activeDate
                    : getBoundaryDate(index, "next") || getNextPrevDate(sub.activeDate, "next");

            sub['prevDate'] = subPrevDate;
            sub['nextDate'] = subNextDate;
        });
    };

    if (timetable) {
        months.forEach((month, index) => {
            if (timetable[month]?.activeDate) {
                if (firstMonth) {
                    const prevDate = getNextPrevDate(timetable[month]?.activeDate, "prev");
                    timetable[month]['prevDate'] = prevDate;
                    timetable[month]['nextDate'] = getBoundaryDate(index, "next") || getNextPrevDate(timetable[month]?.activeDate, "next");

                    if (timetable[month]?.subTimetables) {
                        updateSubTimetables(timetable[month]?.subTimetables, index, true);
                    }

                    firstMonth = false;
                } else {
                    timetable[month]['prevDate'] = getNextPrevDate(timetable[month]?.activeDate, "prev");
                    timetable[month]['nextDate'] = getNextPrevDate(timetable[month]?.activeDate, "next");

                    if (timetable[month]?.subTimetables) {
                        updateSubTimetables(timetable[month]?.subTimetables, index, false);
                    }
                }
            }
        });
    }

    return timetable;
};
import React from "react";

const ExternalPageDisplay = ({ url }) => {
    return (
        <div className="externalPageDisplay">
            <iframe
                src={url}
                title="External Page"
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                }}
                sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation-by-user-activation"
            />
        </div>
    );
};

export default ExternalPageDisplay;
import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

const DirectoryTree = ({ profiles, handleStackClick }) => {
    const [validProfiles, setValidProfiles] = useState({});
    const containerRef = useRef(null);
    const romanMap = {
        1: { name: "I", value: "first" },
        2: { name: "II", value: "second" },
        3: { name: "III", value: "third" },
        4: { name: "IV", value: "fourth" },
        5: { name: "V", value: "fifth" }
    };

    // Set valid profiles
    useEffect(() => {
        if (profiles && typeof profiles === "object") {
            const hasValidProfiles = Object.values(profiles).some(profile =>
                Array.isArray(profile?.organizations) && profile.organizations.length > 0
            );

            if (hasValidProfiles) {
                setValidProfiles(profiles);
            } else {
                setValidProfiles({});
            }
        } else {
            setValidProfiles({});
        }
    }, [profiles]);

    // Adjust heights using useLayoutEffect for synchronous update before paint
    useLayoutEffect(() => {
        const adjustHeights = () => {
            const container = containerRef.current;
            const containers = container?.querySelectorAll(".subStacksList");
            let subStackListHeight = 0;
            if (containers) {
                containers.forEach((currentContainer, index) => {
                    const nextContainer = containers[index + 1];
                    const profilesStackList = currentContainer.querySelector(".profilesStackList");
                    const stackItemList = profilesStackList.querySelectorAll(".stackItem");
                    let profilesStackListHeight = 0;
                    if (stackItemList) {
                        const totalLength = Array.from(stackItemList).length;
                        const lastElementHeight = Array.from(stackItemList)[totalLength - 1];
                        if (lastElementHeight && lastElementHeight?.offsetHeight > 15) {
                            profilesStackListHeight = (profilesStackList?.offsetHeight) - (lastElementHeight?.offsetHeight - 15);
                        } else {
                            profilesStackListHeight = profilesStackList?.offsetHeight;
                        }
                    }
                    if (nextContainer) {
                        subStackListHeight += nextContainer.offsetTop - currentContainer.offsetTop;
                    }
                    if (profilesStackListHeight) {
                        // Set CSS variable on the profiles stack list element
                        profilesStackList.style.setProperty(
                            "--profiles-stack-list-height",
                            `${profilesStackListHeight - 8}px`
                        );
                    }
                });
            }
            if (container) {
                container.style.setProperty(
                    "--sub-stack-list-height",
                    `${subStackListHeight ? subStackListHeight + 8 : 8}px`
                );
            }
        };

        adjustHeights();
        window.addEventListener("resize", adjustHeights);
        return () => window.removeEventListener("resize", adjustHeights);
    }, [validProfiles]);

    // Only render if valid profiles exist
    if (!Object.keys(validProfiles).length) {
        return null;
    }

    return (
        <div className="directoryTree">
            <div className="mainStacksList">
                <span className="stackListTitle">Sections</span>
                <div className="subStacksListContainer" ref={containerRef}>
                    {Object.keys(validProfiles).map((stackKey, index) => {
                        const stackData = validProfiles[stackKey];
                        if (!stackData?.organizations || !stackData.organizations.length) return null;

                        return (
                            <div key={stackKey} className="subStacksList">
                                <span
                                    className="subStacksListTitle"
                                    onClick={(event) => handleStackClick(event, romanMap[index + 1]?.value)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {romanMap[index + 1]?.name}
                                </span>
                                <div className="profilesStackList">
                                    {stackData.organizations.map((org) => (
                                        <Link to={org.username} key={org.username} className="stackItem">
                                            {org.screenName}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default DirectoryTree;
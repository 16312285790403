import $ from 'jquery';
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { fetchSortedUser, usersListForGuets, fetchEventsListGuest } from '../../Services/api';
import { MyContext } from '../../context';
import { sortArrayOfObjects } from '../../utils/arrayHelper';
import { convertToTeX, formatSearchResult, formatTitleForOrganization } from '../../utils/math';
import { getListBySortingType, getProfilesList } from '../../utils/profiles/manageProfilesList';
import { searchProfile } from '../../utils/profiles/searchProfiles';
import { showInitials } from '../../utils/profiles/shared';
import {
   getActiveDatesOfTheMonth, getActiveDateBasedOnImportantEvent, determineActiveDateForCurrentMonth,
   handleSubCalendarData, updateVisibilityOfSubTimetables
} from '../../utils/timetableHelper/timetableHelper';
import { determineAndUpdateActiveDatesForTimetables } from '../../utils/timetableHelper/timetableActiveDatesUpdater';
import GuestFooter from '../GuestFooter';
import moment from 'moment';
import Timetable from '../Timetable/Timetable';
import { handleClickOfProfiles } from '../../utils/profiles/clickHandler';
import { getdateformat } from '../../utils/dateFormatterForTI/dateFormatter';

export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];

export const homepagePathname = "/"
class SecondTabs extends React.Component {
   static contextType = MyContext
   constructor(props) {

      super(props);
      this.state = {
         contentEditable: "timesort",
         hasMoreUser: true, hasMoreGlobalUser: true,
         localPage: 1, globalPage: 1, scrollPosition: 0,
         searchPerameter: "", slectedConst: "", slectedVar: "", searchinguser: [], recomended: [],activitySortedUsers: [], temporallySortedUsers: [],
         baseUrl: process.env.REACT_APP_BASE_URL, searchloader: false, error: "",
         slectedConst: "", baseUrlNode: process.env.REACT_APP_BASE_URL_NODE, usersData: [],
         inputValue: "",
      }
   }

   componentDidMount() {
      const { setActiveTab, sharedData } = this.context;
      const statesFromContext = sharedData.pageWiseData[homepagePathname]?.state;

      setActiveTab("second");

      localStorage.setItem("footer", "second");

      setTimeout(() => {
         const infiniteScrollContainer = document.getElementById("scrollableDivOne_3");
         if (infiniteScrollContainer && statesFromContext?.scrollPosition) {
            infiniteScrollContainer.scrollTo({ top: statesFromContext.scrollPosition });
         }
      }, 100);

      document.addEventListener('click', this.handleClickOfSecondTabs);
   }

   componentWillMount() {
         const { sharedData } = this.context;
         const statesFromContext = sharedData.pageWiseData[homepagePathname]?.state;

         if (statesFromContext?.usersData?.length > 0) {
            this.processExistingRecommendedUsers();
         } else {
            this.processFreshRecommendedUsers();
         }
   }

   handleClickOfSecondTabs = (event) => {
      handleClickOfProfiles(event, this.props, this.props.setVisitedPaths);
   };

   processExistingRecommendedUsers = () => {
      const { sharedData } = this.context;
      const { appContext } = this.props;
      const {  actions, state } = appContext;
      const {lastOpenedProfile } = state;
      const { setLastOpenedProfile } = actions;
      const statesFromContext = sharedData.pageWiseData[homepagePathname]?.state;
      const prevUsersData = statesFromContext?.usersData || [];
      const storedData = JSON.parse(localStorage.getItem("entry")) || [];
      const lastVisitedProfile = lastOpenedProfile;
      let recomended = storedData.slice(0, prevUsersData.length);
      
      lastVisitedProfile.forEach((opendProfile) => {
         const indexOfVisitedProfile = prevUsersData.findIndex(
            (profile) => profile.userId === Object.values(opendProfile)[0]?.userId
         );

         if (indexOfVisitedProfile >= 0) {
            prevUsersData.unshift(prevUsersData.splice(indexOfVisitedProfile, 1)[0]);
         } else {
            prevUsersData.unshift(Object.values(opendProfile)[0]);
            prevUsersData.pop();
         }
      });

      this.setState(
         {
            ...sharedData.pageWiseData[homepagePathname]?.state,
            recomended,
            usersData: prevUsersData,
         }
      );

      setLastOpenedProfile([]);
   };

   processFreshRecommendedUsers = () => {
      const freshLoad = true;
      const storedData = JSON.parse(localStorage.getItem("entry")) || [];
      let recomended = [];

      if (storedData.length > 0) {
         recomended = storedData.slice(0, 12);
         this.setState({ globalPage: 2, localPage: 2 });
      } else {
         usersListForGuets(1, 256).then((res) => {
            if (res.length > 0) {
               localStorage.setItem("entry", JSON.stringify(res));
               recomended = res.slice(0, 12);
               this.setState({ localPage: 2, globalPage: 2, hasMoreUser: true });
            } else {
               this.setState({ hasMoreGlobalUser: false });
            }
         });
      }

      this.setState({ recomended }, () => {
         if (recomended.length > 0) {
            this.renderUserTimetables(recomended, freshLoad);
         } else {
            setTimeout(() => this.processFreshRecommendedUsers(), 1000);
         }
      });
   };

   displayOpenProfiles(openProfiles) {
      this.addProfilePathsToHistory(openProfiles);
      this.redirectToLastVisitedProfile(openProfiles);
   }

   redirectToLastVisitedProfile(openProfiles) {
      this.props.history.push(openProfiles[openProfiles.length - 1], { enteredBy: "push" });
   }

   addProfilePathsToHistory(openProfiles) {
      openProfiles.slice(0, openProfiles.length - 1).forEach(path => {
         window.history.pushState({ enteredBy: "pushState" }, "", path);
      });
   }
   componentWillUnmount() {
      const scrollPosition = document.getElementById("scrollableDivOne_3")?.scrollTop;
      this.updateSharedDataInContext(scrollPosition);
      document.removeEventListener('click', this.handleClickOfSecondTabs);
   }

   updateSharedDataInContext(scrollPosition) {
      const { sharedData, setSharedData } = this.context
      let openProfiles = []

      if (sharedData.pageWiseData[homepagePathname] && sharedData.pageWiseData[homepagePathname].openProfiles)
         openProfiles = sharedData.pageWiseData[homepagePathname].openProfiles

      sharedData.pageWiseData[homepagePathname] = { ...sharedData.pageWiseData[homepagePathname], state: {...this.state, scrollPosition}, openProfiles }

      setSharedData({
         ...sharedData,
      });
   }

   fetchMoreUserData = () => {
      this.nonregister(this.state.localPage);
   };

   async nonregister(page) {
      let freshLoad = false;
      // fetch(this.state.baseUrl+'/api/group/dummyusers.php',{
      //    method: "GET",
      //    headers: {
      //    Accept: "application/json",
      //    "Content-Type": "application/json",
      //    },     
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //    this.setState({recomended:responseJson.response});
      //    localStorage.setItem("entry", JSON.stringify(this.state.recomended));
      //    }).catch((error)=>{
      //    console.log(error);
      //    })
      // fetch(this.state.baseUrlNode+'/api/guestRecomended',{
      //    method: "GET", 
      //    })
      //    .then((response) => response.json())
      //    .then((responseJson) =>{
      //       this.setState({recomended:responseJson.data},()=>{
      //          console.log('recognize',this.state.recomended);
      //          //this.mainProfileColor();
      //       });
      //       localStorage.setItem("entry", JSON.stringify(responseJson.data));
      //       //console.log('responseJson ################################',responseJson.data);
      //    }).catch((error)=>{
      //       console.log('error ################################',error.message);
      //    })
      if(JSON.parse(localStorage.getItem("entry"))?.length) {
         const storedData = JSON.parse(localStorage.getItem("entry"));
         const newData = storedData.slice(this.state.usersData?.length, this.state.usersData?.length + 12);
         if (newData.length === 12) {
            this.setState((prevState) => ({
               recomended: [...prevState.recomended, ...newData],
               localPage: page + 1,
               hasMoreUser: true
            }));
            this.renderUserTimetables(newData, freshLoad);
         } else {
            if(this.state.hasMoreGlobalUser) {
               if (newData.length === 0) {
                  await usersListForGuets(this.state.globalPage, 256).then((res) => {
                     if (res.length > 0) {
                        const updatedData = [...storedData, ...res];
                        localStorage.setItem("entry", JSON.stringify(updatedData));
                        this.setState({ globalPage: this.state.globalPage + 1 });
                     }
                     if (res.length == 0 || res.length < 256) {
                        this.setState({ hasMoreGlobalUser: false });
                     }
                     this.nonregister(this.state.localPage);
                  })
               }
            } else {
               this.setState({ hasMoreUser: false });
            }
         }
      }
      // if (data.length > 0) {
      //    this.setState((prevState) => ({
      //       recomended: [...prevState.recomended, ...data],
      //       page: page + 1,
      //       hasMoreUser: true
      //    }));
      //    this.renderUserTimetables(data);
      // }

      // if (data.length == 0 || data.length < 12) {
      //    this.setState({ hasMoreUser: false });
      // }

      // initially filter data for temporally/activity
      // this.getSortedUser('temporally', this.state.recomended);
      // this.getSortedUser('activity', this.state.recomended);
   }



   userSearch(event) {
      const { setSearching } = this.context
      setSearching(event.target.value ? true : false);

      this.setState({ searchPerameter: event.target.value, searchloader: true }, () => {
         this.timeout = setTimeout(async function () {
            // this.searchUser();
            // this.getSearchedProfiles()
            let profiles = await searchProfile(this.state.searchPerameter, this.state.recomended)
            this.setState({ searchinguser: profiles, searchloader: false })
         }.bind(this), 300);
      });
      if (this.timeout) {
         clearTimeout(this.timeout)
         this.timeout = null
      }
   }

   async getSearchedProfiles() {

   }

   async searchUser() {
      if (this.state.searchPerameter != "") {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         var response = await fetch(this.state.baseUrl + '/api/user/Gusearch.php?search=' + this.state.searchPerameter + '&page=1&limit=16&user_id=' + variable, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         });
         const movies = await response.json();
         if (movies.response.length) {
            const dusers = await movies.response;
            this.setState({ searchinguser: [] }, () => {
               this.setState({ searchinguser: dusers, searchloader: false }, () => {
                  this.mainProfileColor()
               });
               return;
            });
         }
         else {
            this.setState({ error: "User Not Found" });
         }
      }
   }
   mainProfileColor() {
      for (let index = 0; index < this.state.recomended?.length; index++) {
         var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
         $(".multiuser" + index + ", .imgg").css("color", "");
         $(".multiuser" + index + ", .imgg").css("color", random_color.textColor);
         $(".multiuser" + index + ", .imgg").css("background-color", "");
         $(".multiuser" + index + ", .imgg").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
      }
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".multiuser, .imgg").css("color", "");
      $(".multiuser, .imgg").css("color", random_color.textColor);
      $(".multiuser, .imgg").css("background-color", "");
      $(".multiuser, .imgg").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   removeSearch() {
      document.getElementById("lemoncals").value = "";
      this.setState({ searchinguser: [], searchPerameter: "" });
   }
   relevancySearch() {
      this.setState({ slectedVar: "", recomended: [] });
      if (JSON.parse(localStorage.getItem("entry"))) {
         this.setState({ recomended: JSON.parse(localStorage.getItem("entry")) });
      }
   }
   mutualitySearch() {
      this.setState({ slectedVar: "mutual" });
   }
   alphabeticallySearch() {
      this.setState({ slectedVar: "alpha" });
   }
   PopularitySearch() {
      this.setState({ slectedVar: "popular" });
   }
   flipSearch() {
      this.setState({ slectedVar: "flip" });
   }
   PseudorandomlySearch() {
      this.setState({ slectedVar: "shuffle" });
   }
   temporallySearch() {
      this.setState({ slectedVar: "temporal" });
   }
   activitySearch() {
      this.setState({ slectedVar: "active" });
   }
   mutualitySearchList() {
      $(".pupup3").toggle();
      this.setState({ slectedConst: "mutual" });
      if (this.state.searchPerameter == "") {
         this.setState({ slectedVar: "mutual" });
      }
   }
   alphabeticallySearchList() {
      $(".pupup3").toggle();
      this.setState({ slectedConst: "alpha" });
      if (this.state.searchPerameter == "") {
         this.setState({ slectedVar: "alpha" });
      }
   }
   relevancySearchList() {
      $(".pupup3").toggle();
      if (this.state.searchPerameter == "") {
         this.relevancySearch()
      }
      this.setState({ slectedConst: "" });
      fetch(this.state.baseUrl + '/api/user/Gusearch.php?search=' + this.state.searchPerameter + '&page=1&limit=16&user_id=' + localStorage.getItem('userid'), {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({ searchinguser: responseJson.response }, () => {
               //this.mainProfileColor();
            });
         });
   }

   filterUserin(data) {
      if (this.state.slectedConst == "") {
         return data;
      }
      else if (this.state.slectedConst == "mutual") {
         return data.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse();
      }
      else if (this.state.slectedConst == "alpha") {
         return data.sort((a, b) => a.screenName.localeCompare(b.screenName));
      }
   }
   openpop() {
      $(".pupup3").toggle();
   }
   fetchmakeprofile(data) {
      var first = data.split(" ")[0];
      var firstn = first.charAt(0);

      if (data.split(" ")[1]) {
         var second = data.split(" ")[1];
         var secondn = second.charAt(0);
         return firstn + secondn;
      }
      else {
         return firstn;
      }
   }
   shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
  }
   async getSortedUser(sortingType, users) {
      const newUserList = users ? users : JSON.parse(localStorage.getItem('entry'));
      const result = await fetchSortedUser(sortingType,newUserList);
      if(sortingType == 'temporally') {
         this.setState({ temporallySortedUsers: result });
      } else if (sortingType == 'activity') {
         this.setState({ activitySortedUsers: result });
      }
   }
   filterUser(data) {
      if (this.state.slectedVar == "") {
         return data;
      }
      else if (this.state.slectedVar == "mutual") {
         const list = getListBySortingType({sortingType: 'Mutuality'});
         return list;
      }
      else if (this.state.slectedVar == "alpha") {
         return data.sort((a, b) => a.screenName.localeCompare(b.screenName));
      } 
      else if (this.state.slectedVar == "popular") {
         return sortArrayOfObjects(data, 'total_visits', false)
      }
      else if (this.state.slectedVar == "flip") {
         return data.reverse();
      }
      else if (this.state.slectedVar == "shuffle") {
         const shuffledData = this.shuffleArray(data);
         return shuffledData;
      }
      else if (this.state.slectedVar == "temporal") {
         return sortArrayOfObjects(data, 'eventStats.totalEventsIn3Months', false)
      }
      else if (this.state.slectedVar == "active") {
         return sortArrayOfObjects(data, 'eventStats.closestEventInSeconds', true)
      }
   }
   setStorage(user) {
      const activeTab = this.context.activeTab
      const { appContext, location } = this.props;
      const { state, actions } = appContext;

      // update searching to false to the context
      const { setSearching } = this.context;
      setSearching(false);

      if (activeTab == 'second' && location.pathname == '/' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 1) {
         actions.setHomePageActive({
            status: false,
            activeHomePageSubspace: null,
         });
      }
      
      localStorage.setItem("backid", "front");

      this.props.setVisitedPaths((prevPaths) => [...prevPaths, user.username]);
      if(this.state.searchPerameter != '') {
         this.setState({searchPerameter: '', inputValue: ''});
      }
   }

   handleMouseOverTab = (event, eventData, actionType) => {
      const targetElement = event.target;
      let parentDiv = targetElement?.parentNode;
      while (!parentDiv.classList.contains('higherLevelGroup')) {
         parentDiv = parentDiv.parentNode;
      }
      if(parentDiv && eventData) {
         const linkElement = parentDiv.querySelector('.hoveredViews');
         if (linkElement) {
            if(actionType == 'leave') {
               linkElement.innerText = '';
               linkElement.style.padding = '';
               return
            }
            const formatedDate = getdateformat(eventData.created_at, null, eventData.timezone);
            linkElement.innerText = `${eventData?.views || 0} ${eventData?.views === 1 ? 'view' : 'views'} since ${formatedDate}`;
            linkElement.style.padding = '2px 5px';
         }
      }
      const elementRect = targetElement?.getBoundingClientRect();
      const elementX = elementRect?.left + window?.scrollX;
      const elementY = elementRect?.top + window?.scrollY;
      let elementR = window.innerWidth - (elementRect?.right + window.scrollX);
      const fullnameElement = targetElement?.querySelector(".fullname");
      const tooltip = targetElement?.querySelector("p");
      if (fullnameElement) {
         fullnameElement.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
         if (fullnameElement?.classList.contains("right-aligned") && fullnameElement?.classList.contains("fullName")) {
            fullnameElement.style.right = `${elementR}px`;
         } else {
            fullnameElement.style.left = `${elementX}px`;
         }
         fullnameElement.style.maxWidth = 'max-content';
      } else if (tooltip) {
         const nameElement = targetElement?.querySelector(".fullname");
         if (tooltip && tooltip?.classList.contains('fullName')) {
            tooltip.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
            if(tooltip?.classList.contains('right-aligned') && tooltip?.classList.contains('fullName')) {
               tooltip.style.right = `${elementR}px`;
            } else {
               tooltip.style.left = `${elementX}px`;
            }
            tooltip.style.maxWidth = 'max-content';
         } else if (nameElement) {
            nameElement.style.top = `calc(${elementY + targetElement?.offsetHeight}px - 2px)`;
            if (targetElement?.classList.contains("right-aligned") && targetElement?.classList.contains("fullname")) {
               nameElement.style.right = `${elementR}px`;
            } else {
               nameElement.style.left = `${elementX}px`;
            }
            nameElement.style.maxWidth = 'max-content';
         }
      }
   };

   addNextAndPrevDates = (usersData) => {

      // function to get the next/prev dates
      const getNextPrevDate = (val, user, action) => {
         const monthYearsMap = user?.monthYear || [];
         const monthYearDateMap = user?.monthYearDateMap || {};

         const selectedMonthYear = moment(val).format("MMYYYY");

         if (monthYearsMap?.length && Object.keys(monthYearDateMap)?.length) {
            const activeIndex = monthYearsMap.indexOf(selectedMonthYear);
            const dateMap = monthYearsMap[action === 'prev' ? activeIndex + 1 : activeIndex - 1];
            return monthYearDateMap[dateMap]?.maxDate;
         } else {
            return undefined;
         }
      };

      // function to update next/prev dates of subTimetables
      const updateSubTimetables = (subTimetables, user) => {
         Object.values(subTimetables).forEach((sub, subIndex) => {
            const subPrevDate = subIndex === 0 ?
               getNextPrevDate(sub.activeDate, user, "prev") :
               Object.values(subTimetables)[subIndex - 1]?.activeDate;

            const subNextDate = subIndex === Object.values(subTimetables).length - 1 ?
               getNextPrevDate(sub.activeDate, user, "next") :
               Object.values(subTimetables)[subIndex + 1]?.activeDate;

            sub['prevDate'] = subPrevDate;
            sub['nextDate'] = subNextDate;
         });
      };

      if (usersData) {
         usersData.forEach((user) => {
            if (user?.timetableData?.activeDate) {
               let timetableData = user?.timetableData;
               timetableData.prevDate = getNextPrevDate(timetableData.activeDate, user, "prev");
               timetableData.nextDate = getNextPrevDate(timetableData.activeDate, user, "next");              

               if (timetableData.subTimetables) {                  
                  updateSubTimetables(timetableData.subTimetables, user);
               }
            }
         });
      }

      return usersData;
   };

   updateSubCalendarDateRanges(timetableData, subTimetables) {      
      const subtimetables = subTimetables ? Object.values(subTimetables) : [];
      const isLastMonth = moment(timetableData.maxDate).format("MMYYYY") === moment(timetableData.activeDate).format("MMYYYY");
      const isFirstMonth = moment(timetableData.minDate).format("MMYYYY") === moment(timetableData.activeDate).format("MMYYYY");
      let minDate = timetableData.minDate;
      let maxDate = timetableData.maxDate;

      if ((isLastMonth || isFirstMonth) && subtimetables.length) {
         const isLastMonthVisible = isFirstMonth
            ? subtimetables[0].isVisible === true
            : subtimetables[subtimetables.length - 1].isVisible === true;

         if (!isLastMonthVisible) {
            minDate = isFirstMonth
               ? moment(minDate).subtract(1, 'months').startOf('month').toDate()
               : minDate;
            maxDate = isLastMonth
               ? moment(maxDate).add(1, 'months').startOf('month').toDate()
               : maxDate;
         }
      }

      const updatedSubTimetables = subtimetables.map((sub) => ({
         ...sub,
         minDate,
         maxDate,
      }));

      return {
         ...subTimetables,
         ...Object.fromEntries(updatedSubTimetables.map((sub, index) => [Object.keys(subTimetables)[index], sub]))
      };
   }

   shiftActiveCalendar(date, action, userId) {
      let userData = this.state.usersData.find((user) => user.userId == userId);
      let newUserData = userData;

      // function to update the usersData
      const updateUsersData = (usersData, date, action) => {
         let updatedData = userData;
         const availableEvents = getActiveDatesOfTheMonth(date, usersData.events);
         // const newDate = determineDateToBeSelectForMonths(date, availableEvents);
         const newDate = determineActiveDateForCurrentMonth(date, availableEvents);
         let subtimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

         if (subtimetables) {
            subtimetables = action === "next" ?
               updateVisibilityOfSubTimetables(subtimetables, 1) :
               updateVisibilityOfSubTimetables(subtimetables, Object.keys(subtimetables)?.length);
         }

         if (updatedData.timetableData.profileData) {
            if (updatedData.timetableData.subTimetables) {
               const data = updatedData.timetableData.subTimetables;
               const activeEntry = Object.entries(data).find((entry) => entry[1]?.isActive === true);
               
               if (activeEntry) {
                  updatedData.timetableData.profileData = activeEntry[1].profileData;
                  updatedData.timetableData.savePost = activeEntry[1].savePost;
               }
               if (subtimetables) {
                  const newSub = Object.values(subtimetables);
                  
                  newSub.map((sub) => {
                     if (sub.isActive) {
                        if(activeEntry) {
                           sub.profileData = activeEntry[1].profileData;
                           sub.savePost = activeEntry[1].savePost;
                        } else {
                           sub.profileData = updatedData.timetableData.profileData;
                           sub.savePost = updatedData.timetableData.savePost;
                        }
                     }
                  })
                  updatedData.timetableData.subTimetables = subtimetables;
               }
            }
         }

         updatedData.timetableData.activeDate = newDate;
         updatedData.timetableData.availableEventsOnDates = availableEvents;
         updatedData.timetableData.subTimetables = subtimetables;

         return updatedData;
      }
      
      // function to shift to next calendar
      const shiftToNextCalendar = (date, userData) => {
         let updatedUserData = userData;
         const subtimetables = userData.timetableData.subTimetables ? Object.entries(userData.timetableData.subTimetables) : [];
         const activeIndex = subtimetables?.length ? subtimetables.findIndex(([_, sub]) => sub.isActive === true) : -1;
         
         if (activeIndex !== -1 && activeIndex !== subtimetables?.length - 1) {
            updatedUserData.timetableData.subTimetables = updateVisibilityOfSubTimetables(userData.timetableData.subTimetables, activeIndex+2, activeIndex+1);
         } else {
            updatedUserData = updateUsersData(userData, date, "next");
         }

         return updatedUserData;
      };

      // function to shift to previous calendar
      const shiftToPreviousCalendar = (date, userData) => {
         let updatedUserData = userData;
         const subtimetables = userData.timetableData.subTimetables ? Object.entries(userData.timetableData.subTimetables) : [];
         const activeIndex = subtimetables?.length ? subtimetables.findIndex(([_, sub]) => sub.isActive === true) : -1;

         if (activeIndex !== -1 && activeIndex !== 0) {
            updatedUserData.timetableData.subTimetables = updateVisibilityOfSubTimetables(userData.timetableData.subTimetables, activeIndex, activeIndex+1);
         } else {
            updatedUserData = updateUsersData(userData, date, "prev");
         }

         return updatedUserData;
      };      

      // call the function based on the action
      if (action === "next") {
         newUserData = shiftToNextCalendar(date, userData);
      } else {
         newUserData = shiftToPreviousCalendar(date, userData);
      }

      // update the active dates for the timetable data 
      let updatedTimetableData = { current: newUserData.timetableData };
      updatedTimetableData = determineAndUpdateActiveDatesForTimetables(updatedTimetableData);
      newUserData.timetableData = updatedTimetableData.current;

      newUserData.timetableData.subTimetables = this.updateSubCalendarDateRanges(newUserData.timetableData, newUserData.timetableData.subTimetables);
      const userDataArray = this.addNextAndPrevDates([newUserData]);
      
      this.setState((prevState) => ({
         usersData: prevState.usersData.map(item => {
            if (item.userId === userId) {
               return {
                  ...item,
                  timetableData: {
                     ...item.timetableData,
                     ...userDataArray[0].timetableData
                  }
               };
            } else {
               return item;
            }
         })
      }))
   }

   updateActiveDatesOfTimetable(date, action, userId) {

      // call shiftActiveCalendar function to shift calendar based on the action
      this.shiftActiveCalendar(date, action, userId);
   }

   updateTimetableData(data, user, subTimetableKey = null) {
      if (user) {
         this.setState(prevState => ({
            usersData: prevState.usersData.map(item => {
               if (item.userId == user) {
                  if (!subTimetableKey) {
                     return {
                        ...item,
                        timetableData: {
                           ...item.timetableData,
                           ...data,
                           ...(data?.activeDate && (() => {
                              const availableEvents = getActiveDatesOfTheMonth(data.activeDate, item.events);

                              return {
                                 availableEventsOnDates: availableEvents,
                                 activeDate: data.activeDate
                              };
                           })())
                        }
                     }
                  } else {
                     return {
                        ...item,
                        timetableData: {
                           ...item.timetableData,
                           ...(data?.activeDate && (() => {
                              return {
                                 activeDate: data.activeDate
                              };
                           })()),
                           subTimetables: {
                              ...item.timetableData.subTimetables,
                              [subTimetableKey]: {
                                 ...item.timetableData.subTimetables[subTimetableKey],
                                 ...data,
                              }
                           }
                        }
                     }
                  }
               } else {
                  return item;
               }
            })
         }));
      }
   }
   
   updateStatesFromTimetable = (data, user) => {
      this.setState(prevState => ({
         usersData: prevState.usersData.map(item =>
            item.userId == user
               ? {
                  ...item,
                  events: data.events,
                  monthYear: data.otherMonthYear,
                  timetableData: {
                     ...item.timetableData,
                     activeDate: data.activeDate,
                     minDate: data.minDate,
                     maxDate: data.maxDate,
                     availableEventsOnDates: getActiveDatesOfTheMonth(data.activeDate, item.events)
                  }
            } : item
         )
      }));
   }

   getClosestOrLatestEvent(events) {
      const currentDate = moment();
      let closestEvent = null;
      let minDateDifference = Infinity;
      events.forEach(event => {
         const eventDate = moment(event.eventOn, "DD-MM-YYYY");
         const dateDifference = Math.abs(currentDate.diff(eventDate, 'days'));

         if (eventDate.isSame(currentDate, 'day')) {
            closestEvent = eventDate;
            return;
         } else if (dateDifference < minDateDifference) {
            closestEvent = eventDate;
            minDateDifference = dateDifference;
         }
      });
      let date = closestEvent ? closestEvent.toDate() : null;
      let availableEvents = getActiveDatesOfTheMonth(date, events);

      if (availableEvents.length < 4) {
         let nextClosestEvent = events.find(event => {
            const eventDate = moment(event.eventOn, "DD-MM-YYYY");
            return eventDate.isAfter(date) && eventDate.month() !== moment(date).month();
         });

         if (!nextClosestEvent) {
            nextClosestEvent = events.find(event => {
               const eventDate = moment(event.eventOn, "DD-MM-YYYY");
               return eventDate.isBefore(date) && eventDate.month() !== moment(date).month();
            });
         }

         if (nextClosestEvent) {
            date = moment(nextClosestEvent.eventOn, "DD-MM-YYYY").toDate();
            availableEvents = getActiveDatesOfTheMonth(date, events);
         }
      }
      
      const activeDateObj = getActiveDateBasedOnImportantEvent(date, availableEvents);
      const activeDate = activeDateObj ? moment(activeDateObj.eventOn, "DD-MM-YYYY").toDate() : null;

      return {activeDate, availableEvents};
   }

   mapMonthYearToDate = async (eventList) => {
      let lastMY = "";
      let lastMYDate = "";
      let monthYearDateMap = {};
      let monthYear = [];
      let uniqueMonthYearSet = new Set();

      eventList.sort((a, b) => {
         const dateA = moment(a.eventOn, "DD/MM/YYYY");
         const dateB = moment(b.eventOn, "DD/MM/YYYY");
         return dateB - dateA;
      });

      eventList.forEach((dateObj) => {
         const min = moment(dateObj.eventOn, "DD/MM/YYYY");
         const minie = new Date(min);
         const currentMY = min.format("MMYYYY");

         if (!uniqueMonthYearSet.has(currentMY)) {
            uniqueMonthYearSet.add(currentMY);
            monthYear.push(currentMY);
         }

         if (currentMY !== lastMY) {
            monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate };
            monthYearDateMap[currentMY] = { ...monthYearDateMap[lastMY], maxDate: minie };
         }

         lastMY = currentMY;
         lastMYDate = minie;
      });

      monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate };

      return { monthYearDateMap, monthYear };
   }

   renderUserTimetables = async (allUsers, freshLoad) => {
      // const currentDate = moment(new Date()).format("DD-MM-YYYY");
      // const dummyListData = {
      //    TIMEDIFF: 4079527,
      //    dateCount: 0,
      //    eventOn: currentDate,
      //    importantEvents: 0,
      //    priority: 1
      // }
      
      if (allUsers?.length > 0) {
         try {
            const usersData = await Promise.all(
               allUsers.map(async (user) => {
                  const eventList = await fetchEventsListGuest(user.user_id);
                  const mapMonthYear = await this.mapMonthYearToDate(eventList);
                  let {activeDate, availableEvents} = this.getClosestOrLatestEvent(eventList);
                  // const newDate = determineDateToBeSelectForMonths(activeDate, availableEvents);
                  const newDate = determineActiveDateForCurrentMonth(activeDate, availableEvents);
                  let subtimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;                  

                  if (subtimetables) {
                     const calendars = Object.values(subtimetables);
                     const shouldActiveIndex = calendars.findIndex(el => {
                        const eventDates = el.availableEventsOnDates.map(event => moment(event.eventOn, "DD-MM-YYYY"));

                        if (eventDates.length === 0) return false;

                        const minDate = moment.min(eventDates);
                        const maxDate = moment.max(eventDates);

                        return moment(newDate).isBetween(minDate, maxDate, 'day', '[]');
                     });

                     const activeIndex = shouldActiveIndex >= 0 ? shouldActiveIndex : 0;
                     subtimetables = updateVisibilityOfSubTimetables(subtimetables, activeIndex+1);
                     subtimetables = this.updateSubCalendarDateRanges({
                        activeDate: newDate,
                        minDate: mapMonthYear.monthYearDateMap[mapMonthYear.monthYear[mapMonthYear.monthYear.length - 1]]?.minDate || undefined,
                        maxDate: mapMonthYear.monthYearDateMap[mapMonthYear.monthYear[0]]?.maxDate || undefined
                     }, subtimetables)
                  }

                  const duplicateTimetableData = {
                     activeDate: newDate, 
                     prevDate: undefined,
                     nextDate: undefined,
                     minDate: mapMonthYear.monthYearDateMap[mapMonthYear.monthYear[mapMonthYear.monthYear.length - 1]]?.minDate || undefined,
                     maxDate: mapMonthYear.monthYearDateMap[mapMonthYear.monthYear[0]]?.maxDate || undefined,
                     profileData: [],
                     availableEventsOnDates: availableEvents,
                     appendedTiles: {data: [], appendedMonth: null},
                     savePost: [],
                     subTimetables: subtimetables
                  }

                  // update the active dates for the timetable data 
                  let updatedTimetableData = { current: duplicateTimetableData };
                  updatedTimetableData = determineAndUpdateActiveDatesForTimetables(updatedTimetableData, eventList);

                  return {
                     timetableData: updatedTimetableData.current,
                     userId: user.user_id,
                     userData: user,
                     events: eventList,
                     monthYearDateMap: mapMonthYear.monthYearDateMap,
                     monthYear: mapMonthYear.monthYear,
                  };
               })
            );
            if(freshLoad) {
               const newUsersData = this.addNextAndPrevDates(usersData);
               this.setState({ usersData: newUsersData });  
            } else {
               const newUsersData = this.addNextAndPrevDates(usersData);
               this.setState(prevState => ({
                  usersData: [...prevState.usersData, ...newUsersData]
               }));
            }
         } catch (error) {
            console.error("Error fetching user events:", error);
         }
      } else {
         setTimeout(() => {
            this.renderUserTimetables(allUsers, freshLoad);
         }, 500);
      }

      // {
      //    this.state.usersData.length > 0 && this.state.usersData.map((item, index) => (
      //       <Timetable 
      //          key={index}
      //          selfProfile={false}
      //          isScreenVisible={this.props.isScreenVisible}
      //          subspaceNumber={1}
      //          calendar={`${item.userId}`}
      //          userDetails={item.userData}  
      //          timetableData={item.timetableData}
      //          states={{
      //             events: item.events,
      //             otherMinimum: item.timetableData?.minDate,
      //             otherMaximum: item.timetableData?.maxDate,
      //             otherMonthYear: item?.monthYear,
      //          }}
      //          updateTimetableData={(data) => this.updateTimetableData(data, `${item.userId}`)}
      //          updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data, `${item.userId}`)} 
      //          updateActiveDatesOfTimetable={(date, action) => console.log("data", date)}
      //       />
      //    ))
      //   }
   };


   renderUserGroups = () => {
      const allUsers = this.filterUser(this.state.recomended);
      const Allelements = [];
      const upperGroupSize = 192

      for (let g = 0; g < Math.ceil(allUsers.length / upperGroupSize); g++) {
         const users = allUsers.slice(g * upperGroupSize, (g + 1) * upperGroupSize);
         const userGroups = [];
         let groupSize;

         for (let i = 0; i < users.length; i += groupSize) {
            let higherLevelGroupClass = '';

            if (i / 64 / 2 === 0) {
               groupSize = 64; // First group with 64 elements
               higherLevelGroupClass = 'firstGroup';
            // } else if (i == 192) {
            //    groupSize = 64; // Last group with 64 elements
            //    higherLevelGroupClass = 'lastGroup';
            } else {
               groupSize = 128; // Middle groups with 128 elements
               higherLevelGroupClass = 'middleGroup';
            }

            const group = users.slice(i, i + groupSize);
            const parentDivs = [];
            const hoveredViews = (<p className='hoveredViews'></p>);

            for (let j = 0; j < group.length; j += 32) {
               const subgroup = group.slice(j, j + 32);
               const parentDiv = (
                  <div className={`parentDiv group-${i / 32}`} key={i + j}>
                     {subgroup.map((u, k) => (
                        <Link
                           to={{
                              pathname: `/${u.username}`,
                              state: { id: u.user_id }
                           }}
                           onClick={this.setStorage.bind(this, u)}
                           className="col-3" key={k}>
                           <span
                              // to={{
                              //    pathname: `/${u.username}`,
                              //    state: { id: u.user_id }
                              // }}
                              className="link"
                           // data-id={i + j + k}
                           // id={u?.user_id}

                           // data-div_name="#two_3"
                           // onClick={this.setStorage.bind(this, u)}
                           // data-toggle="tab"
                           >
                              {u.profileImage !== "" ? (
                                 <img className="u_img" src={this.state.baseUrl + u.profileImage} align="images" />
                              ) : (
                                 ""
                              )}
                              <p onMouseOver={(e) => this.handleMouseOverTab(e, u, 'over')}>{showInitials(u?.screenName)}
                                 <p className={`fullName ${(typeof this.props.abc == 'string') && (j == 32 || j == 96) && (k != 0 && (k % 4) != 0)  ? 'right-aligned' : 'left-aligned'}`}>{u?.screenName}</p>
                              </p>
                           </span>
                        </Link>
                     ))}
                  </div>
               );

               parentDivs.push(parentDiv);
            }

            userGroups.push(
               <div className={`higherLevelGroup ${higherLevelGroupClass}`} key={i / 32}>
                  {parentDivs}
                  {hoveredViews}
               </div>
            );
            // console.log({ higherLevelGroupClass, isLastGroup, parentDivs, userGroups });
         }
         Allelements.push(
            <div className='rowBlock'>
               {userGroups}
            </div>
         );
      }
      return Allelements;
   };
   render() {
      return (
         //<div className="newscreen1 newscreen01">
         //<div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback nabc" : "screen1 active fades abc"}  id="one_3">
         <div className={`${this.props.pos} screen1 active`} id="one_3">
            <div className="top" id="myHeader">
               {/* header  */}
               <section className="header1">
                  <div className="search">
                     <form className="form-inline mt-md-0">
                        <input
                           className="form-control"
                           autoCapitalize='none'
                           id="lemoncals"
                           autocomplete="off"
                           autocorrect="off"
                           spellCheck="false"
                           onChange={(e) => this.setState({ inputValue: e.target.value })}
                           value={this.state.inputValue} type="text"
                           onKeyUp={this.userSearch.bind(this)}
                           placeholder={localStorage.getItem('screenname') ? "Search Timesort, " + localStorage.getItem('screenname').split(" ")[0] + "!" : "Search Timesort"}
                           aria-label="Search"
                        />
                        {(() => {
                           if (this.state.searchPerameter != "") {
                              return (<span
                                 className="btn closeBtn"
                                 onClick={() => {
                                    this.setState({ searchPerameter: "", inputValue: "" });
                                    const { setSearching } = this.context;
                                    setSearching(false);
                                 }}
                                 dangerouslySetInnerHTML={{ __html: convertToTeX('\\times') }}
                              >
                              </span>);
                           }
                           // else
                           // {
                           // return(<span className="btn"><span>\(\bullet\)</span></span>);
                           // }
                        })()}
                     </form>
                     {/* <img className="remove" onClick={this.openpop.bind(this)} src="../../images/Filter.png"/> */}
                  </div>
                  {this.state.searchloader == false ? this.state.searchPerameter != "" && this.state.searchinguser.length <= 0 ? <div className="loaderclass notfound">
                     <p>&ndash;</p>
                  </div> : "" :
                     this.state.searchinguser.length <= 0 ?
                        <div className="loaderclass userloder">
                           <img
                              src="../../images/newgif.gif"
                              alt="loading"
                           />
                        </div> : ""
                  }
                  {/* {
                     this.state.searchPerameter == "" ?
                        <div className="row bttndiv">
                           <div className="col-6 header_left ">
                              <a onClick={this.relevancySearch.bind(this)} href="#">relevancy</a>
                           </div>
                           <div className="col-4 header_center text-center">
                     <a onClick={this.mutualitySearch.bind(this)} href="#">mutuality</a>
                  </div>
                           <div className="col-6 header_right ">
                              <a onClick={this.alphabeticallySearch.bind(this)} href="#">alphabetically</a>
                           </div>
                        </div> : ""
                  } */}
               </section>
            </div>
            {/* {
     this.state.searchloader == true &&  this.state.searchPerameter != "" && this.state.searchinguser.length>0 ?
     <div className="loaderclass userlodertop">
         <img
             src="../../images/newgif.gif"
             alt="loading"
          /> 
          </div>:""
   } */}
            {
               this.state.searchPerameter != "" && this.state.searchinguser.length > 0 && this.state.searchloader == true ?
                  <div className="loaderclass userlodertop">
                     <img
                        src="../../images/newgif.gif"
                        alt="loading"
                     />
                  </div> : ""
            }

            <div className={this.state.searchPerameter == "" ? "tab-content tab-content3 searchlst kapil" : "tab-content tab-content3 searchlst kapil aftersearch"} id='scrollableDivOne_3'>
               {
                  (() => {
                     const searchLenghth = this.filterUserin(this.state.searchinguser).length;
                     const leftSearchColumn = this.state.searchinguser.slice(0, Math.ceil(searchLenghth / 2));
                     const rightSearchColumn = this.state.searchinguser.slice(Math.ceil(searchLenghth / 2), searchLenghth);
                     return this.state.searchPerameter !== "" ? (
                        <div className="row py-0 my-0">
                           <div className="col-6 leftSearchColumn" >
                              {leftSearchColumn.map((p, i) => {
                                 return (


                                    <Link to={{
                                       pathname: `/${p.username}`,
                                       state: { id: p.user_id }
                                    }} onClick={this.setStorage.bind(this, p)}
                                    >
                                       <div className="userin" id={p.user_id} data-div_name="#one_3">

                                          {/* <span class="imgg">
                                    {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage}
                                       align="images" />
                                       : <span className="multiuser" >{this.fetchmakeprofile(p.screenName)}</span>}
                                 </span> */}
                                          <h6 dangerouslySetInnerHTML={{ __html: formatSearchResult(p.screenName) }}></h6>

                                          {/* <p >{p.mutual} mutual</p> */}
                                       </div>
                                    </Link>

                                 )
                              })
                              }
                           </div>
                           <div className="col-6 rightSearchColumn" >
                              {rightSearchColumn.map((p, i) => {
                                 return (


                                    <Link to={{
                                       pathname: `/${p.username}`,
                                       state: { id: p.user_id }
                                    }} onClick={this.setStorage.bind(this, p)}
                                    >
                                       <div className="userin" id={p.user_id} data-div_name="#one_3">

                                          {/* <span class="imgg">
                                    {p.profileImage != "" ? <img className="u_img" src={this.state.baseUrl + p.profileImage}
                                       align="images" />
                                       : <span className="multiuser" >{this.fetchmakeprofile(p.screenName)}</span>}
                                 </span> */}
                                          <h6 dangerouslySetInnerHTML={{ __html: formatSearchResult(p.screenName) }}></h6>

                                          {/* <p >{p.mutual} mutual</p> */}
                                       </div>
                                    </Link>

                                 )
                              })
                              }
                           </div>
                        </div>
                     )
                        :
                        this.state.recomended?.length > 0 ?
                           <div>
                              <div className="userlist">
                                 {/* <h2 className="mt-0">Explore</h2> */}
                                 <InfiniteScroll
                                    dataLength={this.state.usersData.length - 10}
                                    // scrollThreshold={'0.6'}
                                    next={this.fetchMoreUserData}
                                    hasMore={this.state.hasMoreUser}
                                    loader={<div className="userlistloader"><img src="../../images/newgif.gif" alt="loading" /></div>}
                                    scrollableTarget="scrollableDivOne_3">
                                    <div className="row mainUserList">
                                       {/* <div className="sortingBtnWrapper">
                                          <div className="sortingBtn">
                                             <a onClick={this.relevancySearch.bind(this)} href="javascript:void(0)">R</a>
                                             <p onClick={this.relevancySearch.bind(this)} className='sortingBtnInfo'>Relevancy</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.temporallySearch.bind(this)} href="javascript:void(0)">T</a>
                                             <p onClick={this.temporallySearch.bind(this)} className='sortingBtnInfo'>Temporally</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.mutualitySearch.bind(this)} href="javascript:void(0)">M</a>
                                             <p onClick={this.mutualitySearch.bind(this)} className='sortingBtnInfo'>Mutuality</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.activitySearch.bind(this)} href="javascript:void(0)">A</a>
                                             <p onClick={this.activitySearch.bind(this)} className='sortingBtnInfo'>Activity</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.alphabeticallySearch.bind(this)} href="javascript:void(0)">A</a>
                                             <p onClick={this.alphabeticallySearch.bind(this)} className='sortingBtnInfo'>Alphabetically</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.PseudorandomlySearch.bind(this)} href="javascript:void(0)">P</a>
                                             <p onClick={this.PseudorandomlySearch.bind(this)} className='sortingBtnInfo'>Pseudorandomly</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.flipSearch.bind(this)} href="javascript:void(0)">F</a>
                                             <p onClick={this.flipSearch.bind(this)} className='sortingBtnInfo'>Flip</p>
                                          </div>
                                          <div className="sortingBtn">
                                             <a onClick={this.PopularitySearch.bind(this)} href="javascript:void(0)">P</a>
                                             <p onClick={this.PopularitySearch.bind(this)} className='sortingBtnInfo'>Popularity</p>
                                          </div>
                                       </div> */}
                                      {/* {
                                       this.renderUserGroups()
                                      } */}
                                      <div className="calendarGroupContainer">
                                          {
                                             this.state.usersData.length > 0 && this.state.usersData.map((item, index) => {
                                                const subtimetables = item.timetableData.subTimetables ? Object.entries(item.timetableData.subTimetables) : [];
                                                const activeTimetable = subtimetables.length && subtimetables.find(([subKey, subData]) => subData.isActive === true);
                                                
                                                return (
                                                   <div className={`calendarInnerWrapper`}>
                                                      <div className={`organizationTitle compressedTitle`}>
                                                         <Link
                                                            state={item?.userData?.user_id}
                                                            to={`/${item.userData.username}`}
                                                            // dangerouslySetInnerHTML={{ __html: formatTitleForOrganization( || "") }}
                                                            onClick={this.setStorage.bind(this, item?.userData)}
                                                         >{item?.userData?.screenName || ""}</Link>
                                                      </div>
                                                      {subtimetables && subtimetables?.length && activeTimetable?.length ? (
                                                         <Timetable
                                                            key={activeTimetable[0] ? activeTimetable[0] : index}
                                                            selfProfile={false}
                                                            isScreenVisible={this.props.isScreenVisible}
                                                            subspaceNumber={1}
                                                            calendar={`${item.userId}`}
                                                            userDetails={item.userData}
                                                            timetableData={activeTimetable[1]}
                                                            states={{
                                                               events: item.events,
                                                               otherMinimum: item.timetableData?.minDate,
                                                               otherMaximum: item.timetableData?.maxDate,
                                                               otherMonthYear: item?.monthYear,
                                                            }}
                                                            updateTimetableData={(data) => this.updateTimetableData(data, `${item.userId}`, activeTimetable[0])}
                                                            updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data, `${item.userId}`)}
                                                            updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetable(date, action, `${item.userId}`)}
                                                         />
                                                      ) : (
                                                         <Timetable
                                                            key={index}
                                                            selfProfile={false}
                                                            isScreenVisible={this.props.isScreenVisible}
                                                            subspaceNumber={1}
                                                            calendar={`${item.userId}`}
                                                            userDetails={item.userData}
                                                            timetableData={item.timetableData}
                                                            states={{
                                                               events: item.events,
                                                               otherMinimum: item.timetableData?.minDate,
                                                               otherMaximum: item.timetableData?.maxDate,
                                                               otherMonthYear: item?.monthYear,
                                                            }}
                                                            updateTimetableData={(data) => this.updateTimetableData(data, `${item.userId}`)}
                                                            updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data, `${item.userId}`)}
                                                            updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetable(date, action, `${item.userId}`)}
                                                         />
                                                      )}
                                                   </div>
                                                )
                                             })
                                          }
                                      </div>
                                    </div>
                                    {/* <div className="row ">
           { this.filterUser(this.state.recomended).map((u,i)=>{
               
            return(
            <div className="col-3">
               <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3" data-toggle="tab" >
               <Link to={{
               pathname: `/${u.username}`,
            state: {id:u.user_id}
             }} onClick={this.setStorage.bind(this,u)}
              >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser"+i}>
               <div className="profile_initials"> */}
                                    {/* {this.fetchmakeprofile(u.screenName)} */}
                                    {/* </div>
               </span>}</Link>
                  <p>{u.screenName}</p>
               </a>
            </div>
            )
               
            })
         }
            </div> */}
                                 </InfiniteScroll>
                              </div>
                              {/* <div className="userlist">
             <h2 className="mt-0">More</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=12 && i<=23)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3"  data-toggle="tab" >
                    <Link to={{
               pathname: `/${u.username}`,
            state: {id:u.user_id}
             }} onClick={this.setStorage.bind(this)}
              >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser"+i} >
               <div className="profile_initials">
                  {this.fetchmakeprofile(u.screenName)}
                  </div>
               </span>}</Link>
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div>
                 <div className="userlist">
             <h2 className="mt-0">More</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=24 && i<=35)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-div_name="#one_3"  data-toggle="tab" >
                    <Link to={{
               pathname: `/${u.username}`,
            state: {id:u.user_id}
             }} onClick={this.setStorage.bind(this)}
              >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser"+i} >
               <div className="profile_initials">
                  {this.fetchmakeprofile(u.screenName)}
                  </div>
               </span>}</Link>
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div>
                 <div className="userlist">
             <h2 className="mt-0">More</h2>
              <div className="row ">
                { this.filterUser(this.state.recomended).map((u,i)=>{
                    if(i>=36 && i<=59)
                    {
                 return(
                 <div className="col-3">
                    <a className="link" data-id={i} id={u.user_id} data-toggle="tab" >
                    <Link to={{
               pathname: `/${u.username}`,
            state: {id:u.user_id}
             }}  onClick={this.setStorage.bind(this)}
              >
               {u.profileImage!="" ? <img className="u_img" src={this.state.baseUrl+u.profileImage}
             align="images"/>
             
             :<span className={"multiuser"+i} >
               <div className="profile_initials">
                  {this.fetchmakeprofile(u.screenName)}
                  </div>
                  </span>}</Link>
                       <p>{u.screenName}</p>
                    </a>
                 </div>
                 )
                    }
                 })
              }
                 </div>
                 </div>          */}
                           </div>
                           : <div className="loaderclass">
                              <img
                                 src="../../images/newgif.gif"
                                 alt="loading"
                              />
                           </div>
                  })()}
            </div>
            <GuestFooter {...this.props} />
            <div className="op pupup3">
               <div className="remove h-100" onClick={this.openpop.bind(this)}></div>
               <ul>
                  <h5 className="text-center">sort</h5>
                  <li><a onClick={this.relevancySearchList.bind(this)} className="remove"><img src="../../images/More.png" /> relevancy</a></li>
                  {/* <li><a  onClick={this.mutualitySearchList.bind(this)} className="remove"><img src="../../images/mut.png"/> mutuality</a></li> */}
                  <li><a onClick={this.alphabeticallySearchList.bind(this)} className="remove"><img src="../../images/az.png" /> alphabetically</a></li>
                  <button onClick={this.openpop.bind(this)} className="upbtn remove">Cancel</button>
               </ul>
            </div>
         </div>
         //</div>

      );
   };
};



export default SecondTabs;
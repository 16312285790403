import moment from "moment";

export function convertMinutesToTwoDigit(number) {
    let numberStr = String(number)
    return numberStr.length == 1 ? `0${numberStr}` : numberStr
}

export function formatUnixTimestamp(unixTimestamp, timeZone) {    
    if(!unixTimestamp) {
        return [null, ''];
    }

    // Check if the timeZone is valid
    const validTimeZones = moment.tz.names();
    const finalTimeZone = validTimeZones.includes(timeZone) ? timeZone : moment.tz.guess();
    
    const dateObjUser = moment.tz(unixTimestamp * 1000, finalTimeZone);
    const time = moment(dateObjUser).format("h:mma");
    const isTimePresent = time != "12:00am";
    const localTimezone = !isTimePresent ? finalTimeZone : moment.tz.guess();

    const dateObj = moment.tz(unixTimestamp * 1000, localTimezone);
    const timeZoneAbbreviation = dateObj.zoneName();

    return [dateObj, timeZoneAbbreviation];
}
export const handleClickOfProfiles = (event, props, setVisitedPaths) => {
    // event.preventDefault();
    const eventTarget = event.target;
    const linkElement = eventTarget.classList.contains('linkFromLatex') ||
        eventTarget.classList.contains('plain-link')
        ? eventTarget
        : eventTarget.closest('.linkFromLatex');

    if (linkElement) {
        // add underline effect to the clicked element through the clicked class
        const childElement = linkElement.querySelector(':scope > .latex-italics');
        if (childElement) {
            childElement.classList.add('clicked');
            childElement.addEventListener('transitionend', () => {
                childElement.classList.remove('clicked');
            }, { once: true });
        }
          

        // then move to the functionality
        event.preventDefault();
        const href = linkElement.href;
        if (href.includes("timesort.com")) {
            handleTimesortRedirection(href, props, setVisitedPaths);
        } else {
            handleExternalRedirection(event, linkElement, props, setVisitedPaths);
        }
    } else {
        if (eventTarget) {
            if (eventTarget?.classList.contains('cover-block') || eventTarget?.classList.contains('subStacksListTitle')) return;            
            
            const parentLinkElement = eventTarget?.classList.contains('parentLinkFromLatex') ?
                eventTarget :
                eventTarget.parentElement?.parentElement.classList.contains('parentLinkFromLatex') ?
                    eventTarget.parentElement.parentElement : null;

            handleExternalRedirection(event, parentLinkElement, props, setVisitedPaths);
        }
    }
};

// Function to handle timesort.com redirection
export const handleTimesortRedirection = (href, props, setVisitedPaths) => {
    const { appContext } = props;

    if (appContext) {
        const { state, actions } = appContext;

        if (state.homePageActive.status && state.homePageActive.activeHomePageSubspace === 1) {
            actions.setHomePageActive({
                status: false,
                activeHomePageSubspace: null,
            });
        }
    }

    const urlAfterTimesort = href.split("timesort.com/")[1];
    if (props.history) {
        props.history.push(urlAfterTimesort);
        setVisitedPaths((prevPaths) => [...prevPaths, urlAfterTimesort]);
    }
}

// Function to handle external link redirection
const handleExternalRedirection = (event, linkElement, props, setVisitedPaths) => {
    if (linkElement) {
        event.preventDefault();
        const externalLink = linkElement.getAttribute("data-href") || linkElement.getAttribute("href");

        if (externalLink) {
            if (linkElement.target === "_blank") {
                window.open(externalLink, "_blank");
            } else {
                const newURL = `/external-link?url=${externalLink}`;
                if (props.history) {
                    props.history.push(newURL);
                    setVisitedPaths((prevPaths) => [...prevPaths, newURL]);
                }
            }
        }
    }
};
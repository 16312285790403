
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React from "react";
import "react-calendar/dist/Calendar.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "reactstrap";
import romans from "romans";
import { ControlBar, Player } from "video-react";
import Api from "../../RestApis/Api";
import { MyContext } from "../../context/index.js";
import { getdateformat } from "../../utils/dateFormatterForTI/dateFormatter.js";
import { getGrammarContainer, handleGrammarContainer, removeGrammarContainer } from '../../utils/footer/handleGrammarContainer.js';
import { getDualTooltipContainer, getTooltipContainer, handleDualTooltipContainer, handleTooltipContainer } from "../../utils/footer/handleTooltipContainer.js";
import { getRomanCountOfSubspacesInStack, formatRomanCount } from '../../utils/footer/romanCountOfSubspacesInStack.js';
import { getTabWithMostOpenProfilesInStack, getTabwiseOpenProfileScreenNames } from "../../utils/footer/tabWithMostOpenProfilesInStack.js";
import { formatDate, formatOrganizationName, getLinkElement } from "../../utils/math";
import { fetchTeXCompiledCaption, modifyHref, modifyWebsiteName } from "../../utils/post/manageCaptions";
import { addToRecents, seeLessLikeThis, seeMoreLikeThis } from "../../utils/profiles/manageProfilesList";
import { showInitials } from "../../utils/profiles/shared";
import { ReactComponent as TimelineSvg } from '../../utils/svg/Bidirectionally-traversed-timeline.svg';
import { ReactComponent as CalendarSvg } from '../../utils/svg/Calendar.svg';
import { ReactComponent as DepartSvg } from '../../utils/svg/Depart.svg';
import { ReactComponent as DownwardArrow } from '../../utils/svg/Down-facing-arrow.svg';
import { ReactComponent as FilterSvg } from '../../utils/svg/Filter.svg';
import { ReactComponent as BlockNavigation } from "../../utils/svg/Forward-slash.svg";
import { ReactComponent as HandShakeSvg } from '../../utils/svg/Handshake.svg';
import { ReactComponent as NewsPaperSvg } from '../../utils/svg/Newspaper.svg';
import { ReactComponent as RevisitSvg } from '../../utils/svg/Revisit.svg';
import { ReactComponent as ZZSvg } from '../../utils/svg/ZZ.svg';
import { determineDateToBeSelectForMonths, getActiveDateBasedOnImportantEvent, getActiveDatesOfTheMonth } from "../../utils/timetableHelper/timetableHelper.js";
import Timetable from "../Timetable/Timetable.js";
import CustomVideoPlayer from "../customVideoPlayer/customVideoPlayer.js";
import { handleClickOfProfiles } from "../../utils/profiles/clickHandler.js";

export const GRADIENT_COLORS = [
  { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
  { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
  { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
  { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
  { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
  { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class UserProfile extends React.Component {
  static contextType = MyContext
  timeoutId = null;
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      randColor: {},
      c_userid: "",
      cemail: "",
      cscreenname: "",
      ctotalpost: "",
      cusername: "",
      cfollowers: "",
      crequest: "",
      cbio: "",
      cfollowing: "",
      cprofileCover: "",
      cprofileImage: "",
      cuserid: "",
      ctoken: "",
      cmutual: "",
      cwebsite: "",
      schedule: "",
      c_isFollowing: 0,
      follower: 0,
      c_isRequested: 0,
      c_make_public: "",
      searchinguser: [],
      searchPerameter: "",
      otherMarks: [],
      other_minimum: "",
      other_maximum: "",
      NextO: "",
      PreviousO: "",
      profilecheckOther: "",
      valueCalendarOther: new Date(),
      otherProfiledata: [],
      slectedVar: "",
      evntData: [],
      contat: "of",
      topcontat: "of",
      baseUrl: process.env.REACT_APP_BASE_URL,
      baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
      profilecheckOther: "",
      evtId: "",
      time: false,
      mobi: 0,
      other_monthyear: [],
      umid: "",
      hasMore: true,
      profileType: "",
      seeLessPage: 1,
      seeMorePage: 1,
      clickedButtonToExit: "",
      savePost: [],
      activeTab: "",
      subspaceNumber: this.props.subspace.number,
      componentId: this.props.subspace.inWords + "_4",
      subspaceInWords: this.props.subspace.inWords,
      subspaceInRoman:romans.romanize(this.props.subspace.number).toLowerCase(),
      subspaceInCapWords: this.makeFirstLetterCapital(this.props.subspace.inWords),
      tabWiseData: {},
      f1: {}, f2: {}, f3: {}, s1: { userId: null, screenName: '' }, s2: {}, s3: {}, t1: {}, t2: {}, t3: {}, activeProfile: '', destinationInfo: '',
      leftSideArray: [],
      rightSideArray: [],
      currentMonthYear: "",
      monthYearDateMap: {},
      monthYearShortAndFull: {},
      preventNavigation: false,
      tabWithMostOpenProfiles: '', openProfilesInStack: '', numeralOpenProfilesInStack: '', subspaceNumberNumeral: '',
      tabwiseStackInfo:{
        first:{lengthInRoman:""},
        second:{lengthInRoman:""},
        third:{lengthInRoman:""}
     }, folder1: {}, folder2: {}, folder3: {},
     availableEventsOnDates: [],
     appendedTiles: {data: [], appendedMonth: null},
      userDetails: {},
      timetableData: {
        threeMonthsEarlier: {
          activeDate: undefined,
          prevDate: undefined,
          nextDate: undefined,
          minDate:undefined,
          maxDate:undefined,
          profileData: [],
          availableEventsOnDates: [],
          appendedTiles: {data: [], appendedMonth: null},
          savePost: []
       },
        twoMonthsEarlier: {
           activeDate: undefined,
           prevDate: undefined,
           nextDate: undefined,
           minDate:undefined,
           maxDate:undefined,
           profileData: [],
           availableEventsOnDates: [],
           appendedTiles: {data: [], appendedMonth: null},
           savePost: []
        },
        previous: {
           activeDate: undefined,
           prevDate: undefined,
           nextDate: undefined,
           minDate:undefined,
           maxDate:undefined,
           profileData: [],
           availableEventsOnDates: [],
           appendedTiles: {data: [], appendedMonth: null},
           savePost: []
        },
        current: {
           activeDate: undefined,
           prevDate: undefined,
           nextDate: undefined,
           minDate:undefined,
           maxDate:undefined,
           profileData: [],
           availableEventsOnDates: [],
           appendedTiles: {data: [], appendedMonth: null},
           savePost: []
        },
        next: {
           activeDate: undefined,
           prevDate: undefined,
           nextDate: undefined,
           minDate:undefined,
           maxDate:undefined,
           profileData: [],
           availableEventsOnDates: [],
           appendedTiles: {data: [], appendedMonth: null},
           savePost: []
        },
        twoMonthsAhead: {
           activeDate: undefined,
           prevDate: undefined,
           nextDate: undefined,
           minDate:undefined,
           maxDate:undefined,
           profileData: [],
           availableEventsOnDates: [],
           appendedTiles: {data: [], appendedMonth: null},
           savePost: []
        },
        threeMonthsAhead: {
          activeDate: undefined,
          prevDate: undefined,
          nextDate: undefined,
          minDate:undefined,
          maxDate:undefined,
          profileData: [],
          availableEventsOnDates: [],
          appendedTiles: {data: [], appendedMonth: null},
          savePost: []
       }
     },
     seeMoreSeeLessPopup: false,
     isProfileUpdatedInContext: false,
     updateTemporalInformation: false,
    };
    this.userBadgeRef = React.createRef();
    this.child = React.createRef()
    this.calendarRef = React.createRef();
    this.onchangelableOther = this.onchangelableOther.bind(this);
    this.getMonthYear = this.getMonthYear.bind(this);
  }

  handleMouseOver() {
    $(".katexContentBlock .katex a").attr("target", "_blank");
    $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
  }
  componentDidMount() {    
    document.addEventListener("mouseover", this.handleMouseOver);
    document.addEventListener('click', this.handleClickOfUserProfiles);

    localStorage.setItem("footer", "second");
    const { sharedData, setSharedData, activeTab } = this.context
    if (sharedData) {
      if (sharedData.pageWiseData[this.props.user_id]) {
        this.setState({ ...sharedData.pageWiseData[this.props.user_id] })
      }// else if (sharedData.lastViewedUser)
      // this.setState({ ...sharedData.lastViewedUser })
    }

    this.setState({ seeMorePage: 1, activeTab: activeTab, preventNavigation: false })

    $(`#${this.state.componentId} .upbutton`).click(function () {
      $(`#${this.state.componentId} .pupup2`).toggle();
    });
    $(`#${this.state.componentId} .upbutton2`).click(function () {
      $(`#${this.state.componentId} .pupup2`).toggle();
    });
    $(`#${this.state.componentId} .errorbtn`).click(function () {
      $(`#${this.state.componentId} .errorpop`).toggle();
    });
    $(`#${this.state.componentId} .remove`).click(function () {
      $(`#${this.state.componentId} .pupup3`).toggle();
    });
    // var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
    // this.setState({ randColor: random_color }, () => {
    //this.mainProfileColor();
    // })

    //  setting timeout of 1ms because state was not getting updated on time
    // this.setSubscript()
    setTimeout(() => {
      if (this.props.user_id)
        this.setfunction(this.props.user_id);

      this.showInitialsOfOpenProfilesOnTab()
    }, 1)

    this.updateCalendarStyling(null); //initially update the calendar styling according to available temporal information
  }

  handleClickOfUserProfiles = (event) => {
    handleClickOfProfiles(event, this.props, this.props.setVisitedPaths);
  };

  componentDidUpdate(prevProps) {
    const { appContext } = this.props;
    const { state } = appContext;

    // check when the homepage status will update
    if (prevProps.appContext.state !== state) {
      this.checkBackNavigation();
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.user_id) {
  //     this.setfunction(nextProps.user_id);
  //     setTimeout(() => {
  //       this.props.resetUserId()
  //     }, 500);

  //     this.setState({ activeTab: this.context.activeTab, seeMorePage: 1, })
  //     this.showInitialsOfOpenProfilesOnTab();
  //   }
  // }

  componentWillUnmount() {
    // document.title = "Timesort"
    this.updateSharedDataInContext();
    if (this.timeoutId)
      clearTimeout(this.timeoutId);
    document.addEventListener('click', this.handleClickOfUserProfiles);
  }

  componentWillReceiveProps(nextProps) {
  }

  getFolderWiseData() {
    const folderData = this.props.appContext.state.folderData;

    const folder1TabwiseOpenProfileScreenNames = folderData?.folder1 && getTabwiseOpenProfileScreenNames(folderData?.folder1);
    const folder2TabwiseOpenProfileScreenNames = folderData?.folder2 && getTabwiseOpenProfileScreenNames(folderData?.folder2);
    const folder3TabwiseOpenProfileScreenNames = folderData?.folder3 && getTabwiseOpenProfileScreenNames(folderData?.folder3);

    this.setState({ folder1: folder1TabwiseOpenProfileScreenNames, folder2: folder2TabwiseOpenProfileScreenNames, folder3: folder3TabwiseOpenProfileScreenNames });
  }

  updateSharedDataInContext() {
    if (!this.state.c_userid) return

    const { sharedData, setSharedData } = this.context
    // this.updateOpenedProfilesInContext();

    sharedData.pageWiseData[this.state.c_userid] = this.state

    setSharedData({
      ...sharedData,
      lastViewedUser: this.state,
    });
  }

  updateOpenedProfilesInContext() {
    if (this.state.isProfileUpdatedInContext) 
      return;

    const { appContext } = this.props;
    const {  actions } = appContext;
    const { setLastOpenedProfile } = actions;

    setLastOpenedProfile((prevLastOpenedProfile) => {
      const currentUserId = this.state.c_userid;

      const filteredProfiles = prevLastOpenedProfile.filter(
        (profile) => !profile[currentUserId]
      );

      const updatedProfiles = [
        ...filteredProfiles,
        {
          [currentUserId]: {
            userId: currentUserId,
            events: this.state.otherMarks,
            monthYearDateMap: this.state.monthYearDateMap,
            monthYear: this.state.other_monthyear,
            userData: this.state.userDetails,
            timetableData: this.state.timetableData['current'],
          },
        },
      ];

      return updatedProfiles;
    });
    
    this.setState({ isProfileUpdatedInContext: true });
  }

  /**
   * 
   * @param {string} str 
   * @returns same string with capitalized first letter
   */
  makeFirstLetterCapital(str) {

    if (!str) return str

    let strArray = str.split('')
    strArray[0] = strArray[0].toUpperCase()
    return strArray.join('')
  }

  /**
   * 
   * @param {string} buttonName 
   */
  updateClickedButtonToExit(buttonName) {
    this.updateOpenProfilesOnExit(buttonName);
    this.setState({ clickedButtonToExit: buttonName })
  }
  /**
   * 
   * @param {string} buttonName 
   */
  updateOpenProfilesOnExit(buttonName) {
    const { sharedData, setSharedData } = this.context;
    const activeTab = this.context.activeTab
    let openProfiles = [];
    let activeTabData = sharedData.tabWiseData[activeTab];

    if (activeTabData && activeTabData.openProfiles)
      openProfiles = activeTabData.openProfiles;

    if (buttonName == activeTab) {
      openProfiles = [];
    } else if (buttonName == "back" && openProfiles && openProfiles[openProfiles.length - 1] == this.state.pathname) {
      openProfiles.pop();
    } else if (buttonName != "back" && openProfiles && openProfiles[openProfiles.length - 1] != this.state.pathname) {
      openProfiles.push(this.state.pathname);
    }

    sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles };

    setSharedData({
      ...sharedData,
    });
  }

  mainProfileColor() {

    $(".username, .usernamecl").css("color", "");
    $(".username, .usernamecl").css("color", this.state.randColor.textColor);
    $(".username, .usernamecl").css("background-color", "");
    $(".username, .usernamecl").css("background-image", `linear-gradient(${this.state.randColor.firstColor}, ${this.state.randColor.secondColor})`);
  }
  clickpop() {
    $(`#${this.state.componentId} .errorpop2`).toggle();
  }
  setfunction(id) {

    const token = localStorage.getItem("token");
    if (token) {
      Api.actions(id, "follow");
    }

    this.Nextfunction(id, token);

  }
  Nextfunction(id, token) {
    // let url = "";
    // if (token) {
    const url = this.state.baseUrlNode + '/api/getUserDetails?user_id=' + id;
    // } else {
    //   url = this.state.baseUrl + "/api/user/getUserDetailsGuest.php?user_id=" + id;
    // }
    const post = this.state.savePost;
    const userData = post.find((data) => data.user == id);
    const outputFormat = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState(
          {
            userDetails: responseJson.response,
            cemail: responseJson.response.email,
            cmutual: responseJson.response.mutual,
            cscreenname: responseJson.response.screenName,
            ctotalpost: responseJson.response.totalPost,
            cusername: responseJson.response.username,
            cfollowers: responseJson.response.followers,
            crequest: responseJson.response.request,
            cbio: responseJson.response.bio,
            cfollowing: responseJson.response.following,
            cprofileCover: responseJson.response.profileCover,
            cprofileImage: responseJson.response.profileImage,
            cuserid: responseJson.response.user_id,
            c_userid: responseJson.response.user_id,
            ctoken: responseJson.response.token,
            cwebsite: responseJson.response.website,
            schedule: responseJson.response.schedule,
            c_isFollowing: responseJson.response.isFollowing,
            c_isRequested: responseJson.response.isRequested,
            time: true,
            follower: responseJson.response.isFollowers,
            c_make_public: responseJson.response.make_public,
            mobi: 1,
            profileType: responseJson.response.profileType,
          },
          () => {
            var userObj = { ...responseJson.response };
            addToRecents(userObj).then(() => {
              this.addProfileToOpenProfiles(userObj.user_id)
              this.showInitialsOfOpenProfilesOnTab();
              this.getActiveUser();

              // to check and prevent the back navigation after updating the context
              this.checkBackNavigation();
            })
            if (userData) {
              const convertedDate = moment.tz(userData.date, "DD-MM-YYYY", moment.tz.guess()).format(outputFormat);
              this.setState({
                otherProfiledata: userData.eventDetail, valueCalendarOther: new Date(convertedDate), profilecheckOther: userData.date,
                otherMarks: userData.allEvents, other_maximum: userData.maxDate, other_minimum: userData.minDate, PreviousO: userData.previousMonthyear, NextO: userData.nextMonthYear,
                other_monthyear: userData.otherMonthYear
              });
              return;
            } else {
              this.otherUserEvent(id);
            }
          }
        );

        //  var data = JSON.parse(localStorage.getItem("entry"));
        //  data.unshift(userObj);
        //  const key = "user_id";
        //  const arrayUniqueByKey = [
        //    ...new Map(data.map((item) => [item[key], item])).values(),
        //  ];

        //  if ((arrayUniqueByKey.length == 60) | (data.length <= 0)) {
        //    localStorage.removeItem("entry");
        //    localStorage.setItem("entry", JSON.stringify(arrayUniqueByKey));
        //  } else {
        //    arrayUniqueByKey.pop();
        //    localStorage.removeItem("entry");
        //    localStorage.setItem("entry", JSON.stringify(arrayUniqueByKey));
        //  }
      });
  }

  addTimeoutForDateChange = (callback) => {
    const now = new Date();
    const nextMidnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0, 0, 0, 0
    );

    const remainingTime = nextMidnight.getTime() - now.getTime();

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.setState({ updateTemporalInformation: true });

    this.timeoutId = setTimeout(() => {
      try {
        callback();
      } catch (error) {
        console.error('Error updating temporal information:', error);
      }
    }, remainingTime);

    return remainingTime;
  };

  updateTemporalInformation = () => {
    if (this.state.updateTemporalInformation) {
      this.setState({ updateTemporalInformation: false });
      this.addTimeoutForDateChange(this.updateTemporalInformation);
    }
  };

  fetchEvntListOther() {
    if(this.state.evntData.length >= 20) 
      return

    if (this.state.hasMore == true) {
      const variable = localStorage.getItem("userid");
      const token = localStorage.getItem("token");
      this.setState({ hasMore: true })
      setTimeout(() => {

        var date = Date.now() / 1000;
        var serverpath = this.state.baseUrl + "/api/event/getEventsUserSpecific.php?userID=" + this.state.c_userid + "&date=" + date;

        fetch(serverpath, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
            "Authorization": "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            const timeout = this.addTimeoutForDateChange(this.updateTemporalInformation);
            const events = responseJson.response;
            if (events.length < 20)
              this.setState({ contat: 'on', topcontat: 'on', hasMore: false })

            if (responseJson.message == "Event not available") {
              $(`#${this.state.componentId} .loaderclass`).removeClass("downscroll");
              $(`#${this.state.componentId} .loaderclass`).addClass("stopscroll");
            }
            if (events.length) {
              this.setState({ evntData: events })
              // var hash="#hashu"+events[events.length-5].eventID;
              // window.location.hash = hash;
              const centerEventIndex = Math.floor(events.length / 2)

              const centerEventHashDivSelector = `#hashu${events[centerEventIndex].eventID}`
              this.scrollTimelineToElementPosition(centerEventHashDivSelector);

            }
          });
      }, 100);
    }
  }

  /**
   * will scroll the Infinite Scroll to *offsetTop* position of the element selected by given jquery selector 
   * 
   * @param {string} selector HTML Element selector 
   */
  scrollTimelineToElementPosition(selector) {
    $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).scrollTop($(`#${this.state.componentId} ${selector}`)[0]?.offsetTop - 10);
  }

  otherUserEvent(id) {
    const token = localStorage.getItem("token");
    let url = "";
    if (token) {
      url = this.state.baseUrl + "/api/event/profileEventDates.php?profileId=" + id;
    } else {
      url = this.state.baseUrl + "/api/event/profileEventDatesGuest.php?profileId=" + id;
    }
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "TimeZone": moment.tz.guess(),
        "Authorization": "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.response.length > 0) {
          this.setState({ otherMarks: responseJson.response.sort((a, b) => (moment(a.eventOn, "DDMMYYYY") > moment(b.eventOn, "DDMMYYYY")) ? 1 : -1).reverse() }, () => {
            this.mapMonthYearToDate().then(() => this.updateMinMaxDateOfTimetables())
            var newMinimum = this.state.otherMarks[this.state.otherMarks.length - 1].eventOn;
            var newMaximum = this.state.otherMarks[0].eventOn;
            var min = moment(newMinimum, "DD/MM/YYYY");
            var minie = new Date(min);
            var maxo = moment(newMaximum, "DD/MM/YYYY");
            var me = new Date(maxo);
            this.setState({ other_minimum: minie, other_maximum: me }, () => {
              var array = [];
              for (let index = 0; index < this.state.otherMarks.length; index++) {
                var dateads = moment(this.state.otherMarks[index].eventOn, "DD/MM/YYYY");
                var elx = new Date(dateads);
                var finaldate = moment(elx).format("MMYYYY");
                array.push(finaldate);
              }
              this.setState({ other_monthyear: Array.from(new Set(array.reverse())), core: true }, () => {
                this.setState({ other_monthyear: this.state.other_monthyear.reverse() }, () => {
                  var newdate = new Date();
                  var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));
                  if (idxw && idxw != -1) {
                    var fdate = moment(newdate).format("MMYYYY");
                  }
                  else {
                    var checker = this.getlatest(responseJson.response, newdate);
                    if (checker == undefined) {
                      var metacroxs = this.getless(responseJson.response, newdate)
                    }
                    else {
                      var metacroxs = checker;
                    }
                    var minyy = moment(metacroxs, "DD/MM/YYYY");
                    var minieyy = new Date(minyy);
                    var fdate = moment(minieyy).format("MMYYYY");
                  }
                  var index_val = this.state.other_monthyear.indexOf(fdate);
                  if (index_val == 0) {
                    var previous = this.state.other_monthyear[index_val + 1];
                    this.setState({ NextO: "", PreviousO: this.checkdateOther(previous) }, () => {
                    });
                  }
                  else {
                    var previous = this.state.other_monthyear[index_val + 1];
                    var next = this.state.other_monthyear[index_val - 1];
                    this.setState({ NextO: this.checkdateOther(next), PreviousO: this.checkdateOther(previous) });
                  }
                })
              });
              var newdate = new Date();
              var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));
              if (idxw && idxw != -1) {
                var datescolor = this.state.otherMarks[idxw].eventOn;
                this.setState({ valueCalendarOther: newdate, profilecheckOther: datescolor });
                var min0 = moment(datescolor, "DD/MM/YYYY");
                var miniex = new Date(min0);
                this.onCalendarOtherUser(miniex);
              }
              else {
                var checker = this.getlatest(responseJson.response, newdate);
                if (checker == undefined) {
                  var metacroxs = this.getless(responseJson.response, newdate)
                }
                else {
                  var metacroxs = checker;
                }
                var datescolor = metacroxs;
                var min0 = moment(metacroxs, "DD/MM/YYYY");
                var miniex = new Date(min0);
                this.setState({ profilecheckOther: datescolor, valueCalendarOther: miniex });
                this.onCalendarOtherUser(miniex);
              }

              
              const userData = this.state.timetableData.current.savePost.find((data) => data.user == id);

              const shouldNotUpdateActiveDates = userData ? true : false
              this.showMonthYear(miniex, shouldNotUpdateActiveDates);
            });
          });
        }
        else {
          var olddate = new Date();
          this.setState({ other_minimum: olddate, other_maximum: olddate, valueCalendarOther: "" });
        }
      })
      .catch((error) => {
      })
  }

  updateMinMaxDateOfTimetables() {
    const otherMonthYear = this.state.other_monthyear;
    const monthYearDateMap = this.state.monthYearDateMap;
    this.setState(prevState => ({
       timetableData: {
          threeMonthsEarlier: {
             ...prevState.timetableData.threeMonthsEarlier,
             minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
             maxDate: monthYearDateMap[otherMonthYear[3]]?.maxDate || undefined,
          },
          twoMonthsEarlier: {
             ...prevState.timetableData.twoMonthsEarlier,
             minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
             maxDate: monthYearDateMap[otherMonthYear[2]]?.maxDate || undefined,
          },
          previous: {
             ...prevState.timetableData.previous,
             minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
             maxDate: monthYearDateMap[otherMonthYear[1]]?.maxDate || undefined,
          },
          current: {
             ...prevState.timetableData.current,
             minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
             maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
          },
          next: {
             ...prevState.timetableData.next,
             minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 2]]?.minDate || undefined,
             maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
          },
          twoMonthsAhead: {
             ...prevState.timetableData.twoMonthsAhead,
             minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 3]]?.minDate || undefined,
             maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
          },
          threeMonthsAhead: {
             ...prevState.timetableData.threeMonthsAhead,
             minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 4]]?.minDate || undefined,
             maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
          }
       }
    }));
 }

  getActiveUser() {
    const entry = JSON.parse(localStorage.entry);
    if (this.props.user_id) {
      const userId = this.props.user_id;
      const user = entry.find((user) => user.user_id == userId)?.screenName;
      if (user) {
        this.setState({ activeProfile: user });
      } else {
        setTimeout(() => {
          this.getActiveUser();
        }, [1000])
      }
    } else {
      setTimeout(() => {
        this.getActiveUser();
      }, [1000])
    }
  }

  //   determineDestination(tab) {
  //     const token = localStorage.getItem('token');
  //     const pathToRedirect = () => {
  //        const path = tab === 'first' && !token ? 'Settings' : tab === 'first' && token ? 'Self' :
  //                     tab === 'second' ? 'Temporal information' : 
  //                     tab === 'third' && this.state.profileType === 'consumer' ? 'Settings' : 'Enter'; 
  //        return path;
  //     } 

  //     let activeStack = '';
  //     if(tab == 'first') {
  //        activeStack = this.state.f3?.screenName || this.state.f2?.screenName || this.state.f1?.screenName || pathToRedirect()
  //     } else if (tab == 'second') {
  //        activeStack = this.state.s3?.screenName || this.state.s2?.screenName || this.state.s1?.screenName || pathToRedirect()
  //     } else if (tab == 'third') {
  //        activeStack = this.state.t3?.screenName || this.state.t2?.screenName || this.state.t1?.screenName || pathToRedirect()
  //     }
  //     this.setState({destinationInfo: activeStack});
  //  }

  showMonthYear(showDate, shouldNotUpdateActiveDates) {
    const monthsShort = {
      '01': "Jan",
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'Jun',
      '07': 'Jul',
      '08': 'Aug',
      '09': 'Sep',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec'
    };
    this.setState({ currentMonthYear: showDate }, () => {
      const dateActive = this.state.currentMonthYear
      const dateIndex = moment(dateActive).format("MMYYYY")
      const sortedArray = this.getSurroundingMonths(dateIndex);
      const result = sortedArray.map(({ month, year, originalIndex }, index) => {
        return {
           date: `${month < 10 ? '0' : ''}${month}${year}`,
           originalIndex,
           display: index < 5
        }
     })
        .sort((a, b) => a.originalIndex - b.originalIndex);

      const activeIndex = result.indexOf(dateIndex);
      const currentDate = new Date();
      const mappedArray = result.map(dateObj => {
        let { date, display } = dateObj
        console.log();
        const month = date.substr(0, 2);
        const year = date.substr(4, 2);
        const monthName = monthsShort[month];
        const currentYear = moment(currentDate).format('YYYY');
        const MY = `${monthName}`

        return { short: MY, date: this.state.monthYearDateMap[date].maxDate, isActiveMonth: date === dateIndex, display}
     });

     if (shouldNotUpdateActiveDates)
      return

      const { threeMonthsEarlier, twoMonthsEarlier, prev, next, twoMonthsAhead, threeMonthsAhead } = this.getActiveDatesOfNextPrevMonths(showDate, mappedArray);
      
      let activeDate = showDate;
      const currentMonthYear = moment(activeDate).format("MMYYYY");
      const activeMonthYear = moment().format("MMYYYY");

      if (currentMonthYear !== activeMonthYear) {
        const availableEvents = getActiveDatesOfTheMonth(showDate, this.state.otherMarks);
        activeDate = this.convertToDate(getActiveDateBasedOnImportantEvent(showDate, availableEvents));
      }
      
      this.setState(prevState => {
        const getAvailableEvents = (date) => getActiveDatesOfTheMonth(date, this.state.otherMarks);

        const threeMonthsEarlierEvents = getAvailableEvents(threeMonthsEarlier);
        const twoMonthsEarlierEvents = getAvailableEvents(twoMonthsEarlier);
        const prevEvents = getAvailableEvents(prev);
        const currentEvents = getAvailableEvents(showDate);
        const nextEvents = getAvailableEvents(next);
        const twoMonthsAheadEvents = getAvailableEvents(twoMonthsAhead);
        const threeMonthsAheadEvents = getAvailableEvents(threeMonthsAhead);

        return {
          leftSideArray: mappedArray.filter(map => map.display),
          timetableData: {
            threeMonthsEarlier: {
              ...prevState.timetableData.threeMonthsEarlier,
              activeDate: determineDateToBeSelectForMonths(threeMonthsEarlier, threeMonthsEarlierEvents),
              availableEventsOnDates: threeMonthsEarlierEvents,
            },
            twoMonthsEarlier: {
              ...prevState.timetableData.twoMonthsEarlier,
              activeDate: determineDateToBeSelectForMonths(twoMonthsEarlier, twoMonthsEarlierEvents),
              availableEventsOnDates: twoMonthsEarlierEvents,
            },
            previous: {
              ...prevState.timetableData.previous,
              activeDate: determineDateToBeSelectForMonths(prev, prevEvents),
              availableEventsOnDates: prevEvents,
            },
            current: {
              ...prevState.timetableData.current,
              activeDate: determineDateToBeSelectForMonths(activeDate, currentEvents),
              availableEventsOnDates: currentEvents,
            },
            next: {
              ...prevState.timetableData.next,
              activeDate: determineDateToBeSelectForMonths(next, nextEvents),
              availableEventsOnDates: nextEvents,
            },
            twoMonthsAhead: {
              ...prevState.timetableData.twoMonthsAhead,
              activeDate: determineDateToBeSelectForMonths(twoMonthsAhead, twoMonthsAheadEvents),
              availableEventsOnDates: twoMonthsAheadEvents,
            },
            threeMonthsAhead: {
              ...prevState.timetableData.threeMonthsAhead,
              activeDate: determineDateToBeSelectForMonths(threeMonthsAhead, threeMonthsAheadEvents),
              availableEventsOnDates: threeMonthsAheadEvents,
            }
          }
        }
      }, () => {
        this.updateOpenedProfilesInContext();
      })
    });
  }
  getClosestValues = (activeIndex, array) => {
    const parseMonthYear = (value) => {
      if (value) {
        const year = parseInt(value.slice(2, 6), 10);
        const month = parseInt(value.slice(0, 2), 10);
        return { year, month };
      }
    };
    const activeDate = parseMonthYear(activeIndex);
    const parsedArray = array.map((value, index) => ({ ...parseMonthYear(value), originalIndex: index }));
    const sortedArray = parsedArray.sort((a, b) => {
       const diffA = Math.abs((a.year - activeDate.year) * 12 + (a.month - activeDate.month));
       const diffB = Math.abs((b.year - activeDate.year) * 12 + (b.month - activeDate.month));
       return diffA - diffB;
    });
    return sortedArray;
 };
  getSurroundingMonths = (activeMonth) => {
    let months = [...this.state.other_monthyear];
    months.reverse();
    const activeIndex = months[months.indexOf(activeMonth)];
    const surroundingMonths = this.getClosestValues(activeIndex, months);
    return surroundingMonths;
  }
  mapMonthYearToDate = async () => {
    let lastMY = ""
    let lastMYDate = ""
    let monthYearDateMap = {}
    this.state.otherMarks.forEach((dateObj) => {
       var min = moment(dateObj.eventOn, "DD/MM/YYYY");
       var minie = new Date(min);
       const currentMY = moment(minie).format("MMYYYY")
       if (currentMY !== lastMY) {
          monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
          monthYearDateMap[currentMY] = { ...monthYearDateMap[lastMY], maxDate: minie }
       }
       lastMY = currentMY
       lastMYDate = minie
    })
    monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
    this.setState({ monthYearDateMap: { ...monthYearDateMap } })
 }

  checkdateOther(date) {
    for (let index = 0; index < this.state.otherMarks.length; index++) {
      var dateads = moment(this.state.otherMarks[index].eventOn, "DD/MM/YYYY");
      var elx = new Date(dateads);
      var finaldate = moment(elx).format("MMYYYY");
      if (date == finaldate) {
        return this.state.otherMarks[index].eventOn;
      }
    }
  }

  getless(delta, newdate) {
    for (let index = 0; index < delta.length; index++) {
      var dateadscc = "";
      var elxc = "";
      dateadscc = moment(delta[index].eventOn, "DD/MM/YYYY");
      elxc = new Date(dateadscc);
      if (moment(elxc).format("YYYYMMDD") < moment(newdate).format("YYYYMMDD")) {
        return delta[index].eventOn;
      }
    }
  }

  getlatest(delta, newdate) {
    for (var index = delta.length - 1; index >= 0; index--) {
      var dateadscc = "";
      var elxc = "";
      dateadscc = moment(delta[index].eventOn, "DD/MM/YYYY");
      elxc = new Date(dateadscc);
      if (moment(elxc).format("YYYYMMDD") > moment(newdate).format("YYYYMMDD")) {
        return delta[index].eventOn;
      }
    }
  }
  followusercancel2 = (e) => {
    var e = e.currentTarget.id;
    this.setState({ mobi: 0 }, () => {
      const body = { user_id: e };
      this.setState({ c_isRequested: 0 })
      const token = localStorage.getItem("token");
      axios.post(this.state.baseUrl + "/api/follow/cancelFollowRequest.php", body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      })
        .then((res) => {
        })
        .catch((error) => {
          console.log(error);
        })
      this.setState({ mobi: 1 });
    });

  }

  thiredSeeLesst(e) {
    e.stopPropagation();
    this.setState({seeMoreSeeLessPopup: false});
    if (this.state.evtId == "") {
      $(`#four1${this.state.componentId}`).addClass("closeBd");

      seeLessLikeThis(this.state.cuserid)

      setTimeout(() => {
        $(`#four1${this.state.componentId}`).removeClass("closeBd");
      }, 3000);
    }
    else {
      $(`${this.state.componentId} #promis` + this.state.evtId).addClass("closeBd");
      setTimeout(function () {
        $(`${this.state.componentId} #promis` + this.state.evtId).removeClass("closeBd");
      }.bind(this), 3000);
    }
  }
  followuser2 = (e) => {
    var play = e.currentTarget.id;
    this.setState({ mobi: 0 }, () => {
      if (this.state.c_make_public == 1) {
        this.setState({ c_isFollowing: 1 });
        var body = {
          user_id: play,
          isRequest: 1
        };
        console.log("bodypp", body);
      }
      else {
        this.setState({ c_isRequested: 1 });
        var body = {
          user_id: play,
          isRequest: 0
        };

      }

      const token = localStorage.getItem("token");
      axios.post(this.state.baseUrl + "/api/follow/sendFollowRequest.php", body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "TimeZone": moment.tz.guess(),
          "Authorization": "Bearer " + token,
        },
      })
        .then((res) => {
          this.comonuser();
        })
        .catch((error) => {
          console.log(error);
        })
      this.setState({ mobi: 1 });
    })

  }
  changeCurrentTimeOld(seconds, players) {
    let playerd = this[`ref${players}`].getState().player.currentTime;
    this[`ref${players}`].seek(playerd + seconds);
  }
  thiredSeeMoret(e) {
    e.stopPropagation();
    this.setState({seeMoreSeeLessPopup: false});
    if (this.state.evtId == "") {
      $(`#five1${this.state.componentId}`).addClass("closeBd");

      seeMoreLikeThis(this.state.cuserid, this.state.seeMorePage)
        .then((profiles) => {
          if (profiles.length > 0)
            this.setState({ seeMorePage: this.state.seeMorePage + 1 })
        })

      setTimeout(() => {
        $(`#five1${this.state.componentId}`).removeClass("closeBd");
      }, 3000);
    }
    else {
      $(`${this.state.componentId} #promos` + this.state.evtId).addClass("closeBd");
      setTimeout(function () {
        $(`${this.state.componentId} #promos` + this.state.evtId).removeClass("closeBd");
      }.bind(this), 3000);
    }
  }

  comonuser() {
    // const token = localStorage.getItem("token");
    fetch(this.state.baseUrlNode + '/api/getUserDetails?user_id='+ this.state.cuserid, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          c_isRequested: responseJson.response.isRequested,
          c_make_public: responseJson.response.make_public, c_isFollowing: responseJson.response.isFollowing, mobi: 1
        });
      }).catch((error) => {
        console.log(error);
      })
  }
  blockuser() {
    if (this.state.errortitle == "Unfollow " + this.state.cscreenname.split(" ")[0]) {
      var e = this.state.action;
      this.setState({ mobi: 0 }, () => {
        const body = { user_id: e };
        this.setState({ c_isRequested: 0, c_isFollowing: 0 }, () => {
          const token = localStorage.getItem("token");
          axios.post(this.state.baseUrl + "/api/follow/unFollow.php", body, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token,
            },
          })
            .then((res) => {
              this.comonuser();
            })
            .catch((error) => {
              console.log(error);
            })
        });
      })
    }
    else {
      this.setState({ mobi: 0 }, () => {
        if (this.state.errortitle == "Remove Follower") {
          const body = { user_id: this.state.cuserid }
          const token = localStorage.getItem("token");
          axios.post(this.state.baseUrl + "/api/follow/removeFollower.php", body, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token,
            },
          })
            .then((res) => {
              console.log("removeuser");
              this.comonuser();
            }).catch((error) => {
              console.log(error);
            })
        }
        else {
          const body = { profileId: this.state.cuserid }
          const token = localStorage.getItem("token");
          axios.post(this.state.baseUrl + "/api/user/blockUser.php", body, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token,
            },
          })
            .then((res) => {
              this.comonuser();
            }).catch((error) => {
              console.log(error);
            })
        }
      })
    }
  }

  updateCalendarData(data) {
    // if(data) {
    //    this.setState({
    //       availableEventsOnDates: data.availableEventsOnDates,
    //       appendedTiles: {
    //          data: data.appendedTiles.data,
    //          appendedMonth: data.appendedTiles.appendedMonth,
    //       }
    //    })
    // }
  }
 
  updateCalendarStyling (date) {
    // const calendarRef = this.calendarRef.current;

    // //initially update the calendar styling according to available temporal information
    // if(calendarRef) {
    //    updateStylingOfUserCalendar(
    //       calendarRef,
    //       date? date : null,
    //       {
    //          valueCalendarOther: this.state.valueCalendarOther,
    //          availableEventsOnDates: this.state.availableEventsOnDates,
    //          appendedTiles: this.state.appendedTiles,
    //          allEventsList: this.state.otherMarks,
    //       },
    //       this.updateCalendarData,
    //    );
    // } else {
    //    setTimeout(() => {
    //       this.updateCalendarStyling(date);
    //    }, 1);
    // }
  }

  updateTemporalInformationStyling() {
    // const calendarRef = this.calendarRef.current;
    // const availableEvents = this.state.availableEventsOnDates;

    // if(calendarRef && availableEvents.length) {
    //    handleTemporalInformationStyling(calendarRef, availableEvents);
    // } else {
    //    setTimeout(() => {
    //       this.updateTemporalInformationStyling();
    //    }, 1);
    // }
  }

  onCalendarOtherUser = (e) => {
    // this.updateCalendarStyling(e); //update the styling according to available temporal information

    // const token = localStorage.getItem("token");
    // const variable = this.state.cuserid;
    // const tiger = moment(e).format("DD-MM-YYYY");
    // if (this.state.otherMarks.find(x => x.eventOn === tiger)) {
    //   this.setState({ valueCalendarOther: e });
    //   let url = "";
    //   if (token) {
    //     url = this.state.baseUrl + "/api/event/profileEventDateWise.php?profileId=" + variable + "&date=" + tiger;
    //   } else {
    //     url = this.state.baseUrl + "/api/event/profileEventDateWiseGuest.php?profileId=" + variable + "&date=" + tiger;
    //   }
    //   fetch(url, {
    //     method: "GET",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       "TimeZone": moment.tz.guess(),
    //       "Authorization": "Bearer " + token,
    //     },
    //   })
    //     .then((response) => response.json())
    //     .then((responseJson) => {
    //       if (responseJson.message == "Something went wrong") {
    //         this.setState({ error: responseJson.message }, () => {
    //           $(`#${this.state.componentId} .errorpop2`).toggle();
    //         });
    //       }

    //       this.getNextForDate(e);
    //       const post = this.state.savePost;
    //       const opendUser = this.state.cuserid;
    //       const isUserPresent = post.findIndex((data) => data.user == opendUser);

    //       if (isUserPresent !== -1) {
    //         const updatedSavePost = post.map((data) => {
    //           if (data.user === opendUser) {
    //             return {
    //               ...data,
    //               date: tiger,
    //               eventDetail: responseJson.response,
    //               nextMonthYear: this.state.NextO,
    //               previousMonthyear: this.state.PreviousO,
    //             };
    //           } else {
    //             return data;
    //           }
    //         });
    //         this.setState({ savePost: updatedSavePost });
    //       }
    //       else {
    //         this.setState((prevState) => ({
    //           savePost: [
    //             ...prevState.savePost,
    //             {
    //               date: tiger,
    //               user: this.state.cuserid,
    //               eventDetail: responseJson.response,
    //               allEvents: this.state.otherMarks,
    //               minDate: this.state.other_minimum,
    //               maxDate: this.state.other_maximum,
    //               previousMonthyear: this.state.PreviousO,
    //               nextMonthYear: this.state.NextO,
    //               otherMonthYear: this.state.other_monthyear
    //             },
    //           ],
    //         }));
    //       }

    //       this.setState({ otherProfiledata: [] }, () => {
    //         this.setState({ otherProfiledata: responseJson.response }, () => {
    //           this.mainProfileColor()
    //         });
    //       });

    //       this.updateTemporalInformationStyling(); // update styling of temporal information block
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     })
    // }
  }

  updateTimetableData = (data, calendarView) => {
    if (data && ['current', 'next', 'previous', 'twoMonthsEarlier', 'twoMonthsAhead', 'threeMonthsEarlier', 'threeMonthsAhead'].includes(calendarView)) {
        this.setState((prevState) => ({
            timetableData: {
                ...prevState.timetableData,
                [calendarView]: {
                    ...prevState.timetableData[calendarView],
                    ...data,
                },
            },
        }));
    }
};

 updateStatesFromTimetable = (data) => {
    this.setState({
       currentMonthYear: data.activeDate,
       valueCalendarOther: data.activeDate,
       otherMarks: data.allEvents,
       other_minimum: data.minDate,
       other_maximum: data.maxDate,
       other_monthyear: data.otherMonthYear,
    })
 }

  updateActiveDatesOfTimetables(newActiveDate, action, calendar) {
    const getDate = (self, action) => {
      let date = this.state.timetableData[self][action + 'Date']
      if (date) {
        const formattedDate = moment(date, 'DD/MM/YYYY');
        const newDate = new Date(formattedDate);
        return newDate
      }
      else return date
    }

    let currentActiveDateNew = getDate('current', action)

    this.showMonthYear(currentActiveDateNew)
  }

  convertToDate = (activeDate) => {
    return activeDate?.eventOn ? moment(activeDate.eventOn, "DD-MM-YYYY").toDate() : undefined;
  }

  getActiveDatesOfNextPrevMonths(date, monthDateMapping) {
    const activeDates = {
      threeMonthsAhead: undefined,
      twoMonthsAhead: undefined,
      next: undefined,
      prev: undefined,
      twoMonthsEarlier: undefined,
      threeMonthsEarlier: undefined
    };

    const currentMonthIndex = monthDateMapping.findIndex(el => el.isActiveMonth);

    const processDateAtIndex = (index) => {
      const date = monthDateMapping[currentMonthIndex + index]?.date;
      const availableEvents = getActiveDatesOfTheMonth(date, this.state.otherMarks);
      return this.convertToDate(getActiveDateBasedOnImportantEvent(date, availableEvents));
    };

    activeDates.threeMonthsAhead = processDateAtIndex(3);
    activeDates.twoMonthsAhead = processDateAtIndex(2);
    activeDates.next = processDateAtIndex(1);

    let pastMonthsNeeded = 0;
    if (!activeDates.threeMonthsAhead) pastMonthsNeeded = 1;
    if (!activeDates.twoMonthsAhead) pastMonthsNeeded = 2;
    if (!activeDates.next) pastMonthsNeeded = 3;

    if (pastMonthsNeeded > 0) {
      activeDates.prev = processDateAtIndex(-1);
      if (pastMonthsNeeded > 1) {
        activeDates.twoMonthsEarlier = processDateAtIndex(-2);
        if (pastMonthsNeeded > 2) {
          activeDates.threeMonthsEarlier = processDateAtIndex(-3);
        }
      }
    }

    return activeDates;
  }

  weekDays = (dayNumber) => {
    if (dayNumber == 0) {
      return "Sunday";
    }
    if (dayNumber == 6) {
      return "Saturday";
    }
    if (dayNumber == 5) {
      return "Friday";
    }
    if (dayNumber == 4) {
      return "Thursday";
    }
    if (dayNumber == 3) {
      return "Wednesday";
    }
    if (dayNumber == 2) {
      return "Tuesday";
    }
    if (dayNumber == 1) {
      return "Monday";
    }
  }
  formatEventDateWithRelativeTime(daysDifference, eventDate, postedDate, time, isTimePresent, postedYear, currentYear) {
    var [hour, min, meridiem] = time.match(/(\d+):(\d+)([APMapm]{2})/).slice(1);
    var date = "";
    if (daysDifference <= -8 || daysDifference > 7) {
      const day = moment(eventDate).format("D");
      const monthAbbreviation = moment(eventDate).format("MMM");
      const monthMapping = {
        "Mar": "March",
        "Apr": "April",
        "Jun": "June",
        "Jul": "July"
      };
      const month = monthMapping[monthAbbreviation] || monthAbbreviation;
      var lasttag = "";

      if (isTimePresent)
        switch (day % 10) {
          case 1:
            if (day != 11) {
              lasttag = "st";
            } else {
              lasttag = "th";
            }
            break;
          case 2:
            if (day != 12) {
              lasttag = "nd";
            } else {
              lasttag = "th";
            }
            break;
          case 3:
            if (day != 13) {
              lasttag = "rd";
            } else {
              lasttag = "th";
            }
            break;
          default:
            lasttag = "th";
            break;
        }

      date = `${month} ${day}${lasttag}`;
      if (isTimePresent) {
        date += `, ${min > 0 ? time : hour + meridiem}`;
      }
      return date;
    }
    if (daysDifference < -1 && daysDifference > -8) {
      date = "last" + " " + this.weekDays(moment(postedDate).weekday());
      if (isTimePresent) {
        date += ", " + (min > 0 ? time : hour + meridiem);
      }
      return date;
    }
    if (daysDifference < 0 && daysDifference >= -1) {
      date = "yesterday";
      if (isTimePresent) {
        date += `, ${min > 0 ? time : hour + meridiem}`;
      }
      return date;
    }
    if (daysDifference >= 0 && daysDifference < 1) {
      date = "today";
      if (isTimePresent) {
        date += `, ${min > 0 ? time : hour + meridiem}`;
      }
      return date;
    }
    if (daysDifference >= 1 && daysDifference < 2) {
      date = "tomorrow";
      if (isTimePresent) {
        date += `, ${min > 0 ? time : hour + meridiem}`;
      }
      return date;
    }
    if (daysDifference >= 2 && daysDifference < 7) {
      date = "this" + " " + this.weekDays(moment(postedDate).weekday());
      if (isTimePresent) {
        date += ", " + (min > 0 ? time : hour + meridiem);
      }
      return date;
    }
    if (daysDifference == 7) {
      date = "next" + " " + this.weekDays(moment(postedDate).weekday());
      if (isTimePresent) {
        date += ", " + (min > 0 ? time : hour + meridiem);
      }
      return date;
    }
  }
  // formatTimeRange(time1, time2) {
  //   const [hour1, minute1] = time1.slice(0, -2).split(":").map(Number);
  //   const [hour2, minute2] = time2.slice(0, -2).split(":").map(Number);

  //   const amPm1 = time1.slice(-2);
  //   const amPm2 = time2.slice(-2);

  //   const adjustedHour1 = hour1 === 0 ? 12 : hour1;
  //   const adjustedHour2 = hour2 === 0 ? 12 : hour2;

  //   const minute1Str = convertMinutesToTwoDigit(minute1)
  //   const minute2Str = convertMinutesToTwoDigit(minute2)
    
  //   if ((amPm1 === "am" && amPm2 === "pm") || (amPm1 === "pm" && amPm2 === "am")) {
  //     return `${adjustedHour1}${minute1 != 0 ? ":" + minute1Str : ""}${amPm1}&ndash;${adjustedHour2}${minute2 != 0 ? ":" + minute2Str : ""}${amPm2}`;
  //   } else if ((adjustedHour1 != adjustedHour2) && ((amPm1 === "am" && amPm2 === "am") || (amPm1 === "pm" && amPm2 === "pm"))) {
  //     return `${adjustedHour1}&ndash;${adjustedHour2}${amPm1}`;
  //   } else {
  //     return `${adjustedHour1}:${minute1Str}&ndash;${minute2Str}${amPm1}`;
  //   }
  // }
//   getdateformat(datedata, datedata2, timeZone) {
//     const [date1, abbreviation1] = formatUnixTimestamp(datedata, timeZone);
//     const [date2, abbreviation2] = formatUnixTimestamp(datedata2, timeZone);
//     const time1 = moment(date1).format("h:mma");
//     const time2 = moment(date2).format("h:mma");
//     const currentDate = moment(new Date()).format("YYYY-MM-DD");
//     const currentYear = moment(currentDate, "YYYY-MM-DD").year();
//     const postedDate1 = moment(date1).format("YYYY-MM-DD");
//     const postedDate2 = moment(date2).format("YYYY-MM-DD");
//     const postedYear1 = moment(postedDate1, "YYYY-MM-DD").year();
//     const postedYear2 = moment(postedDate2, "YYYY-MM-DD").year();
//     const durationForDate1 = moment.duration(moment(postedDate1).diff(moment(currentDate)));
//     const durationForDate2 = moment.duration(moment(postedDate2).diff(moment(currentDate)));
//     const daysDifference1 = durationForDate1.asDays();
//     const daysDifference2 = durationForDate2.asDays();
//     const isTime1Present = time1 !== "12:00am";
//     const isTime2Present = time2 !== "12:00am";
//     if (postedDate1 && postedDate2 && postedDate1 === postedDate2) {
//        const formattedDate = this.formatEventDateWithRelativeTime(daysDifference1, date1, postedDate1, time1, isTime1Present, postedYear1, currentYear)
//        const timeRegex = /\b\d{1,2}(?::\d{2})?(?:am|pm)?\b/i;
//        const sentenceWithoutTime = formattedDate.replace(timeRegex, '').trim();
//        const formattedTime = this.formatTimeRange(time1, time2);
//        return `${sentenceWithoutTime} ${formattedTime}${isTime1Present || isTime2Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1}`;
//     } else if (date1 && date2 === null || date2 === '') {
//        var formattedDate = this.formatEventDateWithRelativeTime(daysDifference1, date1, postedDate1, time1, isTime1Present, postedYear1, currentYear)
//        formattedDate = formattedDate.replace(/,/, ' at');
//        return `${formattedDate}${isTime1Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1}`;
//     } else {
//        var formattedDate1 = this.formatEventDateWithRelativeTime(daysDifference1, date1, postedDate1, time1, isTime1Present, postedYear1, currentYear)
//        var formattedDate2 = this.formatEventDateWithRelativeTime(daysDifference2, date2, postedDate2, time2, isTime2Present, postedYear2, currentYear)
//        formattedDate1 = formattedDate1.replace(/,/, ' at');
//        formattedDate2 = formattedDate2.replace(/,/, ' at');
//        if (postedYear1 === postedYear2) {
//           if(abbreviation1 === abbreviation2) {
//              return `from ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''} to ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
//           }
//           return `from ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''} to ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
//        } else {
//           if(abbreviation1 === abbreviation2) {
//              return `from ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1} to ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
//           }
//           return `from ${formattedDate1}${isTime1Present ? ' ' + abbreviation1 : ''}${postedYear1 == currentYear ? '' : ', ' + postedYear1} to ${formattedDate2}${isTime2Present ? ' ' + abbreviation2 : ''}${postedYear2 == currentYear ? '' : ', ' + postedYear2}`;
//        }
//     }
//  }

  fetchEvntList2Other() {
    if (this.state.loadthired == false) {
      // let currentHeight =
      //   $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`)[0].scrollHeight -
      //   $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).height();

      $(`#${this.state.componentId} .loaderclass`).removeClass("removescrolling");
      const token = localStorage.getItem("token");
      this.setState({ hasMore: true, loaddocument: true, items: Array.from({ length: 20 }) })
      var time = Math.floor(new Date() / 1000);
      setTimeout(() => {
        $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).addClass("scrollbarhiden");
        let scrollId = ""
        if (this.state.inverse == false) {
          var serverpath = this.state.baseUrl + "/api/event/getEventsUserSpecific.php?userID=" + this.state.c_userid + "&date=" + this.state.evntData[this.state.evntData.length - 1].postDate + "&eventType=0&eventID=" + this.state.evntData[this.state.evntData.length - 1].eventID;
          scrollId = this.state.evntData[this.state.evntData.length - 1].eventID;
        }
        if (this.state.inverse == true) {
          var serverpath = this.state.baseUrl + "/api/event/getEventsUserSpecific.php?userID=" + this.state.c_userid + "&date=" + this.state.evntData[0].postDate + "&eventType=1&eventID=" + this.state.evntData[0].eventID;
          scrollId = this.state.evntData[0].eventID;
        }
        fetch(serverpath, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
            "Authorization": "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {

            if (responseJson.response.length < 20) {
              if (this.state.inverse)
                this.setState({ topcontat: 'on' });
              else
                this.setState({ contat: 'on' });
            }
            if (responseJson.response.length > 0) {
              var datas = responseJson.response;
              if (this.state.inverse == true) {

                for (var i = datas.length - 1; i >= 0; i--) {
                  this.state.evntData.unshift(datas[i]);
                }
              }
              else {
                for (var i = datas.length - 1; i >= 0; i--) {
                  this.state.evntData.push(datas[i]);
                }
              }
              if (this.state.inverse == false) {

                $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).addClass("scrollbarhiden");
                setTimeout(() => {
                  $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).removeClass("scrollbarhiden");
                }, 100)
              }
              if (this.state.inverse == true) {

                $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).addClass("scrollbarhiden");
                setTimeout(() => {
                  $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).removeClass("scrollbarhiden");
                }, 100)
              }
              // this.setState({ loaddocument: false }, () => {
              if (this.state.evntData.length > 20) {
                this.setState({ items: this.state.items.concat(Array.from({ length: 10 })) }, () => {
                });
              }
              // setTimeout(() => {
              this.setState({ loadthired: false });
              if (this.state.inverse) {
	              const scrollSelector = `#hashu${scrollId}`
              	this.scrollTimelineToElementPosition(scrollSelector)
              }
              // }, 100);
              // });
            }
            else {
              $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).addClass("scrollbarhiden");
              setTimeout(() => {
                $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).removeClass("scrollbarhiden");
              }, 100)
            }
            this.setState({ loaddocument: false })
          });
      }, 100);
    }
  }
  onscrolldocumentOther() {
    var pxcelate = $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`)[0].scrollHeight;
    var px = $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).scrollTop();
    if (px < 50) {
      if (this.state.topcontat == "of") {
        this.setState({ inverse: true, loadthired: false }, () => {
          if (px == 0) {
            this.timeout = setTimeout(() => {
              this.fetchEvntList2Other();
            }, 100);
            setTimeout(() => {
              if (this.timeout) {
                clearTimeout(this.timeout)
                this.timeout = null
              }
            }, 100);
          }
        });
        // $(`#${this.state.componentId} .loaderclass`).removeClass("bottomscrolls closeCd");
        // $(`#${this.state.componentId} .loaderclass`).addClass("topscrolls");
        $(`#${this.state.componentId} .contantf`).addClass("closeCd");
      }
      if (this.state.topcontat == "on") {
        $(`#${this.state.componentId} .contantf`).removeClass('closeCd');
        // $(`#${this.state.componentId} .loaderclass`).addClass('closeCd');
      }
      else {
        $(`#${this.state.componentId} .contantf`).addClass('closeCd');
      }
    }
    else {
      if (this.state.contat == "of") {
        this.setState({ inverse: false, loadthired: false }, () => {

          if (Math.round(px + $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).height()) == pxcelate) {
            this.timeout = setTimeout(() => {
              this.fetchEvntList2Other();
            }, 100);
            setTimeout(() => {
              if (this.timeout) {
                clearTimeout(this.timeout)
                this.timeout = null
              }
            }, 100);
          }
        });
        // $(`#${this.state.componentId} .loaderclass`).removeClass("topscrolls closeCd");
        // $(`#${this.state.componentId} .loaderclass`).addClass("bottomscrolls");
      }
      if (this.state.contat == "on" && Math.round(px + $(`#${this.state.componentId} #scrollableDiv${this.state.subspaceInCapWords}FourProfile`).height()) == pxcelate) {
        $(`#${this.state.componentId} .contantf`).removeClass("closeCd");
        // $(`#${this.state.componentId} .loaderclass`).addClass("closeCd");
      }
      else {
        $(`#${this.state.componentId} .contantf`).addClass("closeCd");
      }
    }
  }
  onchangelableOther() {
    if (this.state.profilecheckOther) {
      $(`.newscreen1 #${this.state.componentId} .react-calendar__navigation__prev-button`).unbind().click(function () {
        var mydate = this.state.PreviousO;

        var min = moment(mydate, "DD/MM/YYYY");
        var minie = new Date(min);
        this.showMonthYear(minie)
        this.setState({ valueCalendarOther: minie, currentMonthYear: minie }, () => {
          this.getNextPreviousOther(minie);
        });
      }.bind(this));
      $(`.newscreen1 #${this.state.componentId} .react-calendar__navigation__next-button`).unbind().click(function () {
        var mydatet = this.state.NextO;
        var minx = moment(mydatet, "DD/MM/YYYY");
        var minief = new Date(minx);
        this.showMonthYear(minief)
        this.setState({ valueCalendarOther: minief, currentMonthYear: minief }, () => {
          this.getNextPreviousOther(minief);
        });
      }.bind(this));
    }
  }

  getMonthYear(maxDate) {
    var mydate = maxDate;
    var min = moment(mydate, "DD/MM/YYYY");
    var minie = new Date(min);
    this.showMonthYear(minie)
    this.setState({ valueCalendarOther: minie }, () => {
      this.getNextPreviousOther(minie);
    });
  }
  getDateFullName(date) {
    if (date) {
      const month = moment(date).format('MMMM');
      const year = moment(date).format('YYYY');
      return month + ' ' + year;
    }
  }
  getNextPreviousOther(dates) {
    var e = dates;
    this.onCalendarOtherUser(e);
    const newDate = moment(dates).format("MMYYYY");
    var index_val = this.state.other_monthyear.indexOf(newDate);
    if (index_val == 0) {
      var previous = this.state.other_monthyear[index_val + 1];
      this.setState({ NextO: "", PreviousO: this.checkdateOther(previous) });
    }
    else {
      var previous = this.state.other_monthyear[index_val + 1];
      var next = this.state.other_monthyear[index_val - 1];
      this.setState({ NextO: this.checkdateOther(next), PreviousO: this.checkdateOther(previous) });
    }
  }
  getNextForDate(dates) {
    this.showMonthYear(dates)
    const newDate = moment(dates).format("MMYYYY");
    var index_val = this.state.other_monthyear.indexOf(newDate);
    if (index_val == 0) {
      var previous = this.state.other_monthyear[index_val + 1];
      this.setState({ NextO: "", PreviousO: this.checkdateOther(previous) });
    }
    else {
      var previous = this.state.other_monthyear[index_val + 1];
      var next = this.state.other_monthyear[index_val - 1];
      this.setState({ NextO: this.checkdateOther(next), PreviousO: this.checkdateOther(previous) });
    }
  }
  fetchmakeprofile(data) {
    var first = data.split(" ")[0];
    var firstn = first.charAt(0);
    if (data.split(" ")[1]) {
      var second = data.split(" ")[1];
      var secondn = second.charAt(0);
      return firstn + secondn;
    }
    else {
      return firstn;
    }
  }
  unfollowuser2 = (e) => {
    this.setState({
      action: e.currentTarget.id, errortitle: "Unfollow " + this.state.cscreenname.split(" ")[0]
      , editerror: "Are you sure you would like to unfollow " + this.state.cscreenname + ", " + localStorage.getItem("screenname")?.split(" ")[0] + "?"
    }, () => {
      $(`#${this.state.componentId} .errorpop`).toggle();
    })
  }
  likepost(e) {
    $(`${this.state.componentId} #` + e.currentTarget.id).toggleClass("thumbs-down");
    var hascc = $(`${this.state.componentId} div`).hasClass("thumbs-down");
    if (hascc == true) {
      setTimeout(function () {
        $(`${this.state.componentId} .thumbs-down`).removeClass("thumbs-down");
      }, 1500);
    }
    var evtid = e.currentTarget.id.replace("croxs", "");
    const body = {
      user_id: localStorage.getItem("userid"),
      eventID: evtid,
    }
    const token = localStorage.getItem("token");
    axios.post(this.state.baseUrl + "/api/event/addOrRemoveLike.php", body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    })
      .then((res) => {
        console.log("res");
      });
  }
  collectMentions(value) {
    var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
    var hash = value.match(/(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
    if (more || hash) {
      if (more !== null && more.length > 0) {
        $(document).ready(function () {
          for (let index = 0; index < more.length; index++) {
            $("." + more[index].substring(1)).unbind().click(function () {
              this.fetchuserid(more[index].substring(1));
            }.bind(this));
          }
        }.bind(this));
      }
      if (hash !== null && hash.length > 0) {
        console.log()
        $(document).ready(function () {
          for (let index = 0; index < hash.length; index++) {
            $("." + hash[index].substring(1)).unbind().click(function () {
              this.mainfuncion(hash[index].substring(1))
            }.bind(this));
          }
        }.bind(this));
      }
    }
  }

  fetchuserid(name) {
    fetch(this.state.baseUrl + '/api/user/search.php?exact_match=1&search=' + name, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
      },
    }).then((response) => response.json())
      .then((responseJson) => {
        var id = responseJson.response[0].user_id;
        var data = { ids: id };
        this.gouserprofile(data);
      })
  }

  /**
   * 
   * @param {String} jstatus 
   * @returns 
   */
  fetchafterfilter(jstatus) {
    this.collectMentions(jstatus);
    var regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&"\(\)\*\+=]+/ig;
    const replaced2 = jstatus.replace(regex, value => {
      const link = getLinkElement(value)
      return `<a href=${"https://" + value} target="_blank">${link}</a>`
    });
    //  var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
    var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
    var replaced7 = replaced2.replace(mention_preg, value => `<a class=${value.substring(1)}>${value}</a>`);
    var regex2 = /!+([a-zA-Z0-9_]+)/ig;
    var replaced3 = replaced7.replace(regex2, value => `<span>${value}</span>`);
    var replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, "<br>");
    return replaced;
  }
  setStorage() {
    localStorage.setItem("userpoint", "other");
    localStorage.setItem("backid", "front");
  }
  gobackpage() {
    this.updateClickedButtonToExit("back")
    if (this.props.visitedPaths.length > 0) {
      this.props.setVisitedPaths((prevPaths) => {
        const newPaths = prevPaths.slice(0, prevPaths.length - 1);
        return newPaths;
      });
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
    } else {
      this.props.history.push({
        pathname: "/",
      })
    }
  }
  checkBackNavigation() {
    const { activeTab } = this.context
    if (activeTab === 'second') {
      const { sharedData } = this.context;
      let activeTabData = sharedData.tabWiseData[activeTab]?.openProfiles;
      const { appContext } = this.props;
      const { state } = appContext;
      if (activeTabData?.length === 1 && state.homePageActive.status) {
        this.setState({ preventNavigation: true });
      } else {
        this.setState({ preventNavigation: false });
      }
    }
  }
  fetchmakeprofile(data) {
    var first = data.split(" ")[0];
    var firstn = first.charAt(0);

    if (data.split(" ")[1]) {
      var second = data.split(" ")[1];
      var secondn = second.charAt(0);
      return firstn + secondn;
    }
    else {
      return firstn;
    }
  }

  godateOther() {
    $(`#${this.state.componentId} #listuser`).removeClass("active");
    $(`#${this.state.componentId} #user`).addClass("active");
    $(`#${this.state.componentId}`).removeClass("text-left profile-pag");
    $(`#${this.state.componentId}`).addClass("screen1 else");
    $(`#${this.state.componentId} #one2list`).removeClass("active show");
    $(`#${this.state.componentId} #one2date`).addClass("active show");
  }
  godocumentOther() {
    $(`#${this.state.componentId} #user`).removeClass("active");
    $(`#${this.state.componentId} #listuser`).addClass("active");
    $(`#${this.state.componentId}`).removeClass("screen1");
    $(`#${this.state.componentId}`).addClass("text-left profile-pag");
    $(`#${this.state.componentId} #one2date`).removeClass("active show");
    $(`#${this.state.componentId} #one2list`).addClass("active show");
    this.setState({ fistname: 0 });
    this.fetchEvntListOther();
  }

  addProfileToOpenProfiles(userId) {
    const { sharedData, setSharedData } = this.context;
    const activeTab = this.context.activeTab
    let openProfiles = [];
    let activeTabData = sharedData.tabWiseData[activeTab];

    if (activeTabData && activeTabData.openProfiles.length)
      openProfiles = [...activeTabData.openProfiles];

    if (openProfiles[openProfiles.length - 1] !== userId)
      openProfiles.push(userId)

    sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles };

    setSharedData({
      ...sharedData,
    });
  }

  openUserProfile(id, tab) {
    const { appContext } = this.props;
    const { state, actions } = appContext;

    var e = { ids: id };
    const { sharedData } = this.context;
    const openedProfiles = sharedData.tabWiseData[tab].openProfiles;

    if (this.state.activeTab !== tab) {
      this.addProfilePathsToHistory(e, openedProfiles, tab);
    } else {
      this.context.setActiveTab(tab);
      this.setState({ activeTab: tab });
      this.gouserprofile(e);
    }
    if (tab !== 'second' && state.homePageActive.status && state.homePageActive.activeHomePageSubspace === this.state.subspaceNumber) {
      actions.setHomePageActive({
        status: false,
        activeHomePageSubspace: null,
      })
    }
  }

  async showInitialsOfOpenProfilesOnTab() {
    const { sharedData } = this.context;
    const tabWiseData = sharedData.tabWiseData;

    this.updateFolderWiseData()

    if (!localStorage.entry)
      return await new Promise((resolve) => setTimeout(() => resolve(this.showInitialsOfOpenProfilesOnTab()), 600))

    this.updateSubspaceStackIndicator(tabWiseData)

    const entry = JSON.parse(localStorage.entry);

    const getUserInfo = (id) => {
      const name = entry.find((user) => user.user_id == id)?.screenName;
      const data = { userId: id, screenName: name };
      return data;
    }

    const showData = (profiles, tab) => {
      if (!profiles || !profiles.openProfiles) {
        return {};
      }

      const dataLength = profiles?.openProfiles.length;
      var result = {};

      if (profiles.openProfiles && profiles.openProfiles.length > 0) {
        const openProfiles = profiles.openProfiles;
        result[`${tab}1`] = openProfiles.length === 1 ? getUserInfo(openProfiles[dataLength - 1]) : openProfiles.length === 2 ? getUserInfo(openProfiles[dataLength - 2]) : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 3]) : {};
        result[`${tab}2`] = (openProfiles.length === 1 || openProfiles.length === 0) ? {} : openProfiles.length === 2 ? getUserInfo(openProfiles[dataLength - 1]) : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 2]) : {};
        result[`${tab}3`] = (openProfiles.length === 1 || openProfiles.length === 2) ? {} : openProfiles.length >= 3 ? getUserInfo(openProfiles[dataLength - 1]) : {};
      }

      return result;
    };
    const firstTab = showData(tabWiseData["first"], 'F');
    const secondTab = showData(tabWiseData["second"], 'S');
    const thirdTab = showData(tabWiseData["third"], 'T');
    const data = { 'firstTab': firstTab, 'secondTab': secondTab, 'thirdTab': thirdTab }
    this.setState({
      f1: data['firstTab']?.['F1'] || {},
      f2: data['firstTab']?.['F2'] || {},
      f3: data['firstTab']?.['F3'] || {},
      s1: data['secondTab']?.['S1'] || {},
      s2: data['secondTab']?.['S2'] || {},
      s3: data['secondTab']?.['S3'] || {},
      t1: data['thirdTab']?.['T1'] || {},
      t2: data['thirdTab']?.['T2'] || {},
      t3: data['thirdTab']?.['T3'] || {},
    });
  }

  handleScreenDifferentiatorMouseOver(e, position) {
    this.getFolderWiseData();
    
    setTimeout(() => {
      handleDualTooltipContainer(e, position);
    }, 1);
  }

  async updateSubspaceStackIndicator(tabWiseData) {
    const { tab, openProfilesInStack } = getTabWithMostOpenProfilesInStack(tabWiseData)

    this.setState({ tabWithMostOpenProfiles: tab, openProfilesInStack });
    const numeralStack = openProfilesInStack === 'one' ? 'i' : openProfilesInStack === 'two' ? 'ii' : openProfilesInStack === 'three' ? 'iii' : '';
    this.setState({ numeralOpenProfilesInStack: numeralStack });
    const subspaceNumeral = this.state.subspaceNumber == 2 ? 'ii' : this.state.subspaceNumber == 3 ? 'iii' : '';
    this.setState({ subspaceNumberNumeral: subspaceNumeral });

    const folderData = this.props.appContext.state.folderData;

    const tabwiseOpenProfileScreenNames = getTabwiseOpenProfileScreenNames(tabWiseData);
    const folder1TabwiseOpenProfileScreenNames = folderData?.folder1 && getTabwiseOpenProfileScreenNames(folderData?.folder1);
    const folder2TabwiseOpenProfileScreenNames = folderData?.folder2 && getTabwiseOpenProfileScreenNames(folderData?.folder2);
    const folder3TabwiseOpenProfileScreenNames = folderData?.folder3 && getTabwiseOpenProfileScreenNames(folderData?.folder3);

      this.setState({
         tabwiseStackInfo: {
            first: { ...this.state.tabwiseStackInfo["first"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "first"), organizations: [...tabwiseOpenProfileScreenNames["first"] || []] },
            second: { ...this.state.tabwiseStackInfo["second"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "second"), organizations: [...tabwiseOpenProfileScreenNames["second"] || []] },
            third: { ...this.state.tabwiseStackInfo["third"], lengthInRoman: getRomanCountOfSubspacesInStack(tabWiseData, "third"), organizations: [...tabwiseOpenProfileScreenNames["third"] || []] },
         }
      })

 }

 updateFolderWiseData(){
  const { state, actions } = this.props.appContext;
  const { setFolderData } = actions;

  const { sharedData } = this.context;
  const tabWiseData = sharedData.tabWiseData;
  let keys= Object.keys(tabWiseData)
  let values = Object.values(tabWiseData)
  let data= {}

  values.forEach((val, ind) => {
     data[keys[ind]] = val
  })
  setFolderData((fData) => { return { ...fData, [`folder${this.state.subspaceNumber}`]: JSON.parse(JSON.stringify(data)) } })
}

  hitgoback() {
    this.props.callParentFunction("hitgoback")
  }
  goProfile() {
    this.props.callParentFunction("goProfile")
  }
  gotosetting() {
    this.props.callParentFunction("gotosetting")
  }
  fetchFilterUser() {
    this.props.callParentFunction("fetchFilterUser")
  }
  goToPost(e) {
    this.props.callParentFunction("goToPost", e)
  }
  gouserprofile(e) {
    this.props.callParentFunction("gouserprofile", e)
  }
  mainfuncion(e) {
    this.props.callParentFunction("mainfuncion", e)
  }
  addProfilePathsToHistory(e, openProfile, tab) {
    this.props.callParentFunction("addProfilePathsToHistory", { e, openProfile, tab })
  }

  // setSubscript() {
  //   if ($(` .newscreen${this.state.subspaceNumber} .footer-menu li`).is(".active"))
  //     $(`.newscreen${this.state.subspaceNumber} .footer-menu li a sub`).html(`<img src='/images/Checkmark.png' alt='active-img' />`)
  //   else
  //     $(`.newscreen${this.state.subspaceNumber} .footer-menu li a sub`).html(``)
  // }

  render() {

    $(".katexContentBlock .katex a").attr("target", "_blank");
    $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");

    return (
      //<div className="newscreen1 newscreen01">
      //<div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback abc" : "screen1 active fades nabc"}  id={`${this.state.componentId}`}>
      <div className={`${this.props.pos} screen1 ${this.props.subspace.class}`} id={`${this.state.componentId}`}>
        <div className="top newbg" id="myHeader">
          <section className="header">
            <div className="row">
              <div className=" header_left pr-0">
                <ul className="nav nav-tabs noteee">
                  <li>
                    <span defaultStyle="opacity:1" className={`${!this.state.preventNavigation ? 'linkback' : 'linkback blockNavigation'}`} onClick={!this.state.preventNavigation ? this.hitgoback.bind(this) : () => { }}>
                      <RevisitSvg className="backin" />
                      {this.state.preventNavigation ? <BlockNavigation class="blockedIcon" /> : ''}
                    </span>
                    <p onClick={!this.state.preventNavigation ? this.hitgoback.bind(this) : () => { }} className={`hoverIconInfo ${this.state.preventNavigation ? 'line-through' : ''}`}>Revisit</p>
                  </li>
                  <li>
                    <a className=" link active" onClick={this.godateOther.bind(this)} id="user" data-toggle="tab">
                      <CalendarSvg />
                    </a>
                    <p onClick={this.godateOther.bind(this)} id="user" className='hoverIconInfo'>Timetables</p>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-toggle="tab" className="" onClick={this.godocumentOther.bind(this)} id="listuser">
                      <TimelineSvg />
                    </a>
                    <p onClick={this.godocumentOther.bind(this)} id="listuser" className='hoverIconInfo'>Bidirectionally-traversed timeline</p>
                  </li>
                </ul>
              </div>
              <div className=" monthsBlock">
                <ul>
                  {
                    this.state.leftSideArray &&
                    this.state.leftSideArray.map((item) =>
                      <li>
                        <p className={`dateInfo ${item.isActiveMonth ? "active" : ""}`} onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                        <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                      </li>
                    )
                  }
                  {
                    this.state.rightSideArray &&
                    this.state.rightSideArray.map((item) =>
                      <li>
                        <p onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                        <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                      </li>
                    )
                  }
                </ul>
              </div>
              {/* <div className=" header_center pl-0 pr-0 text-center">
                <h3>
                  <a href="javascript:void(0)">{this.state.cscreenname}</a>
                </h3>
              </div> */}
              <div className=" header_right pl-0">
                <ul className="uld">
                  {/* {(() => {  if(this.state.mobi==1)
                                    {
 if(this.state.isBlocked==1 && this.state.c_make_public == 0)
{
return(<li id={this.state.cuserid} onClick={this.followuser2.bind(this)} className="following requst"><a className=""><span  className="follow" >request</span></a></li>);
}
else if(this.state.isBlocked==1 && this.state.c_make_public == 1)
{
   return(<li id={this.state.cuserid} onClick={this.followuser2.bind(this)}  className="following requst"><a className=""><span className="follow" >follow</span></a></li>);
}                                      
else if(this.state.c_isFollowing == 0 && this.state.c_make_public == 1 )
{
return(<li id={this.state.cuserid} onClick={this.followuser2.bind(this)} className="following requst"><a className=""><span  className="follow" >follow</span></a></li>);
}
else if(this.state.c_isFollowing == 0 && this.state.c_make_public == 0 && this.state.c_isRequested == 0 )
{
return(<li id={this.state.cuserid} onClick={this.followuser2.bind(this)} className="following requst"><a className=""><span className="follow" >request</span></a></li>);
}
else if(this.state.c_make_public == 0 && this.state.c_isRequested == 1)
{
return(<li id={this.state.cuserid} onClick={this.followusercancel2.bind(this)} className="following"><a className=""><span >requested</span></a></li> );
}
else if(this.state.c_isFollowing == 1)
{
return(<li id={this.state.cuserid} onClick={this.unfollowuser2.bind(this)} className="following"><a className=""><span >following</span></a></li>);
}
                                    }
  })()} */}
                  {this.state.schedule ? <li className="more abort" ><a href={modifyHref(this.state.schedule)} target='_blank'><DepartSvg /> <span>{modifyWebsiteName(this.state.schedule)}{modifyWebsiteName(this.state.schedule)?.endsWith("/") && <label className="hdots">...</label>}</span></a></li> : <li className="more abort" ></li>}
                  <li className="more" onClick={() => this.setState({seeMoreSeeLessPopup: true})}>
                    <FilterSvg />
                    <div className='hoverIconInfo'>
                      <p onClick={this.thiredSeeMoret.bind(this)}>see more like this</p>
                      <p onClick={this.thiredSeeLesst.bind(this)}>see less like this</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          {/* userpoint  */}
          {this.state.cbio && this.state.cbio !== null && this.state.cwebsite ? (
            <section className="userpoint seconduser">
              {/* <div className="test">
         {this.state.cprofileImage!="" ? <img src={this.state.baseUrl+this.state.cprofileImage} align="images"/> : <span className="usernamecl">{this.fetchmakeprofile(this.state.cscreenname)}</span> } 
        <ul>
               <li><a href="javascript:void(0)"><span><NumberFormat value={this.state.ctotalpost} displayType={"text"} thousandSeparator={true}/>
               </span> <span className
               ="namespan">Posts</span></a></li>
               <li><a className="link" onClick={this.therdPersonFollowers.bind(this)} data-toggle="tab" href="javascript:void(0)"><span><NumberFormat value={this.state.cfollowers} displayType={"text"} thousandSeparator={true}/></span><span className
               ="namespan">Followers</span></a></li>
               <li><a className="link" onClick={this.therdPersonFollowing.bind(this)} data-toggle="tab" href="javascript:void(0)"><span><NumberFormat value={this.state.cfollowing} displayType={"text"} thousandSeparator={true}/></span><span className
               ="namespan">Following</span></a></li>
               <li><a className="link" onClick={this.therdPersonmutual.bind(this)}  data-toggle="tab" href="javascript:void(0)"><span><NumberFormat value={this.state.cmutual} displayType={"text"} thousandSeparator={true}/></span><span className
               ="namespan">Mutual</span></a></li>
            </ul> 
        </div> */}
              {this.state.cbio && this.state.cbio !== null ? (
                <h3
                  dangerouslySetInnerHTML={{
                    __html: this.fetchafterfilter(this.state.cbio),
                  }}
                ></h3>
              ) : (
                <h3></h3>
              )}
              {this.state.cwebsite ? (
                <p>
                  <a href={"https://" + this.state.cwebsite}>
                    {this.state.cwebsite ? this.state.cwebsite : ""}
                  </a>
                </p>
              ) : (
                ""
              )}
              {/* {(() => {
      if(this.state.cwebsite){fetchafterfilter
      return(<p><a href={"https://"+this.state.cwebsite}>{this.state.cwebsite ? this.state.cwebsite : ""}</a></p>);
      }
      })()} */}
            </section>
          ) : (
            ""
          )}
        </div>
        <div className="tab-content tab-content4">
          {/* sectionone */}
          {this.state.time == true ? (
            <>
              <div id="one2date" className="tab-pane in active show">
                <div className="flex-center">
                  {
                    this.state.timetableData.current?.activeDate ? <Timetable
                      selfProfile={false}
                      isScreenVisible={this.props.isScreenVisible}
                      subspaceNumber={this.state.subspaceNumber}
                      calendar='current'
                      userDetails={this.state.userDetails}
                      timetableData={this.state.timetableData.current}
                      states={{
                        events: this.state.otherMarks,
                        otherMinimum: this.state.other_minimum,
                        otherMaximum: this.state.other_maximum,
                        otherMonthYear: this.state.other_monthyear,
                      }}
                      updateTimetableData={(data) => this.updateTimetableData(data, 'current')}
                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'current')}
                    /> : ''
                  }
                  {
                    this.state.timetableData.next?.activeDate ? <Timetable
                      selfProfile={false}
                      isScreenVisible={this.props.isScreenVisible}
                      subspaceNumber={this.state.subspaceNumber}
                      calendar='next'
                      userDetails={this.state.userDetails}
                      timetableData={this.state.timetableData.next}
                      states={{
                        events: this.state.otherMarks,
                        otherMinimum: this.state.other_minimum,
                        otherMaximum: this.state.other_maximum,
                        otherMonthYear: this.state.other_monthyear,
                      }}
                      updateTimetableData={(data) => this.updateTimetableData(data, 'next')}
                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'next')}
                    /> : ''
                  }
                  {
                    this.state.timetableData.twoMonthsAhead?.activeDate ? <Timetable
                      selfProfile={false}
                      isScreenVisible={this.props.isScreenVisible}
                      subspaceNumber={this.state.subspaceNumber}
                      calendar='twoMonthsAhead'
                      userDetails={this.state.userDetails}
                      timetableData={this.state.timetableData.twoMonthsAhead}
                      states={{
                        events: this.state.otherMarks,
                        otherMinimum: this.state.other_minimum,
                        otherMaximum: this.state.other_maximum,
                        otherMonthYear: this.state.other_monthyear,
                      }}
                      updateTimetableData={(data) => this.updateTimetableData(data, 'twoMonthsAhead')}
                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'twoMonthsAhead')}
                    /> : ''
                  }
                  {
                    this.state.timetableData.threeMonthsAhead?.activeDate ? <Timetable
                      selfProfile={false}
                      isScreenVisible={this.props.isScreenVisible}
                      subspaceNumber={this.state.subspaceNumber}
                      calendar='threeMonthsAhead'
                      userDetails={this.state.userDetails}
                      timetableData={this.state.timetableData.threeMonthsAhead}
                      states={{
                        events: this.state.otherMarks,
                        otherMinimum: this.state.other_minimum,
                        otherMaximum: this.state.other_maximum,
                        otherMonthYear: this.state.other_monthyear,
                      }}
                      updateTimetableData={(data) => this.updateTimetableData(data, 'threeMonthsAhead')}
                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'threeMonthsAhead')}
                    /> : ''
                  }
                  {
                    this.state.timetableData.previous?.activeDate ? <Timetable
                      selfProfile={false}
                      isScreenVisible={this.props.isScreenVisible}
                      subspaceNumber={this.state.subspaceNumber}
                      calendar='previous'
                      userDetails={this.state.userDetails}
                      timetableData={this.state.timetableData.previous}
                      states={{
                        events: this.state.otherMarks,
                        otherMinimum: this.state.other_minimum,
                        otherMaximum: this.state.other_maximum,
                        otherMonthYear: this.state.other_monthyear,
                      }}
                      updateTimetableData={(data) => this.updateTimetableData(data, 'previous')}
                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'previous')}
                    /> : ''
                  }
                  {
                    this.state.timetableData.twoMonthsEarlier?.activeDate ? <Timetable
                      selfProfile={false}
                      isScreenVisible={this.props.isScreenVisible}
                      subspaceNumber={this.state.subspaceNumber}
                      calendar='twoMonthsEarlier'
                      userDetails={this.state.userDetails}
                      timetableData={this.state.timetableData.twoMonthsEarlier}
                      states={{
                        events: this.state.otherMarks,
                        otherMinimum: this.state.other_minimum,
                        otherMaximum: this.state.other_maximum,
                        otherMonthYear: this.state.other_monthyear,
                      }}
                      updateTimetableData={(data) => this.updateTimetableData(data, 'twoMonthsEarlier')}
                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'twoMonthsEarlier')}
                    /> : ''
                  }
                  {
                    this.state.timetableData.threeMonthsEarlier?.activeDate ? <Timetable
                      selfProfile={false}
                      isScreenVisible={this.props.isScreenVisible}
                      subspaceNumber={this.state.subspaceNumber}
                      calendar='threeMonthsEarlier'
                      userDetails={this.state.userDetails}
                      timetableData={this.state.timetableData.threeMonthsEarlier}
                      states={{
                        events: this.state.otherMarks,
                        otherMinimum: this.state.other_minimum,
                        otherMaximum: this.state.other_maximum,
                        otherMonthYear: this.state.other_monthyear,
                      }}
                      updateTimetableData={(data) => this.updateTimetableData(data, 'threeMonthsEarlier')}
                      updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                      updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'threeMonthsEarlier')}
                    /> : ''
                  }
                </div>
                <div id={`five1${this.state.componentId}`} class="likeaddtos">
                  <img
                    class="w-100"
                    src="../../images/More.png"
                    alt="image"
                  />
                </div>
                <div class="likeaddtops" id={`four1${this.state.componentId}`}>
                  <img
                    class="w-100"
                    src="../../images/Erase.png"
                    alt="image"
                  />
                </div>
                {/* <div className="sectionone sectionone_div">
                  <div
                    className={
                      !this.state.cbio || !this.state.cwebsite
                        ? "inkap emptybio prin"
                        : "inkap"
                    }
                  >
                    <div className="inkapil">
                      {this.state.c_isFollowing == 0 &&
                        this.state.c_make_public == 0 ? (
                        <div className="blankcalendar requestcalender">
                          <div className="testc">
                            <p>
                              {this.state.cscreenname
                                ? "You must follow " +
                                this.state.cscreenname +
                                " to view any of " +
                                this.state.cscreenname.split(" ")[0] +
                                "s post, " +
                                localStorage.getItem("screenname")?.split(" ")[0]
                                : ""}
                            </p>
                          </div>
                          <span>
                            <img src="../../images/calendar.png" alt="image" />
                          </span>
                        </div>
                      ) : this.state.otherMarks.length > 0 ? (
                        <div>
                          <div id={`five1${this.state.componentId}`} class="likeaddtos">
                            <img
                              class="w-100"
                              src="../../images/More.png"
                              alt="image"
                            />
                          </div>
                          <div class="likeaddtops" id={`four1${this.state.componentId}`}>
                            <img
                              class="w-100"
                              src="../../images/Erase.png"
                              alt="image"
                            />
                          </div>

                          <div class="table" ref={this.calendarRef}>
                            <Calendar
                              onChange={this.onCalendarOtherUser.bind(this)}
                              activeStartDate={this.state.valueCalendarOther}
                              locale="en-US" // Set locale to US English
                              firstDayOfWeek={0}
                              minDate={this.state.other_minimum}
                              maxDate={this.state.other_maximum}
                              tileClassName={({ date, view }) => {
                                if (this.state.otherMarks.length > 0) {
                                  this.onchangelableOther();
                                  if (
                                    moment(this.state.valueCalendarOther).format(
                                      "DD-MM-YYYY"
                                    ) === moment(date).format("DD-MM-YYYY")
                                  ) {
                                    return "orange";
                                  }
                                  if (this.state.otherMarks.find((x) => x.eventOn === moment(date).format("DD-MM-YYYY"))) {
                                    var newdate = new Date();
                                    var idx = this.state.otherMarks.findIndex((x) => x.eventOn === moment(date).format("DD-MM-YYYY"));
                                    var idxdata =
                                      this.state.otherMarks[idx].dateCount;

                                    if (this.state.otherMarks[idx].importantEvents)
                                      return "darkblue";

                                    if (
                                      idxdata == 1 &&
                                      moment(date).format("DD-MM-YYYY") !=
                                      moment(newdate).format("DD-MM-YYYY")
                                    ) {
                                      return "highlight";
                                    }
                                    if (
                                      idxdata != 1 &&
                                      moment(date).format("DD-MM-YYYY") !=
                                      moment(newdate).format("DD-MM-YYYY")
                                    ) {
                                      return "darkblue";
                                    }
                                    var idxw = this.state.otherMarks.findIndex(
                                      (x) =>
                                        x.eventOn ===
                                        moment(newdate).format("DD-MM-YYYY")
                                    );
                                    if (idxw != -1) {
                                      var idxdataw =
                                        this.state.otherMarks[idxw].dateCount;
                                      if (idxdataw == 1) {
                                        return "green";
                                      }
                                      if (idxdataw != 1) {
                                        return "darkgreen";
                                      }
                                    } else {
                                      return "yellow";
                                    }
                                  } else {
                                    return "blocklight";
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : ("")}
                    </div>
                  </div>
                  {(this.state.c_isFollowing == 1) |
                    (this.state.c_make_public == 1) ? (
                    this.state.otherProfiledata.length > 0 ? (
                      <div className="mb-2">
                        {this.state.otherProfiledata.length > 0
                          ? this.state.otherProfiledata.map((j, x) => {
                            return (
                              <div className='temporal-information-block'>
                                <div class="likeaddtos" id={"promos" + j.eventID}>
                                  <img
                                    class="w-100"
                                    src="../../images/More.png"
                                    alt="image"
                                  />
                                </div>
                                <div class="likeaddtos" id={"promis" + j.eventID}>
                                  <img
                                    class="w-100"
                                    src="../../images/setting_dont_follow_back.png"
                                    alt="image"
                                  />
                                </div>
                                <div
                                  id={"croxs" + j.eventID}
                                  className="postlike"
                                  onDoubleClick={this.likepost.bind(this)}
                                >
                                  <div className="userin rmrf">
                                    <h1
                                      id={j.user_id}
                                      className="new-heading-tex-789"
                                      onMouseOver={(e) => handleDateDifferenceContainer(e, this.state.subspaceNumber)}
                                      dangerouslySetInnerHTML={{
                                        __html: formatDate(this.getdateformat(j.postedOn, j.postedOn2, j.timezone))
                                      }}
                                    ></h1>
                                    <DateDifferenceContainer />
                                    <h5>
                                      {j?.link ? (
                                        <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                          <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status) }} />
                                        </a>
                                      ) : (
                                        <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status) }} />
                                      )}
                                    </h5>
                                  </div>
                                  <div className="post ">
                                    {(() => {
                                      if (j.mediaType == "video") {
                                        return (
                                          <div
                                            id={"Video" + j.eventID}
                                            class="video-div"
                                          >
                                            <Player
                                              ref={(player) => {
                                                this[`ref${j.eventID}`] = player;
                                              }}
                                              id={"Videos" + j.eventID}
                                              autoPlay={false}
                                              className="video-react-has-started"
                                            >
                                              <source
                                                src={
                                                  this.state.baseUrl + j.eventImage
                                                }
                                                type="video/mp4"
                                              />
                                              <ControlBar
                                                autoHide={false}
                                                className="my-class"
                                              />
                                            </Player>
                                            <ul class="videoulkap pb-3">
                                              <li>
                                                <Button
                                                  data-id={"player" + j.id}
                                                  className={-10}
                                                  onClick={() =>
                                                    this.changeCurrentTimeOld(
                                                      -10,
                                                      j.eventID
                                                    )
                                                  }
                                                >
                                                  <img
                                                    class="img1"
                                                    src="../../images/Backwards.png"
                                                  />
                                                  <span>10</span>
                                                </Button>
                                              </li>
                                              <li>
                                                <img
                                                  class="img2"
                                                  src="../../images/pause.png"
                                                />
                                              </li>
                                              <li>
                                                <Button
                                                  data-id={j.id}
                                                  className={10}
                                                  onClick={() =>
                                                    this.changeCurrentTimeOld(
                                                      10,
                                                      j.eventID
                                                    )
                                                  }
                                                >
                                                  <img
                                                    class="img3"
                                                    src="../../images/Forwards.png"
                                                  />
                                                  <span>10</span>
                                                </Button>
                                              </li>
                                            </ul>
                                          </div>
                                        );
                                      } else if (j.mediaType == "image") {
                                        return (
                                          <img
                                            className="w-100 mt-1"
                                            src={this.state.baseUrl + j.eventImage}
                                            alt="image"
                                          />
                                        );
                                      }
                                    })()}
                                    <div className="heartitwo">
                                      <img
                                        className="w-100"
                                        src="../../images/cklick.png"
                                        alt="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <ul
                                  className={
                                    j.mediaType == null || j.mediaType == ""
                                      ? "videoul mt3 status"
                                      : "videoul mt3"
                                  }
                                >
                                  {(() => {
                                    if (j.withwho != null) {
                                      return (
                                        <li>
                                          <img
                                            src="../../images/b.png"
                                            alt="icon"
                                          />
                                          {(() => {
                                            if (j.withwho != null) {
                                              return j.withwho.map((k, l) => {
                                                return (
                                                  <span>
                                                    <a
                                                      id={k.user_id}
                                                      href="javascript:void(0)"
                                                      onClick={this.gouserprofile.bind(
                                                        this
                                                      )}
                                                    >
                                                      {k.screenName}
                                                    </a>
                                                    <span>, </span>
                                                  </span>
                                                );
                                              });
                                            } else {
                                              return (
                                                <a href="javascript:void(0)"></a>
                                              );
                                            }
                                          })()}
                                        </li>
                                      );
                                    }
                                  })()}
                                  {(() => {
                                    if (j.likedByUsers.length > 0) {
                                      return (
                                        <li>
                                          <img
                                            src="../../images/c.png"
                                            alt="icon"
                                          />
                                          {(() => {
                                            if (j.likedByUsers.length > 0) {
                                              return j.likedByUsers.map((q, t) => {
                                                return (
                                                  <span>
                                                    <a
                                                      id={q.user_id}
                                                      href="javascript:void(0)"
                                                      onClick={this.gouserprofile.bind(
                                                        this
                                                      )}
                                                    >
                                                      {q.screenName}
                                                    </a>
                                                    <span>, </span>
                                                  </span>
                                                );
                                              });
                                            }
                                          })()}
                                        </li>
                                      );
                                    }
                                  })()}
                                  {(() => {
                                    if (j.likedByUsers.length > 0) {
                                      return (
                                        <li>
                                          <img
                                            src="../../images/d.png"
                                            alt="icon"
                                          />
                                          {(() => {
                                            if (j.commentByUsers.length > 0) {
                                              return j.commentByUsers.map(
                                                (m, index) => {
                                                  return (
                                                    <span>
                                                      <a
                                                        id={m.user_id}
                                                        href="javascript:void(0)"
                                                        onClick={this.gouserprofile.bind(
                                                          this
                                                        )}
                                                      >
                                                        {m.screenName}
                                                      </a>
                                                      <span>, </span>
                                                    </span>
                                                  );
                                                }
                                              );
                                            }
                                          })()}
                                        </li>
                                      );
                                    }
                                  })()}
                                </ul>
                              </div>
                            );
                          })
                          : ""}
                      </div>
                    ) : ("")
                  ) : (
                    ""
                  )}
                </div> */}
              </div>

              <div id="one2list" className="tab-pane fade">
                <div
                  class={
                    this.state.evntData.length > 0
                      ? "loaderclass closeCdff"
                      : "loaderclass onscroll"
                  }
                >
                  <img src="../../images/newgif.gif" alt="loading" />
                </div>
                {/* <div
                  class={
                    this.state.contat == "of"
                      ? "loaderclass contantf closeCd"
                      : "loaderclass contantf"
                  }
                >
                  No More post Available
                </div> */}
                <div
                  className={
                    "sectionone changable" +
                    (!this.state.cbio || !this.state.cwebsite
                      ? " emptyBioWebsite"
                      : "")
                  }
                >
                  <div
                    id={`scrollableDiv${this.state.subspaceInCapWords}FourProfile`}
                    className={
                      this.state.evntData.length > 0
                        ? this.state.loaddocument == true
                          ? "scrollClass newscreen1 "
                          : "scrollClass newscreen1"
                        : "scrollClass newscreen1"
                    }
                    style={{
                      overflow: "auto",
                      display: "flex !important",
                      flexDirection: "row",
                    }}
                  >
                    {(this.state.updateTemporalInformation || !this.state.updateTemporalInformation) && 
                    <InfiniteScroll
                      className={
                        this.state.evntData.length > 0 ? "topbuttom_scroll" : ""
                      }
                      dataLength={this.state.evntData.length}
                      style={{ display: "flex", flexDirection: "row" }} //To put endMessage and loader to the top.
                      hasMore={true}
                      onScroll={this.onscrolldocumentOther.bind(this)}
                      loader={
                        <div className={`loaderclass ${this.state.inverse ? "topscrolls" : "bottomscrolls"} ${this.state.loaddocument ? "" : "closeCd"}`}>
                          <img src="../../images/newgif.gif" alt="loading" />
                        </div>
                      }
                      scrollableTarget={`scrollableDiv${this.state.subspaceInCapWords}FourProfile`}
                    >
                      <div className="mb-2 timeline-wrapper">
                        {this.state.evntData.map((j, i) => {
                          return (
                            <div className="timeline-event-box">
                              <span
                                id={"hashu" + j.eventID}
                                className={j.eventID}
                              ></span>

                              <div
                                id={"crox" + j.eventID}
                                className="postlike"
                                onDoubleClick={this.likepost.bind(this)}
                              >
                                <div className="userin rmrf">
                                  {/* <a href="#">{j.profileImage!="" ? <img src={this.state.baseUrl+j.profileImage} align="images"/> : <span className="username">{localStorage.getItem("staticname")}</span> }</a> */}
                                  <h1
                                    className="new-heading-tex-780"
                                    dangerouslySetInnerHTML={{
                                      __html: formatDate(
                                        getdateformat(j.postedOn, j.postedOn2, j.timezone)
                                      ),
                                    }}
                                  ></h1>
                                  <h5>
                                    {/* {j?.link ? (
                                      <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                        <div className='userLinkWrapper'>
                                        <img src="../../images/UD.png"></img>
                                        <div className="UserLinkDetails">
                                          <p><span className='userLinkSource'>Source:</span> {j?.source}</p>
                                          <p className='userLinkMessage'>Verify the correctness of temporal information via the original source, if you plan to act on it</p>
                                        </div>
                                        </div>
                                      </a>
                                    ) : ''} */}
                                    {j?.link ? (
                                      <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                        <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, this.state.subspaceNumber) }} />
                                      </a>
                                    ) : ( 
                                        <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, this.state.subspaceNumber) }} />
                                    )}
                                  </h5>
                                  {/* {(() => {
const regex = /(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&"\(\)\*\+=]+/ig;
const text = j.status;
const replaced2 = text.replace(regex, value => {
   const link = getLinkElement(value)
   return `<a href=${"https://"+value} target="_blank">${link}</a>`
});
const regex2 = /!+([a-zA-Z0-9_]+)/ig;
const replaced3 = replaced2.replace(regex2, value => `<span>${value}</span>`);
const replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, "<br>");
return(<h5 dangerouslySetInnerHTML={{__html: this.fetchafterfilter(j.status)}}>
</h5>);
})()} */}
                                  {/* {this.state.cbio && this.state.cbio !==null ? <h3 dangerouslySetInnerHTML={{__html: this.fetchafterfilter(this.state.cbio)}}></h3> : <h3></h3> }
{this.state.cwebsite ? <p><a href={"https://"+this.state.cwebsite}>{this.state.cwebsite ? this.state.cwebsite : ""}</a></p> :"" } */}
                                </div>
                                <div className="post ">
                                  {(() => {
                                    if (j.mediaType == "video") {
                                      return (
                                        <div
                                          id={"Video" + j.eventID}
                                          class="video-div"
                                        >
                                          <CustomVideoPlayer
                                            ref={(el) => (this[`ref${i}`] = el)}
                                            type="video/mp4"
                                            src={this.state.baseUrl + j.eventImage}
                                            autoPlay={false}
                                            onClose={() => { }}
                                            className={"video-react-has-started"}
                                            onPause={() => {}}
                                            onRewind={this.changeCurrentTimeNew(-10, i)}
                                            onForward={this.changeCurrentTimeNew(10, i)}
                                          />
                                          {/* <Player
                                            ref={(player) => {
                                              this[`ref${i}`] = player;
                                            }}
                                            autoPlay={false}
                                            className="video-react-has-started"
                                          >
                                            <source
                                              src={this.state.baseUrl + j.eventImage}
                                              type="video/mp4"
                                            />
                                            <ControlBar
                                              autoHide={true}
                                              className="my-class"
                                            />
                                          </Player> */}
                                          {/* <ul class="videoulkap pb-3">
                                            <li>
                                              <Button
                                                data-id={"player" + j.id}
                                                className={-10}
                                                onClick={() =>
                                                  this.changeCurrentTimeNew(-10, i)
                                                }
                                              >
                                                <img
                                                  class="img1"
                                                  src="../../images/Backwards.png"
                                                />
                                                <span>10</span>
                                              </Button>
                                            </li>
                                            <li>
                                              <img
                                                class="img2"
                                                src="../../images/pause.png"
                                              />
                                            </li>
                                            <li>
                                              <Button
                                                data-id={j.id}
                                                className={10}
                                                onClick={() =>
                                                  this.changeCurrentTimeNew(10, i)
                                                }
                                              >
                                                <img
                                                  class="img3"
                                                  src="../../images/Forwards.png"
                                                />
                                                <span>10</span>
                                              </Button>
                                            </li>
                                          </ul> */}
                                        </div>
                                      );
                                    } else if (j.mediaType == "image") {
                                      return (
                                        <img
                                          className="w-100 mt-1"
                                          src={this.state.baseUrl + j.eventImage}
                                          alt="image"
                                        />
                                      );
                                    }
                                  })()}
                                  <div className="hearti">
                                    <img
                                      className="w-100"
                                      src="../../images/cklick.png"
                                      alt="image"
                                    />
                                  </div>
                                </div>
                              </div>
                              <ul
                                className={
                                  j.mediaType == null
                                    ? "videoul mt3 status"
                                    : "videoul mt3"
                                }
                              >
                                <li>
                                  {/* {(() => { 
if(j.commentCounts>0)
{

return( <a className="link link2k" id={j.eventID} data-toggle="tab" >< Link to={{
   pathname:`/SinglePage/${j.eventID}`,
state: {back:"front",image:localStorage.getItem("profileImage"),name:localStorage.getItem("screenname"),username:localStorage.getItem("username")}
 }} onClick={this.setStorage.bind(this)} >
<img src="../../images/comment.png" Style="height:20px;"
alt="icon"/><NumberFormat value={j.commentCounts} displayType={"text"} thousandSeparator={true}/></Link></a>)
}
else
{
return(<a className="link" id={j.eventID}  data-toggle="tab" ><Link  to={{
   pathname:`/SinglePage/${j.eventID}`,
state: {back:"front",image:localStorage.getItem("profileImage"),name:localStorage.getItem("screenname"),username:localStorage.getItem("username")}
 }} onClick={this.setStorage.bind(this)}>
<img src="../../images/comment.png" Style="height:20px;"alt="icon"/></Link></a>)
}})()} */}
                                  {/* <a className="upbutton" id={j.eventID} onClick={this.thireduserpop2.bind(this)} ><img className="option ml-3" src="../../images/option.png"
      align="icon"/></a> */}
                                </li>

                                {(() => {
                                  if (j.withwho != null) {
                                    return (
                                      <li>
                                        <img src="../../images/b.png" alt="icon" />
                                        {(() => {
                                          if (j.withwho != null) {
                                            return j.withwho.map((k, l) => {
                                              return (
                                                <span>
                                                  <a
                                                    id={k.user_id}
                                                    href="javascript:void(0)"
                                                    onClick={this.gouserprofile.bind(
                                                      this
                                                    )}
                                                  >
                                                    {k.screenName}
                                                  </a>
                                                  <span>, </span>
                                                </span>
                                              );
                                            });
                                          } else {
                                            return <a href="#"></a>;
                                          }
                                        })()}
                                      </li>
                                    );
                                  }
                                })()}
                                {(() => {
                                  if (j.likedByUsers.length > 0) {
                                    return (
                                      <li>
                                        <img src="../../images/c.png" alt="icon" />
                                        {(() => {
                                          if (j.likedByUsers.length > 0) {
                                            return j.likedByUsers.map((q, t) => {
                                              return (
                                                <span>
                                                  <a
                                                    id={q.user_id}
                                                    href="javascript:void(0)"
                                                    onClick={this.gouserprofile.bind(
                                                      this
                                                    )}
                                                  >
                                                    {q.screenName}
                                                  </a>
                                                  <span>, </span>
                                                </span>
                                              );
                                            });
                                          }
                                        })()}
                                      </li>
                                    );
                                  }
                                })()}
                                {(() => {
                                  if (j.likedByUsers.length > 0) {
                                    return (
                                      <li>
                                        <img src="../../images/d.png" alt="icon" />
                                        {(() => {
                                          if (j.commentByUsers.length > 0) {
                                            return j.commentByUsers.map(
                                              (m, index) => {
                                                return (
                                                  <span>
                                                    <a
                                                      id={m.user_id}
                                                      href="javascript:void(0)"
                                                      onClick={this.gouserprofile.bind(
                                                        this
                                                      )}
                                                    >
                                                      {m.screenName}
                                                    </a>
                                                    <span>, </span>
                                                  </span>
                                                );
                                              }
                                            );
                                          }
                                        })()}
                                      </li>
                                    );
                                  }
                                })()}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="loaderclass">
              <img src="../../images/newgif.gif" alt="loading" />
            </div>
          )}
        </div>

        {localStorage.getItem("userid") ? (
          localStorage.getItem("profileType") === "consumer" ? (
            <footer className="ant-layout-footer">
              <div className="footer-menu menu1">
                {this.state.subspaceNumber === 3 ?
                  <div className="screenDifferentiator-container left-differentiator">
                    <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                      <ZZSvg />
                      <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                    </div>
                  </div> :
                  <div className='screenDifferentiator-container left-differentiator'>
                    <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                      <ZZSvg />
                      <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                    </div>
                  </div>}
                <ul className="flex align-center justify-center nav">
                  <div className="user-badge">
                    <div ref={this.userBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName(this.state.activeProfile, this.userBadgeRef.current) }} />
                  </div>
                  <li className={this.context.activeTab === "second" ? "active" : ""}>
                    {
                      this.state.tabwiseStackInfo.second.lengthInRoman &&
                      <div className='subspace-stack-indicator'>
                        <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                        <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                          <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                        </div>
                      </div>
                    }

                    <button onClick={this.fetchFilterUser.bind(this)} className={`bottomMenuBtn`}>
                      <div>
                        <CalendarSvg width={23} />
                        <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                          {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                          <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                        </sub>
                      </div>
                      {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                      <p className='destinationInfo'>{this.state.destinationInfo}</p>
                    </button>
                    <div className="footer-initials-group">
                      {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                      {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                      {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}

                    </div>
                  </li>
                  <li className={this.context.activeTab === "third" ? "active" : ""}>
                    {
                      this.state.tabwiseStackInfo.third.lengthInRoman &&
                      <div className='subspace-stack-indicator'>
                        <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                        <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                          <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                        </div>
                      </div>
                    }

                    <button data-toggle="tab" onClick={this.gotosetting.bind(this)} className={`bottomMenuBtn`}>
                      <div>
                        <HandShakeSvg width={43} />
                        <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                          {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                          <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                        </sub>
                      </div>
                      {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                    </button>
                    <div className="footer-initials-group">
                      {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                      {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                      {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}
                    </div>
                  </li>
                </ul>

                {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer(event,this.state.subspaceNumber)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                <div class="grammar-container">
                    <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                    <div class="grammar-table">
                      <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>{this.state.subspaceNumberNumeral}</span></span>)</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                    </div>
                </div></div> : ''} */}

                {this.state.subspaceNumber == 2 && window.innerWidth > 2149 ?
                  <div className="screenDifferentiator-container right-differentiator">
                    <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                      <ZZSvg />
                      <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                    </div>
                  </div> : ''}
              </div>
            </footer>
          ) : (
            <footer className="ant-layout-footer">
              <div className="footer-menu menu1 ">
                {this.state.subspaceNumber === 3 ? 
                  <div className="screenDifferentiator-container left-differentiator">
                    <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                      <ZZSvg />
                      <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                    </div>
                  </div> : 
                    <div className='screenDifferentiator-container left-differentiator'>
                      <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                        <ZZSvg />
                        <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                      </div>
                    </div>}
                <ul className="flex align-center nav justify-center">
                  <div className="user-badge">
                    <div ref={this.userBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName(this.state.activeProfile, this.userBadgeRef.current) }} />
                  </div>
                  <li
                    className={this.context.activeTab === "first" ? "active left" : ""}
                  >
                  {
                     this.state.tabwiseStackInfo.first.lengthInRoman && 
                     <div className='subspace-stack-indicator'>
                        <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                        <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                          <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                        </div>
                     </div>
                  }

                    <button onClick={this.goProfile.bind(this)} className={`bottomMenuBtn`}>
                      <div>
                        <NewsPaperSvg width={23} />
                          <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                            {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                            <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                          </sub>
                      </div>
                      {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                    </button>
                    <div className="footer-initials-group">
                      {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                      {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                      {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}

                    </div>
                  </li>
                  <li className={this.context.activeTab === "second" ? "active" : ""}>
                      {
                        this.state.tabwiseStackInfo.second.lengthInRoman &&
                        <div className='subspace-stack-indicator'>
                          <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                          <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                            <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                          </div>
                        </div>
                      }
                    <button onClick={this.fetchFilterUser.bind(this)} className={`bottomMenuBtn`}>
                      <div>
                        <CalendarSvg width={23} />
                          <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                            {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                            <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                          </sub>
                      </div>
                      {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                      <p className='destinationInfo'>{this.state.destinationInfo}</p>
                    </button>
                    <div className="footer-initials-group">
                      {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                      {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                      {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}

                    </div>
                  </li>
                  <li className={this.context.activeTab === "third" ? "active" : ""}>
                      {
                        this.state.tabwiseStackInfo.third.lengthInRoman &&
                        <div className='subspace-stack-indicator'>
                          <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                          <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                            <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                          </div>
                        </div>
                      }

                    <button data-toggle="tab" onClick={this.goToPost.bind(this)} className={`bottomMenuBtn`}>
                      <div>
                        <HandShakeSvg width={43} />
                          <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                            {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                            <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                          </sub>
                      </div>
                      {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                    </button>
                    <div className="footer-initials-group">
                      {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                      {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                      {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}
                    </div>
                  </li>
                </ul>

                {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer(event,this.state.subspaceNumber)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
                <div class="grammar-container">
                    <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                    <div class="grammar-table">
                      <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                        <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>{this.state.subspaceNumberNumeral}</span></span>)</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                    </div>
                </div></div> : ''} */}

                  {this.state.subspaceNumber == 2 && window.innerWidth > 2149 ?
                    <div className="screenDifferentiator-container right-differentiator">
                      <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                        <ZZSvg />
                        <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                      </div>
                    </div> : ''}
              </div>
            </footer>
          )
        ) : (
          <footer className="ant-layout-footer">
            <div className="footer-menu menu1">
                {this.state.subspaceNumber === 3 ?
                  <div className="screenDifferentiator-container left-differentiator">
                    <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                      <ZZSvg />
                      <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                    </div>
                  </div> :
                  <div className='screenDifferentiator-container left-differentiator'>
                    <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 1)}>
                      <ZZSvg />
                      <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder1, this.state.folder2)}</div>
                    </div>
                  </div>}
              <ul className="flex align-center nav justify-center">
                <div className="user-badge">
                  <div ref={this.userBadgeRef} dangerouslySetInnerHTML={{ __html: formatOrganizationName(this.state.activeProfile, this.userBadgeRef.current) }} />
                </div>
                <li className={this.context.activeTab === "first" ? "active left" : ""}>
                    {
                      this.state.tabwiseStackInfo.first.lengthInRoman &&
                      <div className='subspace-stack-indicator'>
                        <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 1)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.first.lengthInRoman) }}></span>
                        <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                          <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "i", this.state.tabwiseStackInfo.first.lengthInRoman)}</div>
                        </div>
                      </div>
                    }

                  <button onClick={this.gotosetting.bind(this)} className={`bottomMenuBtn`}>
                    <div>
                      <NewsPaperSvg width={23} />
                        <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                          {this.state.activeTab == 'first' ? <DownwardArrow /> : ''}
                          <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                        </sub>
                    </div>
                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                  </button>
                  <div className="footer-initials-group">
                    {this.state.f1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.f1?.userId, 'first')}>{showInitials(this.state.f1?.screenName)}<span>{this.state.f1?.screenName}</span></p> : ''}
                    {this.state.f2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f2?.userId, 'first')}>{showInitials(this.state.f2?.screenName)}<span>{this.state.f2?.screenName}</span></p> : ''}
                    {this.state.f3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.f3?.userId, 'first')}>{showInitials(this.state.f3?.screenName)}<span>{this.state.f3?.screenName}</span></p> : ''}
                  </div>
                </li>
                <li className={this.context.activeTab === "second" ? "active" : ""}>
                    {
                      this.state.tabwiseStackInfo.second.lengthInRoman &&
                      <div className='subspace-stack-indicator'>
                        <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 2)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.second.lengthInRoman) }}></span>
                        <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                          <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "ii", this.state.tabwiseStackInfo.second.lengthInRoman)}</div>
                        </div>
                      </div>
                    }
                  <button onClick={this.fetchFilterUser.bind(this)} className={`bottomMenuBtn`}>
                    <div>
                      <CalendarSvg width={23} />
                        <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                          {this.state.activeTab == 'second' ? <DownwardArrow /> : ''}
                          <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                        </sub>
                    </div>
                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                    <p className='destinationInfo'>{this.state.destinationInfo}</p>
                  </button>
                  <div className="footer-initials-group">
                    {this.state.s1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.s1?.userId, 'second')}>{showInitials(this.state.s1?.screenName)}<span>{this.state.s1?.screenName}</span></p> : ''}
                    {this.state.s2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s2?.userId, 'second')}>{showInitials(this.state.s2?.screenName)}<span>{this.state.s2?.screenName}</span></p> : ''}
                    {this.state.s3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.s3?.userId, 'second')}>{showInitials(this.state.s3?.screenName)}<span>{this.state.s3?.screenName}</span></p> : ''}

                  </div>
                </li>
                <li className={this.context.activeTab === "third" ? "active" : ""}>
                    {
                      this.state.tabwiseStackInfo.third.lengthInRoman &&
                      <div className='subspace-stack-indicator'>
                        <span onClick={(event) => handleGrammarContainer(event,this.state.subspaceNumber, 3)} dangerouslySetInnerHTML={{ __html: formatRomanCount(this.state.tabwiseStackInfo.third.lengthInRoman) }}></span>
                        <div onClick={(event) => removeGrammarContainer(event)} className="grammar-container-cover">
                          <div className="grammar-container">{getGrammarContainer(this.state.subspaceInRoman, "iii", this.state.tabwiseStackInfo.third.lengthInRoman)}</div>
                        </div>
                      </div>
                    }

                  <button data-toggle="tab" onClick={this.goToPost.bind(this)} className={`bottomMenuBtn`}>
                    <div>
                      <HandShakeSvg width={43} />
                        <sub onMouseOver={(e) => handleTooltipContainer(e, this.state.subspaceNumber)}>
                          {this.state.activeTab == 'third' ? <DownwardArrow /> : ''}
                          <div className='tooltip-container'>{getTooltipContainer(this.state.tabwiseStackInfo)}</div>
                        </sub>
                    </div>
                    {/* <p className='destinationInfo'>{this.state.destinationInfo}</p> */}
                  </button>
                  <div className="footer-initials-group">
                    {this.state.t1?.userId ? <p className='initial-item' onClick={() => this.openUserProfile(this.state.t1?.userId, 'third')}>{showInitials(this.state.t1?.screenName)}<span>{this.state.t1?.screenName}</span></p> : ''}
                    {this.state.t2?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t2?.userId, 'third')}>{showInitials(this.state.t2?.screenName)}<span>{this.state.t2?.screenName}</span></p> : ''}
                    {this.state.t3?.userId ? <p className='initial-item initial-delimiter' onClick={() => this.openUserProfile(this.state.t3?.userId, 'third')}>{showInitials(this.state.t3?.screenName)}<span>{this.state.t3?.screenName}</span></p> : ''}
                  </div>
                </li>
              </ul>

              {/* {this.state.openProfilesInStack ? <div className='subspace-stack-indicator'><p onMouseOver={(event) => handleGrammarContainer(event,this.state.subspaceNumber)}><span>{this.state.tabWithMostOpenProfiles}</span>&nbsp;{this.state.openProfilesInStack}</p>
              <div class="grammar-container">
                    <div class="grammar-heading"><p>Space <span className='mathcal'>S</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
                    <div class="grammar-table">
                      <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>point set</span> <span class="small-text">(<span className='text-gyre'><span className='katex-main-i'>{this.state.numeralOpenProfilesInStack}</span> on tab <span className='katex-main-i'>{this.state.tabWithMostOpenProfiles}</span> of folder <span className='katex-main-i'>{this.state.subspaceNumberNumeral}</span></span>)</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>tab</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>tab set</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder set</span></p></div>
                      <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="small-text">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>set stack set set</span></p></div>
                    </div>
                </div></div> : ''} */}

                {this.state.subspaceNumber == 2 && window.innerWidth > 2149 ?
                  <div className="screenDifferentiator-container right-differentiator">
                    <div className='screenDifferentiator' onMouseOver={(e) => this.handleScreenDifferentiatorMouseOver(e, 2)}>
                      <ZZSvg />
                      <div className='tooltip-container'>{getDualTooltipContainer(this.state.folder2, this.state.folder3)}</div>
                    </div>
                  </div> : ''}
            </div>
          </footer>
        )}
        <div className={`op pupup4 ${this.state.seeMoreSeeLessPopup ? 'zIndex' : ''}`}>
          <div className="more h-100" onClick={() => this.setState({seeMoreSeeLessPopup: false})}></div>
          <ul>
            <li>
              <a className="more" onClick={this.thiredSeeMoret.bind(this)}>
                <img src="../../images/More.png" /> see more like this
              </a>
            </li>
            <li>
              <a className="more" onClick={this.thiredSeeLesst.bind(this)}>
                <img src="../../images/Erase.png" /> see less like this
              </a>
            </li>
            {/* <li><Link className="more" 
            data-div_sname={this.state.cscreenname} data-div_image={this.state.cprofileImage} className={this.state.cusername} id={this.state.cuserid}  onClick={this.gochatting.bind(this)}
            ><img src="../../images/setting_request.png"/>{this.state.cscreenname ? "message "+this.state.cscreenname.split(" ")[0] : "message"}</Link></li>
         {this.state.follower==1 ? <li><a  className="more" onClick={this.removeuserpop.bind(this)}><img src="../../images/remove.png"/> remove follower</a></li> : "" } */}
            {/* <li><a  className="more" onClick={this.blockuserpop.bind(this)}><img src="../../images/block.png"/>{this.state.cscreenname ? "block "+this.state.cscreenname.split(" ")[0] : "block"}</a></li> */}
            {/* <li><span className="link more" ><a id={this.state.c_userid} className={this.state.cscreenname} onClick={this.goreport.bind(this)}><img src="../../images/report.png"/>{this.state.cscreenname ? "report "+this.state.cscreenname.split(" ")[0] :""}</a></span></li> */}
            <button className=" more upbtn" onClick={() => this.setState({seeMoreSeeLessPopup: false})}>
              Cancel
            </button>
          </ul>
        </div>
        <div className="op errorpop mainpop">
          <div className="errorbtn error h-100"></div>
          <ul>
            <li className="lipost">{this.state.errortitle}</li>
            <li>{this.state.editerror}</li>
            <div className="newpop">
              <button className="upbtn errorbtn">CANCEL</button>
              <button className="upbtn errorbtn" onClick={this.blockuser.bind(this)}>
                YES
              </button>
            </div>
          </ul>
        </div>

        <div className="op errorpop2">
          <div className="errorbtn2 error h-100"></div>
          <ul>
            <li>{this.state.error}</li>

            <button className="upbtn errorbtn2" onClick={this.clickpop.bind(this)}>
              ok
            </button>
          </ul>
        </div>
      </div>
    );
  };
};



export default UserProfile;
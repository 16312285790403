import React, { useEffect, useRef, forwardRef } from "react";
import { Player, ControlBar } from "video-react";

const CustomVideoPlayer = forwardRef(
   ({ src, autoPlay, onClose, onClick, className, onRewind, onPause, onForward }, ref) => {
      // Local ref for the whole component
      const localRef = useRef(null);

      // Effect for adding event listener
      useEffect(() => {
         const playerElement = ref?.current || localRef.current;

         const handleClick = (event) => {
            const button = event.target.closest("button.custom-video-player-btn");

            if (button) {
               button.classList.add("clicked");
               setTimeout(() => {
                  button.classList.remove("clicked");
               }, 1000);
            }

            if (onClick) {
               onClick(event);
            }
         };

         if (playerElement) {
            // Attach event listener to the player container
            playerElement.addEventListener("click", handleClick, true);
         }

         return () => {
            if (playerElement) {
               playerElement.removeEventListener("click", handleClick, true);
            }
         };
      }, [onClick, ref]);

      return (
         <div className="custom-video-player" ref={localRef}>
            <Player
               ref={ref}  // Forward the ref to the Player component
               autoPlay={autoPlay}
               className={className || "video-react-has-started"}
               controls
               playsInline
               disablePictureInPicture
            >
               {/* Close Button */}
               <div className="btncrose" onClick={onClose}>
                  <img className="closeImage" src="../../images/closeImage.png" alt="Close" />
               </div>

               {/* Video Source */}
               <source src={src} type="video/mp4" />

               {/* Control Bar */}
               <ControlBar autoHide={false} className="my-class" />
            </Player>

            {/* Custom Controls */}
            <ul className="videoulkap pb-3">
               <li>
                  <button className="custom-video-player-btn" onClick={onRewind}>
                     <img className="img1" src="../../images/Backwards.png" alt="Rewind" />
                     <span>10</span>
                  </button>
               </li>
               <li>
                  <button className="custom-video-player-btn" onClick={onPause}>
                     <img className="img2" src="../../images/pause.png" alt="Pause" />
                  </button>
               </li>
               <li>
                  <button className="custom-video-player-btn" onClick={onForward}>
                     <img className="img3" src="../../images/Forwards.png" alt="Forward" />
                     <span>10</span>
                  </button>
               </li>
            </ul>
         </div>
      );
   }
);

export default CustomVideoPlayer;
import React, { Component } from "react";
import ExternalPageDisplay from "../ExternalPageDisplay/ExternalPageDisplay";
import { ReactComponent as RevisitSvg } from "../../utils/svg/Revisit.svg";
import Footer from "../Footer";
import GuestFooter from "../GuestFooter";

class ExternalPage extends Component {
    constructor(props) {
        super(props);
        const path = this.props.location.pathname;
        const params = this.props.location.search.split('?url=')[1];
        this.state = {
            externalUrl: params
        }
    }

    // when the url is not given it redirect to home
    componentDidMount() {        
        if (!this.state.externalUrl) {
            this.props.history.push('/');
        }
    }

    goToPrevious() {
        localStorage.setItem("backid", "back");
        this.props.history.goBack();
    }

    render() {
        const { externalUrl } = this.state;
        return (
            <div className={`${this.props.pos} externalPage`} id="one_24">
                <div className="top" id="myHeader">
                    <section className="header headeredit">
                        <div className="row">
                            <div className="col-4 header_left pr-0">
                                <ul className="nav nav-tabs">
                                    <li onClick={this.goToPrevious.bind(this)}>
                                        <a className="linkback" data-toggle="tab"><RevisitSvg /></a>
                                        <p class="hoverIconInfo">Revisit</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="header_center pl-0 pr-0 text-center">
                                <h6>external link</h6>
                            </div>
                        </div>
                    </section>
                </div>
                <ExternalPageDisplay url={externalUrl} />
                {localStorage.getItem('token') ? <Footer {...this.props} /> : <GuestFooter {...this.props} />}
            </div>
        );
    }
}

export default ExternalPage;
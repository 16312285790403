import React, { useEffect, useState } from "react";
import CustomPopUp from "./CustomPopup";

function ProtectedPage({ pageId, config, children }) {
  const [errorMessages, setErrorMessages] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(() => !!localStorage.getItem(`access-${pageId}`));
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const auth = localStorage.getItem(`access-${pageId}`);
    if (auth) {
      setIsAuthorized(true);
    }
  }, [pageId]);

  const validPassword = config[pageId];

  const handleSubmit = (event) => {
    event.preventDefault();
    const passValue = event.target.pass.value;

    if (!passValue) {
      setErrorMessages("Password is required");
      return;
    }

    if (passValue === validPassword) {
      localStorage.setItem(`access-${pageId}`, "true");
      if (pageId === 'dashboard') {
        window.location.href = "/";
        return;
      }
      window.location.reload();
    } else {
      setOpenPopup(true);
    }
  };

  // Login form JSX
  const renderForm = (
    <div className="form login">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" />
          {errorMessages && <div className="error">{errorMessages}</div>}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return isAuthorized ? (
    <>{children}</>
  ) : (
    <>
      <div className="app-login">
        <div className="login-form-login">
          <div className="title">Sign In</div>
          {renderForm}
        </div>
      </div>
      {openPopup && (
        <CustomPopUp
          isOpen={openPopup}
          onClose={() => setOpenPopup(false)}
          popup={{
            heading: "Invalid identifier",
            message: "Enter a valid identifier.",
            onClick: () => setOpenPopup(false),
          }}
          multipleButton={false}
        />
      )}
    </>
  );
}

export default ProtectedPage;
import { renderMathToString } from "../math";
import { customCommandsPattern, customSpecialCommandsPattern, encloseTeXCommandInDelimiter, getTeXCommand, } from "./commands";

/**
* 
* @param {String} caption post caption with tex code
* @returns 
*/
export function fetchTeXCompiledCaption(caption, subspaceNumber, isTrimmed = false, eventID) {    
    caption = caption.replace("\\n", "\n")
    const regex = /\$\$([\s\S]*?)\$\$|\$([\s\S]*?)\$|\\\[([\s\S]*?)\\\]|\\\(([\s\S]*?)\\\)|([^\$\\]+)/g;
    // const newLineTeX = "}\\\\\\text{";

    // fixing caption by replacing HTML tags with TeX expressions
    // Replace <br> or <div> with newline
    caption = caption.replace(/<br>|<div>/g, "\n");

    // Remove </div>
    caption = caption.replace(/<\/div>/g, "");

    // Replace <u> with \underline{
    caption = caption.replace(/<u>/g, "\\underline{");

    // Remove </u>
    caption = caption.replace(/<\/u>/g, "}");

    // get the display mode option through parenthesis and compile the TeX
    let texCompiledText = caption.replace(regex, (match, doubleDollarMatch, dollarMatch, squareBracketMatch, parenthesisMatch, plainText) => {
        // console.log("captured: ", { doubleDollarMatch, dollarMatch, squareBracketMatch, parenthesisMatch, plainText });
        if (doubleDollarMatch !== undefined) {
            return renderMathToString(findAndReplaceCustomCommandsWithTeX(doubleDollarMatch, subspaceNumber), true);
        } else if (dollarMatch !== undefined) {
            return renderMathToString(findAndReplaceCustomCommandsWithTeX(dollarMatch, subspaceNumber), false);
        } else if (squareBracketMatch !== undefined) {
            return renderMathToString(findAndReplaceCustomCommandsWithTeX(squareBracketMatch, subspaceNumber), true);
        } else if (parenthesisMatch !== undefined) {
            return renderMathToString(findAndReplaceCustomCommandsWithTeX(parenthesisMatch, subspaceNumber), false);
        } else {         
            plainText = checkAndRenderPlainTextWithCustomCommmands(plainText, subspaceNumber);
            plainText = plainText.replace(/\n/g, "<br>");
            return plainText;
        }
    });

    // wrap plan links in <a>
    texCompiledText = wrapPlainLinks(texCompiledText, subspaceNumber);

    // Prepare the ellipsis element as a string if the caption was trimmed
    const ellipsis = isTrimmed ? `<span class="text-ellipsis" data-eventid=${eventID}> &middot;&middot;&middot;</span>` : '';

    if (texCompiledText.startsWith(`<p class="katexContentBlock">`)) {
        return isTrimmed
            ? texCompiledText.replace(/<\/p>$/, `${ellipsis}</p>`)
            : texCompiledText;
    } else {
        return `<p class="katexContentBlock">${texCompiledText}${ellipsis}</p>`;
    }
}


// Construct the regex pattern with custom commands
const pattern = new RegExp(`(?<!\\\\)(\\b(${customCommandsPattern})|\\B(${customSpecialCommandsPattern}))(\\(([^()\\\\]+|\\\\.|\\((?:[^()\\\\]+|\\\\.|\\((?:[^()\\\\]+|\\\\.)*\\))*\\))*\\))(?:(\\(([^()\\\\]+|\\\\.|\\((?:[^()\\\\]+|\\\\.|\\((?:[^()\\\\]+|\\\\.)*\\))*\\))*\\)))?`, 'g')

/**
 * 
 * @param {String} input 
 * @param {Boolean} addDelimiter
 * @param {number|string} subspaceNumber
 * @returns 
 */
function findAndReplaceCustomCommandsWithTeX(input, addDelimiter, subspaceNumber) {
    if (!input)
        return input

    const result = input.replace(pattern, (match, command, textCommand, specialCommand, content1, _, content2) => {
        if (!command)
            return match

        content1 = trimParenthesis(content1)
        content2 = trimParenthesis(content2)

        const processedTeXCommandsWithContent = getTeXCommand(command, content1, content2, subspaceNumber)

        // console.log({ match, command, textCommand, specialCommand, content1, content2, processedTeXCommandsWithContent })

        return addDelimiter ? encloseTeXCommandInDelimiter(command, processedTeXCommandsWithContent) : processedTeXCommandsWithContent
    });

    return result == input ? result : findAndReplaceCustomCommandsWithTeX(result);
}

function checkAndRenderPlainTextWithCustomCommmands(text, subspaceNumber) {
    const newText = findAndReplaceCustomCommandsWithTeX(text, true, subspaceNumber)

    if (newText === text)
        return text

    return fetchTeXCompiledCaption(newText, subspaceNumber);
}

/**
 * 
 * @param {String} text 
 * @returns 
 */
function trimParenthesis(text) {
    if (!text)
        return

    if (text.startsWith("("))
        text = text.slice(1)

    if (text.endsWith(")"))
        text = text.slice(0, text.length - 1)

    return text
}

/**
 * 
 * @param {String} content 
 */
export function processCustomTableContent(content) {
    if (!content)
        return ""

    let rows = content.split("\n")
    let processedContent = rows.map(r => {
        let columns = r.split("|")
        columns = columns.map(c => `\\text{${c}}`)
        return columns.join("& \\quad")
    }).join("\\\\")
    return processedContent
}

export function modifyHref(href) {
    if (href && !href.startsWith('https://')) {

        return 'https://' + href;
    }
    // If the href starts with 'https://' or is empty, return it unchanged
    return href;
}

export function modifyWebsiteName(name) {
    if (!name) return name;

    let newName = name.startsWith('https://www.') ? name.replace('https://www.', '') : name;
    newName = newName.startsWith('https://') ? newName.replace('https://', '') : newName;
    newName = newName.endsWith('/') ? newName.slice(0, -1) : newName;
    let splitted = newName.split("/")
    newName = splitted.length > 3 ? splitted[0] + "/" + splitted[1] + "/" : newName

    return newName;
}

export function wrapPlainLinks(str, subspaceNumber) {
    const urlRegex = /(?<!l\()(?<!href=["'])\bhttps?:\/\/[^\s<"]+/g;

    return str.replace(urlRegex, (match) => {
        return `<a class="plain-link" href="${match}" target="${subspaceNumber == 1 ? "_self" : "_blank"}" style="cursor: pointer;">${match}</a>`;
    });
}
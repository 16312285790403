import $ from 'jquery';
import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import Screen2 from './screen2';
import Screen3 from './screen3';
import Screen1v2 from './Screen1v2';
import { MyProvider } from './context';
import ProtectedPage from './ProtectedPage';
import { useAppContext } from './appContext';
import { pagePasswordConfig } from './config';
import Firstsidebar from './components/Firstsidebar';
import Secondsidebar from './components/Secondsidebar';
import UploadProducerAccounts from './components/uploadProducerAccounts';
import UploadTemporalInformation from './components/UploadTemporalInformation';

const MainContent = ({ setId, visitedPaths, setVisitedPaths, appContext, id }) => {
  return (
    <>
      <div className='allComponentWrapper'>
        <Route render={(props) => (
          <Firstsidebar {...props} appContext={appContext} setVisitedPaths={setVisitedPaths} isSidebarVisible={$(window).width() > 575} />
        )} />
        <div className="itmain">
          <MyProvider>
            <Screen1v2 abc={$(window).width() >= 320 && $(window).width() <= 900 ? id : null} setVisitedPaths={setVisitedPaths} visitedPaths={visitedPaths} isScreenVisible={$(window).width() > 0} />
          </MyProvider>
          <MyProvider>
            <Screen2 appContext={appContext} abc={$(window).width() >= 1701 && $(window).width() <= 2150 ? id : null} resetAbc={() => setId('')} isScreenVisible={!($(window).width() < 1701)} />
          </MyProvider>
          <MyProvider>
            <Screen3 appContext={appContext} abc={$(window).width() >= 2151 ? id : null} resetAbc={() => setId('')} isScreenVisible={!($(window).width() < 2151)} />
          </MyProvider>
        </div>
        <Route render={(props) => (
          <Secondsidebar {...props} appContext={appContext} click_detect={(id) => setId(id)} />
        )} />
      </div>
    </>
  );
};

const App = () => {
  const [id, setId] = useState('');
  const [visitedPaths, setVisitedPaths] = useState([]);
  const appContext = useAppContext();

  return (
    <Router history={createBrowserHistory()}>
      <Switch>
        <Route
          exact
          path="/upload-temporal-information"
          render={() => (
            <ProtectedPage pageId="temporalInformation" config={pagePasswordConfig}>
              <UploadTemporalInformation />
            </ProtectedPage>
          )}
        />

        <Route
          exact
          path="/upload-producer-accounts"
          render={() => (
            <ProtectedPage pageId="producerAccounts" config={pagePasswordConfig}>
              <UploadProducerAccounts />
            </ProtectedPage>
          )}
        />

        <Route
          path="/"
          render={() => (
            <ProtectedPage pageId="dashboard" config={pagePasswordConfig}>
              <MainContent
                setId={setId}
                visitedPaths={visitedPaths}
                setVisitedPaths={setVisitedPaths}
                appContext={appContext}
                id={id}
              />
            </ProtectedPage>
          )}
        />
      </Switch>
    </Router>
  );
};

export default App;

import React, { useContext, useState } from "react";
import {
  useLocation,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useEffect } from "react";
import $ from "jquery";
import { MyContext } from "../context";
import { useAppContext } from "../appContext";
import { routes } from "../utils/constants/routes";
import { pageState } from "../utils/constants/pageState";
import { recentListForLogin, userListForLogin, usersListForGuets, getRecommendationsFromPastVisitsForLogin } from "../Services/api";
import { getProfilesList } from "../utils/profiles/manageProfilesList";

function Screen1v2({ abc, setVisitedPaths, visitedPaths, isScreenVisible }) {
  const context = useContext(MyContext)
  const appContext = useAppContext();
  const [touchStartX, setTouchStartX] = useState(0);
  const SWIPE_THRESHOLD = 50;

  let [routesArray, setRoutesArray] = useState(routes);

  const { sharedData } = context
  const tabWiseData = sharedData.tabWiseData

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const routeMatch = useRouteMatch();
  const routeProps = { location, history, params, routeMatch, setVisitedPaths, visitedPaths, appContext, abc, isScreenVisible};

  useEffect(() => {
    const { sharedData } = context
    const path = location.pathname+location.search;
    
    let isForgroundStateSet = false;
    let isBackgroundStateSet = false;

    const isLoggedInUser = isLoggedIn();
    
    if (sharedData.freshLoad) {
      setInterval(refreshUserDataPeriodically, 3 * 60 * 60 * 1000);
    }

    let updatedArray = routesArray.map((route) => {
      if (!isBackgroundStateSet && route.state == pageState.forground) {
        isBackgroundStateSet = true;
        return { ...route, state: pageState.background };
      }

      const isValidUser = route.loginRequired ? isLoggedInUser : true;

      const newState = isValidUser ? getNewStateForRoute(path, route) : pageState.terminate;

      if (
        (isForgroundStateSet && newState === pageState.forground) ||
        (isBackgroundStateSet && newState === pageState.background)
      ) {
        return route;
      }

      if (!isForgroundStateSet && newState === pageState.forground) {

        if (route.id == "one_4") updateOpenProfilesOnActiveTab(path)

        isForgroundStateSet = true;
      }

      if (!isBackgroundStateSet && newState === pageState.background)
        isBackgroundStateSet = true;
      return { ...route, state: newState };
    });

    setRoutesArray([...updatedArray])
  }, [location?.pathname]);

  useEffect(() => {
    findAndsetLiveComponent();
  }, [routesArray]);

  async function refreshUserDataPeriodically() {
    const isLoggedInUser = localStorage.getItem("timeToken")

    if (isLoggedInUser) {
      recentListForLogin().then((res) => {
        localStorage.setItem("recents", JSON.stringify(res));
      })
    }

    if (isLoggedInUser) {
      getRecommendationsFromPastVisitsForLogin().then((res) => {
        localStorage.setItem("recommendationsFromPastVisits", JSON.stringify(res));
      })
    }

    if (isLoggedInUser) {
      userListForLogin().then((res) => {
        localStorage.setItem("mostVisitedProfiles", JSON.stringify(res));
      })
    } else {
      usersListForGuets().then((res) => {
        localStorage.setItem("mostVisitedProfiles", JSON.stringify(res));
      })
    }

    const entry = await getProfilesList();
  }

  // added this to track the default swipe navigation by browser 
  useEffect(() => {
    const handleTouchStart = (event) => {
      if (event.touches.length === 1) {
        localStorage.setItem('touchStartX', event.touches[0].clientX.toString());
      }
    };

    const handleTouchMove = (event) => {
      if (event.touches.length === 1) {
        const touchStartX = parseFloat(localStorage.getItem('touchStartX') || '0');
        const touchEndX = event.touches[0].clientX;
        const diff = touchStartX - touchEndX;

        if (Math.abs(diff) >= 50) {
          localStorage.setItem('backid', 'swipe');
        }
      }
    };

    const handleTouchEnd = () => {
      localStorage.removeItem('touchStartX');
    };

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
    document.addEventListener('touchend', handleTouchEnd, false);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);
  

  function findAndsetLiveComponent() {
    for (let i = 0; i < routesArray.length; i++) {
      const route = routesArray[i];
      if (route.state === pageState.forground) {
        localStorage.getItem("backid") == "back"
          ? animationlinkback("#" + route.id)
          : animationscript("#" + route.id);
      }
      if (route.state === pageState.background)
        setTimeout(() => hideBackground(), 400);
      if (route.isCloned && route.state !== pageState.forground)
        setTimeout(() => removeClonedRoute(), 600);
    }
  }

  function removeClonedRoute() {
    setRoutesArray((routesArray) =>
      routesArray.filter((route) => !route.isCloned)
    );
  }

  function hideBackground() {
    setRoutesArray((routesArray) =>
      routesArray.map((route) => {
        if (route.state === pageState.background)
          return { ...route, state: pageState.terminate };

        return route;
      })
    );
  }
  function isLoggedIn() {
    return (
      localStorage.getItem("userid") &&
      localStorage.getItem("userid") !== null &&
      localStorage.getItem("userid").trim() !== ""
    );
  }

  function getNewStateForRoute(path, route) {

    if (isPathMatchingRoute(path, route)) {
      // If it's a match, determine the state based on the foreground condition.
      return isForgroundState(route) ? pageState.background : pageState.forground;
    } else {
      // If there's no match, set the state to terminate.
      return pageState.terminate;
    }
  }

  function isPathMatchingRoute(path, route) {
    return route.exactMatch ? path === route.path : pathStartsWithRoute(path, route);
  }

  function isForgroundState(route) {
    return route.state === pageState.forground;
  }

  function pathStartsWithRoute(path, route) {
    return path.startsWith(route.path) && path.length > route.path.length;
  }

  function animationscript(id) {
    const backId = localStorage.getItem('backid');
    const classNames = ['active'];

    if (backId !== 'swipe') {
      classNames.push('fades');
    }
    classNames.push('abc');

    let frontElement = $(id + ".front");
    frontElement.removeClass('fades abc active').addClass(classNames.join(' '));

    var bgElement = $(".newscreen01 .screen1.back").addClass("aactive active");

    setTimeout(function () {
      bgElement.removeClass("active fades back aactive");
      $(".newscreen01 .screen1").removeClass("abc front back fades");
      $(id).addClass("active");
      if (localStorage.getItem('backid') == 'swipe') {
        localStorage.setItem('backid', 'front'); // update the backid after animation
      }
    }, 400);
  }

  function animationlinkback(id) {
    const backId = localStorage.getItem('backid');
    const classNames = ['active'];

    if (backId !== 'swipe') {
      classNames.push('fadesback');
    }
    classNames.push('nabc');

    let frontElement = $(id + ".front");
    frontElement.removeClass('fadesback nabc active').addClass(classNames.join(' '));

    var bgElement = $(".newscreen01 .screen1.back").addClass("aactive active");

    setTimeout(function () {
      bgElement.removeClass("active fadesback back aactive");
      $(".newscreen01 .screen1").removeClass("nabc front back fadesback");
      $(id).addClass("active");
      if (localStorage.getItem('backid') == 'swipe') {
        localStorage.setItem('backid', 'front'); // update the backid after animation
      }
    }, 400);
  }

  return (
    <>
      <div className="newscreen1 newscreen01">
        {routesArray.map((route) => {
          if (route.state === pageState.forground)
            return route.comp({ ...routeProps, pos: "front" })
          else if (route.state === pageState.background)
            return route.comp({ ...routeProps, pos: "back" })
        })}
      </div>
    </>
  );

  async function updateOpenProfilesOnActiveTab(path) {
    const { sharedData, activeTab, setSharedData } = context
    let openProfiles = []
    let activeTabData = sharedData.tabWiseData[activeTab]

    if (sharedData.freshLoad) {
      window.history.pushState({ enteredBy: "pushState" }, "", "/");
      window.history.pushState({ enteredBy: "pushState" }, "", path);
    }

    if (activeTabData && activeTabData.openProfiles)
      openProfiles = activeTabData.openProfiles

    if (openProfiles && openProfiles[openProfiles.length - 1] !== location.pathname) {
      openProfiles.push(location.pathname)
    }
    sharedData.tabWiseData[activeTab] = { ...sharedData.tabWiseData[activeTab], openProfiles }
    setSharedData(() => {
      return {
        ...sharedData,
      }
    });
  }
}

export default Screen1v2;

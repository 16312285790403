const wordReg = /[A-Za-z]/

export function showInitials(name) {
    if (!name)
        return ''

    const words = name.split(' ');

    let initials = words[0][0].toUpperCase();
    initials += getSecondLetter(words);

    return initials
}

export function showInitialOfFullName(name) {
    if (!name) {
        return '';
    }

    let initials = '';
    const words = name.split(' ');
    const wordsToBeRemoved = ['at', 'of', 'and']; 

    words.forEach(word => {
        if (!wordsToBeRemoved.includes(word.toLowerCase())) {
            initials += word[0].toUpperCase();
        }
    });
    
    return initials;
}

/**
 *  
 * @param {[string]} words 
 * @returns 
 */
function getSecondLetter(wordsArray) {
    const words = JSON.parse(JSON.stringify(wordsArray))
    let secondLetter = ''

    if (words.length > 1) {
        secondLetter = words[words.length - 1][0];
        words.pop()
    } else {
        secondLetter = words[0][1];

        if (words[0].length <= 2)
            return secondLetter

        words[0] = words[0].substring(1, words[0].length)
    }

    if (!wordReg.test(secondLetter))
        return getSecondLetter(words)

    return secondLetter
}

export const truncateExcludingLinks = (text, limit = 99) => {
    let result = "";
    let count = 0;
    let lastSpaceIndex = -1;
    let i = 0;
    let isTrimmed = false;

    const processWrapper = (start, open, close) => {
        const openLen = open.length;
        const closeIndex = text.indexOf(close, start + openLen);
        if (closeIndex === -1) {
            return { out: text[start], countAdded: 1, newIndex: start + 1 };
        }
        const innerText = text.slice(start + openLen, closeIndex);
        let counted = innerText.length;
        const remaining = limit - count;
        let truncatedFlag = false;
        let finalInner = innerText;
        if (counted > remaining) {
            finalInner = innerText.slice(0, remaining);
            counted = remaining;
            truncatedFlag = true;
        }
        const newOut = open + finalInner + close;
        return { out: newOut, countAdded: counted, newIndex: closeIndex + close.length, truncated: truncatedFlag };
    };

    const htmlTags = ["<br>", "<div>", "</div>", "<u>", "</u>"];

    while (i < text.length && count < limit) {
        if (text.startsWith("l(", i)) {
            const closingIndex = text.indexOf(")", i);
            if (closingIndex !== -1) {
                result += text.slice(i, closingIndex + 1);
                i = closingIndex + 1;
                continue;
            }
        }

        if (
            text[i] === "(" &&
            text[i + 1] === "!" &&
            text[i + 2] === "("
        ) {
            const { out, countAdded, newIndex, truncated } = processWrapper(i, "(!(", "))");
            result += out;
            count += countAdded;
            i = newIndex;
            if (truncated) {
                isTrimmed = true;
                break;
            }
            continue;
        }

        if (text.startsWith("!(", i)) {
            const { out, countAdded, newIndex, truncated } = processWrapper(i, "!(", ")");
            result += out;
            count += countAdded;
            i = newIndex;
            if (truncated) {
                isTrimmed = true;
                break;
            }
            continue;
        }

        if (text[i] === "<") {
            let foundTag = false;
            for (const tag of htmlTags) {
                if (text.startsWith(tag, i)) {
                    result += tag;
                    i += tag.length;
                    foundTag = true;
                    break;
                }
            }
            if (foundTag) continue;
        }

        if (text[i] === " ") {
            lastSpaceIndex = result.length;
        }
        result += text[i];
        count++;
        i++;
    }

    if (lastSpaceIndex !== -1 && count >= limit) {
        result = result.slice(0, lastSpaceIndex);
        isTrimmed = true;
    }

    if (i < text.length) {
        isTrimmed = true;
    }

    return { text: result, isTrimmed };
};  